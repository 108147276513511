import { Column } from 'react-table';
import { Cell, Header } from 'redesign/table';
import { UserAvatar } from 'redesign/user-avatar/user-avatar';
import {
  ShiftDetailBaseColumnDefinition,
  ShiftDetailCellBaseProps,
} from '../types/shift-detail-tables.types';

export const getShiftDetailsColumnDefinitionBase: () => Column<ShiftDetailBaseColumnDefinition>[] =
  () => {
    const flexWorkerImage: Column<ShiftDetailBaseColumnDefinition> = {
      Header: '',
      accessor: 'flexWorkerImage',
      Cell: FlexWorkerImageCell,
      width: '130px',
    };
    const fw: Column<ShiftDetailBaseColumnDefinition> = {
      Header: () => <Header>Flexwerker</Header>,
      accessor: 'flexWorkerFullName',
      Cell: FlexWorkerCell,
    };

    return [flexWorkerImage, fw];
  };

const FlexWorkerImageCell = ({
  value,
  onProfileClick,
}: ShiftDetailCellBaseProps<'flexWorkerImage'>) => {
  const {
    id,
    firstName,
    lastName,
    imageUrl,
    relation,
    isFlexWorker,
    partnerName,
    hasCandidateLevel,
  } = value;

  let label;
  if (!isFlexWorker) {
    label = partnerName;
  }
  if (hasCandidateLevel) {
    label = 'Candidate';
  }

  return (
    <UserAvatar
      onClick={() => onProfileClick(id, relation)}
      firstName={firstName}
      lastName={lastName}
      imageUrl={imageUrl}
      avatarLabel={label}
    />
  );
};
const FlexWorkerCell = ({ value }: ShiftDetailCellBaseProps<'flexWorkerFullName'>) => {
  return <Cell>{value}</Cell>;
};
