import { LevelTableCellProps } from 'redesign/table';
import { ProjectPlanningProject } from 'hq/services/project-planning-service.types';

export const INITIAL_PAGE = 0;

export type HqProjectPlanningColumnDefinition = ProjectPlanningProject & {
  id: string;
  action: string;
};

export type HqProjectPlanningTableOptions = {
  onShowDetail: (projectId: string) => void;
};

export type HqProjectPlanningCellProp<T extends keyof HqProjectPlanningColumnDefinition> =
  LevelTableCellProps<
    HqProjectPlanningColumnDefinition,
    HqProjectPlanningColumnDefinition[T],
    HqProjectPlanningTableOptions
  >;
