import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconAdd } from 'assets/img';
import { LwButton } from 'redesign/button';
import { GridDateControl } from './grid-date-control/grid-date-control';
import { GridHeadProps } from './planning-toolbox.types';
import { DepartmentSelect } from '../../../components';

export const PlanningToolbox = ({ date, setExportModalOpen }: GridHeadProps) => {
  const classes = getClasses();

  return (
    <Box className={classes.header}>
      <Box>
        <LwButton to="create" color="primary" startIcon={<IconAdd />} iconColorMode="fill">
          Voeg shift toe
        </LwButton>
      </Box>
      <GridDateControl date={date} />
      <Box display="flex" className={classes.buttonSection}>
        <LwButton color="secondary" onClick={() => setExportModalOpen(true)}>
          Export
        </LwButton>
        <DepartmentSelect />
      </Box>
    </Box>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(8),
    gap: theme.spacing(8),
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonSection: {
    gap: theme.spacing(8),
  },
  exportButton: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));
