import { useNavigate, useParams } from 'react-router-dom';
import { userAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useUpdateUser } from 'hq/hooks/queryHooks/users/useUpdateUser';
import { useGetUser } from 'hq/hooks/queryHooks/users/useGetUser';
import { HqUser } from 'types/users/UsersTypes';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { UserForm } from './user-form';
import { HqUserFormContext } from './user-create';
import { useUsersPage } from '../hooks/use-users';
import { useRolesMatrix } from '../../../../hooks/queryHooks/users/useRolesMatrix';
import { RoleMatrix } from '../../../../services/userService';
import { LwButton } from '../../../../../redesign/button';
import { Header } from '../../../../../redesign/header';
import { Page } from '../../../../../redesign/page';
import { Toolbox } from '../../../../../redesign/toolbox';

export const UserEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const userQuery = useGetUser(id);
  const rolesMatrixQuery = useRolesMatrix();

  if (rolesMatrixQuery.status !== 'success') {
    return <RenderUnsettledUI data={rolesMatrixQuery} />;
  }

  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return <UserEditImpl data={userQuery.data} roleMatrix={rolesMatrixQuery.data} />;
};

const UserEditImpl = ({ data, roleMatrix }: { data: HqUser; roleMatrix: RoleMatrix[] }) => {
  const methods = useForm<HqUserFormContext>({ defaultValues: data });
  const { alertSuccess, alertError } = useAlert();
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const { filters } = useUsersPage();

  const updateUser = useUpdateUser();

  const onSubmit = () => {
    methods.handleSubmit((data: HqUser) => {
      updateUser.mutate(
        { userId: data.id, data },
        {
          onSuccess: () => {
            alertSuccess(alerts.success.updated);
            navigate(generatePath(routes.HQUsersDetail, { id: data.id }, filters));
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  };

  const onCancel = () => navigate(-1);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Page
          header={<Header titleText="Nieuwe gebruiker aanmaken" />}
          toolbox={
            <Toolbox>
              <LwButton onClick={onSubmit}>Akkoord</LwButton>
              <LwButton color="secondary" onClick={onCancel}>
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <UserForm state={'create'} roleMatrix={roleMatrix} />
        </Page>
      </form>
    </FormProvider>
  );
};
