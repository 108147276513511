import { Box } from '@mui/material';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'ogp/Routes';
import { flexPoolAlerts as alerts, useAlert } from 'shared/components/alerts';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { FormProvider, useForm } from 'react-hook-form';
import { FlexPoolFormValuesOGP } from 'types/flex-pools/flex-pool-types';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign/button';
import { useCreateFlexPool } from './hooks/use-create-flex-pool';
import { FlexPoolForm } from '../shared/components';

const FlexPoolCreate = () => {
  const generatePath = useCompanyRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<FlexPoolFormValuesOGP>();
  const navigate = useNavigate();
  const mutation = useCreateFlexPool({
    onSuccess: () => {
      alertSuccess(alerts.success.created);
      navigate(generatePath(routes.FlexPools));
    },
    onError: (e) => alertError(e),
  });
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => mutation.mutate(data))();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe flexpool aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={returnTo(routes.FlexPools)}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <Box width={'100%'}>
          <FlexPoolForm state="create" />
        </Box>
      </Page>
    </FormProvider>
  );
};

export { FlexPoolCreate };
