import { LocationAutoSuggestItem } from 'types/geocoding/GeocodingTypes';
import { OgpApiService } from './ogp-api-service';

export class GeocodeService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   *
   * @param address string that needs to be geocoded
   * @returns a location that is geocoded, which includes the accurate address, latitude, and longitude.
   * It is wrapped in an array due to the usage of this within the `AutocompleteElement` component
   */
  geocode = async (address: string, signal?: AbortSignal): Promise<LocationAutoSuggestItem[]> => {
    const result = await this.ogpApiService.get<LocationAutoSuggestItem[]>({
      params: { address },
      path: `geocode`,
      signal,
    });
    return result;
  };
}
