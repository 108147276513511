import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useAlert } from 'shared/components/alerts';
import useGetCheckoutInboxExport from 'ogp/hooks/queryHooks/shiftclaims/useGetCheckoutInboxExport';
import { pluralize } from 'shared/utils/pluralize';
import { useOgpUpdateFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/ogp/use-ogp-update-flex-worker-flex-pools';
import { useChooseFlexWorkerFlexPoolsDialog } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/use-choose-flex-worker-flex-pool-dialog';
import { useOgpGetFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/ogp/use-ogp-flex-worker-flex-pools';
import { useOgpSetNoShow } from 'shared/components/dialogs/no-show-dialog/query-hooks/ogp/use-ogp-set-no-show';
import { useNoShowDialog } from 'shared/components/dialogs/no-show-dialog/use-no-show-dialog';
import { useCallback, useState } from 'react';
import { InboxTableActions } from './components/inbox-table-actions';
import { InboxTableColumnDefinition, ShiftsInboxTableProps } from './helpers/inbox-table.types';
import { InboxTable } from './components/inbox-table';
import { useCounterOfferDialog } from '../../../dialogs/use-counter-offer-dialog';
import { getSettleCheckoutCopy } from '../../../../shared';
import useSettleCheckouts from '../../../hooks/use-settle-checkout';

export const InboxTableWrapper = ({
  data,
  handleSortClick,
  sortState,
  toggleDrawer,
  nrOfActiveFilters,
}: ShiftsInboxTableProps) => {
  const {
    open: openNoShowDialog,
    Element: NoShowDialog,
    setIsFlexWorker: noShowSetIsFlexWorker,
  } = useNoShowDialog(useOgpSetNoShow);
  const { open: openCounterOfferDialog, Element: CounterOfferDialog } = useCounterOfferDialog();
  const { open: openChooseFlexWorkerFlexPoolsDialog, Element: ChooseFlexWorkerFlexPools } =
    useChooseFlexWorkerFlexPoolsDialog(
      useOgpUpdateFlexWorkerFlexPools,
      useOgpGetFlexWorkerFlexPools
    );
  const settleMutation = useSettleCheckouts();
  const exportMutation = useGetCheckoutInboxExport();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onSelectionChange = useCallback((rows: InboxTableColumnDefinition[]) => {
    setSelectedIds(rows.map((i) => i.id));
  }, []);

  const handleSettleClaims = useCallback((ids: string[]) => {
    const { dialog, getResultMessage } = getSettleCheckoutCopy(ids);

    openDialog(
      {
        ...dialog,
        primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
      },
      () => {
        disableDialog();
        settleMutation.mutate(ids, {
          onSuccess: (data) => {
            const message = getResultMessage(data.length);
            setSelectedIds([]);
            alertSuccess(message);
            closeDialog();
          },
          onError: (e) => {
            alertError(e);
          },
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  }, []);

  const handleExport = useCallback((ids: string[]) => {
    const pluralTitleCheckouts = pluralize(ids.length, 'checkout', `${ids.length} checkouts`);
    const title = `Weet je zeker dat je deze ${pluralTitleCheckouts} wilt exporteren?`;

    openDialog(
      {
        title,
        content: `De export wordt als csv bestand opgeslagen`,
        primaryButton: (props) => <PrimaryButton {...props}>Exporteer</PrimaryButton>,
      },
      () => {
        disableDialog();
        exportMutation.mutate(ids, {
          onSuccess: () => {
            const pluralCheckouts = pluralize(
              ids.length,
              'checkout is',
              `${ids.length} checkouts zijn`
            );
            alertSuccess(`De ${pluralCheckouts} geëxporteerd!`);
            closeDialog();
          },
          onError: (e) => {
            alertError(e);
          },
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  }, []);

  return (
    <>
      <InboxTableActions
        selectedClaimIds={selectedIds}
        onOpenExport={handleExport}
        onOpenSettle={handleSettleClaims}
        toggleDrawer={toggleDrawer}
        nrOfActiveFilters={nrOfActiveFilters}
      />
      {CounterOfferDialog}
      {NoShowDialog}
      {ChooseFlexWorkerFlexPools}
      <InboxTable
        openNoShowDialog={openNoShowDialog}
        noShowSetIsFlexWorker={noShowSetIsFlexWorker}
        openChooseFlexWorkerFlexPoolsDialog={openChooseFlexWorkerFlexPoolsDialog}
        openCounterOfferDialog={openCounterOfferDialog}
        data={data}
        handleSortClick={handleSortClick}
        onSelectionChange={onSelectionChange}
        handleSettleClaims={handleSettleClaims}
        sortState={sortState}
      />
    </>
  );
};
