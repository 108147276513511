import { BreakOption, Shift } from 'types/shifts/shift-types';
import { OgpApiService } from './ogp-api-service';
import {
  GetShiftCreateDataResponse,
  GetShiftEligiblePartnerResponse,
  GetShiftPartnerResponse,
  GetShiftPlanningListItemsParams,
  GetShiftPlanningListItemsResponse,
  OGPCreateShiftPayload,
  OGPMultiplyShiftPayload,
  OGPShiftDetailResponse,
  OGPUpdateShiftPayload,
  OpenShiftToPartnersRequest,
} from './shift-service.types';

export class ShiftService {
  constructor(private readonly ogpApiService: OgpApiService) {}
  /**
   * Create a new shift
   */
  createShift = async (shift: OGPCreateShiftPayload) => {
    return await this.ogpApiService.create({
      path: `shifts`,
      body: shift,
      decoder: (json: any) => json.shift as Shift,
    });
  };

  getShiftCreateData = async () => {
    const response = await this.ogpApiService.get<GetShiftCreateDataResponse>({
      path: `shifts/get-shift-create-data`,
    });

    return response;
  };

  getShiftPartners = async (shiftId: string) => {
    const response = await this.ogpApiService.get<GetShiftPartnerResponse>({
      path: `shifts/${shiftId}/partners`,
    });
    return response;
  };

  /**
   * Update an existing shift
   */
  updateShift = async (shift: OGPUpdateShiftPayload) => {
    return await this.ogpApiService.update({
      path: `shifts/${shift.id}`,
      body: shift,
    });
  };

  /**
   * Multiply an existing shift
   */
  multiplyShift = async (params: OGPMultiplyShiftPayload) => {
    return await this.ogpApiService.create({
      path: `shifts/${params.id}/multiply`,
      body: params,
    });
  };

  /**
   * Forward shift to selected partners
   */
  openShiftToPartners = async ({ shiftId, partnerIds }: OpenShiftToPartnersRequest) => {
    return await this.ogpApiService.update({
      path: `shifts/${shiftId}/partners`,
      body: { partnerIds },
    });
  };

  /**
   * Get list of partners the shift can be forwarded to
   */
  getShiftEligiblePartners = async (shiftId: string) => {
    const response = await this.ogpApiService.get<GetShiftEligiblePartnerResponse>({
      path: `shifts/${shiftId}/eligible-partners`,
    });
    return response;
  };

  /**
   * Get the planning overview for shifts
   */
  getShiftPlanningListItems = async (params: GetShiftPlanningListItemsParams) => {
    const response = await this.ogpApiService.get<GetShiftPlanningListItemsResponse>({
      path: 'shifts/planning',
      params,
    });
    return response;
  };

  /**
   * Get a single shift
   */
  getShift = async (shiftId: string) => {
    const response = await this.ogpApiService.get<OGPShiftDetailResponse>({
      path: `shifts/${shiftId}`,
    });
    return response;
  };

  /**
   * Get breakoptions
   */
  getBreakOptions = async () => {
    const response = await this.ogpApiService.get<{ breakOptions: BreakOption[] }>({
      path: `shifts/get-break-options`,
    });

    return response;
  };

  /**
   * Mark a shift as archived in the database when the user
   * wants to delete a shift
   */
  archiveShift = async (shiftId: string) => {
    return await this.ogpApiService.update({
      path: `shifts/${shiftId}/archive`,
    });
  };
}
