import type { Column } from 'react-table';
import SortIcon from 'assets/img/icons/caret-down.svg?react';

import { Box, Button, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { IconCertificateCheck } from 'assets/img';
import {
  AccreditationCellProp,
  AccreditationsColumnDefinition,
  AccreditationTableDefinition,
} from 'types/accreditations/accreditations.types';
import { LevelTableHeaderProps } from 'redesign/table';
import { State, translateState } from './accreditations.types';

const getAccreditationsColumnDefinition = (
  filteredState: State
): Column<AccreditationsColumnDefinition>[] => {
  const flexWorkerName: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Naam flexwerker</Header>,
    accessor: 'flexWorkerName',
    Cell: FlexWorkerNameCell,
  };
  const certificate: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Certificaattype</Header>,
    accessor: 'certificate',
    Cell: CertificateCell,
  };
  const submittedDate: Column<AccreditationsColumnDefinition> = {
    Header: SubmittedDateHeaderCell,
    accessor: 'submittedDate',
    Cell: SubmittedDateCell,
  };

  const state: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Status</Header>,
    accessor: 'state',
    Cell: () => <Typography variant="subtitle2">{translateState(filteredState)}</Typography>,
  };

  const actions: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Actie</Header>,
    accessor: 'actions',
    Cell: ActionCell,
  };
  return [flexWorkerName, certificate, state, submittedDate, actions];
};

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" color="secondary" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const SubmittedDateHeaderCell = (
  cell: LevelTableHeaderProps<AccreditationsColumnDefinition, AccreditationTableDefinition>
) => {
  if (!cell.data[0]) {
    return null;
  }

  return (
    <Button
      variant="text"
      onClick={() => {
        cell.onSortClick();
      }}
    >
      <Header>Datum van toevoeging</Header>
      <StyledSortIcon
        style={{
          transform: cell.sortState === 'asc' ? 'rotate(180deg)' : '',
        }}
      />
    </Button>
  );
};

const FlexWorkerNameCell = (cell: AccreditationCellProp<'flexWorkerName'>) => {
  return (
    <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
      {cell.value}
    </Typography>
  );
};

const CertificateCell = (cell: AccreditationCellProp<'certificate'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const SubmittedDateCell = (cell: AccreditationCellProp<'submittedDate'>) => {
  const date = DateTime.fromISO(cell.value).setLocale('nl');
  return (
    <Typography variant="subtitle2" style={{ marginLeft: '32px' }}>
      {date.toFormat('ccc, dd-LL-yy')}
    </Typography>
  );
};

const ActionCell = (cell: AccreditationCellProp<'actions'>) => {
  return (
    <ActionBox
      onClick={() =>
        cell.onShowDetail(cell.row.original.flexWorkerId, cell.row.original.certificateId)
      }
      role="button"
    >
      <IconCertificateCheck />
      <ActionText>Controleer details</ActionText>
    </ActionBox>
  );
};

const ActionBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}));

const ActionText = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 750,
    textDecoration: 'underline',
    marginLeft: 8,
  },
}));

const StyledSortIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  height: '12px',
  width: '12px',
  transition: 'ease transform .5s',
}));

export { getAccreditationsColumnDefinition };
