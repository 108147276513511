import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { departmentService } from 'ogp/services';
import {
  departmentsResponse2BranchOfficesWithDepartmentsModel,
  departmentsResponse2DepartmentsSelectModel,
} from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { FirstParamOfFn } from 'types/utility';

type Response = Awaited<ReturnType<typeof departmentService.getDepartments>>;

type Params = FirstParamOfFn<typeof useGetDepartments>;

const useGetDepartments = <TData = Response>(
  params: FirstParamOfFn<typeof departmentService.getDepartments>,
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await departmentService.getDepartments(params);

  return useQuery(QUERY_KEYS_OGP.departments(params), queryFn, {
    select,
  });
};

const useGetDepartmentsAsSelect = (params: Params) => {
  const result = useGetDepartments(params, departmentsResponse2DepartmentsSelectModel);
  return result;
};

const useGetDepartmentsAsList = (params: Params) => {
  const result = useGetDepartments(params);
  return result;
};

const useGetDepartmentsAsBlocks = (branchOfficeId?: string) => {
  const queryFn = async () => await departmentService.getDepartmentList(branchOfficeId);

  return useQuery(QUERY_KEYS_OGP.departmentListing(branchOfficeId), {
    queryFn,
    select: (data) => data,
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};

const useGetDepartmentsAsUserRolesList = (params: Params) => {
  const queryFn = async () => await departmentService.getDepartments(params);

  return useQuery(QUERY_KEYS_OGP.departments(params), {
    queryFn,
    select: departmentsResponse2BranchOfficesWithDepartmentsModel,
  });
};

export {
  useGetDepartmentsAsSelect,
  useGetDepartmentsAsList,
  useGetDepartmentsAsBlocks,
  useGetDepartmentsAsUserRolesList,
};
