import React, { useMemo } from 'react';
import { FlexWorkerName } from 'types/flexWorkers/FlexWorkerTypes';

type UseFlexWorkerAutocompleteParams = {
  available: FlexWorkerName[];
  initial?: string[];
};

const useFlexWorkerAutocomplete = ({
  available,
  initial = [],
}: UseFlexWorkerAutocompleteParams) => {
  const [selected, setSelected] = React.useState<string[]>(initial);
  const options = available.map(({ id, fullName }) => ({ id, name: fullName }));

  const reset = () => {
    setSelected([]);
  };

  const optionsFiltered = useMemo(() => {
    return options.filter((option) => !selected.includes(option.id));
  }, [options, selected]);

  return {
    selectedFlexWorkers: selected,
    selectFlexWorkers: setSelected,
    resetFlexWorkers: reset,
    flexWorkerOptions: optionsFiltered,
  };
};

export { useFlexWorkerAutocomplete };
