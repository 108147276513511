import { useNavigate } from 'react-router-dom';
import { partnerWorkerAlerts as alerts, useAlert } from 'shared/components/alerts';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { LwButton } from 'redesign/button';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PARTNER_PATHS } from 'partner/paths';
import { RenderUnsettledUI } from 'shared/components';
import { useParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';
import { PartnerWorkerFormValues } from './partner-worker-form.types';
import { useUpdatePartnerWorker } from './hooks/use-update-partner-worker';
import { PartnerWorkerFormDataProvider } from './partner-worker-form';
import { useGetPartnerWorker } from '../hooks/use-get-partner-worker';

const PartnerWorkerUpdate = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Partner worker ID was supposed to be passed via route param');

  const { status, error, refetch, data } = useGetPartnerWorker(id);
  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }
  return <PartnerWorkerUpdateForm id={id} data={data} />;
};

const PartnerWorkerUpdateForm = ({ id, data }: { id: string; data: PartnerWorkerFormValues }) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = usePartnerRelativePath();
  const mutation = useUpdatePartnerWorker();
  const formMethods = useForm<PartnerWorkerFormValues>({ defaultValues: data });

  const handleSubmit = () => {
    formMethods.handleSubmit((data) =>
      mutation.mutate(
        { partnerWorkerId: id, payload: data },
        {
          onSuccess: () => {
            navigate(generatePath(PARTNER_PATHS.Worker));
            alertSuccess(alerts.success.updated);
          },
          onError: (e) => alertError(e),
        }
      )
    )();
  };

  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutation.status);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Kandidaat bijwerken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(PARTNER_PATHS.Worker))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerWorkerFormDataProvider />
      </Page>
    </FormProvider>
  );
};

export { PartnerWorkerUpdate };
