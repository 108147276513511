import { OgpProjectGetResponse } from 'ogp/services/project-service.types';
import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { PlacementColumnDefinition } from './placements-table.types';

export const getPlacementsTableData = (
  project: OgpProjectGetResponse
): PlacementColumnDefinition[] => {
  const activePlacements = project.placements.filter((placement) => placement.state === 'active');

  const result = activePlacements.map((placement) => {
    return {
      flexWorker: placement.flexWorker.fullName,
      email: placement.flexWorker.email,
      phone: placement.flexWorker.phone,
      flexWorkerAvatar: {
        id: placement.flexWorker.id,
        firstName: placement.flexWorker.firstName,
        lastName: placement.flexWorker.lastName,
        infix: placement.flexWorker.infix,
        fullName: placement.flexWorker.fullName,
        relation: {
          id: placement.id,
          relation: RelationType.PLACEMENT,
        },
        imageUrl: placement.flexWorker.imageUrl,
      },
      actions: { placement, project },
    };
  });
  return result;
};
