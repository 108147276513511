import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { CompanyData } from 'types/companies/company-types';
import { companyService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

type CompanyMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  Omit<CompanyData, 'isTempworkingCompany'>
>;

export const useCreateCompany = (options?: CompanyMutationOptions) => {
  const mutationFn = async (company: Omit<CompanyData, 'isTempworkingCompany'>) =>
    await companyService.createCompany(company);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: CompanyMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.companies());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
