import { Box, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useGetShiftClaimExport } from 'ogp/hooks/queryHooks/shiftclaims/useGetShiftClaimExport';
import { useGetShiftClaimExportFilters } from 'ogp/hooks/queryHooks/shiftclaims/useGetShiftClaimExportFilters';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { LwFormDate } from 'redesign/forms/date';
import { FormCheckbox } from 'redesign/forms/checkbox-group';
import { LwIconButton } from 'redesign/icon-button';
import { IconCross } from 'assets/img';
import { LwButton } from 'redesign/button';
import { OGPShiftClaimExportFilterResponse } from 'ogp/services/shift-claim-service.types';
import { ExportFilters, ExportModalProps } from './export-drawer.types';

export const ExportModal = ({
  exportModalOpen,
  setExportModalOpen,
  startDate,
  endDate,
}: ExportModalProps) => {
  const theme = useTheme();
  const { alertSuccess, alertError } = useAlert();
  const { handleSubmit, watch, control, setValue, getValues, reset } = useForm<ExportFilters>();
  const classes = getClasses();
  const exportFiltersQuery = useGetShiftClaimExportFilters();

  const today = new Date().toISOString().split('T')[0];
  const mutation = useGetShiftClaimExport({
    onSuccess: () => alertSuccess(alerts.success.downloadExport),
    onError: (e) => alertError(e),
  });

  useEffect(() => {
    setValue('fromDate', startDate.toFormat('yyyy-LL-dd'));
    setValue('toDate', endDate.toFormat('yyyy-LL-dd'));
  }, [exportModalOpen, startDate, endDate, setValue]);

  const disabled =
    (!watch('fromDate') && !watch('toDate')) ||
    (Object.values(watch('branchOfficeIds') ?? {}).every((x) => !x) &&
      Object.values(watch('jobIds') ?? {}).every((x) => !x));

  function onSubmit(value: ExportFilters) {
    const { fromDate, branchOfficeIds, toDate, jobIds } = value;
    const filterData = {
      fromDate,
      toDate,
      branchOfficeIds: Object.keys(branchOfficeIds).filter((x) => branchOfficeIds[x]),
      jobIds: Object.keys(jobIds).filter((x) => jobIds[x]),
    };

    mutation.mutate(filterData);
  }

  function clearForm() {
    reset();
  }

  function onBranchOfficeCheck(value: boolean, filter: OGPShiftClaimExportFilterResponse) {
    filter.jobs.forEach((job) => setValue(`jobIds.${job.id}`, value));
  }

  function onJobCheck(filter: OGPShiftClaimExportFilterResponse) {
    // Await the state change before checking all current jobId values
    setTimeout(() =>
      setValue(
        `branchOfficeIds.${filter.id}`,
        filter.jobs.every((job) => getValues(`jobIds.${job.id}`))
      )
    );
  }

  return (
    <Drawer
      anchor="right"
      open={exportModalOpen}
      onClose={() => setExportModalOpen(false)}
      ModalProps={{ style: { zIndex: theme.zIndex.appBar - 1 } }}
      classes={{ paper: classes.drawerPaper }}
    >
      {exportFiltersQuery.status !== 'success' ? (
        <RenderUnsettledUI data={exportFiltersQuery} />
      ) : (
        <Box className={classes.drawerContent}>
          <Box className={classes.head}>
            <Typography variant="h3">Exporteer shifts</Typography>
            <LwIconButton
              iconColorMode="stroke"
              color="secondary"
              onClick={() => setExportModalOpen(false)}
            >
              <IconCross />
            </LwIconButton>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Box className={classes.formFields}>
              <Box className={classes.formDates}>
                <LwFormDate
                  name="fromDate"
                  label="Vanaf"
                  control={control}
                  maxDate={watch('toDate') || today}
                  className={classes.date}
                />
                <LwFormDate
                  name="toDate"
                  label="Tot"
                  control={control}
                  minDate={watch('fromDate')}
                  className={classes.date}
                />
              </Box>
              {exportFiltersQuery.data.map((branchOffice) =>
                branchOffice.jobs.length === 0 ? null : (
                  <Box className={classes.branchOffice} key={branchOffice.id}>
                    <FormCheckbox
                      key={branchOffice.id}
                      label={branchOffice.name}
                      className={classes.branchOfficeCheckbox}
                      control={control}
                      name={`branchOfficeIds.${branchOffice.id}`}
                      onChange={(value: boolean) => onBranchOfficeCheck(value, branchOffice)}
                    />
                    <Box className={classes.branchOfficeJobs} id={branchOffice.id}>
                      {branchOffice.jobs.map((job) => (
                        <FormCheckbox
                          className={classes.jobCheckbox}
                          key={job.id}
                          label={job.label}
                          control={control}
                          name={`jobIds.${job.id}`}
                          onChange={() => onJobCheck(branchOffice)}
                        />
                      ))}
                    </Box>
                  </Box>
                )
              )}
            </Box>
            <Box className={classes.actions}>
              <LwButton color="primary" type="submit" disabled={disabled}>
                Download
              </LwButton>
              <LwButton color="secondary" onClick={clearForm}>
                Wissen
              </LwButton>
            </Box>
          </form>
        </Box>
      )}
      ;
    </Drawer>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  drawerContent: {
    width: theme.spacing(100),
    height: '100%',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(8, 8, 6),
  },
  form: {
    height: 'calc(100% - 112px)', // head height + margin bottom
  },
  formDates: {
    display: 'flex',
    gap: '14px',
  },
  formFields: {
    padding: theme.spacing(2, 4, 2, 8),
    height: 'calc(100% - 105px)', // form actions height
    overflowY: 'scroll',
  },
  branchOffice: {
    marginTop: theme.spacing(4),
    '& span': {
      fontWeight: 'bold',
    },
  },
  branchOfficeCheckbox: {
    margin: theme.spacing(1, 0),
  },
  branchOfficeJobs: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(10),
    '& span': {
      fontWeight: 'normal',
    },
  },
  jobCheckbox: {
    margin: theme.spacing(1, 0),
  },
  date: {
    width: '160px',
  },
  drawerPaper: {
    zIndex: 1,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(4, 8, 8),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    background: 'white',
  },
}));
