import { Box, styled, tabClasses, TabProps, Tabs } from '@mui/material';
import { useGetFlexWorkerProfile } from 'hq/hooks/queryHooks/flexWorkers/use-get-flex-worker-profile';
import { RenderUnsettledUI } from 'shared/components';
import { ModalPanelTabType } from 'redesign/modal';
import { useState } from 'react';
import { ProfileAbout } from '../about/profile-about';
import { ProfileSectorPreferences } from '../sector-preferences/profile-sector-preferences';
import { ProfileAccreditations } from '../accreditations/profile-accreditations';
import { ProfileSkills } from '../skills/profile-skills';
import { ProfileOthers } from '../others/profile-others';
import { ProfileWorkExperienceTab } from '../../../../../../../../shared/components/common/work-experience/work-experience-tab';
import { LwTab } from '../../../../../../../../redesign/tab';

type FlexWorkerProfileProps = {
  flexWorkerId: string;
};

const FlexWorkerProfile = ({ flexWorkerId }: FlexWorkerProfileProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { refetch, status, data, error } = useGetFlexWorkerProfile(flexWorkerId);

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ refetch, status, error }} />;
  }

  const options: ModalPanelTabType[] = [
    {
      id: 'About',
      tabLabel: 'Over',
      element: <ProfileAbout data={data} />,
    },
    {
      id: 'Preferences',
      tabLabel: 'Sectorvoorkeuren',
      element: <ProfileSectorPreferences data={data} />,
    },
    {
      id: 'Certificate',
      tabLabel: 'Certificaten',
      element: <ProfileAccreditations flexWorkerId={flexWorkerId} />,
    },
    {
      id: 'WorkExperience',
      tabLabel: 'Werkervaring',
      element: <ProfileWorkExperienceTab workExperiences={data.workExperiences} />,
    },
    {
      id: 'Skills',
      tabLabel: 'Vaardigheden',
      element: <ProfileSkills mappedSkills={data.mappedSkills} />,
    },
    {
      id: 'Overig',
      tabLabel: 'Overig',
      element: <ProfileOthers mappedSkills={data.mappedSkills} />,
    },
  ];

  return (
    <Box
      marginTop={2}
      marginLeft={2}
      display="flex"
      flexDirection="column"
      height="100%"
      minHeight={0}
      flex="0 1 auto"
    >
      <StyledTabs
        value={options[selectedTab].id}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        onChange={(_, tabId) => {
          const selectedTab = options.findIndex((i) => i.id === tabId);
          setSelectedTab(selectedTab);
        }}
        aria-label="icon label tabs example"
      >
        {options.map((tab) => (
          <StyledTab key={tab.id} value={tab.id} label={tab.tabLabel} />
        ))}
      </StyledTabs>
      <Box role="tabpanel">{options[selectedTab].element}</Box>
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    gap: theme.spacing(4),
  },
}));

const StyledTab = styled(LwTab)<TabProps>(() => ({
  [`&.${tabClasses.root}`]: {
    color: 'black',
    border: `1px solid rgba(0, 0, 0, 0.20)`,
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgba(0, 0, 0, 0.20)',
      border: `1px solid rgba(0, 0, 0, 0.10)`,
    },
  },
  [`&.${tabClasses.selected}`]: {
    color: '#000',
    backgroundColor: 'rgba(0, 0, 0, 0.20)',
    border: '1px solid transparent',
  },
  [`&.${tabClasses.disabled}`]: {
    color: 'rgba(0, 0, 0, 0.40)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: `1px solid rgba(0, 0, 0, 0.20)`,
  },
}));

export { FlexWorkerProfile };
