import { Box } from '@mui/material';
import { useGetFlexWorkers } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorkers';
import { useCallback, useMemo } from 'react';
import { FlexWorkersTable } from './flex-workers-table';
import { FlexWorkerPagination } from './flex-worker-pagination';
import { FlexWorkerToolbox } from './flex-worker-toolbox/flex-worker-toolbox';
import {
  FLEX_WORKERS_INITIAL_PARAMS,
  FlexWorkerParams,
  SetFlexWorkerParams,
} from './flex-workers.types';
import { FlexWorkerDetail } from './flex-worker-detail/flex-worker-detail';
import { Page } from '../../../../redesign/page';
import { Header } from '../../../../redesign/header';
import { Toolbox } from '../../../../redesign/toolbox';
import { useExternalParams } from '../../../../shared/hooks';
import { LWModal } from '../../../../redesign/modal';
import { RenderUnsettledUI } from '../../../../shared/components';
import { PaginatedCollection } from '../../../../types/Pagination';
import {
  FlexWorkerFilterCounts,
  FlexWorkerListItem,
} from '../../../../types/flexWorkers/FlexWorkerTypes';

const FlexWorkers = () => {
  const [params, setParams] = useExternalParams(FLEX_WORKERS_INITIAL_PARAMS);

  const flexWorkersQuery = useGetFlexWorkers({
    level: params.level as FlexWorkerParams['level'],
    employmentType: params.employmentType as FlexWorkerParams['employmentType'],
    disabled: params.disabled as FlexWorkerParams['disabled'],
    filter: params.filter,
    page: params.page as number,
    size: params.size as number,
  });

  if (flexWorkersQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexWorkersQuery} />;
  }

  return (
    <FlexWorkersImpl
      params={params as FlexWorkerParams}
      setParams={setParams}
      data={flexWorkersQuery.data}
    />
  );
};

const FlexWorkersImpl = ({
  params,
  setParams,
  data,
}: {
  params: FlexWorkerParams;
  setParams: SetFlexWorkerParams;
  data: PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts;
}) => {
  const pagination = useMemo(
    () => ({
      page: params.page as number,
      setPage: (page: number) => setParams({ page }),
      setSize: (size: number) => setParams({ size }),
      size: params.size as number,
    }),
    [params]
  );

  const closeModal = useCallback(() => setParams({ flexWorkerId: undefined }), []);

  return (
    <>
      <Page
        header={<Header titleText="Flexwerkers" />}
        toolbox={
          <Toolbox>
            <FlexWorkerToolbox params={params} setParams={setParams} data={data} />
          </Toolbox>
        }
      >
        <Box display="flex" width="100%" maxHeight={'100%'} flexDirection={'column'}>
          <Box>
            <FlexWorkersTable params={params} setParams={setParams} flexWorkersData={data} />
          </Box>
          <Box mt="auto">
            <FlexWorkerPagination data={data} pagination={pagination} />
          </Box>
        </Box>
      </Page>

      <LWModal isOpen={!!params.flexWorkerId} onClose={closeModal} testId="detail-modal">
        {params.flexWorkerId ? (
          <FlexWorkerDetail id={params.flexWorkerId} onClose={closeModal} />
        ) : null}
      </LWModal>
    </>
  );
};

export { FlexWorkers };
