import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { projectPlanningService } from 'hq/services';
import { useInfiniteQuery } from 'react-query';
import { FirstParamOfFn } from 'types/utility';

const useGetProjectPlanningProjects = (
  params: FirstParamOfFn<typeof projectPlanningService.getProjectPlanningProjects>
) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_HQ.projectPlanningProjectsFilters(params),
    queryFn: async ({ pageParam = 0 }) =>
      await projectPlanningService.getProjectPlanningProjects({
        pageParam,
        toDate: params.toDate,
        pageSizeParam: params.pageSizeParam,
        company: params.company,
        branchOffice: params.branchOffice,
        department: params.department,
        jobType: params.jobType,
        filledStatus: params.filledStatus,
        hasApplicants: params.hasApplicants,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
export { useGetProjectPlanningProjects };
