import { Box, Typography } from '@mui/material';
import { DefaultTheme, styled } from '@mui/styles';
import IconCross from 'assets/img/icons/cross.svg?react';
import {
  IconApproved,
  IconCalendar,
  IconCandidates,
  IconCircle,
  IconEdit,
  IconMail,
  IconViewed,
} from 'assets/img';
import { LwIconButton } from 'redesign/icon-button';
import { ContextMenuButton, ContextMenuItem } from 'redesign/context-menu';
import { GetPartnerInboxMessageResponse } from 'partner/shared/services/partner-inbox-service.types';
import { useCallback } from 'react';
import { useMarkMessageAsUnread } from '../../hooks/use-mark-message-as-unread';
import { useAlert } from '../../../../../../shared/components/alerts';

type PartnerMessageHeadProps = {
  data: GetPartnerInboxMessageResponse & { id: string };
  close: () => void;
};

export const PartnerMessageDetailModalHead = ({ data, close }: PartnerMessageHeadProps) => {
  const { alertError } = useAlert();
  const mutation = useMarkMessageAsUnread();
  const onMenuClick = useCallback(() => {
    mutation.mutate({ messageId: data.id }, { onSuccess: close, onError: (e) => alertError(e) });
  }, [alertError, close, data.id, mutation]);
  return (
    <Box mb={8}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column">
          <Typography noWrap variant="h5" mt={2}>
            {data.messageTitle}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" ml="auto" alignItems="center" gap={8}>
          <Box
            display="flex"
            flexDirection="row"
            ml="auto"
            alignItems="middle"
            gap="32px"
            data-testid="partner-message-details-modal-mark-as-not-read"
          >
            <ContextMenuButton size="medium" color="secondary">
              <ContextMenuItem onClick={onMenuClick}>
                <IconEdit /> Markeer as niet gelezen
              </ContextMenuItem>
            </ContextMenuButton>
          </Box>
          <LwIconButton
            iconColorMode="stroke"
            color="secondary"
            onClick={close}
            data-testid="partner-message-details-modal-close"
          >
            <IconCross width={24} height={24} />
          </LwIconButton>
        </Box>
      </Box>
      <Box display="flex" gap="32px" mt={5} ml={2} flexDirection="row">
        <Box display="flex" flexDirection="column" flex={1} gap="16px">
          <Box display="flex">
            <CandidatesIcon />
            <Typography noWrap variant="subtitle2">
              {data.senderName}
            </Typography>
          </Box>
          <Box display="flex">
            <CalendarIcon />
            <Typography noWrap variant="subtitle2">
              {data.createdAt}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} gap="16px">
          <Box display="flex">
            <MailIcon />
            <Typography noWrap variant="subtitle2">
              {data.senderEmail}
            </Typography>
          </Box>
          <Box display="flex">
            {data.isTaskDone ? <TaskDoneIcon /> : <TaskToDoIcon />}
            <Typography noWrap variant="subtitle2">
              {data.isTaskDone ? 'Voltooid' : 'Te doen'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} gap="16px">
          <Box display="flex">
            <ViewedIcon />
            <Typography noWrap variant="subtitle2">
              {data.isRead ? 'Gelezen' : 'Ongelezen'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const getIconStyles = (theme: DefaultTheme) => ({
  opacity: 0.6,
  marginRight: theme.spacing(2),
  flexShrink: 0,
  width: '20px',
  height: '20px',
});

const MailIcon = styled(IconMail)(({ theme }) => getIconStyles(theme));
const CandidatesIcon = styled(IconCandidates)(({ theme }) => getIconStyles(theme));
const TaskDoneIcon = styled(IconApproved)(({ theme }) => getIconStyles(theme));
const TaskToDoIcon = styled(IconCircle)(({ theme }) => getIconStyles(theme));
const CalendarIcon = styled(IconCalendar)(({ theme }) => getIconStyles(theme));
const ViewedIcon = styled(IconViewed)(({ theme }) => getIconStyles(theme));
