import { Box, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconFilter } from 'assets/img';
import { LwButton } from 'redesign/button';

type Props = {
  nrOfActiveFilters: number;
  showSpinner?: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const FilterButton = ({
  onClick,
  nrOfActiveFilters,
  showSpinner = false,
  disabled = false,
}: Props) => {
  const classes = getClasses();

  return (
    <Box>
      <LwButton
        disabled={disabled}
        startIcon={showSpinner ? <CircularProgress size={24} /> : <IconFilter />}
        onClick={onClick}
        color="secondary"
        endIcon={
          nrOfActiveFilters ? (
            <span className={classes.indicator} aria-label="number-of-active-filters">
              {nrOfActiveFilters}
            </span>
          ) : null
        }
      >
        Filter
      </LwButton>
    </Box>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  indicator: {
    fontSize: '12px !important',
    background: '#eee',
    borderRadius: theme.spacing(5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0, 2),
  },
}));

export { FilterButton };
