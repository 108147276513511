import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftClaimService } from 'ogp/services';
import { useQuery } from 'react-query';
import { PaginatedCollection } from 'types/Pagination';
import { ClaimPendingExtended } from '../shared/types/checkouts.types';

export const useGetShiftClaims = (
  params: Parameters<typeof shiftClaimService.getCheckoutsInbox>[0]
) => {
  const queryFn = async () => await shiftClaimService.getCheckoutsInbox(params);

  return useQuery<PaginatedCollection<ClaimPendingExtended>, Error>(
    QUERY_KEYS_OGP.checkoutsPending(params),
    queryFn,
    {
      // No caching here because this data is highly volatile. Showing old data is very undesirable.
      // This is needed for pagination. We want to keep showing the previous data when fetching the new page.
      keepPreviousData: true,
    }
  );
};
