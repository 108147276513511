import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { Alert as MaterialAlert } from '@mui/material';
import { IconAdd, IconApproved, IconError } from 'assets/img';
import { WrapMeWhen } from 'ogp/components/common/wrap-me-when';
import { ExtendedError } from 'shared/services/abstractApiService';
import { useAlertProvider } from './alert-provider';
import { Alert, ErrorLikeAlert } from './alert-provider.types';

const AlertContainer = () => {
  const styles = getStyles();
  const { alert, closeAlert } = useAlertProvider();

  return (
    <Snackbar open={!!alert} autoHideDuration={5000} onClose={closeAlert}>
      {alert ? (
        <MaterialAlert
          iconMapping={{
            error: (
              <AlertSeverityIcon className={styles.alertError}>
                <IconError />
              </AlertSeverityIcon>
            ),
            success: (
              <AlertSeverityIcon className={styles.alertSuccess}>
                <IconApproved />
              </AlertSeverityIcon>
            ),
          }}
          severity={alert.severity}
          className={styles.alert}
        >
          <WrapMeWhen
            when={isErrorLikeAlert(alert)}
            wrapper={(children) => (
              <AlertError error={(alert as ErrorLikeAlert).error}>{children}</AlertError>
            )}
          >
            <strong style={{ marginLeft: '18px' }}>{alert.message}</strong>
          </WrapMeWhen>
          <IconButton
            style={{ marginLeft: '16px' }}
            aria-label="hide alert"
            size="small"
            onClick={closeAlert}
          >
            <IconAdd style={{ transform: 'rotate(-45deg)' }} />
          </IconButton>
        </MaterialAlert>
      ) : undefined}
    </Snackbar>
  );
};

const AlertSeverityIcon = ({
  children,
  ...props
}: React.PropsWithChildren<Pick<React.ComponentProps<typeof Box>, 'className'>>) => {
  return (
    <Box
      {...props}
      component="span"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        borderRadius: '50%',
        width: '48px',
        height: '48px',
      }}
    >
      {children}
    </Box>
  );
};

const AlertError = ({
  error,
  children,
}: React.PropsWithChildren<{ error: ErrorLikeAlert['error'] }>) => {
  if (!(error instanceof ExtendedError)) {
    return <>{children}</>;
  }

  return (
    <Box display="flex" flexDirection="column" style={{ gap: '8px' }}>
      <Typography variant="h3">
        <strong style={{ marginLeft: '18px' }}>{error.title}</strong>
      </Typography>
      {children}
    </Box>
  );
};

const isErrorLikeAlert = (alert: Alert): alert is ErrorLikeAlert => alert.severity === 'error';

const getStyles = makeStyles({
  alert: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiAlert-message': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  alertError: {
    background: 'rgba(204, 68, 0, 0.2)',
    '& path': {
      stroke: '#CC4400',
    },
  },

  alertSuccess: {
    background: 'rgba(0, 204, 136, 0.2)',
    '& path': {
      stroke: '#00CC88',
    },
  },
});

export { AlertContainer };
