import React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import { Control, useFormContext } from 'react-hook-form';
import { LwFormDate } from 'redesign/forms/date/form-date';
import { LwFormInput } from 'redesign/forms/input';
import { IconTrash } from 'assets/img';
import { ItemOf, TakeDefined } from 'types/utility';
import { LwIconButton } from 'redesign/icon-button';
import { BillingEntityFormFields } from './billing-entity-form.types';
import { feesLabels } from '../../utils/utils';

type FeeItemProps = {
  control: Control<BillingEntityFormFields>;
  fee: ItemOf<TakeDefined<BillingEntityFormFields['fees']>['platform']>;
  index: number;
  isEndDateRequired: boolean;
  onRemove?: (index: number) => void;
};

const FeeItem = ({ fee, onRemove, index, control, isEndDateRequired }: FeeItemProps) => {
  const { watch } = useFormContext<BillingEntityFormFields>();
  const classes = getClasses();
  const label = `${feesLabels[fee.feeType]} #${index + 1}`;
  const feeTypeIndex = `${fee.feeType}.${index}` as const;
  const { isExistingFee, isEndDateDisabled } = useDisabledFields(fee);

  return (
    <>
      <Grid container className={classes.feesWrapper}>
        <Grid item xs={3}>
          <LwFormInput
            disabled={isExistingFee}
            label={label}
            control={control}
            name={`fees.${feeTypeIndex}.hourlyRate`}
            rules={{
              required: 'Voer een fee in',
              min: {
                value: 0.01,
                message: 'Fee moet meer zijn dan €0',
              },
            }}
          />
        </Grid>
        {onRemove ? (
          <Grid container item xs={3}>
            <LwIconButton
              iconColorMode="stroke"
              color="secondary"
              className={classes.removeButton}
              onClick={() => onRemove(index)}
              data-testid={`remove-fee-${index}`}
            >
              <IconTrash />
            </LwIconButton>
          </Grid>
        ) : null}
      </Grid>
      <Grid container className={classes.feesWrapper}>
        <Grid item xs={3}>
          <LwFormDate
            disabled={isExistingFee}
            label="Startdatum"
            control={control}
            rules={{
              validate: {
                dateComparison: () => {
                  const startDate = watch(`fees.${feeTypeIndex}.startDate`);
                  const endDate = watch(`fees.${feeTypeIndex}.endDate`);
                  const startDateTime = DateTime.fromISO(startDate);
                  const endDateTime = endDate && DateTime.fromISO(endDate);
                  if (endDateTime && !isDateBefore(startDateTime, endDateTime)) {
                    return 'De startdatum moet voor de einddatum liggen';
                  }
                },
              },
              required: 'Voer een startdatum in',
            }}
            name={`fees.${feeTypeIndex}.startDate`}
            minDate={DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}
            maxDate={watch(`fees.${feeTypeIndex}.endDate`) ?? undefined}
          />
        </Grid>
        <Grid item xs={3}>
          <LwFormDate
            disabled={isEndDateDisabled}
            label="Einddatum"
            control={control}
            name={`fees.${feeTypeIndex}.endDate`}
            rules={isEndDateRequired ? { required: 'Voer een einddatum in' } : {}}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useDisabledFields = (fee: FeeItemProps['fee']) => {
  const initialEndDate = React.useRef(
    fee.endDate ? DateTime.fromFormat(fee.endDate, 'yyyy-MM-dd') : undefined
  );
  const isExistingFee = !!fee.id;
  const isEndDateDisabled =
    isExistingFee && initialEndDate.current && initialEndDate.current < DateTime.now();

  return { isExistingFee, isEndDateDisabled };
};

const isDateBefore = (startDate: DateTime, endDate: DateTime) => {
  const luxonStartDate = startDate.startOf('day');
  const luxonEndDate = endDate.startOf('day');
  return luxonStartDate <= luxonEndDate;
};

const getClasses = makeStyles((theme: Theme) => ({
  feesWrapper: {
    gap: theme.spacing(8),
  },
  removeButton: {
    margin: 'auto',
  },
}));

export { FeeItem };
