import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { LwButton } from '../../../redesign/button';

export const FilterWrapper = ({
  onCancel,
  onClear,
  onSubmit,
  filterName,
  children,
}: {
  onCancel: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onClear: () => void;
  filterName: string;
  children: React.ReactNode[] | React.ReactNode | undefined;
}) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <form onSubmit={onSubmit} style={{ height: '100%' }}>
        <FilterForm>
          <Typography variant="h2" mb={4}>
            {filterName}
          </Typography>
          <Box>{children}</Box>
        </FilterForm>
        <FilterActions>
          <Box flexBasis="100%">
            <LwButton color="primary" type="submit">
              Filter
            </LwButton>
          </Box>
          <LwButton color="secondary" onClick={onCancel}>
            Terug
          </LwButton>
          <LwButton color="secondary" onClick={onClear}>
            Filters wissen
          </LwButton>
        </FilterActions>
      </form>
    </Box>
  );
};

const FilterForm = styled(Box)(({ theme }) => ({
  width: theme.spacing(90),
  padding: theme.spacing(4),
  overflow: 'auto',
  flex: 1,
  height: 'calc(100% - 90px)',
  display: 'flex',
  flexDirection: 'column',
}));

const FilterActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginTop: 'auto',
}));
