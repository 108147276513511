import { Box, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { WorkExperience } from '../../../../types/flexWorkers/FlexWorkerTypes';

const WorkExperienceBox = ({ experience }: { experience: WorkExperience }) => {
  return (
    <Box key={experience.companyName} data-testid="work-experience">
      <StyledJobType>{experience.jobType}</StyledJobType>
      <Box>
        <StyledCompanyName>{experience.companyName}</StyledCompanyName>
        <StyledDateRange>
          {DateTime.fromISO(experience.isoStartDateTime).toFormat('LLLL yyyy')} -{' '}
          {experience.isoEndDateTime
            ? DateTime.fromISO(experience.isoEndDateTime).toFormat('LLLL yyyy')
            : 'Heden'}
        </StyledDateRange>
      </Box>
      <StyledDescription variant="body1">{experience.description}</StyledDescription>
    </Box>
  );
};

const StyledJobType = styled('h3')(({ theme }) => ({
  color: theme.palette.grey[900],
  fontSize: '24px',
  fontWeight: 600,
}));

const StyledCompanyName = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(3),
  fontWeight: 500,
}));

const StyledDateRange = styled('span')(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export { WorkExperienceBox };
