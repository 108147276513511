import { EmploymentType } from 'types/flexWorkers/FlexWorkerTypes';
import { Grid } from '@mui/material';
import React from 'react';
import { TakeDefined } from 'types/utility';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { useGetFreelanceJobFormData } from './use-get-freelance-job-form-elements';
import { useGetTempWorkJobFormData } from './use-get-temp-work-job-form-elements';
import { JobFormState } from './use-get-shared-job-form-elements';

export const JobForm = ({
  employmentType,
  jobCreateData,
  formState,
}: {
  employmentType: EmploymentType;
  jobCreateData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>;
  formState: JobFormState;
}) => {
  const hook =
    employmentType === 'TEMPWORKER' ? useGetTempWorkJobFormData : useGetFreelanceJobFormData;
  const inputsToShow = hook(formState, jobCreateData);

  return (
    <form style={{ width: '100%' }} aria-label="Job form">
      <Grid container item xs={6} spacing={4}>
        {Object.entries(inputsToShow).map(([key, node]) => (
          <React.Fragment key={key}>{node}</React.Fragment>
        ))}
      </Grid>
    </form>
  );
};
