import type { Column } from 'react-table';
import { Box, styled, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatCurrencyCents, formatDuration, formatTime } from 'shared/utils/formatting';
import { DateTime } from 'luxon';
import SortIcon from 'assets/img/icons/caret-down.svg?react';
import { Cell, Header, LevelTableHeaderProps } from 'redesign/table';
import { LwButton } from 'redesign/button';
import {
  CheckoutsCellProp,
  CheckoutsColumnBase,
  CheckoutsTableOptions,
} from './get-checkouts-column-definition-base.types';
import { getShiftDurationInMs } from '../utils/get-shift-duration-in-ms';
import { mapExpenseTypeToDescription } from '../../../../../../../shared/utils/constants';

const getCheckoutsColumnDefinitionBase: (
  allowExpenses: boolean
) => Record<
  'fw' | 'job' | 'date' | 'scheduled' | 'timeDeviationAndBreak' | 'expense',
  Column<CheckoutsColumnBase>
> = (allowExpenses) => {
  return {
    fw: {
      Header: () => (
        <Typography variant="body2" color="secondary">
          Worker
        </Typography>
      ),
      accessor: 'worker',
      Cell: WorkerCell,
    },
    job: {
      Header: JobHeaderCell,
      accessor: 'job',
      Cell: JobCell,
    },
    scheduled: {
      Header: () => (
        <>
          <Typography variant="body2" color="secondary">
            Geplande tijden
          </Typography>
          <Typography variant="body2" color="secondary">
            Geplande uren
          </Typography>
        </>
      ),
      accessor: 'scheduledTime',
      Cell: ScheduledTimesCell,
    },
    timeDeviationAndBreak: {
      accessor: 'scheduledVsWorkedDiffAndBreak',
      Header: () => (
        <>
          <Typography variant="body2" color="secondary">
            Afwijking
          </Typography>
          <Typography variant="body2" color="secondary">
            Pauze
          </Typography>
        </>
      ),
      Cell: TimeDeviationBreakCell,
    },
    date: {
      Header: DateHeaderCell,
      accessor: 'date',
      Cell: DateCell,
    },
    expense: {
      Header: () => <Header>Kosten</Header>,
      accessor: 'expense',
      Cell: (cell: CheckoutsCellProp<'expense'>) => (
        <Cell>
          {cell.value && (allowExpenses || cell.value.some((i) => i.amountInCents !== 0))
            ? cell.value.map((exp) => (
                <Box key={exp.expenseType}>
                  {mapExpenseTypeToDescription(exp.expenseType)}:{' '}
                  {formatCurrencyCents(exp.amountInCents)}
                </Box>
              ))
            : 'Geen'}
        </Cell>
      ),
    },
  };
};

const getClasses = makeStyles(({ spacing }) => ({
  sortIcon: {
    marginLeft: spacing(2),
    height: '12px',
    width: '12px',
    transition: 'ease transform .5s',
  },
}));

const WorkerCell = (cell: CheckoutsCellProp<'worker'>) => {
  const { partnerName } = cell.value;
  if (partnerName) {
    return (
      <>
        <Typography variant="body1">{cell.value.fullName}</Typography>
        <Tooltip title={partnerName}>
          <LabelBox>{partnerName}</LabelBox>
        </Tooltip>
      </>
    );
  }
  return <Typography variant="body1">{cell.value.fullName}</Typography>;
};

const JobHeaderCell = (cell: LevelTableHeaderProps<CheckoutsColumnBase, CheckoutsTableOptions>) => {
  const classes = getClasses();
  return (
    <LwButton
      color="secondary"
      onClick={() => {
        cell.onSortClick('job');
      }}
    >
      <Typography variant="body2" color="secondary">
        Functie
      </Typography>
      <SortIcon
        className={classes.sortIcon}
        style={{
          transform: cell.sortState.order === 'asc' ? 'rotate(180deg)' : '',
          visibility: cell.sortState.sort === 'job' ? 'visible' : 'hidden',
        }}
      />
    </LwButton>
  );
};

const JobCell = (cell: CheckoutsCellProp<'job'>) => {
  return (
    <>
      <Typography variant="body1">{cell.value.name}</Typography>
      <Typography variant="body2" color="secondary">
        {cell.value.location.address}
      </Typography>
    </>
  );
};

const DateHeaderCell = (
  cell: LevelTableHeaderProps<CheckoutsColumnBase, CheckoutsTableOptions>
) => {
  const classes = getClasses();
  return (
    <LwButton
      color="secondary"
      onClick={() => {
        cell.onSortClick('date');
      }}
    >
      <Typography variant="body2" color="secondary">
        Datum
      </Typography>
      <SortIcon
        className={classes.sortIcon}
        style={{
          transform: cell.sortState.order === 'asc' ? 'rotate(180deg)' : '',
          visibility: cell.sortState.sort === 'date' ? 'visible' : 'hidden',
        }}
      />
    </LwButton>
  );
};

const DateCell = (cell: CheckoutsCellProp<'date'>) => {
  const { scheduledStartDate, timeZone } = cell.value;
  return (
    <Typography variant="body1">
      {DateTime.fromJSDate(scheduledStartDate, { zone: timeZone })
        .toFormat('EEE, dd MMM yyyy')
        .replace(/\./g, '')
        .replace(/^\w/, (w) => w.toUpperCase())}
    </Typography>
  );
};

const ScheduledTimesCell = (cell: CheckoutsCellProp<'scheduledTime'>) => {
  const { startDate, endDate, timeZone, breakInMinutes } = cell.value;
  const scheduledDurationMs = getShiftDurationInMs(startDate, endDate, breakInMinutes);
  const scheduledTimeRange = `${formatTime(startDate, timeZone)} - ${formatTime(
    endDate,
    timeZone
  )}`;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2">{scheduledTimeRange}</Typography>
      <Typography variant="body2">
        {formatDuration({ milliseconds: scheduledDurationMs })}
      </Typography>
    </Box>
  );
};

const TimeDeviationBreakCell = (cell: CheckoutsCellProp<'scheduledVsWorkedDiffAndBreak'>) => {
  const { deviationInMs, breakInMinutes } = cell.value;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2">{formatDuration({ milliseconds: deviationInMs })}</Typography>
      <Typography variant="body2">{formatDuration({ minutes: breakInMinutes })}</Typography>
    </Box>
  );
};

const LabelBox = styled(Box)(() => ({
  border: '1px solid #eeeeee',
  borderRadius: '16px',
  backgroundColor: 'white',
  width: '100px',
  padding: '2px 8px',
  marginTop: '0px',
  lineHeight: '12px',
  fontSize: '11px',
  textAlign: 'center',
  zIndex: 100,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export { getCheckoutsColumnDefinitionBase, ScheduledTimesCell };
