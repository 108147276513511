import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import { FormCheckbox } from 'redesign/forms/checkbox-group';
import { computeOverlaps, formatShiftInterval } from './helpers/helpers';
import type { FlexWorker, ShiftApplication } from './bulk-accept-shift-dialog.types';

const BulkAcceptShiftDialogForm = ({
  flexWorker,
  onSubmit,
  shiftApplications,
  ...props
}: Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
  flexWorker: FlexWorker;
  onSubmit: (shiftApplicationIds: string[]) => void;
  shiftApplications: { current: ShiftApplication; rest: ShiftApplication[] };
}) => {
  const classes = getClasses();
  const { control, handleSubmit, watch } = useForm<ReturnType<typeof getDefaultCheckboxValues>>({
    defaultValues: getDefaultCheckboxValues(shiftApplications.current, shiftApplications.rest),
  });
  const checkboxes = watch();
  const overlaps = computeOverlaps([...shiftApplications.rest, shiftApplications.current]);
  const [disabledShiftApplicationIds, setDisabledShiftApplicationIds] = React.useState<string[]>(
    []
  );

  React.useEffect(() => {
    const [selectedShiftApplicationIds, notSelectedShiftApplicationIds] = _(checkboxes)
      .keys()
      .partition((key) => checkboxes[key])
      .value();

    const disabled = notSelectedShiftApplicationIds
      .map((shiftApplicationId) => {
        const overlappingShiftApplicationIds = overlaps[shiftApplicationId];

        return _.intersection(selectedShiftApplicationIds, overlappingShiftApplicationIds).length >
          0
          ? shiftApplicationId
          : undefined;
      })
      .filter(_.isString);

    setDisabledShiftApplicationIds((cur) => {
      if (_.isEqual(cur.sort(), disabled.sort())) {
        return cur;
      }

      return disabled;
    });
  }, [checkboxes, shiftApplications, overlaps]);

  const isThereOverlap = disabledShiftApplicationIds.length > 0;

  return (
    <>
      <p data-testid="og-client-callout">
        Wat fijn!! {flexWorker.firstName} wil meer shifts bij je komen werken. Geef hieronder aan
        wanneer je zijn/haar helpende hand nog meer kan gebruiken.
      </p>
      <FormCheckbox
        checked
        className={classes.pl1}
        disabled
        key={shiftApplications.current.id}
        control={control}
        name={shiftApplications.current.id}
        label={formatShiftInterval(shiftApplications.current.shift.interval)}
      />
      <hr className={classes.hr} />
      <form
        style={{
          maxHeight: '13.5rem',
          overflow: 'auto',
        }}
        className={classes.pl1}
        {...props}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(() => onSubmit(Object.keys(checkboxes).filter((id) => checkboxes[id])))(e);
        }}
      >
        {shiftApplications.rest.map(({ id, shift }) => (
          <FormCheckbox
            disabled={disabledShiftApplicationIds.includes(id)}
            key={id}
            control={control}
            name={id}
            label={formatShiftInterval(shift.interval)}
          />
        ))}
      </form>
      {isThereOverlap ? (
        <p data-testid="shifts-overlap-warning">
          Let op! {flexWorker.firstName} is ijverig, maar kan geen dubbele diensten draaien op
          hetzelfde moment. Kijk en selecteer welke shift(s) wél handig is/zijn.
        </p>
      ) : null}
    </>
  );
};

const getDefaultCheckboxValues = (
  currentShiftApplication: ShiftApplication,
  otherShiftApplications: ShiftApplication[]
): Record<string, boolean> => {
  const defaultValues = otherShiftApplications.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: false }),
    {}
  );

  return {
    ...defaultValues,
    [currentShiftApplication.id]: true,
  };
};

const getClasses = makeStyles(() => ({
  pl1: {
    paddingLeft: '1rem',
  },
  hr: {
    background: grey[300],
    border: 0,
    height: '1px',
  },
}));

export { BulkAcceptShiftDialogForm };
