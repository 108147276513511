import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { companyService } from 'ogp/services';
import { useQuery } from 'react-query';
import { CompanyDetailAndJobGroups } from 'types/companies/company-types';

export const useGetCompanyDetailAndJobGroups = () => {
  const queryFn = async () => await companyService.getCompanyDetailAndJobGroups();

  return useQuery<Promise<CompanyDetailAndJobGroups>, Error, CompanyDetailAndJobGroups>(
    QUERY_KEYS_OGP.ownCompany(),
    queryFn
  );
};
