import { PrebuiltBlock } from 'redesign/block';
import { PartnerData } from 'hq/components/views/partners/hooks/use-create-partner.types';
import { HqApiService } from './hqApiService';
import { GetPartnerRelationsResponse, GetPartnerResponse } from './partner-service.types';
import { PartnerInformationEditFormData } from '../components/views/partner-detail/partner-information/partner-information.types';

class PartnerService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async createPartner(partner: PartnerData) {
    await this.hqApiService.create({
      path: `partners`,
      body: partner,
    });
  }

  public async getPartnerList(): Promise<PrebuiltBlock[]> {
    const response = await this.hqApiService.get<PrebuiltBlock[]>({
      path: 'partners',
    });
    return response;
  }

  public async getPartnerRelations(partnerId: string): Promise<GetPartnerRelationsResponse[]> {
    const response = await this.hqApiService.get<GetPartnerRelationsResponse[]>({
      path: `partners/${partnerId}/relations`,
    });
    return response;
  }

  public async getPartner(partnerId: string): Promise<GetPartnerResponse> {
    const response = await this.hqApiService.get<GetPartnerResponse>({
      path: `partners/${partnerId}`,
    });
    return response;
  }

  public async archivePartner(partnerId: string) {
    await this.hqApiService.delete({
      path: `partners/${partnerId}/archive`,
    });
  }

  public async updatePartnerInformation(
    partnerId: string,
    partnerInformation: PartnerInformationEditFormData
  ) {
    await this.hqApiService.update({
      path: `partners/${partnerId}/edit`,
      body: partnerInformation,
    });
  }
}

export { PartnerService };
