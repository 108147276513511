import { PaginatedCollection } from 'types/Pagination';
import { Table } from 'redesign/table';
import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useAlert } from 'shared/components/alerts';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { IconApproved2, IconCounterOfferAdjust, IconNoShow } from 'assets/img';
import { ContextMenuItem } from 'redesign/context-menu';
import { useUndoNoShow } from 'ogp/hooks/queryHooks/shiftclaims/useUndoNoShow';
import { useHqAdmin } from 'shared/hooks/use-hq-admin';
import { useOgpSetNoShow } from 'shared/components/dialogs/no-show-dialog/query-hooks/ogp/use-ogp-set-no-show';
import { useNoShowDialog } from 'shared/components/dialogs/no-show-dialog/use-no-show-dialog';
import { useMemo } from 'react';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { getArchiveColumnDefinition } from './helpers/get-archive-column-definition';
import { getArchiveTableData } from './helpers/get-archive-table-data';
import { ArchiveTableColumnDefinition, ArchiveTableOptions } from './helpers/archive-table.types';
import { getSettleCheckoutCopy, useCheckoutsArchivePage } from '../../../../shared';
import { ClaimSettledExtended } from '../../../shared/types/checkouts.types';
import { useCounterOfferDialog } from '../../../dialogs/use-counter-offer-dialog';
import useSettleCheckouts from '../../../hooks/use-settle-checkout';

type Props = {
  data: PaginatedCollection<ClaimSettledExtended>;
  sortState: ReturnType<typeof useCheckoutsArchivePage>['sortState'];
  handleSortClick: ReturnType<typeof useCheckoutsArchivePage>['updateSort'];
};

export const CheckoutsArchiveTable = ({ data: queryData, handleSortClick, sortState }: Props) => {
  const { open: openCounterOffer, Element: CounterOfferDialog } = useCounterOfferDialog();
  const {
    open: openNoShow,
    Element: NoShowDialog,
    setIsFlexWorker,
  } = useNoShowDialog(useOgpSetNoShow);
  const settleMutation = useSettleCheckouts();
  const undoNoShowMutation = useUndoNoShow();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const isHqAdmin = useHqAdmin();
  const { allowExpenses } = useFeatureFlagContext();
  const invalidateQuery = useInvalidateQuery();

  const handleUndoNoShow = (claim: ClaimSettledExtended) => {
    openDialog(
      {
        title: `Weet je zeker dat je ${claim.worker.firstName} niet als no show wilt rapporteren?`,
        content: `Als er nog geen ingediende uren zijn krijgt ${claim.worker.firstName} de mogelijkheid om de checkout te starten in de app. Zo niet, dan is de checkout te zien in de checkouts-inbox.`,
      },
      () => {
        disableDialog();
        undoNoShowMutation.mutate(
          {
            shiftClaimId: claim.id,
          },
          {
            onSuccess: () => {
              invalidateQuery(QUERY_KEYS_OGP.statistics());
              closeDialog();
              alertSuccess(`Deze flexwerker is nu niet meer als no-show gemarkeerd`);
            },
            onError: (e) => {
              alertError(e);
            },
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const handleSettleClaim = (id: string) => {
    const { dialog, getResultMessage } = getSettleCheckoutCopy([id]);
    openDialog(
      {
        ...dialog,
        content:
          "De ingevoerde uren van de flexwerker worden hiermee geaccordeerd. Je kan deze daarna terugvinden onder 'alle checkouts'.",
        primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
      },
      () => {
        disableDialog();
        settleMutation.mutate([id], {
          onSuccess: (data) => {
            const message = getResultMessage(data.length);
            alertSuccess(message);
            closeDialog();
          },
          onError: (e) => {
            alertError(e);
          },
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };

  const getMenuActionsFromRow = (claim: ClaimSettledExtended) => {
    setIsFlexWorker(claim.worker.isFlexWorker);
    switch (claim.state) {
      case 'noShow':
        return [
          <ContextMenuItem key="undo-no-show" onClick={() => handleUndoNoShow(claim)}>
            <IconNoShow />
            Undo no show - {claim.noShowReason.shortDescription}
          </ContextMenuItem>,
        ];
      case 'counterOffer':
        return [
          <ContextMenuItem key="counter-offer" onClick={() => openCounterOffer(claim)}>
            <IconCounterOfferAdjust />
            Tegenvoorstel aanpassen
          </ContextMenuItem>,
          ...(isHqAdmin
            ? [
                <ContextMenuItem key="approve" onClick={() => handleSettleClaim(claim.id)}>
                  <IconApproved2 />
                  Flexwerker uren accorderen
                </ContextMenuItem>,
              ]
            : []),
          <ContextMenuItem key="no-show" onClick={() => openNoShow(claim.id)}>
            <IconNoShow />
            No show
          </ContextMenuItem>,
        ];
    }

    return [];
  };

  const columns = useMemo(() => getArchiveColumnDefinition(allowExpenses), [allowExpenses]);
  const tableData = useMemo(() => getArchiveTableData(queryData), [queryData]);

  return (
    <>
      {CounterOfferDialog}
      {NoShowDialog}
      <Table<ArchiveTableColumnDefinition, ArchiveTableOptions>
        columns={columns}
        data={tableData}
        getRowActions={getMenuActionsFromRow}
        sortState={sortState}
        onSortClick={handleSortClick}
      />
    </>
  );
};
