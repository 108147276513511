import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import type { GridDepartmentProps } from './grid-department.types';
import { GridJob } from '../grid-job/grid-job';

export const GridDepartment = ({ data }: GridDepartmentProps) => {
  const classes = getClasses();
  return (
    <section className={classes.section}>
      <Typography className={classes.name} title={data.name}>
        {data.name}
      </Typography>
      {data.jobs.length ? (
        data.jobs.map((job) => <GridJob key={job.id} data={job} />)
      ) : (
        <GridJob data={undefined} />
      )}
    </section>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  section: {
    minHeight: theme.spacing(8),
  },
  name: {
    fontWeight: 700,
    width: '14vw',
    padding: theme.spacing(0, 2, 2, 0),
    position: 'absolute',
    transform: 'translateY(-100%)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
}));
