import { Box, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FilterModal } from 'redesign/filter-modal/filter-modal';
import { CheckboxOption, FormCheckboxGroup } from 'redesign/forms/checkbox-group';
import {
  FlexWorkersForShiftFilterOptions,
  FlexWorkersForShiftFiltersStrings,
} from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';

import {
  parseFlexWorkersForShiftFilters,
  stringifyFlexWorkersForShifts,
} from '../../../../flex-workers-for-work-table/utils/filtering-utils';
import { FormSelectGroup } from '../../../../../../redesign/forms/select-group/form-select-group';
import { useFeatureFlagContext } from '../../../../../contexts/feature-flag-provider';
import { useHqAdmin, useSiteDetection } from '../../../../../hooks';

interface Props {
  handleOpeneningFilters: (val: boolean) => void;
  isOpen: boolean;
  filterValues: FlexWorkersForShiftFilterOptions;
  setFilterValues: (props: FlexWorkersForShiftFilterOptions) => void;
}

export const ShiftAddFlexWorkerFilters = (props: Props) => {
  const { handleOpeneningFilters, isOpen, setFilterValues, filterValues } = props;

  const filterValuesStrings = stringifyFlexWorkersForShifts(filterValues);
  const defaultFilterValues: FlexWorkersForShiftFiltersStrings = useMemo(
    () => ({
      flexPoolOnly: filterValuesStrings.flexPoolOnly ?? undefined,
      maxDistance: filterValuesStrings.maxDistance ?? '0',
      isAvailable: filterValuesStrings.isAvailable ?? undefined,
      workedForDepartment: filterValuesStrings.workedForDepartment ?? undefined,
      hasCertificate: filterValuesStrings.hasCertificate ?? undefined,
      availabilityStatus: filterValuesStrings.availabilityStatus ?? '0',
    }),
    [filterValuesStrings]
  );
  const emptyFilterValues: FlexWorkersForShiftFiltersStrings = useMemo(
    () => ({
      flexPoolOnly: '',
      maxDistance: '0',
      isAvailable: '',
      workedForDepartment: '',
      hasCertificate: '',
      availabilityStatus: '0',
    }),
    []
  );
  const featureFlags = useFeatureFlagContext();
  const isHqAdmin = useHqAdmin();
  const { site } = useSiteDetection();

  const form = useForm<FlexWorkersForShiftFiltersStrings>({
    defaultValues: { ...defaultFilterValues },
  });

  const handleCancel = useCallback(() => {
    form.reset(defaultFilterValues);
    handleOpeneningFilters(false);
  }, [form, handleOpeneningFilters]);

  const handleClear = useCallback(() => {
    form.reset(emptyFilterValues);
  }, [form]);

  const handleSubmit = () => {
    const res = form.getValues();
    setFilterValues(
      parseFlexWorkersForShiftFilters({
        flexPoolOnly: res.flexPoolOnly?.[0],
        maxDistance: res.maxDistance,
        isAvailable: res.isAvailable?.[0],
        workedForDepartment: res.workedForDepartment?.[0],
        hasCertificate: res.hasCertificate?.[0],
        availabilityStatus: res.availabilityStatus,
      })
    );
    handleOpeneningFilters(false);
  };

  const maxDistanceOptions = [
    { label: 'Alle afstanden', value: '0' },
    { label: '< 5km', value: '5' },
    { label: '< 10km', value: '10' },
    { label: '< 30km', value: '30' },
    { label: '< 50km', value: '50' },
    { label: '< 75km', value: '75' },
    { label: '< 100km', value: '100' },
  ];

  const flexpoolCheckboxes: CheckboxOption[] = [
    { label: 'Ja', value: '1' },
    { label: 'Nee', value: '0' },
  ];

  const availabilityStatusOptions =
    featureFlags.allowAvailability && (isHqAdmin || site === 'hq')
      ? [
          { value: '0', label: '-' },
          { value: '1', label: 'Beschikbaar' },
          { value: '2', label: 'Volledig en mogelijk beschikbaar' },
        ]
      : [
          { value: '0', label: '-' },
          { value: '2', label: 'Volledig en mogelijk beschikbaar' },
        ];

  const workedForDepartment: CheckboxOption[] = [{ label: 'Ja', value: '1' }];

  const hasCertificateCheckboxes: CheckboxOption[] = [{ label: 'Ja', value: '1' }];

  return isOpen ? (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <FilterModal
          handleCancel={handleCancel}
          handleClear={handleClear}
          isOpen={isOpen}
          handleSubmit={handleSubmit}
        >
          <FilterGrid>
            <FormCheckboxGroup
              label="In de flexpool"
              name="flexPoolOnly"
              items={flexpoolCheckboxes}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            <FormSelectGroup
              label="Maximale afstand"
              name={'maxDistance'}
              control={form.control}
              options={maxDistanceOptions}
            />
            <FormSelectGroup
              label="Beschikbaarheid"
              name={'availabilityStatus'}
              control={form.control}
              options={availabilityStatusOptions}
              informationText="Beschikbaarheid van de flexwerker"
            />
            <FormCheckboxGroup
              label="Heeft eerder voor deze afdeling gewerkt"
              name="workedForDepartment"
              items={workedForDepartment}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            <FormCheckboxGroup
              label="In bezit van certificaten"
              name="hasCertificate"
              items={hasCertificateCheckboxes}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
          </FilterGrid>
        </FilterModal>
      </form>
    </FormProvider>
  ) : null;
};

const FilterGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  gap: theme.spacing(7),
  alignItems: 'center',
}));
