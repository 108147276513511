import { FreelanceJobDetailsWithId, TempWorkJobDetailsWithId } from 'types/jobs/JobTypes';
import { HelperTexts } from '../types';

export const getHelperTexts = (
  jobData: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId | undefined
): HelperTexts => {
  if (!jobData) {
    return {
      hourlyRate: undefined,
      noShowFine: undefined,
      breakMinutes: undefined,
    };
  }
  const { hourlyRateCents, breakMinutes } = jobData;
  const result: HelperTexts = {
    hourlyRate: `De standaard voor deze functie is ${(hourlyRateCents || 0) / 100} €`,
    breakMinutes: `De standaard voor deze functie is ${breakMinutes} min`,
  };
  if ('noShowFineCents' in jobData) {
    result.noShowFine = `De standaard voor deze functie is  ${
      (jobData.noShowFineCents || 0) / 100
    } €`;
  }
  return result;
};
