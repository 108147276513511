import { DateTime } from 'luxon';
import { AutocompleteOption } from 'redesign/forms/autocomplete';
import {
  ProjectPlanningFilters,
  ProjectPlanningFiltersForm,
} from './hooks/use-project-planning-filters.types';
import { getJobTypes } from '../../../../../../hq/components/views/planner/shifts/filters/utils/utils';
import {
  OgpBusinessUnitsData,
  OgpJobTypesData,
} from '../../../../../services/ogp-shared-service.types';
import { ProjectGetWorkersResponse } from '../../../../../services/project-service.types';

export const getProjectPlanningDefaultValues = (
  appliedFilters: ProjectPlanningFilters,
  businessUnitData: OgpBusinessUnitsData,
  jobTypeData: OgpJobTypesData,
  workerData: ProjectGetWorkersResponse
): ProjectPlanningFiltersForm => {
  const toDate = appliedFilters.toDate
    ? appliedFilters.toDate.toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd');
  const result: ProjectPlanningFiltersForm = {
    toDate,
    branchOffice: null,
    department: null,
    jobType: null,
    worker: null,
    jobTypeCheckboxValue: !!appliedFilters.jobType?.length,
    filledStatusCheckboxValue: !!appliedFilters.filledStatus,
    allowApplicantsFilter: appliedFilters.hasApplicants != null ? true : false,
    filledStatus: appliedFilters.filledStatus ? appliedFilters.filledStatus : 'inProgress',
    hasApplicants: appliedFilters.hasApplicants != null ? appliedFilters.hasApplicants : true,
    businessUnitCheckboxValue: !!appliedFilters.branchOfficeId,
    toDateCheckboxValue: !!appliedFilters.toDate,
    allowWorkersFilter: !!appliedFilters.workerId,
  };

  if (appliedFilters.jobType?.length) {
    const jobTypes = getJobTypes(appliedFilters.jobType, jobTypeData.jobTypes);
    if (jobTypes.length > 0) {
      result.jobType = jobTypes.map((jt) => ({ id: jt.value, name: jt.label }));
    }
  }

  if (appliedFilters.workerId) {
    const worker = workerData.find((i) => i.id === appliedFilters.workerId);
    if (worker) {
      result.worker = {
        label: worker.name,
        value: worker.id,
      };
    }
  }

  if (!businessUnitData.branchOffices) {
    return result;
  }

  const branchOfficeFilter = businessUnitData.branchOffices.find(
    (bo) => bo.branchOfficeId === appliedFilters.branchOfficeId
  );

  if (!branchOfficeFilter) {
    return result;
  }

  const branchOffice: AutocompleteOption = {
    value: branchOfficeFilter.branchOfficeId,
    label: branchOfficeFilter.branchOfficeName,
  };
  result.branchOffice = branchOffice;

  if (!appliedFilters.departmentId) {
    return result;
  }

  const departmentFilter = branchOfficeFilter.departments.find(
    (dept) => dept.departmentId === appliedFilters.departmentId
  );

  if (!departmentFilter) {
    return result;
  }

  const department = {
    value: departmentFilter.departmentId,
    label: departmentFilter.departmentName,
  };

  result.department = department;

  return result;
};
