import { Column } from 'react-table';
import { useTheme } from '@mui/material';
import {
  PartnerPlannerTableCellProp,
  PartnerPlannerTableDefinition,
} from './partner-planner-modal-table.types';
import { getPlannerModalTableDefinition } from './use-get-planner-modal-table-definition';
import { getAvailabilityStatusAndColor } from '../../../../../../../shared/components/flex-workers-for-work-table/utils/helpers';
import { StatusIndicator } from '../../../../../../../redesign/status-indicator/status-indicator';

export const useGetPlannerWorkerTableColumnDefinition =
  (): Column<PartnerPlannerTableDefinition>[] => {
    const [name, email, phone, certificate, actions] = getPlannerModalTableDefinition();

    const availability: Column<PartnerPlannerTableDefinition> = {
      Header: 'Beschikbaarheid',
      accessor: 'availability',
      Cell: AvailabilityCell,
      width: '180px',
    };

    return [name, email, phone, certificate, availability, actions];
  };

const AvailabilityCell = (cell: PartnerPlannerTableCellProp<'availability'>) => {
  const { availability, color } = getAvailabilityStatusAndColor(cell.value);
  const theme = useTheme();
  return (
    <StatusIndicator
      backgroundColor={color}
      label={availability}
      color={theme.palette.lwBlack[100]}
    />
  );
};
