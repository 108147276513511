import { useAlert } from 'shared/components/alerts';
import { useUploadBusCsv } from 'hq/hooks/queryHooks/busCsv/use-upload-bus-csv';
import {
  BusCSVUploadPayload,
  BusCSVUploadResponse,
  RowBusCSVKeys,
} from 'types/adminSettings/admin-settings-service.types';
import { formatDateTimeFromString } from 'shared/utils/formatting';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FileUploader } from './shared/file-uploader';
import { ErrorRow, SuccessRow } from './shared/components';
import { LwButton } from '../../../../redesign/button';
import { Toolbox } from '../../../../redesign/toolbox';

const UploadBusCsvForm = ({
  isCsvVisible,
  setIsCsvVisible,
}: {
  isCsvVisible: boolean;
  setIsCsvVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const rowBusCsvPropertiesToLabel = {
    jobId: 'Job ID',
    flexWorkerId: 'FlexWorker ID',
    startDate: 'Start Date',
    endDate: 'End Date',
    rowStatus: 'ShiftClaim ID',
  } as const;

  const { status, mutate } = useUploadBusCsv();
  const { alertSuccess, alertError } = useAlert();
  const { handleSubmit, setValue, watch } = useForm<BusCSVUploadPayload>();
  const [resultBusCsv, setResultBusCsv] = useState<BusCSVUploadResponse>();
  const onSubmit = (payload: BusCSVUploadPayload) => {
    mutate(payload, {
      onSuccess: (data) => {
        setResultBusCsv(data);
        alertSuccess('CSV imported successfully');
      },
      onError: (e) => alertError(e),
    });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setValue('file', file);
  };

  return (
    <>
      <Toolbox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="row" gap={4}>
            <Box display="flex" flexDirection="column">
              <FileUploader
                title="Upload CSV"
                fileType=".csv"
                onChange={handleFileUpload}
                disabled={status === 'loading'}
              ></FileUploader>
              <Box alignSelf={'center'}>{watch('file')?.name}</Box>
            </Box>
            <Box>
              <Box>
                <LwButton color="primary" type="submit">
                  <Typography variant="button">Submit</Typography>
                </LwButton>
              </Box>
            </Box>
          </Box>
        </form>

        <Toolbox.PushRight>
          <LwButton onClick={() => setIsCsvVisible((prev) => !prev)}>
            Show {isCsvVisible ? 'XML Upload' : 'CSV Upload'}
          </LwButton>
        </Toolbox.PushRight>
      </Toolbox>

      {resultBusCsv ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {Object.keys(resultBusCsv[0]).map((key) => (
                  <TableCell key={key}>
                    <Typography variant="subtitle2">
                      {rowBusCsvPropertiesToLabel[key as RowBusCSVKeys]}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {resultBusCsv.map((row) => (
                <TableRow
                  key={row.jobId + row.flexWorkerId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.jobId}
                  </TableCell>
                  <TableCell>{row.flexWorkerId}</TableCell>
                  <TableCell>{formatDateTimeFromString(row.startDate)}</TableCell>
                  <TableCell>{formatDateTimeFromString(row.endDate)}</TableCell>
                  {row.rowStatus.status === 'Success' ? (
                    <SuccessRow message={row.rowStatus.shiftClaimId} />
                  ) : (
                    <ErrorRow message={row.rowStatus.errorMessage} />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};

export { UploadBusCsvForm };
