import React from 'react';
import { Button, ButtonProps, styled, Theme, useTheme } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { TakeDefined } from 'types/utility';
import { Props } from './button.types';

const LwButton = ({ iconVariant = 'outlined', iconColorMode = 'stroke', ...props }: Props) => {
  const theme = useTheme();
  const sxOverride = getSxOverride({ iconVariant, iconColorMode }, theme);

  return (
    <StyledButton LinkComponent={LinkComponent} sx={sxOverride} variant="contained" {...props} />
  );
};

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} role="link" />;
});

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&.MuiButton-root': {
    alignItems: 'center',
    boxShadow: 'none',
    display: 'flex',
    fontFamily: 'Urbanist',
    fontWeight: 'bold',
    justifyContent: 'center',
    textTransform: 'none',

    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },

  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.lwPrimary[100],
    borderRadius: theme.spacing(7),
    color: theme.palette.lwWhite[100],
    fontSize: '16px',
    fontWeight: 700,
    fontVariationSettings: '"wght" 700',
    gap: theme.spacing(2),
    lineHeight: theme.spacing(5),
    height: '56px',
    padding: theme.spacing(4, 6),

    '&:hover': {
      backgroundColor: theme.palette.lwPrimary[100],
      boxShadow: `0px 12px 32px ${theme.palette.lwSecondary[40]}`,
      color: theme.palette.lwWhite[100],
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 4px ${theme.palette.lwSecondary[10]}`,
    },

    '&:active': {
      backgroundColor: theme.palette.lwSecondary[100],
      color: theme.palette.lwWhite[80],
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.lwPrimary[20],
      color: theme.palette.lwWhite[80],
    },
  },

  '&.MuiButton-containedSecondary': {
    backgroundColor: theme.palette.lwWhite[100],
    border: `1px solid ${theme.palette.lwSecondary[20]}`,
    borderRadius: theme.spacing(7),
    color: theme.palette.lwSecondary[100],
    fontSize: '16px',
    fontWeight: 600,
    fontVariationSettings: '"wght" 600',
    gap: theme.spacing(2),
    height: '56px',
    lineHeight: theme.spacing(5),
    padding: theme.spacing(4, 6),

    '&:hover': {
      backgroundColor: theme.palette.lwWhite[100],
      border: `1px solid ${theme.palette.lwSecondary[40]}`,
      boxShadow: `0px 8px 24px ${theme.palette.lwSecondary[20]}`,
      color: theme.palette.lwPrimary[100],
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 4px ${theme.palette.lwPrimary[10]}`,
    },

    '&:active': {
      backgroundColor: theme.palette.lwSecondary[10],
      border: `1px solid currentColor`,
      color: theme.palette.lwSecondary[100],
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.lwWhite[100],
      color: theme.palette.lwSecondary[40],
    },
  },

  '&.MuiButton-containedTertiary': {
    backgroundColor: '#fafafa',
    borderRadius: theme.spacing(6),
    color: theme.palette.lwBlack[60],
    fontSize: '14px',
    fontWeight: 500,
    fontVariationSettings: '"wght" 500',
    gap: theme.spacing(2),
    lineHeight: theme.spacing(5),
    minHeight: '48px',
    padding: theme.spacing(4, 6),

    '&:hover': {
      backgroundColor: theme.palette.lwBlack[10],
      boxShadow: 'none',
      color: theme.palette.lwBlack[100],
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 4px ${theme.palette.lwSecondary[10]}`,
    },

    '&:active': {
      backgroundColor: theme.palette.lwBlack[20],
      color: theme.palette.lwBlack[100],
    },

    '&.Mui-disabled': {
      background: `linear-gradient(0deg, rgba(240, 240, 240, 0.5), rgba(240, 240, 240, 0.5)), ${theme.palette.lwWhite[100]}`,
      color: theme.palette.lwBlack[40],
    },

    '& svg path': {
      fill: 'currentColor',
    },
  },
}));

const getSxOverride = (
  {
    iconVariant,
    iconColorMode,
  }: {
    iconVariant: TakeDefined<Props['iconVariant']>;
    iconColorMode: TakeDefined<Props['iconColorMode']>;
  },
  theme: Theme
) => ({
  '&.MuiButton-root': {
    ...(iconVariant === 'filled'
      ? {
          padding: theme.spacing(4),
        }
      : {}),
  },
  '&.MuiButton-containedPrimary svg path': {
    [iconColorMode]: 'currentColor',
  },
  '&.MuiButton-containedSecondary': {
    '& svg path, &:hover svg path, &:active svg path': {
      [iconColorMode]: 'currentColor',
    },
  },
  '&:active .MuiButton-startIcon svg path, &:active .MuiButton-endIcon svg path': {
    ...(iconVariant === 'filled'
      ? {
          fill: theme.palette.lwSecondary[100],
        }
      : {}),
  },
  '& .MuiButton-startIcon, & .MuiButton-endIcon': {
    ...(iconVariant === 'filled'
      ? {
          alignItems: 'center',
          backgroundColor: 'currentColor',
          borderRadius: '50%',
          display: 'flex',
          height: '40px',
          justifyContent: 'center',
          width: '40px',

          '& svg path': {
            fill: theme.palette.lwPrimary[100],
          },
        }
      : {}),
  },
});

export { LwButton };
