import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { departmentService } from 'ogp/services';
import {
  departmentResponse2DepartmentDetailModel,
  departmentResponse2DepartmentFormModel,
} from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { FirstParamOfFn } from 'types/utility';

type Response = Awaited<ReturnType<typeof departmentService.getDepartment>>;

type Params = FirstParamOfFn<typeof useGetDepartment>;

const useGetDepartment = <TData = Response>(
  departmentId: string = '',
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await departmentService.getDepartment(departmentId);

  return useQuery(QUERY_KEYS_OGP.department(departmentId), queryFn, {
    enabled: departmentId.length > 0,
    select,
  });
};

const useGetDepartmentAsDetail = (params: Params) => {
  const result = useGetDepartment(params, departmentResponse2DepartmentDetailModel);
  return result;
};

const useGetDepartmentAsForm = (params: Params) => {
  const result = useGetDepartment(params, departmentResponse2DepartmentFormModel);
  return result;
};

export { useGetDepartmentAsDetail, useGetDepartmentAsForm };
