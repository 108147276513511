import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, styled } from '@mui/styles';
import IconCareLeft from 'assets/img/icons/caret-left.svg?react';
import IconCareRight from 'assets/img/icons/caret-right.svg?react';
import { usePlanningUrls } from 'ogp/hooks/usePlanningUrls';
import { LwButton } from 'redesign/button';
import { LwIconButton } from 'redesign/icon-button';
import { DateControlProps } from './grid-date-control.types';

export const GridDateControl = ({ date }: DateControlProps) => {
  const classes = getClasses();
  const { previousWeekUrl, nextWeekUrl, todayUrl } = usePlanningUrls(date);
  const weekNumber = date.toFormat('W');
  const getDateInfo = () => {
    if (date.startOf('week').monthLong === date.endOf('week').monthLong) {
      return `${date.startOf('week').day} - ${date.endOf('week').day} ${
        date.endOf('week').monthLong
      } ${date.toFormat('yyyy')}`;
    } else {
      return `${date.startOf('week').day} ${date.startOf('week').monthLong} - ${
        date.endOf('week').day
      } ${date.endOf('week').monthLong} ${date.toFormat('yyyy')}`;
    }
  };
  const dateInfo = getDateInfo();

  return (
    <StyledBox>
      <LwButton to={todayUrl} color="secondary">
        Vandaag
      </LwButton>
      <Box
        display="flex"
        flexDirection="row"
        margin="auto"
        alignItems="center"
        gap={(theme) => theme.spacing(8)}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <LwIconButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #001a9933',
              borderRadius: '50%',
              padding: '1rem',
            }}
            iconColorMode="stroke"
            to={previousWeekUrl}
            color="secondary"
          >
            <IconCareLeft className={classes.dateIcon} />
          </LwIconButton>
          <Box width="70px" display="flex" alignItems="center" className={classes.weekInfo}>
            Week {weekNumber}
          </Box>
          <LwIconButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #001a9933',
              borderRadius: '50%',
              padding: '1rem',
            }}
            iconColorMode="stroke"
            to={nextWeekUrl}
            color="secondary"
          >
            <IconCareRight className={classes.dateIcon} />
          </LwIconButton>
        </Box>
        <Box width="230px" display="flex" alignItems="center">
          {dateInfo}
        </Box>
      </Box>
    </StyledBox>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  dateIcon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  todayButton: {
    borderRadius: '16px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: theme.spacing(6),
    padding: theme.spacing(4, 3, 4, 3),
  },
  navButton: {
    minWidth: 0,
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
  weekInfo: {
    margin: '0 1rem',
    color: theme.palette.lwSecondary['100'],
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(8),
}));
