import {
  CompanyBasicInfo,
  CompanyDetailAndJobGroups,
  CompanyFormPayload,
} from 'types/companies/company-types';
import { BreakOption } from 'types/shifts/shift-types';

import { OgpApiService, Response } from './ogp-api-service';
import {
  FlexWorkerForNotWelcomeList,
  GetFlexWorkerForNotWelcomeListResponse,
  GetNotWelcomeListResponse,
} from './company-service.types';

export class CompanyService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  // Get only basic company information
  getCompanyBasicInfo = async () => {
    const companyBasicInfo = await this.ogpApiService.get<CompanyBasicInfo>({
      path: `companies/basic-info/`,
    });
    return companyBasicInfo;
  };

  /**
   * Get a single company and its related jobGroups
   */
  getCompanyDetailAndJobGroups = async () => {
    const companyDetailAndJobGroups = await this.ogpApiService.get<CompanyDetailAndJobGroups>({
      path: `companies/`,
    });

    return companyDetailAndJobGroups;
  };

  /**
   * Get a single company
   */
  getBreakOptions = async (): Promise<BreakOption[]> => {
    const response = await this.ogpApiService.get<Response<BreakOption[]>>({
      path: `companies/break-options`,
    });

    return response.breakOptions;
  };

  /**
   * Update an existing company
   */
  updateCompany = async (companyId: string, company: CompanyFormPayload) => {
    return await this.ogpApiService.update({
      path: `companies/${companyId}`,
      body: company,
    });
  };

  /**
   * Returns a list of flexworkers that are on the 'not welcome list' for a company
   */
  getNotWelcomeList = async () => {
    const response = await this.ogpApiService.get({ path: 'not-welcome-list' });
    const result = GetNotWelcomeListResponse.parse(response);
    return result;
  };

  /**
   * Returns a list of flexworkers can be added on the 'not welcome list' for a company
   */
  getAvailableFlexWorkers = async (filter: string, signal: AbortSignal | undefined) => {
    const response = await this.ogpApiService.get<FlexWorkerForNotWelcomeList[]>({
      path: 'not-welcome-list/available',
      params: { filter },
      signal,
    });

    const result = GetFlexWorkerForNotWelcomeListResponse.parse(response);
    return result;
  };

  /**
   * Returns a list of flexworkers that are on the 'not welcome list' for a company
   */
  addFlexWorkerToNotWelcomeList = async (flexWorkerId: string, reason: string) => {
    return await this.ogpApiService.create<undefined>({
      path: `not-welcome-list/add/${flexWorkerId}`,
      body: { reason },
    });
  };

  /**
   * Returns a list of flexworkers can be added on the 'not welcome list' for a company
   */
  removeFlexWorkerFromNotWelcomeList = async (flexWorkerId: string) => {
    return await this.ogpApiService.delete<undefined>({
      path: `not-welcome-list/remove/${flexWorkerId}`,
    });
  };
}
