import {
  FreelanceJobDetailsWithId,
  JobListItem,
  TempWorkJobDetailsWithId,
} from 'types/jobs/JobTypes';

import { HqApiService, Response } from './hqApiService';

export class JobService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Get a list of existing jobs
   */
  public getJobs = async (params: {
    companyId?: string;
    departmentId?: string;
  }): Promise<JobListItem[]> => {
    const { jobs } = await this.hqApiService.get<Response<JobListItem[]>>({
      path: 'jobs',
      params,
    });

    return jobs;
  };

  public getJob = async <T = TempWorkJobDetailsWithId | FreelanceJobDetailsWithId>(
    jobId: string
  ): Promise<T> => {
    const response = await this.hqApiService.get<{ job: T }>({
      path: `jobs/${jobId}`,
    });

    return response.job;
  };
}
