import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'redesign/forms/checkbox-group/form-checkbox';
import { LwFormInput } from 'redesign/forms/input';
import { LwFormSelect } from 'redesign/forms/select';
import { LwFormSlider } from 'redesign/forms/slider/form-slider';
import { SliderMark } from 'redesign/forms/slider/form-slider-input.types';
import { createSelectOption } from 'redesign/select';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { areAtLeastTwoEmploymentTypesEnabled } from 'shared/utils/feature-flag';
import { EmploymentType, OrtListItem } from 'types/companies/company-types';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { LwFormDate } from '../../../../../../redesign/forms/date';

export type CompanyFormData = {
  employmentType: EmploymentType;
  name: string;
  ortId: string;
  ortStartDate: string;
  isTempworkingCompany: boolean;
  workCooldownMinutes: number;
};

type CompanyFormProps = {
  state: 'create' | 'update';
  orts: OrtListItem[];
  defaultOrtId?: string;
};

export const CompanyForm = ({ state, orts, defaultOrtId }: CompanyFormProps) => {
  const { control, watch } = useFormContext<CompanyFormData>();
  const ortOptions = useMemo(() => {
    return orts.map((ort) =>
      createSelectOption(ort, {
        value: 'id',
        label: 'name',
      })
    );
  }, [orts]);

  const sliderMarks: SliderMark[] = useMemo(() => {
    return Array.from({ length: 25 }, (_, i) => ({ value: 60 * i, label: i.toString() }));
  }, []);

  const { allowTempWorkerEmploymentType, allowFreelancerEmploymentType } = useFeatureFlagContext();

  const atLeastTwoEmploymentTypesEnabled = areAtLeastTwoEmploymentTypesEnabled({
    allowTempWorkerEmploymentType,
    allowFreelancerEmploymentType,
  });

  const ortId = watch('ortId');

  return (
    <Grid container item spacing={2} xs={6}>
      <Grid item xs={12}>
        <LwFormInput
          name="name"
          control={control}
          rules={{ required: 'Voer een bedrijfsnaam in' }}
          label="Bedrijfsnaam"
          disabled={state !== 'create'}
        />
      </Grid>
      {atLeastTwoEmploymentTypesEnabled ? (
        <Grid item xs={12}>
          <FormCheckbox
            name="isTempworkingCompany"
            control={control}
            label="Bedrijf voor uitzendkrachten"
            disabled={state !== 'create'}
          />
        </Grid>
      ) : null}
      <Grid item xs={6}>
        <LwFormSelect name="ortId" label="ORT" options={ortOptions} control={control} />
      </Grid>
      <Grid item xs={6}>
        <LwFormDate
          name="ortStartDate"
          label="Startdatum"
          control={control}
          minDate={DateTime.now().startOf('day').toISODate()}
          rules={{
            validate: (val) => {
              if (ortId !== null && defaultOrtId == ortId) {
                return undefined;
              }
              if (val == null && ortId != null) {
                return 'Voer een startdatum in';
              }
              return undefined;
            },
          }}
          disabled={ortId == null || defaultOrtId == ortId}
        />
      </Grid>
      <Grid item xs={12}>
        <LwFormSlider
          name="workCooldownMinutes"
          label="Minimale tijd tussen shifts (in uren)"
          control={control}
          min={0}
          max={1440}
          step={60}
          marks={sliderMarks}
          valueLabelFormat={(value) => `${value / 60} uur`}
          data-testid="work-cooldown-slider"
        />
      </Grid>
    </Grid>
  );
};
