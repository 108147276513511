import {
  AccreditationsColumnDefinition,
  AccreditationTableDefinition,
  RawAccreditationData,
} from 'types/accreditations/accreditations.types';
import { Table } from 'redesign/table';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { getAccreditationsColumnDefinition } from './get-accreditations-column-definition';
import { getAccreditationsTableData } from './get-accreditations-table-data';
import { AccreditationParams, SetAccreditationParams } from './accreditations.types';

type AccreditationsTableProps = {
  data: RawAccreditationData[];
  params: AccreditationParams;
  setParams: SetAccreditationParams;
};

const AccreditationsTable = ({ data, params, setParams }: AccreditationsTableProps) => {
  const columns = useMemo(() => getAccreditationsColumnDefinition(params.state), [params.state]);
  const tableData = useMemo(() => getAccreditationsTableData(data), [data]);

  const handleSortClick = () => {
    setParams({ order: params.order === 'desc' ? 'asc' : 'desc' });
  };

  return (
    <>
      <Box height="90%">
        <Table<AccreditationsColumnDefinition, AccreditationTableDefinition>
          stickyHeader
          columns={columns}
          data={tableData}
          sortState={params.order}
          onShowDetail={(flexWorkerId, certificateId) => setParams({ flexWorkerId, certificateId })}
          onSortClick={handleSortClick}
        />
      </Box>
    </>
  );
};

export { AccreditationsTable };
