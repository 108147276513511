import { useGetJobs } from 'ogp/hooks/queryHooks/jobs/useGetJobs';
import { RenderUnsettledUI } from 'shared/components';
import { useParams } from 'react-router-dom';
import { ProjectFormType } from 'shared/components/project-forms/types/project-form.types';
import { invariant } from 'shared/utils/utils';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments/hooks';
import { useCompanyEmploymentType } from 'ogp/hooks';
import { ProjectCreateFormPage } from './form/project-create-form-page';
import { useGetProjectDetail } from '../../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-project-detail';

export const ProjectsDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Project ID is not specified');
  const jobsQuery = useGetJobs();
  const projectQuery = useGetProjectDetail(id);
  const employmentTypeQuery = useCompanyEmploymentType();
  const departmentsQuery = useGetDepartmentsAsSelect({ includeArchived: false });

  if (jobsQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobsQuery} />;
  }

  if (projectQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectQuery} />;
  }

  if (employmentTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeQuery} />;
  }

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  const defaultValues: ProjectFormType = {
    departmentId: projectQuery.data.department.id,
    jobId: projectQuery.data.jobId,
    startDate: projectQuery.data.startDate,
    endDate: projectQuery.data.endDate,
    weeklyHours: projectQuery.data.weekHourVolume,
    hourlyRate: projectQuery.data.hourlyRateCents / 100,
  };

  return (
    <ProjectCreateFormPage
      jobsData={jobsQuery.data}
      defaultValues={defaultValues}
      successMessage={`Project ${projectQuery.data.name} duplicated`}
      pageTitle={`Project ${projectQuery.data.name} dupliceren`}
      departments={departmentsQuery.data}
      employmentType={employmentTypeQuery.data}
    />
  );
};
