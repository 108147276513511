import { AutocompleteOption } from 'redesign/forms/autocomplete';
import { BranchOfficeNameListItem } from 'types/companies/branch-office-types';
import { InboxFiltersParams } from '../../helpers/checkouts.types';

const getCheckoutDefaultFilterValues = ({
  branchOffices,
  activeFilters,
}: {
  branchOffices: BranchOfficeNameListItem[];
  activeFilters: InboxFiltersParams;
}) => {
  const findDefaultBranchOffice = (
    branchOffices: BranchOfficeNameListItem[],
    branchOfficeId: string | AutocompleteOption | null | undefined
  ) => {
    const foundBranchOffice = branchOffices.find((bo) => bo.id === branchOfficeId);
    return foundBranchOffice
      ? { value: foundBranchOffice.id, label: foundBranchOffice.name }
      : null;
  };

  const findDepartmentNameById = (departmentId: string) => {
    const department = branchOffices
      .flatMap((branchOffice) => branchOffice.departments)
      .find((department) => department.id === departmentId);

    if (department) {
      return { value: department.id, label: department.name };
    } else {
      return null;
    }
  };

  let defaultDepartment = null;

  if (activeFilters.departmentIds?.length === 1) {
    defaultDepartment = findDepartmentNameById(activeFilters.departmentIds[0]);
  }

  const defaultBranchOffice = findDefaultBranchOffice(branchOffices, activeFilters.branchOffice);

  return { defaultBranchOffice, defaultDepartment };
};

export { getCheckoutDefaultFilterValues };
