import { Grid } from '@mui/material';
import { useCompanyRelativePath, useGetBranchOffices } from 'ogp/hooks';
import { useAlert } from 'shared/components/alerts';
import { useGetJobs } from 'ogp/hooks/queryHooks/jobs/useGetJobs';
import { FormProvider, useForm } from 'react-hook-form';
import { RenderUnsettledUI } from 'shared/components';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { JobListItem } from 'types/jobs/JobTypes';
import { paths } from 'ogp/paths';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { OgpProjectGetResponse, ProjectsUpdatePayload } from 'ogp/services/project-service.types';
import { invariant } from 'shared/utils/utils';
import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { ProjectFormType } from 'shared/components/project-forms/types/project-form.types';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments/hooks';
import { useCompanyEmploymentType } from 'ogp/hooks';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { EmploymentType } from 'types/companies/company-types';
import { InputSelectOption } from 'types/Types';
import { useUpdateProject } from '../../hooks/use-update-project';
import { useGetProjectDetail } from '../../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-project-detail';

export const ProjectsUpdateDataProvider = () => {
  const jobsQuery = useGetJobs();
  const branchOfficeQuery = useGetBranchOffices({ includeArchived: false });
  const { id } = useParams<'id'>();
  invariant(id, 'Project ID should be defined');
  const projectQuery = useGetProjectDetail(id);
  const employmentTypeQuery = useCompanyEmploymentType();
  // TODO: Move departments select from management as it is reused in flexpools as well. Create separate component perhaps?
  const departmentsQuery = useGetDepartmentsAsSelect({ includeArchived: false });

  if (jobsQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobsQuery} />;
  }

  if (branchOfficeQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficeQuery} />;
  }

  if (projectQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectQuery} />;
  }

  if (employmentTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeQuery} />;
  }

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectsUpdate
      jobsData={jobsQuery.data}
      projectData={projectQuery.data}
      departments={departmentsQuery.data}
      employmentType={employmentTypeQuery.data}
    />
  );
};

type ProjectUpdateProps = {
  jobsData: JobListItem[];
  projectData: OgpProjectGetResponse;
  departments: InputSelectOption[];
  employmentType: EmploymentType | undefined;
};

const ProjectsUpdate = ({
  jobsData,
  projectData,
  departments,
  employmentType,
}: ProjectUpdateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<ProjectFormType>({
    defaultValues: {
      departmentId: projectData.department.id,
      jobId: projectData.jobId,
      startDate: projectData.startDate,
      endDate: projectData.endDate,
      hourlyRate: projectData.hourlyRateCents / 100,
      weeklyHours: projectData.weekHourVolume,
    },
  });
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useUpdateProject();
  const { returnTo } = useFormButtons(mutation.status);
  const isProjectWithActivePlacement = projectData.placements.some(
    (placement) => placement.state === 'active'
  );

  const handleSubmit = () => {
    formMethods.handleSubmit((data: ProjectFormType) => {
      const payload: ProjectsUpdatePayload = {
        id: projectData.id,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        hourlyRateCents: Math.round(+data.hourlyRate * 100),
      };
      mutation.mutate(payload, {
        onSuccess: () => {
          alertSuccess('Project geüpdatet');
          navigate(generatePath(routes.Projects));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Update project" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={returnTo(paths.Projects)}
          />
        }
      >
        <Grid container>
          <Grid item xs={6}>
            <ProjectForm
              isProjectWithActivePlacement={isProjectWithActivePlacement}
              mode="update"
              jobsData={jobsData}
              departments={departments}
              employmentType={employmentType}
            />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
