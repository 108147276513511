import { DateTime } from 'luxon';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useOgpUpdateShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-update-shift';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { paths } from 'ogp/paths';
import { useCallback, useMemo } from 'react';
import { OGPCreateShiftPayload, OGPShiftDetailResponse } from 'ogp/services/shift-service.types';
import { transformData } from '../../utils/transform-data';
import { OgpShiftForm } from '../ogp-shift-form';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';

type ShiftEditProps = {
  shift: OGPShiftDetailResponse;
  shiftId: string;
};

export const OgpShiftEditDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(id);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  return <ShiftEdit shift={shiftQuery.data} shiftId={id} />;
};

const ShiftEdit = ({ shift, shiftId }: ShiftEditProps) => {
  const { alertSuccess, alertError } = useAlert();
  const updatePlanningPath = useUpdatePlanningPath();
  const theme = useTheme();
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const disableContractFields = shift.claimants.length > 0;
  const mutation = useOgpUpdateShift({
    onSuccess: (_, variables) => {
      const shiftDetailPath = updatePlanningPath({
        shiftDetailId: shift.id,
        date: DateTime.fromISO(variables.startDate),
      });
      alertSuccess(shiftAlerts.success.created);
      navigate(shiftDetailPath);
    },
    onError: (e) => alertError(e),
  });

  const onCancel = useCallback(
    () =>
      navigate(
        generatePath(paths.Shifts, {
          search: createSearchParams({ shiftDetailId: shiftId }).toString(),
        })
      ),
    [navigate, generatePath, shiftId]
  );

  const shiftData: Partial<OGPCreateShiftPayload> = useMemo(
    () => ({
      ...shift,
      ...(shift.employmentType === 'FREELANCER'
        ? {
            noShowFine: shift.noShowFineCents / 100,
          }
        : {}),
      hourlyRate: shift.hourlyRateCents / 100,
      jobId: shift.job.id,
      departmentId: shift.job.department.id,
    }),
    [shift]
  );
  const formMethods = useForm<FreelanceShiftFormData | TempWorkShiftFormData>({
    defaultValues: shiftData,
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: FreelanceShiftFormData | TempWorkShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate({ ...data, id: shiftId });
    })();
  }, [formMethods, mutation, shiftId]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Shift bewerken" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Box>
          {disableContractFields ? (
            <Typography style={{ marginBottom: theme.spacing(2) }}>
              Contract-gerelateerde velden kunnen niet aangepast worden, omdat er claims op deze
              shift staan.
            </Typography>
          ) : null}
          <Grid container>
            <Grid item xs={6}>
              <OgpShiftForm mode={disableContractFields ? 'disableContractFields' : 'update'} />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </FormProvider>
  );
};
