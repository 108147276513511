import { Table } from 'redesign/table';
import { useMemo } from 'react';
import type { ColumnDefinition, TableDefinition } from './flex-workers-table.types';
import { getFlexWorkersTableData } from './get-flex-workers-table-data';
import { getFlexWorkersColumnDefinition } from './get-flex-workers-column-definition';
import { FlexWorkerItem } from './flex-workers.types';

const FlexWorkersTable = ({
  data,
  onRemoveFlexWorker,
  onShowFlexWorker,
}: {
  data: FlexWorkerItem[];
  onShowFlexWorker: (id: string) => void;
  onRemoveFlexWorker: (id: string) => void;
}) => {
  const columns = useMemo(() => getFlexWorkersColumnDefinition(), []);
  const tableData = useMemo(() => getFlexWorkersTableData(data), [data]);

  return (
    <Table<ColumnDefinition, TableDefinition>
      stickyHeader
      columns={[
        columns.imageUrl,
        columns.name,
        columns.email,
        columns.phone,
        columns.rating,
        columns.deleteAction,
      ]}
      data={tableData}
      onShowFlexWorker={onShowFlexWorker}
      onRemoveFlexWorker={onRemoveFlexWorker}
    />
  );
};

export { FlexWorkersTable };
