import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useQuery } from 'react-query';
import { accreditationsService } from 'hq/services';
import { RawAccreditationData } from 'types/accreditations/accreditations.types';
import { AccreditationParams } from '../accreditations.types';

const useGetAccreditations = (filterParams: AccreditationParams) => {
  const queryFn = async () =>
    await accreditationsService.getAccreditations({
      certificate: filterParams.certificate,
      filter: filterParams.filter,
      order: filterParams.order,
      state: filterParams.state,
    });

  return useQuery<RawAccreditationData[], Error>(
    QUERY_KEYS_HQ.accreditations(filterParams),
    queryFn,
    {
      // No caching here because this data is highly volatile. Showing old data is very undesirable.
      // This is needed for pagination. We want to keep showing the previous data when fetching the new page.
      keepPreviousData: true,
    }
  );
};

export { useGetAccreditations };
