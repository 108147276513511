import Popover from '@mui/material/Popover';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconInfoRedesigned } from 'assets/img';
import { LwIconButton } from 'redesign/icon-button';
import { ShiftInfoProps } from './types';

export function ShiftInfoButton({ id, history }: ShiftInfoProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = getClasses();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return (
    <>
      <LwIconButton
        iconColorMode="stroke"
        color="secondary"
        onClick={(e?: React.MouseEvent<HTMLButtonElement>) => handleClick(e!)}
        data-testid="history-icon-button"
      >
        <IconInfoRedesigned className={classes.icon} />
      </LwIconButton>
      <Popover
        id={id}
        className={classes.popover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={!!anchorEl}
      >
        <Typography variant="subtitle1" className={classes.dataHeading}>
          Geschiedenis
        </Typography>

        <List className={classes.actionList}>
          {history.map((event, i) => (
            <ListItem className={classes.historyAction} key={i}>
              <Accordion>
                <AccordionSummary>
                  <Typography variant="body2">{event.definition}</Typography>
                </AccordionSummary>
                {event.properties ? (
                  <AccordionDetails>
                    <List>
                      {event.properties.map((property, j) => (
                        <ListItem className={classes.property} key={j}>
                          <Typography variant="body2">
                            {property.name}: {property.value}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                ) : null}
              </Accordion>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}

const getClasses = makeStyles((theme: Theme) => ({
  icon: { color: 'black' },
  dataHeading: {
    fontWeight: 600,
    textAlign: 'center',
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
  },
  actionList: {
    overflowY: 'scroll',
  },
  popover: {
    marginTop: theme.spacing(2),
    '& .MuiPopover-paper': {
      boxShadow: theme.shadows[3],
      borderRadius: theme.spacing(1.5),
      maxHeight: theme.spacing(70),
      maxWidth: theme.spacing(80),
      padding: `${theme.spacing(1)}px `,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  historyAction: {
    flexDirection: 'column',
    alignItems: 'start',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    borderRadius: theme.spacing(1),
    '&:nth-child(even)': { background: theme.palette.grey[100] },
  },
  property: {
    padding: theme.spacing(1 / 4, 0, 0, 2),
  },
}));
