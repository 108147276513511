import { Button, Typography } from '@mui/material';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useCancelShiftClaim } from 'hq/hooks/queryHooks/shiftclaims/use-cancel-shift-claim';

interface ShiftClaimCancelProps {
  redirect?: string;
  shiftClaimId: string;
}

const ShiftClaimCancel = ({ shiftClaimId }: ShiftClaimCancelProps) => {
  const { alertSuccess, alertError } = useAlert();

  const cancelShiftClaim = useCancelShiftClaim(shiftClaimId, {
    onSuccess: () => alertSuccess(alerts.success.unsubscribed),
    onError: (e) => alertError(e),
  });

  const handleCancelShiftClaim = async () => {
    if (window.confirm('Weet je zeker dat je deze inschrijving wilt annuleren?')) {
      cancelShiftClaim.mutate();
    }
  };

  return (
    <Button
      onClick={handleCancelShiftClaim}
      variant="contained"
      size="small"
      color="primary"
      style={{ marginRight: '8px' }}
    >
      <Typography variant="body2">Annuleer shiftClaim</Typography>
    </Button>
  );
};

export { ShiftClaimCancel };
