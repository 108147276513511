import { useParams } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import { formatTime } from 'shared/utils/formatting';
import { DateTime } from 'luxon';
import { expenseType } from 'shared/utils/constants';
import { ShiftCheckoutForm } from './shift-checkout-form';
import { ShiftCheckoutFormData } from './shift-checkout-form.types';
import { useGetWorkedShiftQuery } from '../hooks/use-get-worked-shift';
import { ShiftDetailsHeader } from '../shift-details-header/shift-details-header';

const DEFAULT_EXPENSES = expenseType.map((type) => ({
  expenseType: type.id,
  description: type.label,
  amount: '0',
}));

export const CreateCheckout = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const workedShift = useGetWorkedShiftQuery(id);

  if (workedShift.status !== 'success') {
    return <RenderUnsettledUI data={workedShift} />;
  }

  const shiftData = workedShift.data;

  const defaultValues: ShiftCheckoutFormData = {
    startDate: DateTime.fromISO(shiftData.scheduledTime.startDate).toISODate(),
    endDate: DateTime.fromISO(shiftData.scheduledTime.endDate).toISODate(),
    startTime: formatTime(DateTime.fromISO(shiftData.scheduledTime.startDate).toJSDate()),
    endTime: formatTime(DateTime.fromISO(shiftData.scheduledTime.endDate).toJSDate()),
    breakMinutes: shiftData.scheduledTime.breakMinutes ?? 0,
    expenses: DEFAULT_EXPENSES,
  };

  return (
    <Page
      header={<Header titleText="Uren indienen" />}
      pageStyle={{ height: '70%' }}
      toolbox={<ShiftDetailsHeader shiftData={shiftData} />}
    >
      <ShiftCheckoutForm
        shiftId={shiftData.shiftId}
        flexWorkerId={shiftData.flexWorkerId}
        defaultValues={defaultValues}
      />
    </Page>
  );
};
