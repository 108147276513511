import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign/forms/input';
import { Grid } from '@mui/material';
import { PartnerUserFormData } from './partner-user-form.types';

type Props = {
  onSubmit: (data: PartnerUserFormData) => void;
  state: 'update' | 'create';
};

export const PartnerUserForm = ({ onSubmit, state }: Props) => {
  const { control, handleSubmit } = useFormContext<PartnerUserFormData>();
  const phoneNumberRegex = new RegExp('^\\+[0-9]+$');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item spacing={2} xs={6}>
        <Grid item xs={12}>
          <LwFormInput
            name="firstName"
            label="Voornaam"
            control={control}
            rules={{ required: 'Voer een voornaam in' }}
          />
          <LwFormInput name="infix" label="Tussenvoegsel" control={control} />
          <LwFormInput
            name="lastName"
            label="Achternaam"
            control={control}
            rules={{ required: 'Voer een achternaam in' }}
          />
          {state === 'create' && (
            <LwFormInput
              name="email"
              label="Email"
              inputType="email"
              control={control}
              rules={{ required: 'Voer een emailadres in' }}
            />
          )}
          <LwFormInput
            name="phone"
            label="Telefoonnummer"
            inputType="tel"
            control={control}
            rules={{
              required: 'Voer een telefoonnummer in',
              pattern: {
                value: phoneNumberRegex,
                message: 'Voorbeeld: +31612345678',
              },
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
