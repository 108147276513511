import { Cell, Header, LevelTableHeaderProps, Table } from 'redesign/table';
import { ContextMenuButton, ContextMenuItem } from 'redesign/context-menu';
import { IconApproved, IconCounterOffer } from 'assets/img';
import { ItemOf } from 'types/utility';
import { formatCurrencyCents, formatDuration } from 'shared/utils/formatting';
import SortIcon from 'assets/img/icons/caret-down.svg?react';
import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { invariant } from 'shared/utils/utils';
import { pluralize } from 'shared/utils/pluralize';
import { useAlert } from 'shared/components/alerts';
import { LwButton } from 'redesign/button';
import { Box, styled, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import {
  ProjectsInboxCellProps,
  ProjectsInboxTableColumnDefinition,
  ProjectsInboxTableData,
  ProjectsInboxTableOptions,
  ProjectsInboxTableProps,
} from './projects-inbox-table.types';
import { useAddProposal, useGetProjectsCheckoutsExport } from '../hooks';
import { CheckoutsTableOptions } from '../../../shifts-checkouts/shared/columns/get-checkouts-column-definition-base.types';
import { AddProposalDialog, useAddProposalDialog, useSettlePlacementCheckout } from '../../shared';
import { FilterButton, getSettleCheckoutCopy } from '../../../shared';
import { CheckoutTypeToggle } from '../../../checkouts/checkouts-type-provider';
import { mapExpenseTypeToDescription } from '../../../../../../../shared/utils/constants';

const ProjectsInboxTable = ({
  data: queryData,
  handleSortClick,
  sortState,
  toggleDrawer,
  nrOfActiveFilters,
}: ProjectsInboxTableProps) => {
  const columns = React.useMemo(() => getProjectsInboxColumnDefinition(), []);
  const { openDialog, disableDialog, enableDialog, closeDialog } = useDialog();
  const { alertError, alertSuccess } = useAlert();
  const settleCheckout = useSettlePlacementCheckout();
  const exportCheckout = useGetProjectsCheckoutsExport();
  const addProposal = useAddProposal();
  const { getProposalData, handleUpdateProposalData } = useAddProposalDialog();
  const [selected, setSelected] = useState<string[]>([]);
  const tableData = useMemo(() => queryData.items, [queryData.items]);
  const onSelectionChange = useCallback((rows: ProjectsInboxTableColumnDefinition[]) => {
    setSelected(rows.map((i) => i.id));
  }, []);

  const handleExportCheckout = (checkoutIds: string[]) => () => {
    const pluralTitleCheckouts = pluralize(
      checkoutIds.length,
      'checkout',
      `${checkoutIds.length} checkouts`
    );
    const title = `Weet je zeker dat je deze ${pluralTitleCheckouts} wilt exporteren?`;

    openDialog(
      {
        title,
        content: `De export wordt als csv bestand opgeslagen`,
        primaryButton: (props) => <PrimaryButton {...props}>Exporteer</PrimaryButton>,
      },
      () => {
        disableDialog();
        exportCheckout.mutate(
          { checkoutIds },
          {
            onSuccess: () => {
              const pluralCheckouts = pluralize(
                checkoutIds.length,
                'checkout is',
                `${checkoutIds.length} checkouts zijn`
              );
              alertSuccess(`De ${pluralCheckouts} geëxporteerd!`);
              closeDialog();
            },
            onError: (e) => {
              alertError(e);
            },
            onSettled: enableDialog,
          }
        );
      }
    );
  };

  const handleSettleCheckout = (checkoutIds: string[]) => () => {
    const { dialog } = getSettleCheckoutCopy(checkoutIds);
    openDialog(
      {
        ...dialog,
        primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
      },
      () => {
        disableDialog();
        settleCheckout.mutate(checkoutIds, {
          onSuccess: closeDialog,
          onSettled: enableDialog,
        });
      }
    );
  };

  const handleAddProposal = (item: ItemOf<ProjectsInboxTableData['items']>['actions']) => () => {
    openDialog(
      {
        title: 'Tegenvoorstel',
        content: <AddProposalDialog onChange={handleUpdateProposalData} item={item} />,
      },
      () => {
        disableDialog();
        const proposalData = getProposalData();
        invariant(proposalData, 'Proposal data not set');
        addProposal.mutate(
          {
            placementId: item.placement.id,
            checkoutId: item.id,
            payload: proposalData,
          },
          {
            onSuccess: closeDialog,
            onSettled: enableDialog,
          }
        );
      }
    );
  };

  const getRowActions = (item: ItemOf<ProjectsInboxTableData['items']>['actions']) => (
    <ContextMenuButton menuId={`project-actions-menu-${item.id}`}>
      <ContextMenuItem onClick={handleAddProposal(item)}>
        <IconCounterOffer />
        Tegenvoorstel
      </ContextMenuItem>
      <ContextMenuItem onClick={handleSettleCheckout([item.id])}>
        <IconApproved /> Akkoord
      </ContextMenuItem>
    </ContextMenuButton>
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end" gap={10} marginBottom={4}>
        <LwButton
          onClick={handleSettleCheckout(selected)}
          disabled={selected.length === 0}
          color="primary"
        >
          Akkoord
        </LwButton>
        <LwButton
          disabled={selected.length === 0}
          onClick={handleExportCheckout(selected)}
          color="primary"
        >
          Exporteer
        </LwButton>
        <CheckoutTypeToggle />
        <FilterButton onClick={toggleDrawer} nrOfActiveFilters={nrOfActiveFilters} />
      </Box>
      <Table<ProjectsInboxTableColumnDefinition, ProjectsInboxTableOptions>
        columns={columns}
        data={tableData}
        getRowActions={getRowActions}
        sortState={sortState}
        onSortClick={handleSortClick}
        onSelectionChange={onSelectionChange}
        selectable={true}
        selectAllMode="VISIBLE"
      />
    </>
  );
};

const getProjectsInboxColumnDefinition = (): Column<ProjectsInboxTableColumnDefinition>[] => {
  const flexWorker: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'flexWorker',
    Header: () => <Header>Flexwerker</Header>,
    Cell: (cell) => <Cell>{cell.value.fullName}</Cell>,
  };
  const project: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'project',
    Header: () => <Header>Project</Header>,
    Cell: (cell) => <Cell>{cell.value}</Cell>,
  };
  const breakMinutes: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'breakMinutes',
    Header: () => <Header>Pauze</Header>,
    Cell: (cell) => <Cell>{formatDuration({ minutes: cell.value })}</Cell>,
  };
  const createdAt: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'createdAt',
    Header: CreatedAtHeaderCell,
    Cell: (cell) => <Cell>{cell.value}</Cell>,
  };
  const workedFrom: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'workedFrom',
    Header: () => <Header>Begintijd</Header>,
    Cell: (cell) => <Cell>{cell.value}</Cell>,
  };
  const workedTo: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'workedTo',
    Header: () => <Header>Eindtijd</Header>,
    Cell: (cell) => <Cell>{cell.value}</Cell>,
  };
  const actions: Column<ProjectsInboxTableColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };
  const expense: Column<ProjectsInboxTableColumnDefinition> = {
    Header: () => <Header>Gemaakte kosten</Header>,
    accessor: 'expense',
    Cell: (cell) => (
      <Cell>
        {cell.value
          ? cell.value.map((exp) => (
              <Box key={exp.expenseType}>
                {mapExpenseTypeToDescription(exp.expenseType)}:{' '}
                {formatCurrencyCents(exp.amountInCents)}
              </Box>
            ))
          : 'Geen'}
      </Cell>
    ),
  };

  return [flexWorker, project, createdAt, workedFrom, workedTo, expense, breakMinutes, actions];
};

const CreatedAtHeaderCell = (
  cell: LevelTableHeaderProps<ProjectsInboxTableColumnDefinition, CheckoutsTableOptions>
) => {
  return (
    <LwButton
      color="secondary"
      onClick={() => {
        cell.onSortClick('date');
      }}
    >
      <Typography variant="body2" color="secondary">
        Datum
      </Typography>
      <StyledSortIcon
        style={{
          transform: cell.sortState.order === 'asc' ? 'rotate(180deg)' : '',
          visibility: cell.sortState.sort === 'date' ? 'visible' : 'hidden',
        }}
      />
    </LwButton>
  );
};

const StyledSortIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  height: '12px',
  width: '12px',
  transition: 'ease transform .5s',
}));

const ActionCell = (cell: ProjectsInboxCellProps<'actions'>) => {
  const actions = cell.getRowActions(cell.value);
  return <>{actions}</>;
};

export { ProjectsInboxTable };
