import { Box, Typography } from '@mui/material';
import { WorkExperienceBox } from './work-experience-box';
import {
  FlexWorkerProfileData,
  FlexWorkerProfileHQ,
} from '../../../../types/flexWorkers/FlexWorkerTypes';

const ProfileWorkExperienceTab = ({
  workExperiences,
}: {
  workExperiences:
    | FlexWorkerProfileData['workExperiences']
    | FlexWorkerProfileHQ['workExperiences'];
}) => {
  return (
    <Box mt={4}>
      <Typography variant="h3">Werkervaring</Typography>
      <Box data-testid="work-experience-list" mt={2}>
        {workExperiences && workExperiences.length > 0
          ? workExperiences.map((experience) => (
              <WorkExperienceBox key={experience.companyName} experience={experience} />
            ))
          : 'De flexwerker heeft nog geen werkervaring'}
      </Box>
    </Box>
  );
};
export { ProfileWorkExperienceTab };
