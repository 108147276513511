import { Page } from 'redesign/page';
import { useToggle } from 'shared/hooks';
import { RenderUnsettledUI } from 'shared/components';
import { useLevelPagination } from 'ogp/hooks/queryHooks/misc/use-level-pagination';
import { Toolbox } from 'redesign/toolbox';
import { PaginationIndicator } from 'ogp/components/common/pagination-indicator';
import { Box, Drawer } from '@mui/material';
import { Header } from 'redesign/header';
import { useGetProjectsCheckoutsArchive } from './hooks/use-get-projects-checkouts-archive';
import { CheckoutsArchiveTable } from './components/table/checkouts-archive-table';
import { CheckoutsArchiveFilters } from './components/checkouts-archive-filters/checkouts-archive-filters';
import { FilterButton, useCheckoutsArchivePage } from '../../shared';
import { CheckoutTypeToggle } from '../../checkouts/checkouts-type-provider';

const ProjectsCheckoutsArchive = () => {
  const { filters, setFilters, filterCount, sortState, updateSort } = useCheckoutsArchivePage();
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const pagination = useLevelPagination();
  const archiveQuery = useGetProjectsCheckoutsArchive({
    page: pagination.page,
    size: pagination.size,
    ...sortState,
    ...filters,
  });

  const handleSubmit = (data: Partial<typeof filters>) => {
    setFilters(data);
    toggleFilters();
  };

  return (
    <>
      <Page
        header={
          <Header titleText="Archief" subheaderText="Hier zie je alle gewerkte projects diensten" />
        }
        toolbox={
          <Toolbox>
            <Toolbox.PushRight>
              <CheckoutTypeToggle />
              <FilterButton
                onClick={toggleFilters}
                nrOfActiveFilters={filterCount}
                showSpinner={archiveQuery.status === 'loading'}
              />
            </Toolbox.PushRight>
          </Toolbox>
        }
      >
        {archiveQuery.status !== 'success' ? (
          <RenderUnsettledUI data={archiveQuery} />
        ) : (
          <Box display="flex" width="100%" maxHeight={'100%'} flexDirection={'column'}>
            <CheckoutsArchiveTable
              data={{
                ...archiveQuery.data,
                items: archiveQuery.data.items.map((item) => ({
                  ...item,
                  sortState,
                  handleSortClick: updateSort,
                })),
              }}
            />
            <Box mt="auto">
              <PaginationIndicator
                pageCount={archiveQuery.data.totalCount}
                pagination={pagination}
              />
            </Box>
          </Box>
        )}
      </Page>

      <Drawer anchor="right" open={filtersOpen} onClose={toggleFilters}>
        <CheckoutsArchiveFilters
          onSubmit={handleSubmit}
          filters={filters}
          onCancel={toggleFilters}
        />
      </Drawer>
    </>
  );
};

export { ProjectsCheckoutsArchive };
