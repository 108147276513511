import { RenderUnsettledUI } from 'shared/components';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { IconApproved } from 'assets/img';
import { DateTime } from 'luxon';
import { IconBox, SkillBox, StyledChip } from './shared/components';
import { useGetFlexworkerAccreditations } from '../../hooks/use-get-flexworker-accreditations';

const ProfileCertificates = ({ flexWorkerId }: { flexWorkerId: string }) => {
  const accreditationsQuery = useGetFlexworkerAccreditations(flexWorkerId);

  if (accreditationsQuery.status !== 'success') {
    return <RenderUnsettledUI data={accreditationsQuery} />;
  }

  const { data } = accreditationsQuery;

  return (
    <SkillBox>
      {data && data.length > 0
        ? data.map((certificate, index) => (
            <StyledTooltip
              key={index}
              title={
                certificate.expirationDate
                  ? 'Verloopt op ' +
                    DateTime.fromJSDate(new Date(certificate.expirationDate)).toFormat('dd/MM/yy')
                  : 'Geen vervaldatum'
              }
              arrow
              placement="bottom"
            >
              <StyledChip
                label={
                  <IconBox>
                    {certificate.certificate.name} <IconApproved data-testid="certificate-svg" />
                  </IconBox>
                }
                key={index}
                data-testid="certificate-chip"
              />
            </StyledTooltip>
          ))
        : 'De flexwerker heeft nog geen geverifieerde certificaten'}
    </SkillBox>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(0, 43, 255)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 43, 255)',
    padding: '8px 16px',
    borderRadius: theme.spacing(6),
    opacity: 1,
    fontSize: 14,
    fontWeight: 600,
  },
}));

export { ProfileCertificates };
