import { DialogContentText, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { LwFormDate } from 'redesign/forms/date';
import { LwFormNumberInput } from 'redesign/forms/number-input';
import { LwFormSelect } from 'redesign/forms/select';
import { LwFormTime } from 'redesign/forms/time';
import { formatDateTime, formatTime } from 'shared/utils/formatting';
import { getMinDate } from 'shared/utils/get-min-date';
import { FirstParamOfFn, ItemOf, TakeDefined } from 'types/utility';
import { RenderUnsettledUI } from 'shared/components';
import { expenseType, mapExpenseTypeToDescription } from 'shared/utils/constants';
import { ProposalFormData } from './add-proposal-dialog.types';
import { useGetBreakOptions } from '../../../../shared';
import { ProjectsInboxTableData } from '../../../inbox/components/projects-inbox-table.types';
import { AddProposalPayload } from '../../../../../../../../shared/services/placement-service.types';

type Props = {
  item: Pick<
    ItemOf<ProjectsInboxTableData['items']>['actions'],
    'proposal' | 'breakMinutes' | 'expense'
  >;
  onChange: (
    data: Omit<ProposalFormData, 'breakMinutes' | 'expense'> & {
      breakOptionId: string;
      expense: (Omit<ItemOf<TakeDefined<ProposalFormData['expense']>>, 'amount'> & {
        amountInCents: number;
      })[];
    }
  ) => void;
};

const AddProposalDialog = (props: Props) => {
  const breakOptionsQuery = useGetBreakOptions();

  if (breakOptionsQuery.status !== 'success') {
    return <RenderUnsettledUI data={breakOptionsQuery} />;
  }

  return <AddProposalDialogForm breakOptions={breakOptionsQuery.data} {...props} />;
};

const AddProposalDialogForm = ({
  breakOptions,
  item,
  onChange,
}: Props & { breakOptions: TakeDefined<ReturnType<typeof useGetBreakOptions>['data']> }) => {
  const { allowExpenses } = useFeatureFlagContext();

  const { minDate } = getMinDate({ allowDateBeforeToday: true });
  const { control, watch, getValues } = useForm<ProposalFormData>({
    defaultValues: transformProposalToFormData(item),
  });

  const transformFormData = React.useCallback(
    (data: ProposalFormData) => {
      const { breakMinutes, ...values } = data;
      const breakOptionId = breakOptions.find((o) => o.value === breakMinutes)?.id;

      const expense = allowExpenses
        ? values.expense?.map(({ amount, ...exp }) => ({
            ...exp,
            amountInCents: amount * 100,
          }))
        : expenseType.map((i) => ({
            expenseType: i.id,
            description: i.label,
            amountInCents: 0,
          }));

      return {
        ...values,
        expense,
        breakOptionId,
      };
    },
    [allowExpenses, breakOptions]
  );

  React.useEffect(() => {
    // @ts-expect-error fix breakOptionId
    onChange(transformFormData(getValues()));
  }, [onChange, getValues, transformFormData]);

  React.useEffect(() => {
    const subscription = watch((data) => {
      // @ts-expect-error fix breakOptionId
      onChange(transformFormData(data as FirstParamOfFn<Props['onChange']>)); // I really don't know better way to type this right now
    });

    return subscription.unsubscribe;
  }, [onChange, watch, transformFormData]);

  return (
    <>
      <DialogContentText>
        Een tegenvoorstel doen betekent dat de flexwerker de mogelijkheid krijgt om het
        tegenvoorstel te accepteren in de app.
      </DialogContentText>
      <Grid item container spacing={3} xs={12} my={4}>
        <Grid item xs={12}>
          <DialogContentText>Ontvangen uren van Flexwerker</DialogContentText>
        </Grid>
        <Grid item xs={9}>
          <DialogContentText>
            {`${formatDateTime(
              DateTime.fromISO(item.proposal.startDate).toJSDate()
            )} - ${formatDateTime(DateTime.fromISO(item.proposal.endDate).toJSDate())}`}
          </DialogContentText>
          <DialogContentText>
            Pauze: {item.breakMinutes !== 0 ? `${item.breakMinutes} min` : 'Geen'}
          </DialogContentText>
        </Grid>
      </Grid>
      <DialogContentText>Uren aanpassen</DialogContentText>
      <Grid style={{ alignItems: 'end' }} container item spacing={2} xs={12}>
        <Grid item xs={6}>
          <LwFormDate
            name="startDate"
            label="Van"
            control={control}
            minDate={minDate}
            maxDate={watch('endDate')}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormTime
            name="startTime"
            label=""
            control={control}
            max={watch('startDate') === watch('endDate') ? watch('endTime') : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormDate name="endDate" label="Tot" control={control} minDate={watch('startDate')} />
        </Grid>
        <Grid item xs={6}>
          <LwFormTime
            name="endTime"
            label=""
            control={control}
            min={watch('startDate') === watch('endDate') ? watch('startTime') : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormSelect
            name="breakMinutes"
            label="Pauze"
            defaultLabel="Selecteer de lengte van de pauze..."
            options={breakOptions}
            control={control}
          />
        </Grid>
        {allowExpenses ? (
          <>
            <Grid item xs={12}>
              <DialogContentText>Expense</DialogContentText>
            </Grid>
            {expenseType.map((exp, i) => (
              <Grid key={exp.id} item xs={12}>
                <LwFormNumberInput
                  name={`expense.${i}.amount`}
                  label={exp.label}
                  control={control}
                />
              </Grid>
            ))}
          </>
        ) : null}
      </Grid>
    </>
  );
};

const transformProposalToFormData = (
  input: React.ComponentProps<typeof AddProposalDialog>['item']
) => {
  return {
    startDate: DateTime.fromISO(input.proposal.startDate).toISODate(),
    endDate: DateTime.fromISO(input.proposal.endDate).toISODate(),
    startTime: formatTime(DateTime.fromISO(input.proposal.startDate).toJSDate()),
    endTime: formatTime(DateTime.fromISO(input.proposal.endDate).toJSDate()),
    breakMinutes: input.breakMinutes,
    expense: input.expense,
  };
};

const useAddProposalDialog = () => {
  const proposalData = React.useRef<AddProposalPayload | undefined>();
  const handleUpdateProposalData = (
    data: FirstParamOfFn<React.ComponentProps<typeof AddProposalDialog>['onChange']>
  ) => {
    proposalData.current = {
      endDateTime: DateTime.fromISO(data.endDate + 'T' + data.endTime).toISO(),
      startDateTime: DateTime.fromISO(data.startDate + 'T' + data.startTime).toISO(),
      breakOptionId: data.breakOptionId,
      expenses: data.expense?.map((exp) => ({
        description: mapExpenseTypeToDescription(exp.expenseType),
        expenseType: exp.expenseType,
        amountInCents: exp.amountInCents,
      })),
    };
  };

  return { getProposalData: () => proposalData.current, handleUpdateProposalData };
};

export { AddProposalDialog, transformProposalToFormData, useAddProposalDialog };
