import { useToggle } from 'shared/hooks';
import { Toolbox } from 'redesign/toolbox';
import { FilterButton } from 'ogp/components/views/administration/shared';

import { Table, useOnScrollDown } from 'redesign/table';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { RenderUnsettledUI } from 'shared/components';
import { LwButton } from 'redesign/button';
import { IconAdd } from 'assets/img';
import { LWModal } from 'redesign/modal';
import { FeatureFlagProvider } from 'shared/contexts/feature-flag-provider';
import { useSearchParams } from 'react-router-dom';
import { Box, Dialog } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useGetShiftPlanningShifts } from './hooks/use-get-shift-planning-shifts';
import { getShiftPlanningTableData } from './utils/get-shift-planning-table-data';
import {
  HqShiftPlanningColumnDefinition,
  HqShiftPlanningTableOptions,
  INITIAL_PAGE,
  ShiftPlanningTableProps,
} from './shift-planning-table.types';
import { getShiftPlanningColumnDefinition } from './utils/get-shift-planning-column-definition';
import { useShiftPlanningPage } from './hooks/use-shift-planning';
import { ShiftPlanningDataProvider } from '../filters/shift-planning-filters-data-provider';
import { ShiftPlanningModalProvider } from '../shift-planning-modal/shift-planning-modal';
import { BulkForwardToPartnerDialog } from '../bulk-forward-to-partner/bulk-forward-to-partner';
import { stringifyFilters } from '../../../../../../shared/utils/stringify-filters';

export const ShiftPlanningTableDataProvider = () => {
  const { filters: appliedFilters } = useShiftPlanningPage();

  const shiftsQuery = useGetShiftPlanningShifts({
    fromDate: appliedFilters.fromDate ?? undefined,
    company: appliedFilters.company ?? undefined,
    branchOffice: appliedFilters.branchOffice ?? undefined,
    department: appliedFilters.department ?? undefined,
    jobType: appliedFilters.jobType ?? undefined,
    hasApplicants: appliedFilters.hasApplicants ?? undefined,
    filledStatus: appliedFilters.filledStatus ?? undefined,
    partner: appliedFilters.partner ?? undefined,
    pageParam: INITIAL_PAGE,
    pageSizeParam: DEFAULT_PAGE_SIZE,
  });

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }

  return <ShiftPlanningTable shiftsQuery={shiftsQuery} />;
};

const ShiftPlanningTable = ({ shiftsQuery }: ShiftPlanningTableProps) => {
  const { data } = shiftsQuery;
  const generatePath = useHqRelativePath();
  const { filters: appliedFilters, setFilters, selectedFilterCount } = useShiftPlanningPage();
  const flatData = useMemo(() => data.pages.flatMap((page) => page.items), [data]);
  const tableData = useMemo(() => getShiftPlanningTableData(flatData), [flatData]);
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
  const onSelectionChange = useCallback((rows: HqShiftPlanningColumnDefinition[]) => {
    setSelectedShiftIds(rows.map((i) => i.id));
  }, []);
  const columns = useMemo(() => getShiftPlanningColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: shiftsQuery,
    flatData: flatData,
  });

  const { on: filtersOpen, toggle: toggleFilters } = useToggle();

  const [searchParams] = useSearchParams();
  const defaultShiftDetailId = searchParams.get('shiftDetailId');
  const [shiftId, setShiftId] = useState<string | undefined>(defaultShiftDetailId || undefined);

  const openShiftDetail = useCallback(
    (shiftId: string) => {
      setFilters({ shiftDetailId: shiftId });
      setShiftId(shiftId);
    },
    [setFilters]
  );

  const closeShiftDetail = useCallback(() => {
    setFilters({ shiftDetailId: undefined });
    setShiftId(undefined);
  }, [setFilters]);

  const [isBulkDialogOpen, setIsBulkDialogOpen] = useState(false);

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Toolbox underline={false}>
        <LwButton
          to={generatePath(
            hqRoutes.PlannerShiftCreateShift,
            undefined,
            stringifyFilters(appliedFilters)
          )}
          color="primary"
          startIcon={<IconAdd />}
          iconColorMode="fill"
        >
          Voeg shift toe
        </LwButton>
        <LwButton
          disabled={selectedShiftIds.length === 0}
          color="secondary"
          onClick={() => setIsBulkDialogOpen(true)}
          iconColorMode="fill"
        >
          Naar partners{selectedShiftIds.length > 0 ? ` (${selectedShiftIds.length})` : ''}
        </LwButton>

        <Toolbox.PushRight>
          <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
        </Toolbox.PushRight>
      </Toolbox>

      <Box minHeight={0}>
        <Table<HqShiftPlanningColumnDefinition, HqShiftPlanningTableOptions>
          stickyHeader={true}
          columns={columns}
          data={tableData}
          tableStyle={{ tableLayout: 'fixed' }}
          onScroll={fetchIfBottomScrolled}
          onShowDetail={openShiftDetail}
          data-testid="shift-planning-virtual-table"
          onSelectionChange={onSelectionChange}
          selectable={true}
        />
      </Box>
      <FeatureFlagProvider>
        <ShiftPlanningDataProvider
          appliedFilters={appliedFilters}
          handleFiltersChange={setFilters}
          isOpen={filtersOpen}
          toggleFilters={toggleFilters}
        />
      </FeatureFlagProvider>

      <LWModal isOpen={shiftId != null} onClose={closeShiftDetail} testId="shift-detail">
        {shiftId ? (
          <ShiftPlanningModalProvider
            shiftDetailId={shiftId}
            onDelete={shiftsQuery.refetch}
            closeShiftDetail={closeShiftDetail}
          />
        ) : null}
      </LWModal>

      <Dialog open={isBulkDialogOpen} onClose={() => setIsBulkDialogOpen(false)} fullWidth={true}>
        <BulkForwardToPartnerDialog
          shiftIds={selectedShiftIds}
          closeDialog={() => setIsBulkDialogOpen(false)}
        />
      </Dialog>
    </Box>
  );
};
