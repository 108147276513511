import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign/forms/number-input';

type NoShowFineField = {
  noShowFine: number;
};

export const NoShowFineInput = ({
  disabled,
  helperText,
  placeholder,
}: {
  disabled: boolean;
  helperText: string;
  placeholder: string;
}) => {
  const { control } = useFormContext<NoShowFineField>();

  return (
    <LwFormNumberInput
      name="noShowFine"
      label="No show boete"
      control={control}
      rules={{ required: 'Voer een no show boete in, 0 euro is toegestaan' }}
      step={0.01}
      min={0}
      max={999}
      placeholder={placeholder}
      helperText={helperText}
      data-testid="no-show"
      disabled={disabled}
    />
  );
};
