import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { JobDataWithId } from 'types/jobs/JobTypes';

type JobMutationOptions = UseMutationOptions<unknown, unknown, JobDataWithId>;

export const useUpdateJob = () => {
  const mutationFn = async (job: JobDataWithId) => await jobService.updateJob(job);

  const queryClient = useQueryClient();
  const mutationOptions: JobMutationOptions = {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.jobList());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.job(variables.id));
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
