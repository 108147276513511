import { usePageProvider } from 'ogp/layouts';
import { errorTextWrapperClassName, LwFormSelect } from 'redesign/forms/select';
import { useForm } from 'react-hook-form';
import { ListSubheader, MenuItem } from '@mui/material';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments';

const PLACEHOLDER_VALUE = 'value';
const PLACEHOLDER_OPTION = [
  { label: '', value: '', options: [{ value: PLACEHOLDER_VALUE, label: 'Toon alle afdelingen' }] },
];

const DepartmentSelect = () => {
  const [{ filters }, { setDepartmentId }] = usePageProvider();
  const form = useForm({
    defaultValues: {
      departmentId: filters.departmentId ?? PLACEHOLDER_VALUE,
    },
  });
  const {
    data: branchOfficesWithNames,
    error,
    refetch,
    status,
  } = useGetDepartmentsAsSelect({ includeArchived: false });

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setDepartmentId(e.target.value === 'value' ? undefined : e.target.value);
  };

  if (status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={{ error, status, refetch }}
      />
    );
  }

  return (
    <LwFormSelect
      sx={{
        [`& .${errorTextWrapperClassName}`]: {
          display: 'none',
        },
        '& .MuiBox-root': {
          display: 'none',
        },
      }}
      onChange={handleSelect}
      options={[...PLACEHOLDER_OPTION, ...(branchOfficesWithNames ?? [])]}
      label=""
      name="departmentId"
      control={form.control}
      renderOption={(opt) => [
        <ListSubheader key={opt.value} value={opt.value}>
          <em>{opt.label}</em>
        </ListSubheader>,
        opt.options.map((dpt) => (
          <MenuItem key={dpt.value} value={dpt.value}>
            {dpt.label}
          </MenuItem>
        )),
      ]}
    />
  );
};

export { DepartmentSelect };
