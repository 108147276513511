import {
  BusCSVUploadPayload,
  BusCSVUploadResponse,
  XMLUploadPayload,
  XMLUploadResponse,
} from 'types/adminSettings/admin-settings-service.types';
import { FeatureFlags } from 'shared/services/mappers/mappers';
import { HqApiService } from './hqApiService';

class AdminSettingsService {
  constructor(private readonly hqApiService: HqApiService) {}

  uploadBusCsv = async (uploadBusCsvInfo: BusCSVUploadPayload) => {
    const formData = new FormData();
    formData.append('file', uploadBusCsvInfo.file);
    return await this.hqApiService.create<BusCSVUploadResponse>({
      path: 'admin-settings/process-csv',
      body: formData,
    });
  };

  uploadXML = async (uploadXMLInfo: XMLUploadPayload) => {
    const formData = new FormData();
    formData.append('file', uploadXMLInfo.file);
    return await this.hqApiService.create<XMLUploadResponse>({
      path: 'admin-settings/process-xml',
      body: formData,
    });
  };

  updateFeatureFlags = async (featureFlags: Partial<FeatureFlags>) => {
    await this.hqApiService.update({
      path: `admin-settings/update-feature-flags`,
      body: { featureFlags },
    });
  };
}

export { AdminSettingsService };
