import { PartnerApiService } from './partner-api-service';
import {
  PartnerCheckoutInboxFiltersResponse,
  PartnerCounterOffer,
  PartnerPortalBreakMinutesOption,
  PartnerPortalCheckout,
  PartnerPortalCheckoutParams,
  PartnerPortalCheckouts,
  PartnerPortalCheckoutsMetaData,
  PartnerPortalCheckoutsParams,
  PartnerPortalCounterOffers,
} from './partner-checkouts-service.types';

export class PartnerCheckoutsService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public async getWorkedShifts(
    params: PartnerPortalCheckoutsParams
  ): Promise<PartnerPortalCheckouts> {
    const result = await this.partnerApiService.get<PartnerPortalCheckouts>({
      path: 'checkouts/worked-shifts',
      params: {
        ...params,
        page: params.pageParam,
        pageSize: params.pageSizeParam,
        fromDate: params.fromDate?.toISODate(),
        toDate: params.toDate?.toISODate(),
      },
    });
    return result;
  }

  getWorkedShift = async (shiftClaimId: string): Promise<PartnerPortalCheckout> => {
    const result = await this.partnerApiService.get<PartnerPortalCheckout>({
      path: `checkouts/worked-shifts/${shiftClaimId}`,
    });

    return result;
  };

  public async getCounterOffers(
    params: PartnerPortalCheckoutsParams
  ): Promise<PartnerPortalCounterOffers> {
    const result = this.partnerApiService.get<PartnerPortalCounterOffers>({
      path: 'checkouts/counter-offers',
      params: {
        page: params.pageParam,
        pageSize: params.pageSizeParam,
        fromDate: params.fromDate?.toISODate(),
        toDate: params.toDate?.toISODate(),
      },
    });

    return result;
  }

  getCounterOffer = async (shiftClaimId: string): Promise<PartnerCounterOffer> => {
    const result = this.partnerApiService.get<PartnerCounterOffer>({
      path: `checkouts/counter-offers/${shiftClaimId}`,
    });

    return result;
  };

  getMetaData = async (): Promise<PartnerPortalCheckoutsMetaData> => {
    const result = this.partnerApiService.get<PartnerPortalCheckoutsMetaData>({
      path: 'checkouts/meta-data',
    });

    return result;
  };

  getBreakOptions = async (): Promise<PartnerPortalBreakMinutesOption[]> => {
    const result = this.partnerApiService.get<PartnerPortalBreakMinutesOption[]>({
      path: `checkouts/break-options`,
    });

    return result;
  };

  createCheckout = async ({
    shiftId,
    flexWorkerId,
    ...body
  }: PartnerPortalCheckoutParams): Promise<void> => {
    await this.partnerApiService.update({
      path: `checkouts/worked-shifts/${shiftId}/${flexWorkerId}`,
      body,
    });
  };

  approveCounterOffer = async (shiftClaimId: string): Promise<void> => {
    await this.partnerApiService.update({
      path: `checkouts/counter-offers/${shiftClaimId}`,
    });
  };

  getCheckoutInboxFilters = async () => {
    const checkoutInboxFilters =
      await this.partnerApiService.get<PartnerCheckoutInboxFiltersResponse>({
        path: `checkouts/inbox-filters`,
      });

    return checkoutInboxFilters;
  };
}
