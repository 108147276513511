import { BreakOption, Shift } from 'types/shifts/shift-types';
import { HqApiService } from './hqApiService';
import {
  GetShiftCreateDataResponse,
  GetShiftEligiblePartnerResponse,
  GetShiftPartnerResponse,
  HQCreateShiftPayload,
  HQMultiplyShiftPayload,
  HQShiftDetailResponse,
  HQUpdateShiftPayload,
  OpenShiftToPartnersRequest,
} from './shift-service.types';

class ShiftService {
  constructor(private readonly hqApiService: HqApiService) {}

  getShift = async (shiftId: string) => {
    const response = await this.hqApiService.get<HQShiftDetailResponse>({
      path: `shifts/${shiftId}`,
    });
    return response;
  };

  /**
   * Get breakoptions
   */
  getBreakOptions = async () => {
    const response = await this.hqApiService.get<{ breakOptions: BreakOption[] }>({
      path: `shifts/get-break-options`,
    });

    return response;
  };

  getShiftCreateData = async (companyId: string) => {
    const response = await this.hqApiService.get<GetShiftCreateDataResponse>({
      path: `shifts/get-shift-create-data`,
      params: { companyId },
    });

    return response;
  };

  getShiftPartners = async (shiftId: string) => {
    const response = await this.hqApiService.get<GetShiftPartnerResponse>({
      path: `shifts/${shiftId}/partners`,
    });
    return response;
  };

  getShiftEligiblePartners = async (shiftId: string) => {
    const response = await this.hqApiService.get<GetShiftEligiblePartnerResponse>({
      path: `shifts/${shiftId}/eligible-partners`,
    });
    return response;
  };

  archiveShift = async (shiftId: string) => {
    return await this.hqApiService.update({
      path: `shifts/${shiftId}/archive`,
    });
  };

  createShift = async (shift: HQCreateShiftPayload) => {
    return await this.hqApiService.create({
      path: `shifts`,
      body: shift,
      decoder: (json: any) => json.shift as Shift,
    });
  };

  updateShift = async (shift: HQUpdateShiftPayload) => {
    return await this.hqApiService.update({
      path: `shifts/${shift.id}`,
      body: shift,
    });
  };

  multiplyShift = async (params: HQMultiplyShiftPayload) => {
    return await this.hqApiService.create({
      path: `shifts/${params.id}/multiply`,
      body: params,
    });
  };

  openShiftToPartners = async (data: OpenShiftToPartnersRequest) => {
    return await this.hqApiService.update({
      path: `shifts/shift-partners`,
      body: data,
    });
  };
}

export { ShiftService };
