import { useAlert } from 'shared/components/alerts';
import { partnerUserAlerts } from 'shared/components/alerts/alert-messages';
import { useNavigate, useParams } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';

import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { LwButton } from 'redesign/button';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { useCallback } from 'react';
import { invariant } from 'shared/utils/utils';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useCreatePartnerUser } from './../hooks/use-create-partner';
import { PartnerUserForm } from './partner-user-form';
import { PartnerUserFormData } from './partner-user-form.types';

export const PartnerUserCreate = () => {
  const { id: partnerId } = useParams<'id'>();
  invariant(partnerId);
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const createPartnerUser = useCreatePartnerUser();
  const queryClient = useQueryClient();
  const formMethods = useForm<PartnerUserFormData>();
  const { submitButtonProps, cancelButtonProps } = useFormButtons(createPartnerUser.status);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data) =>
      createPartnerUser.mutate(
        { ...data, partnerId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QUERY_KEYS_HQ.partnerUsers(partnerId));
            alertSuccess(partnerUserAlerts.success.created);
            navigate(generatePath(hqRoutes.PartnerUsers, { id: partnerId }));
          },
          onError: (e) => alertError(e),
        }
      )
    )();
  }, [
    formMethods,
    createPartnerUser,
    partnerId,
    queryClient,
    alertSuccess,
    navigate,
    generatePath,
    alertError,
  ]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe partner gebruiker aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(hqRoutes.PartnerUsers, { id: partnerId }))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerUserForm onSubmit={handleSubmit} state="create" />
      </Page>
    </FormProvider>
  );
};
