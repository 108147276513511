import {
  FlexPool,
  FlexPoolFormValues,
  FlexPoolWithJobsAndFlexWorkers,
} from 'types/flex-pools/flex-pool-types';
import {
  FlexWorkerFlexPools,
  FlexWorkerListItem,
  FlexWorkerProfileData,
} from 'types/flexWorkers/FlexWorkerTypes';
import { HqApiService, Response } from './hqApiService';

export class FlexPoolService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Create a new flexPool
   */
  createFlexPool = async (flexPool: FlexPoolFormValues): Promise<void> => {
    // POST new flexPool to the Backend API
    await this.hqApiService.create({
      path: `flex-pools`,
      body: {
        ...flexPool,
      },
    });
  };

  /**
   * Update an existing flexPool
   */
  updateFlexPool = async (flexPoolId: string, flexPool: FlexPoolFormValues) => {
    await this.hqApiService.update({
      path: `flex-pools/${flexPoolId}`,
      body: flexPool,
    });
  };

  /**
   * Get a list of existing flexPools
   */
  getFlexPools = async (): Promise<FlexPool[]> => {
    const flexPools = await this.hqApiService.get<FlexPool[]>({
      path: 'flex-pools',
    });

    return flexPools;
  };

  /**
   * Unsubscribe a flexWorker from a flexPool
   */
  // TODO: Needs refactor after implementation in backend. flexWorkerId should be in body
  unsubscribeFlexPool = async (flexPoolId: string, flexWorkerId: string) => {
    await this.hqApiService.update({
      path: `flex-pools/${flexPoolId}?flexWorkerId=${flexWorkerId}`,
      body: {
        unsubscribe: true,
      },
    });
  };

  /**
   * Get a single flexPool
   */
  getFlexPool = async (flexPoolId: string): Promise<FlexPoolWithJobsAndFlexWorkers> => {
    const { flexPool } = await this.hqApiService.get<Response<FlexPoolWithJobsAndFlexWorkers>>({
      path: `flex-pools/${flexPoolId}`,
    });

    return flexPool;
  };

  getFlexWorkerFlexPools = async (flexWorkerId: string, jobId: string) => {
    const response = await this.hqApiService.get<FlexWorkerFlexPools[]>({
      path: `flex-workers/${flexWorkerId}/flex-pools-for-job/${jobId}`,
    });
    return response;
  };

  /**
   * Get flexPools for flexWorker
   */
  getFlexPoolsForFlexWorker = async (flexWorkerId: string): Promise<FlexPool[]> => {
    const flexPools = await this.hqApiService.get<FlexPool[]>({
      path: `flex-pools/for-flexworker?flexWorkerId=${flexWorkerId}`,
    });

    return flexPools;
  };

  /**
   * Get a list of flexWorkers that might¹ be eligible for assignment to a flexPool
   * ¹ We return both active and inactive flexWorkers, because we use this API
   *   to show the current members of a flexPool and those can include inactive flexWorkers.
   */
  getEligibleFlexWorkers = async (): Promise<FlexWorkerListItem[]> => {
    const flexWorkers = await this.hqApiService.get<FlexWorkerListItem[]>({
      path: 'flex-pools/eligible-flexworkers',
    });

    return flexWorkers;
  };

  getFlexWorkerProfileThroughFlexPool = async (flexPoolId: string, flexWorkerId: string) => {
    const response = await this.hqApiService.get<FlexWorkerProfileData>({
      path: `flex-pools/${flexPoolId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
