import { FilterOptionsState, Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ShiftPlanningJobTypesData } from 'hq/services/shift-planning-service.types';
import { LwFormAutocomplete } from 'redesign/forms/autocomplete';

import { LwFormMultiSelect } from 'redesign/forms/multi-select';
import { PartnerRelationFormData } from './partner-relations.types';
import { BranchOfficeWithRelatedCompany } from '../../../../services/branch-office-service.types';
import { LwFormInput } from '../../../../../redesign/forms/input';

interface Props {
  state: 'update' | 'create';
  defaultValues?: Partial<PartnerRelationFormData>;
  disabled?: boolean;
  branchOffices: BranchOfficeWithRelatedCompany[];
  jobTypes: ShiftPlanningJobTypesData;
}

const PartnerRelationForm = ({ defaultValues, state, branchOffices, jobTypes }: Props) => {
  const { control, setValue } = useFormContext<PartnerRelationFormData>();

  useEffect(() => {
    if (state === 'update') {
      setValue('jobTypes', defaultValues?.jobTypes ?? []);
      defaultValues?.branchOffices && setValue('branchOffices', defaultValues?.branchOffices);
      defaultValues?.forwardingRulePercentage &&
        setValue('forwardingRulePercentage', defaultValues.forwardingRulePercentage);
    }
  }, []);

  const getBranchOfficeOptionLabel = useCallback(
    (option: PartnerRelationFormData['branchOffices']) => `${option.companyName} - ${option.name}`,
    []
  );

  const filterOptions = useCallback(
    (
      options: PartnerRelationFormData['branchOffices'][],
      state: FilterOptionsState<PartnerRelationFormData['branchOffices']>
    ) => {
      return options.filter((option) => {
        const optionLabel = getBranchOfficeOptionLabel(option);
        return optionLabel.toLowerCase().includes(state.inputValue.toLowerCase().trim());
      });
    },
    [getBranchOfficeOptionLabel]
  );

  const jobTypeLabels: { [key: string]: string } = {};
  jobTypes.jobTypes.forEach((jobType) => (jobTypeLabels[jobType.id] = jobType.name));

  return (
    <form>
      <Grid item spacing={2} xs={6}>
        <Grid container xs={12}>
          <Grid xs={12}>
            <LwFormAutocomplete
              name="branchOffices"
              label="Vestiging"
              getOptionLabel={getBranchOfficeOptionLabel}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              defaultValue={defaultValues?.branchOffices}
              options={branchOffices}
              isLoading={false}
              getOptionKey={(option) => option.id}
              filterOptions={filterOptions}
              rules={{ required: 'Selecteer minstens één vestiging' }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.companyName} - ${option.name}`}
                  </li>
                );
              }}
              control={control}
              onChange={(_event, value) => {
                if (value) {
                  setValue('branchOffices', value);
                }
              }}
              disabled={state !== 'create'}
            />
          </Grid>
          <Grid xs={12}>
            <LwFormMultiSelect
              name="jobTypes"
              label="Functiegroep"
              ariaLabelInput="Functiegroep"
              control={control}
              rules={{ required: 'Selecteer minstens één functiegroep' }}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value.id;
              }}
              options={jobTypes.jobTypes.map((jobType) => ({ name: jobType.name, id: jobType.id }))}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={(_, selectedOptions) => {
                setValue('jobTypes', selectedOptions);
              }}
            />
          </Grid>
          <Grid xs={12}>
            <LwFormInput
              name="forwardingRulePercentage"
              control={control}
              label="Watervalpercentage"
              placeholder="75"
              inputType="number"
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export { PartnerRelationForm };
