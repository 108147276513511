import { useCreateUser } from 'ogp/hooks/queryHooks/users/useCreateUser';
import { userAlerts as alerts, useAlert } from 'shared/components/alerts';
import { routes } from 'Routes';
import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { FormProvider, useForm } from 'react-hook-form';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { useNavigate } from 'react-router-dom';
import { TakeDefined } from 'types/utility';
import { invariant } from 'shared/utils/utils';
import { userModel2UserPayload } from 'ogp/services/mappers/mappers';
import { useGetUserAsForm } from 'ogp/hooks/queryHooks/users/useGetUser';
import { LwButton } from 'redesign/button';
import { UserForm } from './user-form/user-form';

const UserCreate = () => {
  const formMethods = useForm<TakeDefined<ReturnType<typeof useGetUserAsForm>['data']>>();
  const userInfo = useGetOGPUserInfo();
  const { alertSuccess, alertError } = useAlert();
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const createUser = useCreateUser({
    onSuccess: () => {
      alertSuccess(alerts.success.created);
      navigate(generatePath(routes.Users));
    },
    onError: (e) => alertError(e),
  });
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(createUser.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      invariant(userInfo.data, 'User info is missing');
      const createPayload = userModel2UserPayload({
        companyId: userInfo.data?.userInfo.company.slug,
      });
      createUser.mutate(createPayload(data));
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe gebruiker aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton onClick={returnTo(routes.Users)} iconColorMode="fill" {...cancelButtonProps}>
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <UserForm state="create" />
      </Page>
    </FormProvider>
  );
};

export { UserCreate };
