import { FieldValues } from 'react-hook-form';
import { styled, TextFieldProps } from '@mui/material';
import { filledInputClasses } from '@mui/material/FilledInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { inputBaseClasses } from '@mui/material/InputBase';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import {
  StyledErrorText,
  StyledHelperTextWrapper,
  StyledIconWarning,
} from 'redesign/utils/helper-text';
import { StyledLabel } from 'redesign/utils/labels';
import { FormTextAreaProps } from './form-text-area.types';

const FormTextArea = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  placeholder,
  rows,
}: FormTextAreaProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          title={label}
          id={name}
          value={field.value ?? ''}
          label={label}
          variant="filled"
          InputLabelProps={{ required: !!rules?.required, htmlFor: name }}
          multiline
          fullWidth
          minRows={rows || 3}
          placeholder={placeholder}
          error={!!error}
          helperText={error ? error.message : null}
          disabled={disabled}
        />
      )}
    />
  );
};

const LwFormTextArea = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  placeholder,
  rows,
}: FormTextAreaProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div style={{ position: 'relative' }}>
          <StyledTextField
            {...field}
            title={label}
            id={name}
            value={field.value ?? ''}
            variant="filled"
            label={
              <StyledLabel icon={error ? <StyledIconWarning /> : null} required={!!rules?.required}>
                {label}
              </StyledLabel>
            }
            InputLabelProps={{ htmlFor: name }}
            multiline
            fullWidth
            minRows={rows || 3}
            placeholder={placeholder}
            error={!!error}
            disabled={disabled}
          />
          <StyledHelperTextWrapper className="error-text-wrapper">
            <StyledErrorText className={error ? 'error-state' : ''}>
              {error?.message}
            </StyledErrorText>
          </StyledHelperTextWrapper>
        </div>
      )}
    />
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '$.MuiInputBase-input': {},
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwPrimary[10]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${inputAdornmentClasses.root}`]: {
    marginRight: theme.spacing(3),
  },
  [`& .${inputBaseClasses.input}`]: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export { FormTextArea, LwFormTextArea };
