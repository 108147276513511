import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { flexPoolService } from 'hq/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { FlexWorkerFlexPools } from 'types/flexWorkers/FlexWorkerTypes';

export const useHqGetFlexWorkerFlexPools = (
  flexWorkerId: string,
  jobId: string,
  options: UseQueryOptions<FlexWorkerFlexPools[], Error> = {}
) => {
  const queryFn = async () => await flexPoolService.getFlexWorkerFlexPools(flexWorkerId, jobId);

  return useQuery<FlexWorkerFlexPools[], Error>(QUERY_KEYS_HQ.flexWorkers(), queryFn, {
    enabled: !!flexWorkerId.length && !!jobId.length,
    ...options,
  });
};
