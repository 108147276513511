import { jobService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { JobListItem } from 'types/jobs/JobTypes';

export const useGetJobs = (departmentId?: string, companyId?: string, enabled: boolean = true) => {
  const queryFn = async () => await jobService.getJobs({ companyId, departmentId });

  return useQuery<Promise<JobListItem[]>, Error, JobListItem[]>(
    QUERY_KEYS_HQ.jobList(companyId, departmentId),
    queryFn,
    { enabled }
  );
};
