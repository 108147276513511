import { Box, Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BranchOfficeFormData } from 'types/companies/branch-office-types';
import useGeocode from 'ogp/hooks/queryHooks/locations/useGeocode';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { VatListOptions } from 'types/Vat';
import { LwFormInput } from 'redesign/forms/input';
import { LwFormSelect } from 'redesign/forms/select';
import { useGetBillingEntitiesAsSelect } from 'hq/hooks/queryHooks/billing-entities/use-get-billing-entities';
import { LwFormAutocomplete } from '../../../../../../../../../../../redesign/forms/autocomplete';

interface BranchOfficeFormProps {
  state: 'update' | 'create';
  disabled?: boolean;
  companyId: string;
  address: string;
}

const BranchOfficeForm = ({ disabled, state, address, companyId }: BranchOfficeFormProps) => {
  const { control, setValue } = useFormContext<BranchOfficeFormData>();
  const billingEntitiesQuery = useGetBillingEntitiesAsSelect(companyId);
  const [input, setInput] = React.useState(address);
  const debouncedInput = useDebounce(input, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== address);
  const billingEntitiesOptions = billingEntitiesQuery.data ?? [];

  return (
    <Box>
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een vestigingsnaam in' }}
            label="Vestigingsnaam"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="companyNameForOVO"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam voor de OVO in' }}
            label="Bedrijfsnaam voor OVO"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="legalPerson"
            control={control}
            rules={{ required: 'Voer een wettelijke vertegenwoordiger in' }}
            label="Wettelijke vertegenwoordiger"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="cocNumber"
            control={control}
            rules={{
              required: 'Voer een KVK-nummer in',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Een KVK-nummer bestaat uit acht cijfers',
              },
            }}
            label="KVK-nummer"
            placeholder="12345678"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormAutocomplete
            name="location"
            label="Locatie"
            control={control}
            defaultValue={{ type: 'defaultOption', address }}
            rules={{ required: 'Voer een locatie in' }}
            disableClearable
            getOptionLabel={(option) => option.address}
            getOptionKey={(option) => option.address}
            isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
            onChange={(_event, value) => {
              if (value) {
                setValue('location', value);
              }
            }}
            onInputChange={(_e, value) => {
              setInput(value);
            }}
            disabled={disabled}
            options={geoQuery.data ?? []}
            isLoading={geoQuery.isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="locationUrl"
            control={control}
            label="Locatie (maps link)"
            placeholder="Maps link"
            rules={{ required: 'Voer een locatie link in' }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormSelect
            name="VAT"
            control={control}
            options={VatListOptions}
            label="BTW-tarief"
            disabled={disabled}
          />
        </Grid>
        {state === 'create' ? (
          <Grid item xs={12}>
            <LwFormSelect
              name="billingEntityId"
              options={billingEntitiesOptions}
              label="Facturatiegegevens"
              control={control}
              rules={{ required: 'Selecteer een facturatiegegevens' }}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export { BranchOfficeForm };
