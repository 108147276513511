import { CompaniesFilterToolbox } from './components/companies-filter-form/companies-filter-form';
import { COMPANIES_FILTER_INITIAL_VALUE, CompaniesFilterParams } from './companies.types';
import { CompaniesList } from './companies-list';
import { Header } from '../../../../redesign/header';
import { Page } from '../../../../redesign/page';
import { Toolbox } from '../../../../redesign/toolbox';
import { useExternalParams } from '../../../../shared/hooks';

export const Companies = () => {
  const [params, setParams] = useExternalParams(COMPANIES_FILTER_INITIAL_VALUE);

  return (
    <Page
      header={<Header titleText="Bedrijven" />}
      toolbox={
        <Toolbox>
          <CompaniesFilterToolbox params={params as CompaniesFilterParams} setParams={setParams} />
        </Toolbox>
      }
    >
      <CompaniesList params={params as CompaniesFilterParams} />
    </Page>
  );
};
