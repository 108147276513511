import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign/forms/input';
import { Grid } from '@mui/material';
import { GetPartnerResponse } from 'hq/services/partner-service.types';
import { useEffect } from 'react';
import { LwFormSelect } from 'redesign/forms/select';
import { PartnerInformationFormData } from './partner-information.types';

type Props = {
  partner: GetPartnerResponse;
};

export const PartnerInformationEditForm = ({ partner }: Props) => {
  const { control, setValue } = useFormContext<PartnerInformationFormData>();

  useEffect(() => {
    setValue('name', partner.name);
    setValue('deactivated', partner.deactivated === true ? 'true' : 'false');
  }, [partner.name, partner.deactivated, setValue]);

  const statusOptions = [
    { value: 'true', label: 'Gedeactiveerd' },
    { value: 'false', label: 'Actief' },
  ];

  return (
    <form>
      <Grid item spacing={2} xs={6}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            label="Bedrijfsnaam partner"
            control={control}
            rules={{ required: 'Voer een voornaam in' }}
          />
          <LwFormSelect
            name="deactivated"
            label="Toestand"
            control={control}
            options={statusOptions}
          />
        </Grid>
      </Grid>
    </form>
  );
};
