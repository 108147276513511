import { RenderUnsettledUI } from 'shared/components';
import { useGetJobs } from 'hq/hooks/queryHooks/jobs/use-get-jobs';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { ProjectCreateFormPage } from './form/project-create-form-page';

export const ProjectCreateDataProvider = () => {
  const jobsQuery = useGetJobs();
  const companiesQuery = useGetCompanies(undefined, 'FREELANCER');

  if (jobsQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobsQuery} />;
  }

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return (
    <ProjectCreateFormPage
      jobsData={jobsQuery.data}
      companies={companiesQuery.data}
      successMessage={`Project aangemaakt`}
      pageTitle={`Maak nieuw project aan`}
    />
  );
};
