import { useCallback, useState } from 'react';
import { INITIAL_PAGE } from './partner-placements.types';
import { useCancelShiftPlacements } from './hooks/use-cancel-shift-placements';
import { PartnerPlacementsDataProvider } from './partner-placements-table';
import { Header } from '../../../../redesign/header';
import { Page } from '../../../../redesign/page';
import { Toolbox } from '../../../../redesign/toolbox';
import { LwButton } from '../../../../redesign/button';
import { DEFAULT_PAGE_SIZE } from '../../../../shared/utils/constants';
import { partnerPlacementAlerts, useAlert } from '../../../../shared/components/alerts';
import { IconTrash } from '../../../../assets/img';

const PartnerPlacements = () => {
  const [selection, setSelection] = useState<string[]>([]);
  const onSelectionChange = useCallback((selectedIds: string[]) => {
    setSelection(selectedIds);
  }, []);
  const { alertSuccess, alertError } = useAlert();
  const { mutate } = useCancelShiftPlacements();

  const cancelPlacements = useCallback(
    () =>
      mutate(
        { placementIds: selection },
        {
          onSuccess: () => alertSuccess(partnerPlacementAlerts.success.bulkDeleted),
          onError: (e) => alertError(e),
        }
      ),
    [alertError, alertSuccess, mutate, selection]
  );

  return (
    <Page
      header={<Header titleText="Plaatsingen" />}
      toolbox={
        <Toolbox>
          <LwButton
            disabled={selection.length === 0}
            type="button"
            color="primary"
            onClick={cancelPlacements}
            startIcon={<IconTrash />}
          >
            Annuleer alle plaatsingen
          </LwButton>
        </Toolbox>
      }
    >
      <PartnerPlacementsDataProvider
        onSelectionChange={onSelectionChange}
        filters={{
          pageParam: INITIAL_PAGE,
          pageSizeParam: DEFAULT_PAGE_SIZE,
        }}
      />
    </Page>
  );
};

export { PartnerPlacements };
