import { useAlert } from 'shared/components/alerts';
import { branchOfficeAlerts as alerts } from 'shared/components/alerts/alert-messages';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { LwButton } from 'redesign/button';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';

import { PartnerRelationFormData } from './partner-relations.types';
import { PartnerRelationFormDataProvider } from './partner-relation-form-data-provider';
import { useCreatePartnerRelation } from '../hooks/use-create-partner-relation';

const PartnerRelationCreateForm = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const formMethods = useForm<PartnerRelationFormData>({});

  const mutation = useCreatePartnerRelation(id);

  const handleSubmit = () => {
    formMethods.handleSubmit((data: PartnerRelationFormData) =>
      mutation.mutate(data, {
        onSuccess: () => {
          alertSuccess(alerts.success.created);
          navigate(generatePath(routes.PartnerDetail, { id }));
        },
        onError: (e) => alertError(e),
      })
    )();
  };

  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutation.status);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe partner relatie aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(routes.PartnerDetail, { id }))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerRelationFormDataProvider state="create" />
      </Page>
    </FormProvider>
  );
};
export { PartnerRelationCreateForm };
