import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FlexWorkerDataEdit } from 'types/flexWorkers/FlexWorkerTypes';

type FlexWorkerMutationOptions = UseMutationOptions<unknown, unknown, FlexWorkerDataEdit>;

export const useUpdateFlexWorker = (flexWorkerId: string) => {
  const mutationFn = async (data: FlexWorkerDataEdit) =>
    await flexWorkerService.updateFlexWorker(flexWorkerId, data);

  const queryClient = useQueryClient();
  const mutationOptions: FlexWorkerMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.flexWorkers());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
