import { IconRatingThumb } from 'assets/img';
import { Header } from 'redesign/table';
import { formatDuration, formatTime } from 'shared/utils/formatting';
import { ContextMenuButton } from 'redesign/context-menu';
import { LwIconButton } from 'redesign/icon-button';
import type { Column } from 'react-table';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { InboxCellProps, InboxTableColumnDefinition } from './inbox-table.types';

import {
  getCheckoutsColumnDefinitionBase,
  ScheduledTimesCell,
} from '../../../../shared/columns/get-checkouts-column-definition-base';
import {
  CheckoutsCellProp,
  CheckoutsColumnBase,
} from '../../../../shared/columns/get-checkouts-column-definition-base.types';
import { HighlightBox } from '../../../../shared/components/highlight-box';
import { getShiftDurationInMs } from '../../../../shared/utils/get-shift-duration-in-ms';

export const getInboxColumnDefinition: ({
  allowExpenses,
}: {
  allowExpenses: boolean;
}) => Column<InboxTableColumnDefinition>[] = ({ allowExpenses }) => {
  const worked: Column<CheckoutsColumnBase> = {
    Header: () => (
      <>
        <Header>Gewerkte tijden</Header>
        <Header>Gewerkte uren</Header>
      </>
    ),
    accessor: 'workedTime',
    Cell: WorkedTimeCell,
  };

  const rating: Column<InboxTableColumnDefinition> = {
    Header: () => <Header>Beoordeling</Header>,
    accessor: 'rating',
    Cell: RatingCell,
  };

  const actions: Column<InboxTableColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  const { fw, expense, job, date, scheduled, timeDeviationAndBreak } =
    getCheckoutsColumnDefinitionBase(allowExpenses);

  return [fw, rating, job, date, scheduled, worked, timeDeviationAndBreak, expense, actions];
};

const WorkerCell = (cell: CheckoutsCellProp<'worker'>) => {
  return <Typography variant="body1">{cell.value.fullName}</Typography>;
};

const RatingCell = (cell: InboxCellProps<'rating'>) => {
  const classes = getClasses();
  return (
    <LwIconButton
      iconColorMode="stroke"
      onClick={() => cell.onChangeRating(cell.value.id)}
      className={
        cell.value.value !== undefined && cell.value.value ? classes.thumbsUp : classes.thumbsDown
      }
    >
      <IconRatingThumb title="icon-rating" />
    </LwIconButton>
  );
};

const ActionCell = (cell: InboxCellProps<'actions'>) => {
  const actions = cell.getRowActions(cell.value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${cell.value.worker.workerId}`}>
      {actions}
    </ContextMenuButton>
  );
};

const WorkedTimeCell = (cell: CheckoutsCellProp<'workedTime'>) => {
  const { timeZone, startDate, endDate, breakInMinutes, timeDeviationInMs } = cell.value;
  const workedTime = getShiftDurationInMs(startDate, endDate, breakInMinutes);
  const timeRange = `${formatTime(startDate, timeZone)} - ${formatTime(endDate, timeZone)}`;

  return timeDeviationInMs ? (
    <HighlightBox>
      {timeRange}
      {formatDuration({ milliseconds: workedTime })}
    </HighlightBox>
  ) : (
    <ScheduledTimesCell {...cell} />
  );
};

const getClasses = makeStyles(() => ({
  thumbsUp: {
    animation: '$spinUp .2s linear',
    padding: '0',
  },
  '@keyframes spinUp': {
    '0%': {
      transform: 'rotate(-180deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  thumbsDown: {
    animation: '$spinDown .2s linear',
    transform: 'rotate(180deg)',
    padding: '0',
  },
  '@keyframes spinDown': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(-180deg)',
    },
  },
}));
