import { Box, Grid } from '@mui/material';
import { EmploymentType } from 'types/companies/company-types';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { RenderUnsettledUI } from 'shared/components';
import { CompaniesFilterParams } from './companies.types';
import { IconBranchOffices } from '../../../../assets/img';
import { BlockLink, BlockProps, LwBlock } from '../../../../redesign/block';
import { routePrefix } from '../../../../ogp/settings';
import { GetCompaniesResponse } from '../../../services/company-service.types';

export const CompaniesList = ({ params }: { params: CompaniesFilterParams }) => {
  const companiesQuery = useGetCompanies(params.filter, params.employmentType as EmploymentType);

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return <CompaniesImpl data={companiesQuery.data} />;
};

const CompaniesImpl = ({ data }: { data: GetCompaniesResponse[] }) => {
  const generatePath = useHqRelativePath();

  const blocks: BlockProps[] = data.map((block) => {
    return {
      id: block.id,
      title: block.name,
      severity: block.employmentType === 'TEMPWORKER' ? 'actionable' : 'none',
    };
  });

  return (
    <Box width="100%">
      <Grid container spacing={4} sx={{ marginBottom: '32px' }}>
        {blocks.map((block) => (
          <Grid item xs={6} md={4} lg={3} key={block.id} aria-label="block-element">
            <BlockLink to={generatePath(routes.CompanyDetail, { id: block.id })}>
              <LwBlock
                icon={<IconBranchOffices width={24} height={24} />}
                id={block.id}
                title={block.title}
                subtitle={block.subtitle}
                dataBlocks={block.dataBlocks}
                severity={block.severity}
                navigateCallback={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  window.open(`${routePrefix}/${block.id}/planning`);
                }}
              />
            </BlockLink>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
