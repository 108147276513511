import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { HqUserFormContext } from './user-create';
import { HqRole } from '../../../../../types/roles/HqRole';
import { RoleMatrix } from '../../../../services/userService';

export const HqUserRoleMatrix = ({
  roleMatrix,
  disabled,
}: {
  roleMatrix: RoleMatrix[];
  disabled: boolean;
}) => {
  const { watch, setValue } = useFormContext<HqUserFormContext>();

  const identifier = 'roles.hqRole';
  return (
    <RadioGroup name="radio">
      {roleMatrix.map((r) => {
        return (
          <Grid key={`hq-${r.role}`} item xs={6}>
            <FormControlLabel
              disabled={disabled}
              control={
                <Radio
                  name={`hq-${r.role}`}
                  disabled={disabled}
                  title={r.name}
                  checked={watch(identifier) === r.role}
                  onChange={(e) => {
                    setValue(identifier, e.target.checked ? (r.role as HqRole) : undefined);
                  }}
                />
              }
              label={r.name}
            />
          </Grid>
        );
      })}
    </RadioGroup>
  );
};
