import { FlexWorkerItem } from 'ogp/components/views/planning/flex-pools/flex-pool-detail/components/flex-workers/flex-workers.types';
import {
  FlexPoolDetail,
  FlexPoolFilters,
  FlexPoolFormValuesOGP,
  FlexPoolWithJobs,
} from 'types/flex-pools/flex-pool-types';
import { FlexWorkerFlexPools, FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';
import { ParamsWithPagination } from 'types/Pagination';
import { CreateFlexPoolPayload, GetFlexPoolListResponse } from './flex-pool-service.types';
import { OgpApiService, Response } from './ogp-api-service';

export class FlexPoolService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Create a new flexPool
   */
  createFlexPool = async (flexPool: CreateFlexPoolPayload): Promise<void> => {
    await this.ogpApiService.create({
      path: `flex-pools`,
      body: {
        ...flexPool,
      },
    });
  };

  /**
   * Update an existing flexPool
   */
  updateFlexPool = async (flexPoolId: string, flexPool: FlexPoolFormValuesOGP) => {
    await this.ogpApiService.update({
      path: `flex-pools/${flexPoolId}`,
      body: flexPool,
    });
  };

  getFlexPoolList = async (params: ParamsWithPagination<FlexPoolFilters>) => {
    const response = await this.ogpApiService.get<GetFlexPoolListResponse>({
      path: `flex-pools/list`,
      params: { ...params },
    });

    return response;
  };

  /**
   * Get a single flexPool
   */
  getFlexPool = async (flexPoolId: string): Promise<FlexPoolWithJobs> => {
    const { flexPool } = await this.ogpApiService.get<Response<FlexPoolWithJobs>>({
      path: `flex-pools/${flexPoolId}`,
    });

    return flexPool;
  };
  getFlexPoolDetail = async (flexPoolId: string) => {
    const response = await this.ogpApiService.get<Response<FlexPoolDetail>>({
      path: `flex-pools/${flexPoolId}/detail`,
    });

    return response.flexPoolDetail;
  };

  getFlexPoolFlexWorkers = async (flexPoolId: string, flexWorkerName?: string) => {
    const response = await this.ogpApiService.get<FlexWorkerItem[]>({
      path: `flex-pools/${flexPoolId}/flex-workers`,
      params: {
        flexWorkerName,
      },
    });

    return response;
  };

  getJobs = async (flexPoolId: string) => {
    const response = await this.ogpApiService.get<{ id: string; name: string }[]>({
      path: `flex-pools/${flexPoolId}/jobs`,
    });

    return response;
  };

  deleteFlexPool = async (flexPoolId: string) =>
    await this.ogpApiService.delete({
      path: `flex-pools/${flexPoolId}`,
    });

  deleteFlexWorkerFromFlexPool = async (flexPoolId: string, flexWorkerId: string) =>
    await this.ogpApiService.delete({
      path: `flex-pools/${flexPoolId}/flex-worker/${flexWorkerId}`,
    });

  getFlexWorkerFlexPools = async (flexWorkerId: string, jobId: string) => {
    const response = await this.ogpApiService.get<FlexWorkerFlexPools[]>({
      path: `flex-workers/${flexWorkerId}/flex-pools-for-job/${jobId}`,
    });
    return response;
  };

  getFlexWorkerProfileThroughFlexPool = async (flexPoolId: string, flexWorkerId: string) => {
    const response = await this.ogpApiService.get<FlexWorkerProfileData>({
      path: `flex-pools/${flexPoolId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
