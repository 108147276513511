import { Box, styled } from '@mui/material';
import { ScheduleTableContent } from './schedule-table-content';
import { DayTableHeader } from './components/day-table-header';
import { WeekDays } from '../../utils/date-utils';
import { ScheduleSegment } from '../../../types/flexWorkers/FlexWorkerTypes';

type ScheduleTableProps = {
  schedule: Record<WeekDays, ScheduleSegment[]>;
};

const ScheduleTable = ({ schedule }: ScheduleTableProps) => {
  return (
    <TableContentWrapper data-testid="schedule-container">
      <DayTableHeader />
      <ScheduleTableContent schedule={schedule} />
    </TableContentWrapper>
  );
};

export { ScheduleTable };

const TableContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  paddingTop: theme.spacing(4),
}));
