import { IconDocument1 } from 'assets/img';
import { useGetShiftClaimContract } from 'hq/hooks/queryHooks/shiftclaims/use-get-shift-claim-contract';
import { isApplicant } from 'shared/components/shift-detail-modal/components/tabs/helpers';
import { ContextMenuItem } from 'redesign/context-menu';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import {
  ShiftApplicationCancelled,
  ShiftClaimCancelled,
} from 'shared/components/shift-detail-modal/components/tabs/cancelled/cancelled-table.types';

const useHqGetCancelledActions = () => {
  const { alertSuccess, alertError } = useAlert();
  const getContract = useGetShiftClaimContract({
    onSuccess: () => alertSuccess(shiftAlerts.success.downloadOvo),
    onError: (e) => alertError(e),
  });

  const getCancelledActions = (candidate: ShiftApplicationCancelled | ShiftClaimCancelled) => {
    if ('isFlexWorker' in candidate && candidate.isFlexWorker === false) {
      return [];
    }
    return !isApplicant(candidate)
      ? [
          <ContextMenuItem key="ovo" onClick={() => getContract.mutate(candidate.claimId)}>
            <IconDocument1 /> Overeenkomst
          </ContextMenuItem>,
        ]
      : [];
  };

  return { getCancelledActions };
};

export { useHqGetCancelledActions };
