import { DateTime } from 'luxon';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { projectService } from 'ogp/services';
import { useQuery } from 'react-query';
import { expenseType } from 'shared/utils/constants';
import { formatFullName } from 'shared/utils/formatting';
import { FirstParamOfFn } from 'types/utility';

const useGetProjectsCheckoutsArchive = (
  params: FirstParamOfFn<typeof projectService.getCheckoutsArchive>
) => {
  const query = useQuery(QUERY_KEYS_OGP.projectsCheckoutsArchive(params), {
    queryFn: async () => await projectService.getCheckoutsArchive(params),
    keepPreviousData: true,
    select: (data) => ({
      ...data,
      items: data.items.map((item) => {
        const transformedItem = {
          id: item.id,
          state: item.state,
          createdAt: DateTime.fromISO(item.proposal.createdAt).toFormat('EEE, dd MMM yyyy'),
          flexWorker: {
            fullName: formatFullName(item.flexWorker),
            id: item.flexWorker.id,
          },
          placement: {
            id: item.placement.id,
          },
          project: item.project.name,
          proposal: {
            startDate: item.proposal.startDate,
            endDate: item.proposal.endDate,
          },
          breakMinutes: item.proposal.breakMinutes,
          workedFrom: DateTime.fromISO(item.proposal.startDate).toFormat('EEE, dd MMM yyyy, HH:mm'),
          workedTo: DateTime.fromISO(item.proposal.endDate).toFormat('EEE, dd MMM yyyy, HH:mm'),
          expense:
            item.proposal.expense.length > 0
              ? expenseType.map((expenseType) => {
                  const amountInCents =
                    item.proposal.expense.find((expense) => expense.expenseType === expenseType.id)
                      ?.amountInCents ?? 0;

                  return {
                    expenseType: expenseType.id,
                    amountInCents,
                    amount: amountInCents / 100,
                    description: expenseType.label,
                  };
                })
              : undefined,
        };

        return {
          actions: transformedItem,
          ...transformedItem,
        };
      }),
    }),
  });

  return query;
};

export { useGetProjectsCheckoutsArchive };
