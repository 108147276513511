import { DateTime } from 'luxon';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useMutation } from 'react-query';
import { mapExpenseTypeToDescription } from '../../../../../../shared/utils/constants';

export type ShiftClaimData = {
  shiftId: string;
  flexWorkerId: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  breakMinutes: number;
  expenses: {
    expenseType: 'parking' | 'kilometerAllowance';
    amount: string;
    description: string;
  }[];
};

export const useCreateShiftCheckout = () => {
  const mutationFn = async (createShiftClaimData: ShiftClaimData) => {
    const params = {
      shiftId: createShiftClaimData.shiftId,
      flexWorkerId: createShiftClaimData.flexWorkerId,
      endDate: DateTime.fromISO(
        createShiftClaimData.endDate + 'T' + createShiftClaimData.endTime
      ).toISO(),
      startDate: DateTime.fromISO(
        createShiftClaimData.startDate + 'T' + createShiftClaimData.startTime
      ).toISO(),
      breakMinutes: createShiftClaimData.breakMinutes,
      expenses: createShiftClaimData.expenses.map((expense) => ({
        expenseType: expense.expenseType,
        amountInCents: Number(expense.amount) * 100,
        description: mapExpenseTypeToDescription(expense.expenseType),
      })),
    };
    await partnerCheckoutsService.createCheckout(params);
  };

  return useMutation(mutationFn);
};
