import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { Box, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'Routes';
import { BranchOfficesListItemSelectModel } from 'hq/services/mappers/mappers';
import { LwButton } from 'redesign/button';
import { IconTrash } from 'assets/img';
import { useArchiveBranchOfficeForbiddenDialog } from '../../../hooks/use-archive-branch-office-forbidden-dialog';
import { useArchiveBranchOfficeDialog } from '../../../hooks/use-archive-branch-office-dialog';

type BranchOfficeCardProps = {
  branchOffice: BranchOfficesListItemSelectModel;
};

const BranchOfficeCard = ({ branchOffice }: BranchOfficeCardProps) => {
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const { open: openArchiveBranchOfficeDialog, Element: ArchiveBranchOfficeDialog } =
    useArchiveBranchOfficeDialog();
  const {
    open: openArchiveBranchOfficeForbiddenDialog,
    Element: ArchiveBranchOfficeForbiddenDialog,
  } = useArchiveBranchOfficeForbiddenDialog();

  return (
    <>
      <BranchOfficeWrapper data-testid={branchOffice.id}>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">{branchOffice.name}</Typography>
        </Box>
        <Box display="flex" gap={2}>
          <LwButton
            onClick={
              branchOffice.isArchivable
                ? () => openArchiveBranchOfficeDialog(branchOffice.id)
                : openArchiveBranchOfficeForbiddenDialog
            }
            color="secondary"
            data-testid={`${branchOffice.id}-edit`}
          >
            <IconTrash />
          </LwButton>
          <EditButton
            onClick={() => {
              navigate(generatePath(routes.BranchOfficeEdit, { id: branchOffice.id }));
            }}
            color="primary"
          >
            Edit
          </EditButton>
        </Box>
      </BranchOfficeWrapper>
      {ArchiveBranchOfficeDialog}
      {ArchiveBranchOfficeForbiddenDialog}
    </>
  );
};

const EditButton = styled(LwButton)(() => ({
  margin: 'auto',
  textDecoration: 'none',
}));

const BranchOfficeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.lwSecondary[10],
  borderRadius: theme.spacing(3),
}));

export { BranchOfficeCard };
