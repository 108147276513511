import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftClaimService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

export type ShiftClaimUpdateData = {
  shiftClaimId: string;
  startDate: string;
  endDate: string;
  breakMinutes: number;
  expenses: {
    expenseType: 'parking' | 'kilometerAllowance';
    amountInCents: number;
    description: string;
  }[];
};
type ShiftClaimMutationOptions = UseMutationOptions<unknown, Error, ShiftClaimUpdateData>;

export const useUpdateCounterOffer = (options?: ShiftClaimMutationOptions) => {
  const mutationFn = async (shiftClaimData: ShiftClaimUpdateData) =>
    await shiftClaimService.updateCounterOffer(shiftClaimData.shiftClaimId, shiftClaimData);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(mutationFn, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.checkouts());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.checkouts());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.checkoutInboxFilters());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
};
