import { useAlert } from 'shared/components/alerts';
import {
  XMLUploadPayload,
  XMLUploadResponse,
} from 'types/adminSettings/admin-settings-service.types';
import { useUploadXML } from 'hq/hooks/queryHooks/upload-xml/use-upload-xml';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FileUploader } from './shared/file-uploader';
import { ErrorRow, SuccessRow } from './shared/components';
import { LwButton } from '../../../../redesign/button';
import { Toolbox } from '../../../../redesign/toolbox';

const rowXMLPropertiesToLabel = {
  flexWorkerId: 'FlexWorker ID',
  iban: 'IBAN',
  amount: 'Amount',
  rowStatus: 'Status',
} as const;

const XMLUploadForm = ({
  isCsvVisible,
  setIsCsvVisible,
}: {
  isCsvVisible: boolean;
  setIsCsvVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { mutate, status } = useUploadXML();
  const { alertSuccess, alertError } = useAlert();
  const { handleSubmit, setValue, watch } = useForm<XMLUploadPayload>();
  const [resultXML, setResultXML] = useState<XMLUploadResponse>();
  const onSubmit = (payload: XMLUploadPayload) => {
    mutate(payload, {
      onSuccess: (data) => {
        setResultXML(data);
        alertSuccess('XML imported successfully');
      },
      onError: (e) => alertError(e),
    });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setValue('file', file);
  };

  return (
    <>
      <Toolbox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="row" gap={4}>
            <Box display="flex" flexDirection="column">
              <FileUploader
                title="Upload XML"
                fileType=".xml"
                onChange={handleFileUpload}
                disabled={status === 'loading'}
              />
              <Box alignSelf={'center'}>{watch('file')?.name}</Box>
            </Box>
            <Box>
              <Box>
                <LwButton color="primary" type="submit" disabled={status === 'loading'}>
                  <Typography variant="button">Submit</Typography>
                </LwButton>
              </Box>
            </Box>
          </Box>
        </form>
        <Toolbox.PushRight>
          <LwButton onClick={() => setIsCsvVisible((prev) => !prev)}>
            Show {isCsvVisible ? 'XML Upload' : 'CSV Upload'}
          </LwButton>
        </Toolbox.PushRight>
      </Toolbox>

      {resultXML ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">
                    {rowXMLPropertiesToLabel['flexWorkerId']}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{rowXMLPropertiesToLabel['amount']}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{rowXMLPropertiesToLabel['iban']}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    {rowXMLPropertiesToLabel['rowStatus']}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultXML.payments.map((row) => (
                <TableRow
                  key={row.flexWorkerId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.flexWorkerId}</TableCell>
                  <TableCell>
                    {Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR' }).format(
                      row.amount
                    )}
                  </TableCell>
                  <TableCell>{row.iban}</TableCell>
                  {row.rowStatus.status === 'Success' ? (
                    <SuccessRow message="SUCCESS!" />
                  ) : (
                    <ErrorRow message={row.rowStatus.errorMessage} />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};

export { XMLUploadForm };
