import { userService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { HqUser } from 'types/users/UsersTypes';

type UserMutationOptions = UseMutationOptions<unknown, unknown, HqUser>;

export const useCreateUser = () => {
  const mutationFn = async (user: HqUser) => await userService.createUser(user);

  const queryClient = useQueryClient();
  const mutationOptions: UserMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.userList());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
