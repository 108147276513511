import { PrebuiltBlock } from 'redesign/block';
import { Certificate } from 'types/accreditations/accreditations.types';

export type AccreditationFormRepresentation = {
  id: string;
  files?: File[];
};

export enum EmploymentType {
  FREELANCER = 'FREELANCER',
  TEMPWORKER = 'TEMPWORKER',
}

export type GetPartnerWorkersResponse = {
  totalRows: number;
  nextPage: number;
  items: PrebuiltBlock[];
};

export type GetPartnerWorkerResponse = {
  id: string;
  firstName: string;
  lastName: string;
  infix: string | undefined;
  email: string;
  phone: string;
  accreditations: Certificate[];
  employmentType: EmploymentType;
};

export type CreatePartnerWorkerPayload = {
  firstName: string;
  infix: string | undefined;
  lastName: string;
  email: string;
  phone: string;
  accreditations: AccreditationFormRepresentation[] | undefined;
  employmentType: EmploymentType;
};

export type UpdatePartnerWorkerPayload = {
  firstName: string | undefined;
  infix: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  accreditations: AccreditationFormRepresentation[] | undefined;
  employmentType: EmploymentType | undefined;
};
