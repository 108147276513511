import { BusinessUnitsFilterBranchOffice } from './filter-business-units.types';
import { AutocompleteOption } from '../../../../../redesign/forms/autocomplete';

export const getBusinessUnitsOptions = (
  branchOffices: BusinessUnitsFilterBranchOffice[],
  selectedBranchOffice?: AutocompleteOption
) => {
  const options: Record<'branchOffices' | 'departments', AutocompleteOption[]> = {
    branchOffices: [],
    departments: [],
  };

  options.branchOffices = branchOffices.map((branchOffice) => {
    return {
      label: branchOffice.branchOfficeName,
      value: branchOffice.branchOfficeId,
    };
  });

  if (options.branchOffices.length === 0) {
    return options;
  }

  const branchOfficeData = options.branchOffices.find(
    (branchOffice) => branchOffice.value === selectedBranchOffice?.value
  );

  if (!branchOfficeData) {
    return options;
  }

  const ogBranchOffice = branchOffices.find((i) => i.branchOfficeId === branchOfficeData.value);

  if (!ogBranchOffice) {
    return options;
  }

  options.departments = ogBranchOffice.departments.map((department) => {
    return {
      label: department.departmentName,
      value: department.departmentId,
    };
  });

  return options;
};
