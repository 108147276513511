import AddIcon from '@mui/icons-material/Add';
import { LwButton } from 'redesign/button';

const AddButton = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <LwButton to="create" startIcon={<AddIcon />} iconColorMode="fill" color="primary">
      {children}
    </LwButton>
  );
};

export { AddButton };
