import { DateTime } from 'luxon';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { useOgpCreateShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-create-shift';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyEmploymentType, useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { paths } from 'ogp/paths';
import { useCallback, useMemo } from 'react';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { OGPCreateShiftPayload, OGPShiftDetailResponse } from 'ogp/services/shift-service.types';
import { transformData } from '../../utils/transform-data';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { OgpShiftForm } from '../ogp-shift-form';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';

type ShiftDuplicateProps = {
  shift: OGPShiftDetailResponse;
  shiftId: string;
};

export const OgpShiftDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(id);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  return <ShiftDuplicate shift={shiftQuery.data} shiftId={id} />;
};

const ShiftDuplicate = ({ shift, shiftId }: ShiftDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const updatePlanningPath = useUpdatePlanningPath();
  const employmentType = useCompanyEmploymentType();
  invariant(employmentType, 'Employment type is not defined');
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const shiftDateTitle = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd').toFormat('dd-LL-yyyy');

  const mutation = useOgpCreateShift({
    onSuccess: (shift) => {
      const shiftDetailPath = updatePlanningPath({
        shiftDetailId: shift.id,
        date: DateTime.fromISO(shift.utcStartDate),
      });
      alertSuccess(alerts.success.created);
      navigate(shiftDetailPath);
    },
    onError: (e) => alertError(e),
  });

  const onCancel = useCallback(
    () =>
      navigate(
        generatePath(paths.Shifts, {
          search: createSearchParams({ shiftDetailId: shiftId }).toString(),
        })
      ),
    [navigate, generatePath, shiftId]
  );

  const shiftData: Partial<OGPCreateShiftPayload> = useMemo(
    () => ({
      ...shift,
      ...(shift.employmentType === 'FREELANCER'
        ? {
            noShowFine: shift.noShowFineCents / 100,
          }
        : {}),
      hourlyRate: shift.hourlyRateCents / 100,
      jobId: shift.job.id,
      departmentId: shift.job.department.id,
    }),
    [shift]
  );

  const formMethods = useForm<FreelanceShiftFormData | TempWorkShiftFormData>({
    defaultValues: shiftData,
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: FreelanceShiftFormData | TempWorkShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate(data);
    })();
  }, [formMethods, mutation]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateTitle} dupliceren`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <OgpShiftForm mode="duplicate" />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </FormProvider>
  );
};
