import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { GridJobProps } from './grid-job.types';
import { GridDayColumn } from './grid-day-column/grid-day-column';
import { useVisibleDaysProvider } from '../../providers/visible-days-provider';

const GridJobImpl = ({ data }: GridJobProps) => {
  const classes = getClasses();
  const { visibleDays } = useVisibleDaysProvider();
  const grids = useMemo(() => {
    function getShifts(date: string) {
      const shifts = data
        ? data.shifts.filter(
            (shift) =>
              DateTime.fromISO(shift.dateTime).toFormat('dd-LL') ===
              DateTime.fromISO(date).toFormat('dd-LL')
          )
        : [];
      return shifts;
    }

    return visibleDays.map((date) => (
      <GridDayColumn key={date} data={getShifts(date)} dayCount={visibleDays.length} />
    ));
  }, [visibleDays, data]);

  if (!data) {
    return (
      <Box className={clsx(classes.jobWrapper, classes.noJobs)}>
        <Typography className={classes.jobName} />
        <Grid container className={classes.jobShiftGrid} flexWrap={'nowrap'}>
          {visibleDays.map((date) => (
            <GridDayColumn key={date} data={[]} dayCount={visibleDays.length} />
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.jobWrapper}>
      <Typography className={classes.jobName} title={data.name}>
        {data.name}
      </Typography>
      <Grid container className={classes.jobShiftGrid} flexWrap={'nowrap'}>
        {grids}
      </Grid>
    </Box>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  jobWrapper: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    flexWrap: 'nowrap',
    '&:last-child $jobShiftGrid > *': {
      paddingBottom: theme.spacing(24),
    },
  },
  noJobs: {
    '&:last-child $jobShiftGrid > *': {
      paddingBottom: theme.spacing(8),
    },
  },
  jobShiftGrid: {
    '& > .MuiGrid-item:nth-child(2n - 1)': {
      background: 'white',
    },
    // Only needed for targetting by jobWrapper so that we can add specific last-child styling
  },
  jobName: {
    width: '14vw',
    padding: theme.spacing(2, 2, 2, 0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 'none',
    whiteSpace: 'nowrap',
  },
}));

const GridJob = React.memo(GridJobImpl);

export { GridJob };
