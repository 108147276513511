import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions } from 'react-query';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

export const useRejectFlexWorkerIdentityCheck = (
  flexWorkerId: string = '',
  identityVerificationId: string = '',
  options: UseMutationOptions<unknown, unknown>
) => {
  const invalidateQuery = useInvalidateQuery();
  const mutationFn = async () =>
    await flexWorkerService.rejectFlexWorkerIdCheck(identityVerificationId);

  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: UseMutationOptions = {
    onSuccess: (data, variables, context) => {
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
