import { useMemo, useState } from 'react';
import { Box, Button, Divider, IconButton, styled, Typography } from '@mui/material';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { makeStyles } from '@mui/styles';
import NoProfileIcon from '@mui/icons-material/AccountBox';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  IconApproved,
  IconCall,
  IconCancel,
  IconCross,
  IconDocument,
  IconEdit,
  IconInbox,
} from 'assets/img';
import { LwFormDate } from 'redesign/forms/date';
import {
  AccreditationState,
  RawAccreditationData,
} from 'types/accreditations/accreditations.types';
import { LWModal } from 'redesign/modal';
import { DateTime } from 'luxon';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { accreditationsAlerts, useAlert } from 'shared/components/alerts';
import { LazyImage } from 'redesign/lazy-image/lazy-image';
import { useGetAccreditationDetail } from '../hooks/use-get-accreditation';
import { useUpdateAccreditation } from '../hooks/use-update-accreditation';
import { useApproveAccreditation } from '../hooks/use-approve-accreditation';
import { useRejectAccreditation } from '../hooks/use-reject-accreditation';
import { FlexWorkerDetail } from '../../flexWorkers/flex-worker-detail/flex-worker-detail';
import { LwButton } from '../../../../../redesign/button';

type AccreditationDetailProps = {
  flexWorkerId: string;
  certificateId: string;
  close: () => void;
};

type CommonProps = {
  data: RawAccreditationData;
  closeModal: () => void;
};

export const AccreditationDetail = ({
  flexWorkerId,
  certificateId,
  close,
}: AccreditationDetailProps) => {
  const accreditationDetailQuery = useGetAccreditationDetail(certificateId, flexWorkerId);

  if (accreditationDetailQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={accreditationDetailQuery}
      />
    );
  }

  const { data } = accreditationDetailQuery;

  return (
    <ModalWrapperBox>
      <ProfileHeader data={data} close={close} />
      <ModalBody data={data} closeModal={close} />
    </ModalWrapperBox>
  );
};

const ProfileHeader = ({ data, close }: { data: RawAccreditationData; close: () => void }) => {
  const classes = getClasses();
  const [open, setOpen] = useState(false);

  const toggleFwModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box>
        <HeaderWrapper overflow="hidden">
          <FlexRowBoxCenter
            onClick={toggleFwModal}
            role="fw-profile"
            minWidth={0}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <ImageWrap>
              {data?.flexWorker.imageUrl ? (
                <StyledLazyImage src={data?.flexWorker.imageUrl} />
              ) : (
                <StyledNoProfileIcon />
              )}
            </ImageWrap>

            <Typography variant="h3" fontWeight={500} noWrap>
              {data?.flexWorker.firstName} {data?.flexWorker.lastName}
            </Typography>
          </FlexRowBoxCenter>

          <InfoWrap>
            <Box display="flex" flexDirection="row">
              <IconCall width={24} height={24} className={classes.svgIcon} />
              <CredentialText paddingRight={5} noWrap>
                {data?.flexWorker.phone}
              </CredentialText>
            </Box>
            <Box display="flex" flexDirection="row">
              <IconInbox width={24} height={24} className={classes.svgIcon} />
              <CredentialText noWrap>{data?.flexWorker.email}</CredentialText>
            </Box>
            <Box display="flex" flexDirection="row">
              <IconButton onClick={close}>
                <IconCross width={24} height={24} />
              </IconButton>
            </Box>
          </InfoWrap>
        </HeaderWrapper>
        <Divider />
      </Box>

      <LWModal isOpen={open} onClose={toggleFwModal} testId="detail-modal">
        {data.flexWorkerId ? (
          <FlexWorkerDetail id={data.flexWorkerId} onClose={toggleFwModal} />
        ) : null}
      </LWModal>
    </>
  );
};

const ModalBody = ({ data, closeModal }: CommonProps) => {
  const methods = useForm({
    defaultValues: {
      expirationDate: data.expirationDate
        ? DateTime.fromISO(data.expirationDate).toFormat('yyyy-MM-dd')
        : undefined,
    },
  });

  const handleOpenAccreditation = () => {
    window.open(data.fileUrl, '_blank');
  };

  return (
    <FormProvider {...methods}>
      <FlexColumnBoxWrap>
        <CertificateInfo>
          <CertificateWrap>
            <FlexRowBoxCenter>
              <IconDocument width={130} height={150} onClick={handleOpenAccreditation} />
            </FlexRowBoxCenter>
            <ButtonWrapCertificate>
              <OpenCertificateButton onClick={handleOpenAccreditation} role="button">
                Open
              </OpenCertificateButton>
            </ButtonWrapCertificate>
          </CertificateWrap>
          <CertificateInfoWrapper>
            <NameBox>
              <CertificateName>{data?.certificate.name}</CertificateName>
            </NameBox>
            <DateBox>
              <LwFormDate
                name="expirationDate"
                label="Vervaldatum instellen"
                control={methods.control}
              />
            </DateBox>
          </CertificateInfoWrapper>
        </CertificateInfo>
        <Buttons data={data} closeModal={closeModal} />
      </FlexColumnBoxWrap>
    </FormProvider>
  );
};

const Buttons = ({ data, closeModal }: CommonProps) => {
  const methods = useFormContext();
  const expirationDate: string | undefined = methods.watch('expirationDate');

  return (
    <ButtonBigWrap>
      <ButtonWrap>
        <ButtonsBasedOnAccreditationState
          accreditationState={data.state}
          flexWorkerId={data.flexWorkerId}
          certificateId={data.certificateId}
          expirationDate={expirationDate}
          closeModal={closeModal}
        />
      </ButtonWrap>
    </ButtonBigWrap>
  );
};

const ButtonsBasedOnAccreditationState = ({
  accreditationState,
  flexWorkerId,
  certificateId,
  expirationDate,
  closeModal,
}: {
  accreditationState: AccreditationState;
  flexWorkerId: RawAccreditationData['flexWorkerId'];
  certificateId: RawAccreditationData['certificateId'];
  expirationDate: string | undefined;
  closeModal: () => void;
}) => {
  switch (accreditationState) {
    case 'pending': {
      return (
        <>
          <RejectAccreditationButton
            flexWorkerId={flexWorkerId}
            certificateId={certificateId}
            closeModal={closeModal}
          />
          <ApproveAccreditationButton
            flexWorkerId={flexWorkerId}
            certificateId={certificateId}
            expirationDate={expirationDate}
            closeModal={closeModal}
          />
        </>
      );
    }
    case 'rejected': {
      return (
        <>
          <ApproveAccreditationButton
            flexWorkerId={flexWorkerId}
            certificateId={certificateId}
            expirationDate={expirationDate}
            closeModal={closeModal}
          />
          <UpdateAccreditationButton
            flexWorkerId={flexWorkerId}
            certificateId={certificateId}
            expirationDate={expirationDate}
            closeModal={closeModal}
          />
        </>
      );
    }
    case 'verified': {
      return (
        <>
          <RejectAccreditationButton
            flexWorkerId={flexWorkerId}
            certificateId={certificateId}
            closeModal={closeModal}
          />
          <UpdateAccreditationButton
            flexWorkerId={flexWorkerId}
            certificateId={certificateId}
            expirationDate={expirationDate}
            closeModal={closeModal}
          />
        </>
      );
    }
  }
};

const UpdateAccreditationButton = ({
  flexWorkerId,
  certificateId,
  expirationDate,
  closeModal,
}: {
  flexWorkerId: RawAccreditationData['flexWorkerId'];
  certificateId: RawAccreditationData['certificateId'];
  expirationDate: string | undefined;
  closeModal: () => void;
}) => {
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useUpdateAccreditation();

  const mutationData = useMemo(
    () => ({
      flexWorkerId,
      certificateId,
      expirationDate: expirationDate ? new Date(expirationDate) : undefined,
    }),
    [certificateId, flexWorkerId, expirationDate]
  );

  const handleUpdateAccreditation = () => {
    openDialog(
      {
        title: 'Weet u zeker dat u de vervaldatum van het certificaat wilt bijwerken?',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => (
          <SecondaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </SecondaryButton>
        ),
      },
      () => {
        disableDialog();
        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            closeModal();
            alertSuccess(accreditationsAlerts.success.updated);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };

  return (
    <LwButton
      onClick={handleUpdateAccreditation}
      iconColorMode="stroke"
      color="primary"
      startIcon={<IconEdit />}
    >
      Bewerking
    </LwButton>
  );
};

const ApproveAccreditationButton = ({
  flexWorkerId,
  certificateId,
  expirationDate,
  closeModal,
}: {
  flexWorkerId: RawAccreditationData['flexWorkerId'];
  certificateId: RawAccreditationData['certificateId'];
  expirationDate: string | undefined;
  closeModal: () => void;
}) => {
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useApproveAccreditation();

  const mutationData = useMemo(
    () => ({
      flexWorkerId,
      certificateId,
      expirationDate: expirationDate ? new Date(expirationDate) : undefined,
    }),
    [certificateId, flexWorkerId, expirationDate]
  );

  const handleApproveAccreditation = () => {
    openDialog(
      {
        title: 'Weet u zeker dat u het certificaat goedkeurt?',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => (
          <SecondaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </SecondaryButton>
        ),
      },
      () => {
        disableDialog();
        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            closeModal();
            alertSuccess(accreditationsAlerts.success.approved);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };
  return (
    <LwButton
      onClick={handleApproveAccreditation}
      iconColorMode="stroke"
      color="primary"
      startIcon={<IconApproved />}
    >
      Goedkeuren
    </LwButton>
  );
};

const RejectAccreditationButton = ({
  flexWorkerId,
  certificateId,
  closeModal,
}: {
  flexWorkerId: RawAccreditationData['flexWorkerId'];
  certificateId: RawAccreditationData['certificateId'];
  closeModal: () => void;
}) => {
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useRejectAccreditation();

  const mutationData = useMemo(
    () => ({
      flexWorkerId,
      certificateId,
    }),
    [certificateId, flexWorkerId]
  );

  const handleRejectAccreditation = () => {
    openDialog(
      {
        title:
          'Weet u zeker dat u het certificaat wilt afwijzen? Neem voor de details contact op met de flexwerker.',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => (
          <SecondaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </SecondaryButton>
        ),
      },
      () => {
        disableDialog();
        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            closeModal();
            alertSuccess(accreditationsAlerts.success.rejected);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };
  return (
    <LwButton
      onClick={handleRejectAccreditation}
      iconColorMode="stroke"
      color="secondary"
      startIcon={<IconCancel />}
    >
      Afwijzen
    </LwButton>
  );
};

const FlexRowBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const FlexRowBoxCenter = styled(FlexRowBox)(() => ({
  alignItems: 'center',
}));

const FlexColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ModalWrapperBox = styled(FlexColumnBox)(({ theme }) => ({
  padding: theme.spacing(8),
  height: '100%',
}));

const HeaderWrapper = styled(FlexRowBoxCenter)(({ theme }) => ({
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(3),
  cursor: 'pointer',
}));

const ImageWrap = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const FlexColumnBoxWrap = styled(FlexColumnBox)(() => ({
  overflow: 'auto',
}));

const StyledLazyImage = styled(LazyImage)(({ theme }) => ({
  width: '56px',
  height: '56px',
  objectFit: 'contain',
  borderRadius: '50%',
  marginRight: theme.spacing(1),
}));

const StyledNoProfileIcon = styled(NoProfileIcon)(({ theme }) => ({
  width: '56px',
  height: '56px',
  marginRight: theme.spacing(1),
}));

const InfoWrap = styled(FlexRowBoxCenter)(() => ({
  marginLeft: 'auto',
  cursor: 'default',
}));

const CredentialText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  marginLeft: theme.spacing(1),
  opacity: '0.6',
}));

const CertificateInfo = styled(FlexRowBox)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const CertificateWrap = styled(FlexColumnBox)(({ theme }) => ({
  alignItems: 'flex-start',
  marginRight: theme.spacing(8),
}));

const CertificateInfoWrapper = styled(FlexColumnBox)(() => ({
  width: '100%',
  justifyContent: 'space-between',
}));

const ButtonWrapCertificate = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

const OpenCertificateButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    width: '100%',
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: theme.spacing(7),
    padding: '8px 10px',
    fontWeight: 700,
    fontSize: '12px',
    color: 'rgba(0,0,0,0.6)',
  },
}));

const NameBox = styled(FlexRowBox)(() => ({
  height: '48px',
}));

const DateBox = styled(FlexRowBox)(() => ({
  width: '40%',
}));

const CertificateName = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24px',
}));

const ButtonBigWrap = styled(FlexRowBox)(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(2),
  justifyContent: 'center',
}));

const ButtonWrap = styled(FlexRowBox)(({ theme }) => ({
  justifyContent: 'space-around',
  gap: theme.spacing(4),
}));

const getClasses = makeStyles(() => ({
  svgIcon: {
    '& path': {
      stroke: 'rgba(0, 0, 0, 0.4)',
    },
  },
}));
