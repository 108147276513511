import { DateTime } from 'luxon';
import React from 'react';
import { Calendar } from 'react-multi-date-picker';
import { FirstParamOfFn } from 'types/utility';

const useCalendar = (initialValues: { fromDate?: DateTime; toDate?: DateTime }) => {
  const [dateRange, setDateRange] = React.useState(initialValues);

  const handleDateChange = (
    dates: FirstParamOfFn<Exclude<React.ComponentProps<typeof Calendar>['onChange'], undefined>>
  ) => {
    if (dates && Array.isArray(dates)) {
      const [from, to] = dates;
      const fromDate = from?.toDate();
      const toDate = to?.toDate();

      const range = {
        fromDate: DateTime.fromJSDate(fromDate),
        toDate: DateTime.fromJSDate(toDate),
      };

      setDateRange(range);
    }
  };

  const handleResetDateRange = () => {
    setDateRange({
      fromDate: undefined,
      toDate: undefined,
    });
  };

  const getDateRangeFrom = (from?: DateTime, to?: DateTime) => {
    const isDefined = (datish: unknown): datish is DateTime => !!datish;
    const dates = [from, to].filter(isDefined).map((date) => date.toJSDate());

    return dates.length > 0 ? dates : undefined;
  };

  return { dateRange, handleDateChange, getDateRangeFrom, handleResetDateRange };
};

export { useCalendar };
