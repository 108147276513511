import { Box, styled } from '@mui/material';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useNavigate } from 'react-router-dom';
import { EmploymentType, Level } from 'types/flexWorkers/FlexWorkerTypes';
import { FlexWorkerFlexPools } from './flex-worker-flex-pools';
import { LwButton } from '../../../../../../../redesign/button';
import { IconAdd } from '../../../../../../../assets/img';

const FlexPools = ({
  flexWorkerId,
  level,
  employmentType,
}: {
  flexWorkerId: string;
  employmentType: EmploymentType;
  level: Level | undefined;
}) => {
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const canAddToFlexpool = level === 'WORKER' || level === 'STARTER';

  if (!canAddToFlexpool) {
    return <FlexWorkerFlexPools flexWorkerId={flexWorkerId} />;
  }

  return (
    <>
      <HeaderContainer>
        <LwButton
          color="primary"
          onClick={() => {
            navigate(
              generatePath(hqRoutes.FlexPoolsAddFlexWorker, {
                id: flexWorkerId,
                employmentType,
              })
            );
          }}
          startIcon={<IconAdd />}
        >
          Flexpool beheer
        </LwButton>
      </HeaderContainer>
      <FlexWorkerFlexPools flexWorkerId={flexWorkerId} />
    </>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(6),
  marginTop: theme.spacing(2),
}));

export { FlexPools };
