import { Box, Divider } from '@mui/material';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useGetFlexPoolDetail } from 'ogp/hooks/queryHooks/flexPools/useGetFlexPoolDetail';
import { ModalPanelBody, ModalPanelTabType } from 'redesign/modal';
import { FlexPoolDetailHead } from './flex-pool-detail-head';
import { FlexWorkers, Jobs } from './components';

type FlexPoolDetailProps = {
  onClose: () => void;
  id: string;
};

export const FlexPoolDetail = ({ onClose, id }: FlexPoolDetailProps) => {
  const flexPoolQuery = useGetFlexPoolDetail(id);

  if (flexPoolQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={flexPoolQuery}
      />
    );
  }

  const options: ModalPanelTabType[] = [
    {
      id: 'Flexwerkers',
      tabLabel: 'Flexwerkers',
      element: <FlexWorkers flexPoolId={id} />,
    },
    {
      id: 'Functies',
      tabLabel: 'Functies',
      element: <Jobs flexPoolId={id} />,
    },
  ];

  return (
    <Box height="100%" display="flex" flexDirection="column" padding={8}>
      <FlexPoolDetailHead flexPool={flexPoolQuery.data} onClose={onClose} />
      <Box mt={16} mb={8}>
        <Divider />
      </Box>
      <ModalPanelBody tabs={options} />
    </Box>
  );
};
