import { Box, Chip, Typography } from '@mui/material';
import { FlexWorkerProfileHQ, sectorOptions } from 'types/flexWorkers/FlexWorkerTypes';
import { TagBox } from '../shared/utils';

const ProfileSectorPreferences = ({ data }: { data: FlexWorkerProfileHQ }) => {
  return (
    <>
      <Box marginTop={4}>
        <Typography variant="h3">Sectorvoorkeuren</Typography>
      </Box>
      <TagBox>
        {data.sectors && data.sectors.length > 0
          ? data.sectors.map((sector) => <Chip key={sector} label={sectorOptions[sector]} />)
          : 'Nog geen values geselecteerd'}
      </TagBox>
    </>
  );
};

export { ProfileSectorPreferences };
