import { Slider, SliderProps, styled } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { StyledErrorText, StyledHelperTextWrapper } from 'redesign/utils/helper-text';
import { StyledLabel } from 'redesign/utils/labels';
import { FormSliderInputProps } from './form-slider-input.types';

const LwFormSlider = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  placeholder,
  step,
  min,
  max,
  marks,
  valueLabelFormat,
  helperText,
  'data-testid': dataTestId,
}: FormSliderInputProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const message = error ? error.message : helperText;
        return (
          <div>
            <StyledLabel required={!!rules?.required}>{label}</StyledLabel>
            <StyledSlider
              {...field}
              disabled={disabled}
              id={name}
              title={label}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              step={step}
              marks={marks}
              min={min}
              max={max}
              value={field.value ?? 0}
              data-testid={dataTestId}
              placeholder={placeholder}
              role="slider"
            />
            <StyledHelperTextWrapper className="error-text-wrapper">
              {message && (
                <StyledErrorText className={error ? 'error-state' : ''}>{message}</StyledErrorText>
              )}
            </StyledHelperTextWrapper>
          </div>
        );
      }}
    />
  );
};

const StyledSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.grey[500],
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.grey[400],
    height: 8,
    width: 1,
  },
  '& .MuiSlider-valueLabel': {
    color: theme.palette.common.white,
  },
  '& .MuiSlider-thumb.Mui-disabled': {
    backgroundColor: theme.palette.grey[200],
  },
}));

export { LwFormSlider };
