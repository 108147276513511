import { DEFAULT_LEVEL_FILTER } from './flex-workers.settings';
import { PaginationResultSizes } from '../../../../types/Pagination';
import { FlexWorkersFilters } from '../../../../types/flexWorkers/FlexWorkerTypes';

export const FLEX_WORKERS_INITIAL_PARAMS = {
  flexWorkerId: {
    initialValue: undefined,
    type: 'string',
  },
  level: {
    initialValue: DEFAULT_LEVEL_FILTER,
    type: 'string',
  },
  employmentType: {
    initialValue: 'ALL',
    type: 'string',
  },
  disabled: {
    initialValue: false,
    type: 'boolean',
  },
  filter: {
    initialValue: undefined,
    type: 'string',
  },
  page: {
    initialValue: 0,
    type: 'number',
  },
  size: {
    initialValue: PaginationResultSizes[0],
    type: 'number',
  },
} as const;

export type FlexWorkerParams = {
  flexWorkerId?: string;
  level: FlexWorkersFilters['level'];
  employmentType: FlexWorkersFilters['employmentType'];
  disabled: FlexWorkersFilters['disabled'];
  filter?: string;
  page: number;
  size: number;
};

export type SetFlexWorkerParams = (params: Partial<FlexWorkerParams>) => void;
