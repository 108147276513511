import { LwCheckbox } from 'redesign/checkbox/checkbox';
import { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormCheckboxProps } from './form-checkbox.types';

const FormCheckbox = <ControlProps extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  checked,
  onChange,
  className,
}: FormCheckboxProps<ControlProps>) => {
  return (
    <FormControlLabel
      disabled={disabled}
      className={className}
      htmlFor={name}
      sx={{
        '& span.MuiFormControlLabel-label': {
          paddingLeft: '12px',
        },
      }}
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <LwCheckbox
              {...field}
              title={label}
              checked={field.value || checked || false}
              id={name}
              value={field.value ?? false}
              onChange={(e) => {
                if (onChange && typeof onChange === 'function') {
                  onChange(e.target.checked);
                }
                field.onChange(e.target.checked);
              }}
              disabled={disabled}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export { FormCheckbox };
