import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FormCheckbox } from 'redesign/forms/checkbox-group';
import { LwFormDate } from 'redesign/forms/date';
import { FilterToDateFormContext } from './filter-to-date.types';

type Props = {
  minDate?: string;
};

export const FilterToDate = (props: Props) => {
  const { watch, control } = useFormContext<FilterToDateFormContext>();
  const { toDateCheckboxValue } = watch();

  return (
    <Box>
      <FormCheckbox name="toDateCheckboxValue" label="Eindigt na" control={control} />
      {toDateCheckboxValue ? (
        <Box>
          <LwFormDate
            name="toDate"
            label="Eindigt na"
            control={control}
            rules={{ required: true }}
            data-testid="to-date"
            minDate={props.minDate}
          />
        </Box>
      ) : null}
    </Box>
  );
};
