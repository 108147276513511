import { useAlert } from 'shared/components/alerts';
import { branchOfficeAlerts as alerts } from 'shared/components/alerts/alert-messages';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { LwButton } from 'redesign/button';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';

import { RenderUnsettledUI } from 'shared/components';
import { PartnerRelationFormData } from './partner-relations.types';
import { PartnerRelationFormDataProvider } from './partner-relation-form-data-provider';
import { useEditPartnerRelation } from '../hooks/use-edit-partner-relation';
import { useGetPartnerRelations } from '../../partners/hooks/use-get-partner-relations';

const PartnerRelationEditForm = () => {
  const { id } = useParams<'id'>();
  const { partnerRelationId } = useParams<'partnerRelationId'>();
  invariant(id);
  invariant(partnerRelationId);
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const formMethods = useForm<PartnerRelationFormData>({});
  const partnerRelations = useGetPartnerRelations(id);

  const mutation = useEditPartnerRelation(id, partnerRelationId, {
    onSuccess: () => {
      alertSuccess(alerts.success.created);
      navigate(generatePath(routes.PartnerDetail, { id }));
    },
    onError: (e) => alertError(e),
  });

  const handleSubmit = () => {
    formMethods.handleSubmit((data: PartnerRelationFormData) => mutation.mutate(data))();
  };

  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutation.status);

  if (partnerRelations.status !== 'success') {
    return <RenderUnsettledUI data={partnerRelations} />;
  }
  const partnerRelation = partnerRelations.data.find(
    (relation) => relation.partnerRelationId === partnerRelationId
  );

  if (!partnerRelation) {
    return <RenderUnsettledUI data={partnerRelations} />;
  }
  const parsedDefaultValues: Partial<PartnerRelationFormData> = {
    branchOffices: {
      id: partnerRelation.branchOfficeId,
      name: partnerRelation.branchOfficeName,
      companyName: partnerRelation.companyName,
    },
    jobTypes: partnerRelation.jobTypes.map((jobType) => ({ id: jobType.id, name: jobType.name })),
    forwardingRulePercentage: partnerRelation.forwardingRulePercentage ?? undefined,
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Partner relatie aanpassen" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(routes.PartnerDetail, { id }))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerRelationFormDataProvider state="update" defaultValues={parsedDefaultValues} />
      </Page>
    </FormProvider>
  );
};
export { PartnerRelationEditForm };
