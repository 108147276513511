import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign/forms/number-input';
import { EmploymentType } from 'types/companies/company-types';

type HourlyRateField = {
  hourlyRate: number;
};

export const HourlyRateInput = ({
  disabled,
  helperText,
  placeholder,
  employmentType,
}: {
  disabled: boolean;
  helperText: string;
  placeholder: string;
  employmentType: EmploymentType;
}) => {
  const { control } = useFormContext<HourlyRateField>();
  return (
    <LwFormNumberInput
      name="hourlyRate"
      label={employmentType === 'FREELANCER' ? 'Uurtarief' : 'Bruto uurloon'}
      control={control}
      rules={{ required: 'Voer uurtarief in' }}
      step={0.01}
      min={0}
      placeholder={placeholder}
      helperText={helperText}
      data-testid="hourly-rate"
      disabled={disabled}
    />
  );
};
