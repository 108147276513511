import {
  AccreditationDataHQ,
  Certificate,
  RawAccreditationData,
} from 'types/accreditations/accreditations.types';
import { HqApiService } from './hqApiService';
import { AccreditationParams } from '../components/views/accreditations/accreditations.types';

class AccreditationsService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getAccreditations(filterParams: AccreditationParams) {
    const response = await this.hqApiService.get<RawAccreditationData[]>({
      path: 'accreditations',
      params: Object.fromEntries(Object.entries(filterParams).filter(([, value]) => value !== '')),
    });
    return response;
  }

  public async getCertificates() {
    const response = await this.hqApiService.get<Certificate[]>({
      path: 'accreditations/certificates',
    });
    return response;
  }

  public async getAccreditationsForFlexWorker(flexWorkerId: string) {
    const response = await this.hqApiService.get<AccreditationDataHQ[]>({
      path: `accreditations/${flexWorkerId}`,
    });
    return response;
  }

  public async getAccreditationDetail(certificateId: string, flexWorkerId: string) {
    const response = await this.hqApiService.get<RawAccreditationData>({
      path: `accreditations/${flexWorkerId}/${certificateId}`,
    });
    return response;
  }

  public async acceptAccreditation(flexWorkerId: string, certificateId: string, date?: Date) {
    await this.hqApiService.update<void>({
      body: { expirationDate: date },
      path: `accreditations/${flexWorkerId}/${certificateId}/approve`,
    });
  }

  public async updateAccreditation(
    flexWorkerId: string,
    certificateId: string,
    expirationDate: Date | undefined
  ) {
    await this.hqApiService.update<void>({
      body: { expirationDate },
      path: `accreditations/${flexWorkerId}/${certificateId}/update`,
    });
  }

  public async rejectAccreditation(flexWorkerId: string, certificateId: string) {
    await this.hqApiService.delete<void>({
      path: `accreditations/${flexWorkerId}/${certificateId}/reject`,
    });
  }
}

export { AccreditationsService };
