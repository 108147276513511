import { Button, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useApproveFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useApproveFlexWorkerIdentityCheck';
import { useGetFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorkerIdentityCheck';
import { useRejectFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useRejectFlexWorkerIdentityCheck';
import { useState } from 'react';
import { UseMutationOptions } from 'react-query';
import { RenderUnsettledUI } from 'shared/components';
import { useAlert } from 'shared/components/alerts';
import { EmploymentType } from 'types/flexWorkers/FlexWorkerTypes';

const FlexWorkerIdCheck = ({
  flexWorkerId,
  closeModal,
  employmentType,
}: {
  flexWorkerId: string;
  closeModal: () => void;
  employmentType: EmploymentType | null;
}) => {
  const { alertError } = useAlert();
  const styles = getStyles();
  const { data, status, error, refetch } = useGetFlexWorkerIdentityCheck(flexWorkerId);
  const [isIdDocumentDownloaded, setIsIdDocumentDownloaded] = useState(false);
  const identityVerificationId = data?.id;
  const mutationOptions: UseMutationOptions = {
    onSuccess: () => {
      closeModal();
    },
    onError: (e) => alertError(e),
  };
  const approveMutation = useApproveFlexWorkerIdentityCheck(
    flexWorkerId,
    identityVerificationId,
    mutationOptions
  );
  const denyMutation = useRejectFlexWorkerIdentityCheck(
    flexWorkerId,
    identityVerificationId,
    mutationOptions
  );

  const onApprove = async () => {
    await approveMutation.mutateAsync();
    closeModal();
  };

  const onDeny = async () => {
    await denyMutation.mutateAsync();
    closeModal();
  };

  const viewIdDocument = () => {
    setIsIdDocumentDownloaded(true);
    window.open(data?.idDocumentImage);
  };

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }

  return (
    <>
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <h2 className="subtitle">ID Check</h2>
        </Grid>
        <Grid item xs={6} spacing={2}>
          <img src={data.portraitImage} alt="Portrait" className={styles.image} />
        </Grid>
        <Grid item xs={6} spacing={2}>
          <img src={data.selfieImage} alt="Selfie" className={styles.image} />
        </Grid>
        {data.idDocumentImage ? (
          <Grid item xs={12}>
            <Button
              onClick={viewIdDocument}
              className={styles.button}
              variant="contained"
              size="large"
              color="primary"
            >
              <Typography variant="body2">Bekijk identiteitsbewijs</Typography>
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <TextField
            title="Voornaam"
            value={data.firstName}
            label="Voornaam"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            title="Achternaam"
            value={data.lastName}
            label="Achternaam"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            title="Geboortedatum"
            value={new Date(data.dateOfBirth).toLocaleDateString('nl-NL')}
            label="Geboortedatum"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            title="BSN"
            value={data.bsn}
            label="BSN"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            className={styles.button}
            onClick={onApprove}
            variant="contained"
            size="large"
            color="primary"
            disabled={
              data.idDocumentImage
                ? employmentType === 'TEMPWORKER' && !isIdDocumentDownloaded
                : false
            }
          >
            <Typography variant="body2">Goedkeuren</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={styles.button}
            onClick={onDeny}
            variant="contained"
            size="large"
            color="primary"
          >
            <Typography variant="body2">Afwijzen</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const getStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '300px',
  },
  image: {
    width: '100%',
  },
  buttonContainer: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'row',
  },
  infoContainer: {},
  button: { marginRight: 12 },
}));

export { FlexWorkerIdCheck };
