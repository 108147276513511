import { Auth } from 'aws-amplify';
import { AbstractApiService, TokenProvider } from 'shared/services/abstractApiService';

interface Config {
  endpoint: string;
}

/**
 * Helper type to make dealing with responses that return a single entity easier.
 * But it's a real bodge by setting _every possible key_ to the type you specify, so be very careful!
 * e.g. if you cast a response to Response<IShift>, you have to know that the field you need is e.g. `shift`,
 * because both `response.shift` and `response.anythingElse` will result in type IShift.
 */
export interface Response<T> {
  [key: string]: T;
}

class OgpTokenProvider implements TokenProvider {
  public async currentToken(): Promise<string> {
    // TODO: does this also fix refreshing of tokens?
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }
}

export class OgpApiService extends AbstractApiService {
  constructor(config: Config) {
    super(config.endpoint, new OgpTokenProvider());
  }

  public getHeaders() {
    const companyHash = window.location.pathname.split('/')[2];

    return {
      ...super.getHeaders(),
      CompanyId: companyHash,
    };
  }
}
