import { Box, styled, Typography } from '@mui/material';
import { HQShiftClaimForFlexWorkerListItem } from 'hq/services/shift-claim-service.types';
import { DateTime } from 'luxon';
import { ShiftClaimCancel } from './shift-claim-cancel';
import { ShiftClaimOvoDownload } from './shift-claim-ovo-download';

const ShiftClaimCard = ({ shiftClaim }: { shiftClaim: HQShiftClaimForFlexWorkerListItem }) => {
  return (
    <ShiftClaimCardWrapper
      isPending={
        DateTime.fromFormat(shiftClaim.endDateTime, 'd-M-yyyy HH:mm').diffNow().valueOf() < 0
      }
    >
      <Box>
        <Typography variant="h2">{shiftClaim.jobName}</Typography>
        <Typography variant="body1">{shiftClaim.branchOfficeName}</Typography>
        <Box mt={2}>
          <Typography variant="body1">{`starts: ${shiftClaim.startDateTime}`}</Typography>
          <Typography variant="body1">{`ends: ${shiftClaim.endDateTime}`}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {shiftClaim.isCancelable ? <ShiftClaimCancel shiftClaimId={shiftClaim.id} /> : null}
        {shiftClaim.state !== 'pending' ? (
          <ShiftClaimOvoDownload shiftClaimId={shiftClaim.id} />
        ) : (
          <Typography variant="body2">Pending</Typography>
        )}
      </Box>
    </ShiftClaimCardWrapper>
  );
};

const ShiftClaimCardWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPending',
})<{ isPending: boolean }>(({ theme, isPending }) => ({
  padding: theme.spacing(5),
  backgroundColor: '#e5e5e5',
  marginBottom: theme.spacing(6),
  display: 'flex',
  justifyContent: 'space-between',
  opacity: isPending ? 0.4 : 1,
}));

export { ShiftClaimCard };
