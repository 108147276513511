import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import IconCross from 'assets/img/icons/cross.svg?react';
import { hasClaimedState } from 'shared/utils/shiftClaimState';
import clsx from 'clsx';
import { ContextMenuButton } from 'redesign/context-menu';
import {
  IconCalendar,
  IconClock,
  IconCloseCircle,
  IconCoin,
  IconDollar,
  IconNoShow,
  IconPause,
  IconProfileUsers,
} from 'assets/img';
import { DateTime, Duration } from 'luxon';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { formatCurrencyCents, formatDateFromString } from 'shared/utils/formatting';
import { LwIconButton } from 'redesign/icon-button';
import { ReactNode } from 'react';
import { HQShiftDetailResponse } from 'hq/services/shift-service.types';
import { OGPShiftDetailResponse } from 'ogp/services/shift-service.types';
import { daysHoursMinutesUntil } from './utils/days-hours-minutes-until';
import { ShiftInfoButton } from './shift-info-button/shift-info-button';
import { ShiftInfoTranslator } from './shift-info-button/shift-info-translator';

type ShiftDetailHeadProps = {
  data: HQShiftDetailResponse | OGPShiftDetailResponse;
  close: () => void;
  actions: ReactNode;
  isArchiving: boolean;
};

export const ShiftDetailHead = ({ data, close, actions, isArchiving }: ShiftDetailHeadProps) => {
  const classes = getClasses();
  const shiftProperties = getShiftProperties(data);

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box className={classes.companyInfo}>
          <Typography noWrap color="secondary" variant="body1" style={{ fontWeight: 'bold' }}>
            {shiftProperties.companyAndLocation}
          </Typography>
          <Typography noWrap variant="h5" className={classes.jobName}>
            <FlexPoolOnlyIndicator flexPoolOnly={data.job.flexPoolOnly}>
              {shiftProperties.jobName}
            </FlexPoolOnlyIndicator>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" ml="auto" alignItems="middle" style={{ gap: 8 }}>
          <ShiftInfoButton id="shift-history-detail" history={shiftProperties.history} />
          <ContextMenuButton loading={isArchiving} size="medium" color="secondary">
            {actions}
          </ContextMenuButton>
          <LwIconButton iconColorMode="stroke" color="secondary" onClick={close}>
            <IconCross width={24} height={24} />
          </LwIconButton>
        </Box>
      </Box>
      <Box className={classes.properties} display="flex" flexDirection="row" marginTop={5}>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconProfileUsers width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.seats}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconCalendar width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.startDate}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconPause width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.cooldownPeriod}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconClock width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.duration}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconPause width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.break}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconDollar width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.hourlyRate}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconCoin width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.totalRate}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          {data.employmentType === 'FREELANCER' ? (
            <Box className={classes.centerText}>
              <IconNoShow width={24} height={24} className={classes.icon} />
              <Typography noWrap variant="subtitle2">
                {shiftProperties.noShowFine}
              </Typography>
            </Box>
          ) : null}

          <Box className={classes.centerText}>
            <IconCloseCircle width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.cancelationPeriod}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const getShiftProperties = (data: HQShiftDetailResponse | OGPShiftDetailResponse) => {
  const acceptedClaimantsCount = data.claimants.filter(hasClaimedState).length;
  const shiftTimeLengthInMs = DateTime.fromISO(data.isoEndDateTime).diff(
    DateTime.fromISO(data.isoStartDateTime)
  ).milliseconds;
  const shiftBreakLengthInMs = data.breakMinutes * 60 * 1000;
  const duration = Duration.fromMillis(shiftTimeLengthInMs).normalize().toFormat('hh:mm');

  const translator = new ShiftInfoTranslator(data.employmentType);

  const cooldownDuration = Duration.fromObject({ minutes: data.workCooldownMinutes })
    .normalize()
    .toFormat('hh:mm');

  return {
    companyAndLocation: `${data.job.companyName} / ${data.job.location}`,
    jobName: data.job.name,
    history: translator.translateEvents(data.history),
    seats: `${acceptedClaimantsCount} / ${data.maxClaimants} flexwerkers`,
    startDate: formatDateFromString(data.startDate),
    duration: `${data.startTime} - ${data.endTime} (${duration} uren)`,
    break: `${data.breakMinutes === 0 ? 'Geen' : `${data.breakMinutes} min. pauze`}`,
    hourlyRate: `${formatCurrencyCents(data.hourlyRateCents)} / uur`,
    totalRate: `${formatCurrencyCents(
      ((shiftTimeLengthInMs - shiftBreakLengthInMs) / 1000 / 60 / 60) * data.hourlyRateCents
    )} totaal`,
    noShowFine:
      data.noShowFineCents !== undefined
        ? `${formatCurrencyCents(data.noShowFineCents)} no show boete`
        : null,
    cancelationPeriod: `${daysHoursMinutesUntil(data.mutableUntilDate, 'Verstreken')}`,
    cooldownPeriod: `${cooldownDuration} rustperiode na shift`,
  };
};

const getClasses = makeStyles(({ spacing }: Theme) => ({
  properties: {
    gap: spacing(3),
    '& svg': {
      opacity: 0.6,
    },
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  jobName: {
    marginTop: spacing(2),
  },
  infoLabel: {
    flex: 1,
    minWidth: 0,
  },
  centerText: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacing(3),
  },
  icon: {
    marginRight: spacing(3),
    flexShrink: 0,
  },
}));
