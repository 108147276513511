import { EmploymentType } from '../../../../types/companies/company-types';

export const COMPANIES_FILTER_INITIAL_VALUE = {
  employmentType: {
    initialValue: undefined,
    type: 'string',
  },
  filter: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

export type CompaniesFilterParams = {
  filter?: string;
  employmentType?: EmploymentType;
};
export type SetCompaniesFilterParams = (params: CompaniesFilterParams) => void;
