import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import {
  flexPoolService as flexPoolServiceOGP,
  flexWorkerService as flexWorkerServiceOGP,
  placementService as placementServiceOGP,
  shiftApplicationService as shiftApplicationServiceOGP,
  shiftClaimService as shiftClaimServiceOGP,
} from 'ogp/services';
import {
  flexPoolService as flexPoolServiceHQ,
  flexWorkerService as flexWorkerServiceHQ,
  placementService as placementServiceHQ,
  shiftApplicationService as shiftApplicationServiceHQ,
  shiftClaimService as shiftClaimServiceHQ,
} from 'hq/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks/use-site-detection';
import { FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';

export enum RelationType {
  FLEXPOOL = 'flexPool',
  SHIFTCLAIM = 'shiftClaim',
  SHIFTAPPLICATION = 'shiftApplication',
  PLACEMENT = 'placement',
}

export type FlexWorkerRelation = {
  relation: RelationType;
  id: string;
};

export const useGetFlexWorkerProfile = (
  flexWorkerId: string,
  flexWorkerRelation?: FlexWorkerRelation
) => {
  let queryFn;
  const { site } = useSiteDetection();
  if (!flexWorkerRelation) {
    if (site === 'hq') {
      queryFn = async () => await flexWorkerServiceHQ.getFlexWorkerProfileCover(flexWorkerId);
    } else {
      queryFn = async () => await flexWorkerServiceOGP.getFlexWorkerProfileCover(flexWorkerId);
    }
  } else {
    const { id } = flexWorkerRelation;

    if (site === 'hq') {
      switch (flexWorkerRelation.relation) {
        case RelationType.FLEXPOOL:
          queryFn = async () =>
            await flexPoolServiceHQ.getFlexWorkerProfileThroughFlexPool(id, flexWorkerId);
          break;
        case RelationType.SHIFTCLAIM:
          queryFn = async () =>
            await shiftClaimServiceHQ.getFlexWorkerProfileThroughShiftClaim(id, flexWorkerId);
          break;
        case RelationType.SHIFTAPPLICATION:
          queryFn = async () =>
            await shiftApplicationServiceHQ.getFlexWorkerProfileThroughShiftApplication(
              id,
              flexWorkerId
            );
          break;
        case RelationType.PLACEMENT:
          queryFn = async () =>
            await placementServiceHQ.getFlexWorkerProfileThroughPlacement(id, flexWorkerId);
          break;
      }
    } else {
      switch (flexWorkerRelation.relation) {
        case RelationType.FLEXPOOL:
          queryFn = async () =>
            await flexPoolServiceOGP.getFlexWorkerProfileThroughFlexPool(id, flexWorkerId);
          break;
        case RelationType.SHIFTCLAIM:
          queryFn = async () =>
            await shiftClaimServiceOGP.getFlexWorkerProfileThroughShiftClaim(id, flexWorkerId);
          break;
        case RelationType.SHIFTAPPLICATION:
          queryFn = async () =>
            await shiftApplicationServiceOGP.getFlexWorkerProfileThroughShiftApplication(
              id,
              flexWorkerId
            );
          break;
        case RelationType.PLACEMENT:
          queryFn = async () =>
            await placementServiceOGP.getFlexWorkerProfileThroughPlacement(id, flexWorkerId);
          break;
      }
    }
  }

  return useQuery<FlexWorkerProfileData, Error>(QUERY_KEYS_OGP.flexWorker(flexWorkerId), queryFn, {
    enabled: !!flexWorkerId,
  });
};
