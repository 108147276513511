const DEFAULT_PAGE_SIZE = 30;
const expenseType = [
  {
    id: 'parking',
    label: 'Parkeerkosten',
  },
  {
    id: 'kilometerAllowance',
    label: 'Reiskosten',
  },
] as const;

function mapExpenseTypeToDescription(
  expenseType: 'parking' | 'kilometerAllowance'
): 'Parkeerkosten' | 'Reiskosten' {
  if (expenseType === 'parking') {
    return 'Parkeerkosten';
  }
  if (expenseType === 'kilometerAllowance') {
    return 'Reiskosten';
  }
  throw new Error('Invalid expense type');
}

export { expenseType, DEFAULT_PAGE_SIZE, mapExpenseTypeToDescription };
