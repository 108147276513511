import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { flexPoolService } from 'ogp/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { FlexWorkerFlexPools } from 'types/flexWorkers/FlexWorkerTypes';

export const useOgpGetFlexWorkerFlexPools = (
  flexWorkerId: string,
  jobId: string,
  options: UseQueryOptions<FlexWorkerFlexPools[], Error> = {}
) => {
  const queryFn = async () => await flexPoolService.getFlexWorkerFlexPools(flexWorkerId, jobId);

  return useQuery<FlexWorkerFlexPools[], Error>(
    QUERY_KEYS_OGP.flexWorkerFlexPools(flexWorkerId, jobId),
    queryFn,
    {
      enabled: !!flexWorkerId.length && !!jobId.length,
      ...options,
    }
  );
};
