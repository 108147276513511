export enum VAT {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NONE = 'NONE',
}

function vatLabel(value: VAT) {
  switch (value) {
    case VAT.HIGH:
      return 'Hoog';
    case VAT.LOW:
      return 'Laag';
    default:
      return 'Geen';
  }
}

export const VatListOptions = Object.keys(VAT).map((item) => ({
  value: item,
  label: vatLabel(item as VAT),
}));

export { vatLabel };
