import { Box, Grid, GridProps, styled, Typography } from '@mui/material';
import { IconCalendar, IconMail } from 'assets/img';
import { ContextMenuButton, ContextMenuItem } from 'redesign/context-menu';
import { PartnerInboxMessage } from 'partner/shared/services/partner-inbox-service.types';
import { StatusIndicator } from 'redesign/status-indicator/status-indicator';
import { theme } from '../theme';

type LwMessageBlockProps = {
  message: PartnerInboxMessage;
  menuItems?: { handler: () => void; actionName: string }[];
};

export const LwMessageBlock = ({ message, menuItems }: LwMessageBlockProps) => {
  const { isRead, status, subtitle, title, createdAt, taskShortDescription } = message;

  return (
    <BlockWrapper>
      {!isRead ? <StatusDot data-testid="is-read-message" /> : null}
      <ActionStatusWrapper>
        <MessageBlockStatusIndicator status={status} />
        <Box onClick={(e) => e.stopPropagation()}>
          <ContextMenuButton menuId={`inbox-actions-menu`}>
            {menuItems?.map(({ handler, actionName }) => (
              <ContextMenuItem key={actionName} onClick={handler}>
                {actionName}
              </ContextMenuItem>
            ))}
          </ContextMenuButton>
        </Box>
      </ActionStatusWrapper>
      <InfoWrapper>
        <Typography noWrap variant="h1">
          {title}
        </Typography>
        <Typography noWrap variant="body1">
          {subtitle}
        </Typography>
      </InfoWrapper>
      <DetailsWrapper>
        <Detail>
          <IconMail />
          <Typography noWrap variant="body1">
            {taskShortDescription}
          </Typography>
        </Detail>
        <Detail>
          <IconCalendar />
          <Typography noWrap variant="body1">
            {createdAt}
          </Typography>
        </Detail>
      </DetailsWrapper>
    </BlockWrapper>
  );
};

const BlockWrapper = styled(Grid)<GridProps>(({ theme }) => ({
  padding: `${theme.spacing(10)} ${theme.spacing(3)} `,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(8),
  background: theme.palette.lwWhite[100],
  boxShadow:
    '0px 4px 6px 0px rgba(51, 51, 51, 0.03), 0px 0px 8px 0px rgba(51, 51, 51, 0.03), 0px 12px 16px 0px rgba(51, 51, 51, 0.08)',
}));

const ActionStatusWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10 px',
  margin: 0,
}));

const StatusDot = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 5,
  left: 0,
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: theme.palette.lwBlack[40],
}));

const InfoWrapper = styled(Box)(() => ({
  padding: '10px 0px 0px 10px',
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DetailsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '0 10px',
  width: '100%',
}));

const Detail = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '& svg': {
    minWidth: '24px',
  },
}));

// TODO: Replace with styled components instead of importing the theme
const MessageBlockStatusIndicator = ({ status }: { status: string }) => {
  let backgroundColor = '';
  let color = '';
  if (status === 'Task') {
    backgroundColor = theme.palette.lwBlack[40];
    color = theme.palette.lwBlack[100];
  } else {
    backgroundColor = theme.palette.lwBlack[10];
    color = theme.palette.lwBlack[60];
  }
  return (
    <StatusIndicator
      backgroundColor={backgroundColor}
      color={color}
      label={status}
      width={'80px'}
    />
  );
};
