import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftService } from 'ogp/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { BreakOption } from 'types/shifts/shift-types';

export const useGetBreakOptions = (
  options?: UseQueryOptions<
    { breakOptions: BreakOption[] },
    Error,
    { value: number; label: string; id: string }[],
    string[]
  >
) => {
  return useQuery(QUERY_KEYS_OGP.breakOptions(), {
    queryFn: async () => await shiftService.getBreakOptions(),
    select: (response) =>
      response.breakOptions.map((option) => ({
        id: option.id,
        value: option.minutes,
        label: option.label,
      })),
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
    ...options,
  });
};
