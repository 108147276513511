import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
// import loadingImage from 'assets/img/loading.png';
import errorImage from 'assets/img/whoops.png';
import { Link } from 'react-router-dom';
import { Button, Fade, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ExtendedError, ValidationError } from 'shared/services/abstractApiService';

export const StateLoading = () => {
  const styles = getStyles({ color: 'white' });

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  if (showLoader) {
    return (
      <Fade appear in>
        <div className={styles.wrapper}>
          <div className={styles.error}>
            <Typography variant="h2">Aan het laden...</Typography>
            {/* <img src={loadingImage} alt="Loading" className={styles.image} /> */}
          </div>
        </div>
      </Fade>
    );
  }

  return null;
};

interface ErrorProps {
  error: unknown;
}

export const StateError = ({ error }: ErrorProps) => {
  const location = useLocation();
  const styles = getStyles();
  const paths = ['/ogp', '/hq', '/partner'];

  const url = paths.find((path) => location.pathname.startsWith(path)) || '';

  if (!url) {
    // eslint-disable-next-line no-console
    console.error('Unknown location pathname', location.pathname);
  }

  function errorHeading() {
    if (error instanceof ExtendedError) {
      return (
        <Typography className={styles.errorHeading} variant="h2">
          {error.title}
        </Typography>
      );
      // Checking for the rest of the errors that are not ExtendedErrors (Ex ValidationErrors)
    } else if (error instanceof Error) {
      return (
        <Typography className={styles.errorHeading} variant="h2">
          Oeps, er ging iets mis.
        </Typography>
      );
    }
  }

  function errorMessage() {
    if (error instanceof ValidationError) {
      return error.errors.message.map((individualError) => (
        <Typography key={individualError.msg} variant="body1">
          {individualError.msg}
        </Typography>
      ));
    } else if (error instanceof Error) {
      return <Typography variant="body1">{error.message}</Typography>;
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        {errorHeading()}
        {errorMessage()}
        <img src={errorImage} alt="Error" className={styles.image} />
        <Link to={url} onClick={() => (window.location.href = url)}>
          <Button className={styles.button} variant="text" color="primary">
            <Typography variant="h3">Terug naar home</Typography>
          </Button>
        </Link>
      </div>
    </div>
  );
};

const getStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
  error: {
    postion: 'relative',
    width: '25vw',
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignSelf: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  errorHeading: {
    marginBottom: theme.spacing(2),
  },
}));
