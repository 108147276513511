import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService } from 'hq/services';
import { HQCreateShiftPayload } from 'hq/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Shift } from 'types/shifts/shift-types';

type ShiftMutationOptions = UseMutationOptions<Shift, Error, HQCreateShiftPayload>;

export const useHqCreateShift = (options?: ShiftMutationOptions) => {
  const mutationFn = async (shift: HQCreateShiftPayload) => await shiftService.createShift(shift);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(mutationFn, {
    onSuccess: (shift, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shifts());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftPlanningShifts());
      onSuccess && onSuccess(shift, variables, context);
    },
    ...restOptions,
  });
};
