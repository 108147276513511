import { Box, Chip, Typography } from '@mui/material';
import { FlexWorkerProfileHQ } from 'types/flexWorkers/FlexWorkerTypes';
import { TagBox, TitleGrid } from '../shared/utils';

const ProfileSkills = ({ mappedSkills }: { mappedSkills: FlexWorkerProfileHQ['mappedSkills'] }) => {
  const languageSkills = hasLanguageSkills(mappedSkills)
    ? renderLanguageSkills(mappedSkills)
    : 'Nog geen values geselecteerd';

  return (
    <Box marginTop={4}>
      <TitleGrid item xs={12}>
        <Typography variant="h3">Vaardigheden</Typography>
        <TagBox>{languageSkills}</TagBox>
      </TitleGrid>
    </Box>
  );
};

const hasLanguageSkills = (mappedSkills: FlexWorkerProfileHQ['mappedSkills']) =>
  mappedSkills?.some(
    (skill) => skill.label === 'Talen' && skill.qualifications && skill.qualifications.length > 0
  );

const renderLanguageSkills = (mappedSkills: FlexWorkerProfileHQ['mappedSkills']) => {
  return mappedSkills
    ?.filter((skill) => skill.label === 'Talen')
    .flatMap((skill) => skill.qualifications)
    .map((qualification, index) => (
      <Chip key={index} label={qualification} data-testid="skills-chip" />
    ));
};

export { ProfileSkills };
