import { Box, Chip, Typography } from '@mui/material';
import { FlexWorkerProfileHQ } from 'types/flexWorkers/FlexWorkerTypes';
import { TagBox, TitleGrid } from '../shared/utils';

const ProfileOthers = ({ mappedSkills }: { mappedSkills: FlexWorkerProfileHQ['mappedSkills'] }) => {
  const others = hasOtherSkills(mappedSkills)
    ? renderOtherSkills(mappedSkills)
    : 'Nog geen values geselecteerd';
  return (
    <Box marginTop={4}>
      <TitleGrid item xs={12}>
        <Typography variant="h3">Overig</Typography>
        <TagBox>{others}</TagBox>
      </TitleGrid>
    </Box>
  );
};
const hasOtherSkills = (mappedSkills: FlexWorkerProfileHQ['mappedSkills']) =>
  mappedSkills.some(
    (skill) => skill.label === 'Overig' && skill.qualifications && skill.qualifications.length > 0
  );
const renderOtherSkills = (mappedSkills: FlexWorkerProfileHQ['mappedSkills']) => {
  return mappedSkills
    ?.filter((skill) => skill.label === 'Overig')
    .flatMap((skill) => skill.qualifications)
    .map((qualification, index) => (
      <Chip key={index} label={qualification} data-testid="others-chip" />
    ));
};

export { ProfileOthers };
