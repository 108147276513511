import { Column } from 'react-table';
import { Cell, Header } from 'redesign/table';

const getJobsColumnDefinition = (): Record<'name', Column<{ name: string }>> => {
  return {
    name: {
      accessor: 'name',
      Header: <Header>Naam</Header>,
      Cell: ({ value }) => <Cell>{value}</Cell>,
    },
  };
};

export { getJobsColumnDefinition };
