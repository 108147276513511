import { ClaimantColumnDefinition } from './claimant-table.types';
import { ShiftClaimant } from '../claimant-table.types';

export const getClaimantTableData = (claimants: ShiftClaimant[]): ClaimantColumnDefinition[] => {
  return claimants.map((claimant) => ({
    flexWorkerImage: {
      id: claimant.workerId,
      firstName: claimant.firstName,
      lastName: claimant.lastName,
      imageUrl: claimant.imageUrl,
      relation: claimant.relation,
      isFlexWorker: claimant.isFlexWorker,
      partnerName: claimant.partnerName,
    },
    flexPoolIndicator: claimant.flexPoolIndicator,
    flexWorkerFullName: claimant.flexWorkerFullName,
    hourlyRate: claimant.hourlyRateCents,
    email: claimant.email,
    phone: claimant.phone,
    noShow: claimant.noShow,
    actions: claimant,
  }));
};
