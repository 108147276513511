import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { GridDayColumnProps } from './grid-day-column.types';
import { GridShift } from './grid-shift';

export const GridDayColumn = ({ data, dayCount }: GridDayColumnProps) => {
  const classes = getClases({ dayCount: dayCount });

  return (
    <Grid item className={classes.shiftWrapper}>
      {data.length ? (
        data.map((shift) => <GridShift key={shift.id} shiftData={shift} />)
      ) : (
        <GridShift shiftData={null} />
      )}
    </Grid>
  );
};

const getClases = makeStyles((theme: Theme) => ({
  shiftWrapper: {
    width: ({ dayCount }: { dayCount: number }) => `calc(100% / ${dayCount})`,
    padding: theme.spacing(2),
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));
