import { useNavigate, useParams } from 'react-router-dom';
import { BaseJobFormData, FreelanceJobFormData, TempWorkJobFormData } from 'types/jobs/JobTypes';
import { jobAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useUpdateJob } from 'ogp/hooks/queryHooks/jobs/use-update-job';
import { routes } from 'Routes';
import { useCompanyEmploymentType, useCompanyRelativePath } from 'ogp/hooks';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbox } from 'redesign/toolbox';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { TakeDefined } from 'types/utility';
import { LwButton } from 'redesign/button';
import { usePageProvider } from 'ogp/layouts';
import { useGetJob } from 'shared/hooks/query-hooks/use-get-job';
import { JobForm } from '../../forms/shared/job-form';
import { decodeJobFormToJob, encodeJobToJobForm } from '../utils';

const JobEditDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Job ID must be defined');
  const jobQuery = useGetJob(id);
  const jobCreateDataQuery = useGetJobCreateData();

  if (jobCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobCreateDataQuery} />;
  }

  if (jobQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobQuery} />;
  }

  const defaultValues = encodeJobToJobForm(jobQuery.data);

  return <JobEdit defaultValues={defaultValues} jobCreateData={jobCreateDataQuery.data} id={id} />;
};

const JobEdit = ({
  defaultValues,
  jobCreateData,
  id,
}: {
  defaultValues: BaseJobFormData;
  jobCreateData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>;
  id: string;
}) => {
  const formMethods = useForm<TempWorkJobFormData | FreelanceJobFormData>({
    defaultValues,
  });
  const { alertSuccess, alertError } = useAlert();
  const generatePath = useCompanyRelativePath();
  const employmentType = useCompanyEmploymentType();
  const [{ filters }] = usePageProvider();
  const navigate = useNavigate();
  const updateJob = useUpdateJob();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(updateJob.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      const decodedData = decodeJobFormToJob(data);
      updateJob.mutate(
        {
          ...decodedData,
          id,
        },
        {
          onSuccess: () => {
            alertSuccess(alerts.success.updated);
            navigate(generatePath(routes.JobsDetail, { id }, filters));
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Functie aanpassen" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              onClick={returnTo(routes.JobsDetail, { id }, filters)}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        {employmentType.status === 'success' ? (
          <JobForm
            formState="update"
            employmentType={employmentType.data}
            jobCreateData={jobCreateData}
          />
        ) : null}
      </Page>
    </FormProvider>
  );
};

export { JobEditDataProvider };
