import { Controller, FieldValues } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Box, styled, TextFieldProps } from '@mui/material';
import { filledInputClasses } from '@mui/material/FilledInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { inputBaseClasses } from '@mui/material/InputBase';
import {
  StyledErrorText,
  StyledHelperTextWrapper,
  StyledIconWarning,
} from 'redesign/utils/helper-text';
import calendar from 'assets/img/icons/redesign/calendar2.svg';
import { StyledLabel } from 'redesign/utils/labels';
import { FormDateProps } from './form-date.types';

const LwFormDate = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  minDate,
  maxDate,
  disabled,
  ...props
}: FormDateProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box>
            <StyledTextField
              {...field}
              title={label}
              id={name}
              type="date"
              value={field.value ?? ''}
              label={
                <StyledLabel
                  icon={error ? <StyledIconWarning /> : null}
                  required={!!rules?.required}
                >
                  {label}
                </StyledLabel>
              }
              variant="filled"
              InputLabelProps={{ htmlFor: name }}
              fullWidth
              error={!!error}
              inputProps={{ min: minDate, max: maxDate, role: 'textbox', disabled }}
              role="input"
              disabled={disabled}
              {...props}
            />
            <StyledHelperTextWrapper className="error-text-wrapper">
              {error && (
                <StyledErrorText className={error ? 'error-state' : ''}>
                  {error.message}
                </StyledErrorText>
              )}
            </StyledHelperTextWrapper>
          </Box>
        );
      }}
    />
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwPrimary[10]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${inputAdornmentClasses.root}`]: {
    marginRight: theme.spacing(3),
  },
  [`& .${inputBaseClasses.input}`]: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    height: '24px',
    width: '24px',
    background: `url("${calendar}") no-repeat center center`,
  },
}));
export { LwFormDate };
