import { flexPoolService } from 'ogp/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { FlexPoolWithJobs } from 'types/flex-pools/flex-pool-types';

const useGetFlexPool = (flexPoolId: string = '') => {
  const queryFn = async () => await flexPoolService.getFlexPool(flexPoolId);

  return useQuery<Promise<FlexPoolWithJobs>, Error, FlexPoolWithJobs>(
    QUERY_KEYS_OGP.flexPool(flexPoolId),
    queryFn,
    { enabled: flexPoolId.length > 0 }
  );
};

export { useGetFlexPool };
