import { RenderUnsettledUI } from 'shared/components';
import { CompanyDetailAndJobGroups } from 'types/companies/company-types';
import { useGetCompanyDetailAndJobGroups } from '../../../../hooks/queryHooks/companies/use-get-company-detail-and-job-groups';

const CompanyDataProvider = ({
  children,
}: {
  children: (data: CompanyDetailAndJobGroups) => React.ReactNode;
}) => {
  const { status, error, refetch, data } = useGetCompanyDetailAndJobGroups();
  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }
  return <>{children(data)}</>;
};

export { CompanyDataProvider };
