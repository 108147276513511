import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService as ogpJobService } from 'ogp/services';
import { jobService as hqJobService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { FreelanceJobDetailsWithId, TempWorkJobDetailsWithId } from 'types/jobs/JobTypes';
import { useSiteDetection } from '..';

export const useGetJob = <T = FreelanceJobDetailsWithId | TempWorkJobDetailsWithId>(
  jobId: string = ''
): UseQueryResult<T, Error> => {
  const { isOGP, isHQ } = useSiteDetection();
  let queryFn;
  let queryKey;
  if (isOGP) {
    queryFn = async () => await ogpJobService.getJob<T>(jobId);
    queryKey = QUERY_KEYS_OGP.job(jobId);
  } else if (isHQ) {
    queryFn = async () => await hqJobService.getJob<T>(jobId);
    queryKey = QUERY_KEYS_HQ.job(jobId);
  } else {
    throw new Error('Site not supported');
  }

  const options = {
    enabled: jobId.length > 0,
  };

  return useQuery(queryKey, queryFn, options);
};
