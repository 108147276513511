import {
  CompanyBasicInfo,
  CompanyData,
  CompanyDetail,
  CompanyListItemBasicData,
  EmploymentTypeFilter,
  OrtListItem,
} from 'types/companies/company-types';
import { GetCompaniesResponse, GetCompanyPartnerRelationsResponse } from './company-service.types';
import { HqApiService } from './hqApiService';

const checkCompanyData = (object: any): object is CompanyData => {
  return object.name;
};

export class CompanyService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Create a new company
   */
  public createCompany = async (company: Omit<CompanyData, 'isTempworkingCompany'>) => {
    if (!checkCompanyData(company)) {
      throw TypeError; // Invalid data type (not <Company>)
    }

    await this.hqApiService.create({
      path: `companies`,
      body: company,
    });
  };

  /**
   * Update an existing company
   */
  public updateCompany = async (
    companyId: string,
    company: Omit<CompanyData, 'isTempworkingCompany'>
  ) => {
    if (!checkCompanyData(company)) {
      throw TypeError; // Invalid data type (not <Company>)
    }

    if (!companyId) {
      throw new Error("Can't update company, missing company ID");
    }

    await this.hqApiService.update({
      path: `companies/${companyId}`,
      body: company,
    });
  };

  /**
   * Get a list of existing companies
   */
  public getCompanies = async (
    includeArchived: boolean | undefined,
    employmentType: EmploymentTypeFilter,
    filter: string | undefined
  ) => {
    const companies = await this.hqApiService.get<GetCompaniesResponse[]>({
      params: { includeArchived: includeArchived ?? false, filter, employmentType },
      path: 'companies',
    });

    return companies;
  };

  /**
   * Get a single company
   */
  public getCompanyDetail = async (companyId: string): Promise<CompanyDetail> => {
    const company = await this.hqApiService.get<CompanyDetail>({
      path: `companies/${companyId}`,
    });

    return company;
  };

  public getCompanyBasicInfoByWorkId = async (workId: string): Promise<CompanyBasicInfo> => {
    const company = await this.hqApiService.get<CompanyDetail>({
      path: `companies/basic-info-by-work-id/${workId}`,
    });
    return company;
  };

  /**
   * Get the companies where the FW has claimed at least a shift
   */
  public getCompaniesForFlexWorker = async (
    flexWorkerId: string
  ): Promise<CompanyListItemBasicData[]> => {
    const response = await this.hqApiService.get<CompanyListItemBasicData[]>({
      path: `companies/for-flexworker/${flexWorkerId}`,
    });

    return response;
  };

  /**
   * Archive the company
   */
  public archiveCompany = async (companyId: string) =>
    await this.hqApiService.delete<undefined>({
      path: `companies/${companyId}`,
    });

  /**
   * Get a list of default ORTs
   */
  public getOrts = async () => {
    const orts = await this.hqApiService.get<OrtListItem[]>({
      path: 'companies/orts',
    });

    return orts;
  };

  public getPartnerRelations = async (
    companyId: string
  ): Promise<GetCompanyPartnerRelationsResponse[]> => {
    return await this.hqApiService.get<GetCompanyPartnerRelationsResponse[]>({
      path: `companies/${companyId}/partner-relations`,
    });
  };
}
