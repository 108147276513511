import { FilterOptionsState } from '@mui/material';
import { sanitizeString } from 'shared/utils/utils';
import { AutocompleteOption } from 'redesign/forms/autocomplete';
import {
  ShiftPlanningFiltersJobType,
  ShiftPlanningFiltersPlanner,
} from 'hq/services/shift-planning-service.types';
import { ShiftPlanningFiltersData } from '../shift-planning-filters.types';
import { ShiftPlanningFilters } from '../../table/hooks/use-shift-planning.types';

export const getFilterOptions = (
  autocompleteOptions: AutocompleteOption[] | null,
  autocompleteState: FilterOptionsState<any>
) => {
  if (!autocompleteOptions?.length) {
    return [];
  }

  if (autocompleteOptions && autocompleteState.inputValue.length === 0) {
    return autocompleteOptions;
  }

  const sanitizedInputValue = sanitizeString(autocompleteState.inputValue);
  return autocompleteOptions.filter((autocompleteOption) => {
    const sanitizedAutocompleteOption = sanitizeString(autocompleteOption.label);
    return sanitizedAutocompleteOption.includes(sanitizedInputValue);
  });
};

export const checkBranchOfficesInCompany = (
  branchOfficeNames: string[],
  shiftPlanningFiltersData: ShiftPlanningFiltersData
): boolean => {
  const allBranchOfficeNames =
    shiftPlanningFiltersData.companies
      ?.flatMap((company) => company.branchOffices)
      .map((branchOffice) => branchOffice.branchOfficeName) || [];

  // Check if all branch office names are present
  return branchOfficeNames.every((name) => allBranchOfficeNames.includes(name));
};

export const getPartner = (
  appliedFilters: ShiftPlanningFilters,
  partners: ShiftPlanningFiltersPlanner[]
) => {
  if (!appliedFilters) {
    return undefined;
  }
  const partner = partners.find((databasePartner) => {
    return databasePartner.id === appliedFilters.partner;
  });
  if (partner) {
    return { value: partner.id, label: partner.name };
  }
  return undefined;
};

export const getJobTypes = (
  appliedJobTypes: string[] | undefined,
  jobTypes: ShiftPlanningFiltersJobType[]
): AutocompleteOption[] => {
  const cleanedJobTypes: AutocompleteOption[] = [];
  if (!appliedJobTypes || !appliedJobTypes.length) {
    return [];
  }
  appliedJobTypes.forEach((jobTypeParamId) => {
    const jobType = jobTypes.find((databaseJobType) => {
      return databaseJobType.id === jobTypeParamId;
    });
    if (!jobType) {
      return;
    }

    cleanedJobTypes.push({ value: jobType.id, label: jobType.name } as AutocompleteOption);
  });

  return cleanedJobTypes;
};
