import { GetCompanyPartnerRelationsResponse } from 'hq/services/company-service.types';
import { Table } from 'redesign/table';
import { useMemo, useState } from 'react';
import {
  PartnerRelationColumnDefinition,
  PartnerRelationTableOptions,
  SortState,
} from './company-partners-list.types';
import { getCompanyPartnersColumnDefinition } from './table-helpers/get-company-partners-column-definition';
import { getCompanyPartnersTableData } from './table-helpers/get-company-partners-table-data';

export const CompanyPartnersList = ({ data }: { data: GetCompanyPartnerRelationsResponse[] }) => {
  const [sortState, setSortState] = useState<SortState>('ASC');
  const columns = useMemo(() => getCompanyPartnersColumnDefinition(), []);
  const tableData = useMemo(() => getCompanyPartnersTableData(data, sortState), [data, sortState]);

  return (
    <Table<PartnerRelationColumnDefinition, PartnerRelationTableOptions>
      stickyHeader={true}
      columns={columns}
      data={tableData}
      sortState={sortState}
      setSortState={setSortState}
      data-testid="company-partner-table"
    />
  );
};
