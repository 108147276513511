import { formatFullName } from 'shared/utils/formatting';
import { PaginatedCollection } from 'types/Pagination';
import { InboxTableColumnDefinition } from './inbox-table.types';
import { getShiftDateProperties } from '../../../../shared/utils/get-shift-date-properties';
import { ClaimPendingExtended } from '../../../../shared/types/checkouts.types';

export const getInboxTableData = (data: PaginatedCollection<ClaimPendingExtended>) => {
  const result: InboxTableColumnDefinition[] = data.items.map((item) => {
    const shiftDateProperties = getShiftDateProperties(item);
    return {
      id: item.id,
      worker: { fullName: formatFullName(item.worker), partnerName: item.worker.partnerName },
      rating: {
        id: item.rating?.id,
        value: !!item.rating?.value,
      },
      job: {
        name: item.shift.job.name,
        location: item.shift.job.location,
      },
      date: {
        scheduledStartDate: item.fwStartDate,
        timeZone: item.shift.timeZone,
      },
      scheduledTime: {
        startDate: item.shift.utcStartDate,
        endDate: item.shift.utcEndDate,
        timeZone: shiftDateProperties.timeZone,
        breakInMinutes: item.shift.breakMinutes,
        state: item.state,
      },
      workedTime: {
        startDate: shiftDateProperties.workTimes.startDate,
        endDate: shiftDateProperties.workTimes.endDate,
        timeZone: shiftDateProperties.timeZone,
        breakInMinutes: shiftDateProperties.workBreakInMinutes,
        state: item.state,
        timeDeviationInMs: shiftDateProperties.timeDeviationInMs,
      },
      scheduledVsWorkedDiffAndBreak: {
        deviationInMs: shiftDateProperties.timeDeviationInMs,
        breakInMinutes: shiftDateProperties.workBreakInMinutes,
      },
      expense: item.fwExpense,
      actions: item,
    };
  });
  return result;
};
