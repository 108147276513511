import { shiftClaimService } from 'hq/services';
import { useMutation, UseMutationOptions } from 'react-query';

type ShiftClaimMutationOptions = UseMutationOptions<unknown, unknown, void>;

export const useCancelShiftClaim = (shiftClaimId: string, options?: ShiftClaimMutationOptions) => {
  const mutationFn = async () => await shiftClaimService.cancelShiftClaim(shiftClaimId);

  return useMutation(mutationFn, options);
};
