import { Outlet } from 'react-router-dom';
import { themeConfig } from 'redesign/theme';
import { styled } from '@mui/material/styles';
import { PartnerSidebarProvider, Sidebar } from './sidebar';

const PartnerLayout = () => {
  return (
    <>
      <PartnerSidebarProvider>
        <Sidebar />
      </PartnerSidebarProvider>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: themeConfig.backgrounds.main,
  width: '100%',
  height: '100%',
  paddingRight: theme.spacing(8),
  paddingLeft: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  minWidth: '0',
  overflow: 'auto',
}));

export { PartnerLayout };
