import { flexPoolService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FlexPool } from 'types/flex-pools/flex-pool-types';

export const useGetFlexPoolsForFlexWorker = (flexWorkerId: string = '') => {
  const queryFn = async () => await flexPoolService.getFlexPoolsForFlexWorker(flexWorkerId);
  const options = {
    enabled: flexWorkerId.length > 0,
  };

  return useQuery<Promise<FlexPool[]>, Error, FlexPool[]>(
    QUERY_KEYS_HQ.flexPoolsForFlexWorker(flexWorkerId),
    queryFn,
    options
  );
};
