import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { useGetJobs } from 'hq/hooks/queryHooks/jobs/use-get-jobs';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { useHqGetShiftCreateData } from '../hooks/hq/use-hq-get-shift-create-data';
import { useHqGetJob } from '../hooks/hq/use-hq-get-job';
import { FormMode } from '../components/forms/shared/types';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';

type ShiftFormProps = {
  companies: GetCompaniesResponse[];
  mode: FormMode;
};

export const HqShiftForm = ({ companies, mode }: ShiftFormProps) => {
  const { watch, reset } = useFormContext<
    { companyId: string } & (FreelanceShiftFormData | TempWorkShiftFormData)
  >();
  const { companyId, jobId } = watch();
  const shiftCreateDataQuery = useHqGetShiftCreateData(companyId);
  const jobsQuery = useGetJobs(undefined, companyId, !!companyId);
  const selectedJobQuery = useHqGetJob(jobId);

  const selectedCompany = useMemo(
    () => companies.find((i) => i.id === companyId),
    [companies, companyId]
  );

  useEffect(() => {
    reset({ companyId });
  }, [companyId, reset]);

  return (
    <ShiftForm
      employmentType={selectedCompany?.employmentType}
      mode={mode}
      isDisabled={
        jobsQuery.status !== 'success' ||
        shiftCreateDataQuery.status !== 'success' ||
        mode === 'read'
      }
      jobsData={jobsQuery.data ?? []}
      shiftCreateData={
        shiftCreateDataQuery.data ?? { branchOfficesWithDepartments: [], breakOptions: [] }
      }
      jobData={selectedJobQuery.data}
      allowDateBeforeToday={true}
    />
  );
};
