import { Avatar, Box, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { LazyImage } from 'redesign/lazy-image/lazy-image';

type UserAvatarProps = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  firstName: string;
  lastName: string;
  imageUrl?: string;
  avatarLabel?: string;
};

const UserAvatar = ({ onClick, firstName, lastName, imageUrl, avatarLabel }: UserAvatarProps) => {
  const initials = firstName.charAt(0) + lastName.charAt(0);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick(event);
  };

  return (
    <StyledBox>
      <WrapperBox>
        <StyledAvatar aria-label="avatar" onClick={handleClick}>
          {imageUrl ? (
            <Image
              fallBackComponent={<Typography variant="body2">{initials}</Typography>}
              alt="profile"
              src={imageUrl}
            />
          ) : (
            <Typography variant="body2">{initials}</Typography>
          )}
        </StyledAvatar>
        {avatarLabel ? (
          <Tooltip title={avatarLabel}>
            <LabelBox>{avatarLabel}</LabelBox>
          </Tooltip>
        ) : null}
      </WrapperBox>
    </StyledBox>
  );
};

const Image = styled(LazyImage)(() => ({
  width: '100%',
  objectFit: 'contain',
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const WrapperBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30%',
}));

const LabelBox = styled(Box)(() => ({
  border: '1px solid #eeeeee',
  borderRadius: '16px',
  backgroundColor: 'white',
  padding: '0 8px',
  marginTop: '-8px',
  lineHeight: '12px',
  fontSize: '8px',
  textAlign: 'center',
  zIndex: 100,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  cursor: 'pointer',
}));

export { UserAvatar };
