import { useCallback } from 'react';
import { ProjectPlanningTableDataProvider } from './table/project-planning-table';
import { ProjectPlanningDetail } from './project-planning-detail';
import { ProjectPlanningDataProvider } from './filters/project-planning-filters-data-provider';
import { useProjectPlanningPage } from './table/hooks/use-project-planning';
import { IconAdd } from '../../../../../assets/img';
import { LwButton } from '../../../../../redesign/button';
import { Header } from '../../../../../redesign/header';
import { Page } from '../../../../../redesign/page';
import { Toolbox } from '../../../../../redesign/toolbox';
import { hqRoutes } from '../../../../Routes';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';
import { useToggle } from '../../../../../shared/hooks';
import { FilterButton } from '../../../../../ogp/components/views/administration/shared';
import { stringifyFilters } from '../../../../../shared/utils/stringify-filters';

const ProjectPlanningPage = () => {
  const generatePath = useHqRelativePath();
  const { params, setParams, selectedFilterCount } = useProjectPlanningPage();
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const closeProjectDetail = useCallback(() => {
    setParams({ projectDetailId: undefined });
  }, [setParams]);

  return (
    <>
      <Page
        header={<Header titleText="Project Planning" />}
        toolbox={
          <Toolbox underline={false}>
            <LwButton
              to={generatePath(hqRoutes.PlannerProjectCreate, undefined, stringifyFilters(params))}
              color="primary"
              startIcon={<IconAdd />}
              iconColorMode="fill"
            >
              Nieuw project
            </LwButton>
            <Toolbox.PushRight>
              <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
            </Toolbox.PushRight>
          </Toolbox>
        }
      >
        <ProjectPlanningTableDataProvider params={params} setParams={setParams} />
      </Page>
      {params.projectDetailId ? (
        <ProjectPlanningDetail projectId={params.projectDetailId} onClose={closeProjectDetail} />
      ) : null}
      <ProjectPlanningDataProvider
        appliedFilters={params}
        handleFiltersChange={setParams}
        isOpen={filtersOpen}
        toggleFilters={toggleFilters}
      />
    </>
  );
};

export { ProjectPlanningPage };
