import { Grid, ListSubheader, MenuItem } from '@mui/material';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { Control, useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign/forms/input';
import { LwFormSelect } from 'redesign/forms/select';
import { FormSwitch } from 'redesign/forms/switch';
import { LwFormTextArea } from 'redesign/forms/text-area';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { VatListOptions } from 'types/Vat';
import { FreelanceJobFormData, JobCreateData, TempWorkJobFormData } from 'types/jobs/JobTypes';
import { CompanyRole } from 'types/roles/CompanyRole';
import { TakeDefined } from 'types/utility';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';

type SharedJobFormData = Omit<
  TempWorkJobFormData & FreelanceJobFormData,
  'location' | 'hourlyRate' | 'hasOpenShiftClaims' | 'cla' | 'noShowFine' | 'mutableUntilHours'
>;
type SharedJobFormElements = { [key in keyof SharedJobFormData]: React.ReactNode };
export type JobFormState = 'update' | 'create' | 'read';
type JobFormElementProps = { control: Control<Partial<SharedJobFormData>>; state: JobFormState };

export const useGetSharedJobFormElements = (
  state: JobFormState,
  jobData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>,
  departmentData: TakeDefined<ReturnType<typeof useGetDepartmentAsDetail>['data']> | undefined
): SharedJobFormElements => {
  const { control } = useFormContext<Partial<SharedJobFormData>>();

  const res: SharedJobFormElements = {
    flexPoolOnly: <FlexpoolOnly control={control} state={state} />,
    ortApplicable: (
      <OrtApplicable
        control={control}
        state={state}
        companyOrtProvided={jobData.companyOrtProvided}
      />
    ),
    departmentId: (
      <Department control={control} state={state} options={jobData.branchOfficesWithDepartments} />
    ),
    name: <JobName control={control} state={state} />,
    jobTypeId: <JobTypeId control={control} state={state} jobGroups={jobData.jobGroups} />,
    VAT: <VAT control={control} state={state} VatListOptions={VatListOptions} />,
    breakMinutes: (
      <BreakMinutes control={control} state={state} breakMinutesOptions={jobData.breakOptions} />
    ),
    descriptionShort: <DescriptionShort control={control} state={state} />,
    descriptionLong: <DescriptionLong control={control} state={state} />,
    descriptionLawRequirements: <DescriptionLawRequirements control={control} state={state} />,
    contactName: <ContactPerson control={control} state={state} />,
    contactPhoneNumber: <ContactPhoneNumber control={control} state={state} />,
    costCenter: (
      <CostCentre
        control={control}
        state={state}
        helperText={
          departmentData?.costCenter
            ? `De standaard voor deze afdeling is ${departmentData.costCenter}`
            : undefined
        }
      />
    ),
    locationUrl: <LocationUrl control={control} state={state} />,
    imageUrl: <ImageUrl control={control} state={state} />,
  };

  return res;
};

const OrtApplicable = ({
  control,
  state,
  companyOrtProvided,
}: JobFormElementProps & {
  companyOrtProvided: boolean;
}) => {
  return (
    <Grid item xs={6}>
      <FormSwitch
        label="ORT toegepast"
        control={control}
        name="ortApplicable"
        disabled={!companyOrtProvided || state === 'read'}
      />
    </Grid>
  );
};

const FlexpoolOnly = ({ control, state }: JobFormElementProps) => {
  return (
    <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
      <Grid item xs={6}>
        <FormSwitch
          label="Enkel binnen de flexpool"
          control={control}
          name="flexPoolOnly"
          disabled={state === 'read'}
        />
      </Grid>
    </AuthenticatedElement>
  );
};

const Department = ({
  control,
  state,
  options,
}: JobFormElementProps & {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
}) => {
  return (
    <Grid item xs={6}>
      <LwFormSelect
        renderOption={(opt) => [
          <ListSubheader key={opt.value} value="">
            <em>{opt.label}</em>
          </ListSubheader>,
          opt.options.map((dpt) => (
            <MenuItem key={dpt.value} value={dpt.value}>
              {dpt.label}
            </MenuItem>
          )),
        ]}
        name="departmentId"
        label="Afdeling"
        defaultLabel="Selecteer een afdeling..."
        options={options || []}
        control={control}
        rules={{ required: 'Selecteer een afdeling' }}
        disabled={state === 'read' || state === 'update'}
      />
    </Grid>
  );
};

const JobName = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormInput
        name="name"
        label="Functietitel"
        control={control}
        rules={{ required: 'Voer een functietitel in' }}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const JobTypeId = ({
  control,
  state,
  jobGroups,
}: JobFormElementProps & { jobGroups: JobCreateData['jobGroups'] }) => {
  return (
    <Grid item xs={12}>
      <LwFormSelect
        control={control}
        name="jobTypeId"
        rules={{ required: 'Voer een functiegroep in' }}
        label="Functiegroep"
        options={jobGroups.map((group) => ({
          label: group.name,
          value: group.id,
          options: group.jobType.map((jobType) => ({ label: jobType.name, value: jobType.id })),
        }))}
        disabled={state === 'read' || state === 'update'}
        renderOption={(jg) => {
          return [
            <ListSubheader key={jg.value} value={jg.value}>
              <em>{jg.label}</em>
            </ListSubheader>,
            jg.options.map((jt) => (
              <MenuItem key={jt.value} value={jt.value}>
                {jt.label}
              </MenuItem>
            )),
          ];
        }}
      />
    </Grid>
  );
};

const VAT = ({
  control,
  state,
  VatListOptions,
}: JobFormElementProps & {
  VatListOptions: React.ComponentProps<typeof LwFormSelect>['options'];
}) => {
  return (
    <Grid item xs={12}>
      <LwFormSelect
        name="VAT"
        control={control}
        options={VatListOptions}
        label="BTW-tarief"
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const BreakMinutes = ({
  control,
  state,
  breakMinutesOptions,
}: JobFormElementProps & {
  breakMinutesOptions: React.ComponentProps<typeof LwFormSelect>['options'];
}) => {
  return (
    <Grid item xs={6}>
      <LwFormSelect
        name="breakMinutes"
        label="Pauze"
        defaultLabel="Selecteer de lengte van de pauze..."
        options={breakMinutesOptions}
        control={control}
        rules={{ required: 'Selecteer een pauze of geef aan dat er geen pauze is' }}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const DescriptionShort = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={12}>
      <LwFormTextArea
        name="descriptionShort"
        label="Korte omschrijving"
        control={control}
        rules={{ required: 'Voer een korte beschrijving in' }}
        disabled={state === 'read'}
        rows={10}
      />
    </Grid>
  );
};

const DescriptionLong = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={12}>
      <LwFormTextArea
        name="descriptionLong"
        label="Lange omschrijving"
        control={control}
        rules={{ required: 'Voer een lange beschrijving in' }}
        disabled={state === 'read'}
        rows={10}
      />
    </Grid>
  );
};

const DescriptionLawRequirements = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={12}>
      <LwFormTextArea
        name="descriptionLawRequirements"
        label="Afspraken en vereisten"
        control={control}
        rules={{ required: 'Voer afspraken en vereisten in' }}
        disabled={state === 'read'}
        rows={5}
      />
    </Grid>
  );
};

const ContactPerson = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormInput
        name="contactName"
        label="Contactpersoon"
        control={control}
        rules={{ required: 'Voer een naam in' }}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const ContactPhoneNumber = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormInput
        name="contactPhoneNumber"
        label="Telefoon contactpersoon"
        inputType="tel"
        placeholder="0612345678"
        control={control}
        rules={{ required: 'Voer een telefoonnummer in' }}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const CostCentre = ({
  control,
  state,
  helperText,
}: JobFormElementProps & {
  helperText: React.ComponentProps<typeof LwFormInput>['helperText'];
}) => {
  return (
    <Grid item xs={12}>
      <LwFormInput
        name="costCenter"
        label="Kostenplaats"
        control={control}
        disabled={state === 'read'}
        helperText={helperText}
      />
    </Grid>
  );
};
const LocationUrl = ({ control, state }: JobFormElementProps) => {
  return (
    <Grid item xs={12}>
      <LwFormInput
        name="locationUrl"
        label="Locatie URL"
        placeholder="maps link"
        control={control}
        rules={{ required: 'Voer een maps link in' }}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const ImageUrl = ({ control, state }: JobFormElementProps) => {
  return (
    <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
      <Grid item xs={12}>
        <LwFormInput
          name="imageUrl"
          label="Afbeelding URL"
          placeholder="https://"
          control={control}
          disabled={state === 'read'}
        />
      </Grid>
    </AuthenticatedElement>
  );
};
