import {
  HQCreateShiftPayload,
  HQFreelanceShiftDetails,
  HQTempWorkShiftDetails,
} from 'hq/services/shift-service.types';
import {
  OGPCreateShiftPayload,
  OGPFreelanceShiftDetails,
  OGPTempWorkShiftDetails,
} from 'ogp/services/shift-service.types';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';

type FreelanceShiftPayload =
  | HQCreateShiftPayload<HQFreelanceShiftDetails>
  | OGPCreateShiftPayload<OGPFreelanceShiftDetails>;
type TempWorkShiftPayload =
  | HQCreateShiftPayload<HQTempWorkShiftDetails>
  | OGPCreateShiftPayload<OGPTempWorkShiftDetails>;

export const transformData = (formData: FreelanceShiftFormData | TempWorkShiftFormData) => {
  if ('noShowFine' in formData) {
    return transformFreelanceData(formData);
  }
  return transformTempWorkData(formData);
};

const transformFreelanceData = (formData: FreelanceShiftFormData) => {
  const shiftDetails: FreelanceShiftPayload = {
    ...formData,
    employmentType: 'FREELANCER',
    startDate: `${formData.startDate}T${formData.startTime}`,
    endDate: `${formData.endDate}T${formData.endTime}`,
    hourlyRateCents: Math.round(formData.hourlyRate * 100),
    noShowFineCents: Math.round(formData.noShowFine * 100),
    maxClaimants: +formData.maxClaimants,
  };

  return shiftDetails;
};

const transformTempWorkData = (formData: TempWorkShiftFormData) => {
  const shiftDetails: TempWorkShiftPayload = {
    ...formData,
    employmentType: 'TEMPWORKER',
    startDate: `${formData.startDate}T${formData.startTime}`,
    endDate: `${formData.endDate}T${formData.endTime}`,
    hourlyRateCents: Math.round(formData.hourlyRate * 100),
    maxClaimants: +formData.maxClaimants,
  };

  return shiftDetails;
};
