import { userService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { HqUserInfo } from 'types/users/UserInfo';

export const useGetUserInfo = () => {
  const queryFn = async () => await userService.getUserInfo();

  return useQuery<Promise<HqUserInfo>, Error, HqUserInfo>(QUERY_KEYS_HQ.userInfo(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
