import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftService } from 'ogp/services';
import { OGPCreateShiftPayload } from 'ogp/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { Shift } from 'types/shifts/shift-types';

type ShiftMutationOptions = UseMutationOptions<Shift, Error, OGPCreateShiftPayload>;

export const useOgpCreateShift = (options?: ShiftMutationOptions) => {
  const mutationFn = async (shift: OGPCreateShiftPayload) => await shiftService.createShift(shift);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(mutationFn, {
    onSuccess: (shift, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.shifts()); // Bluntly invalidate ALL shift data because the change could be reflected in a lot of places.
      onSuccess && onSuccess(shift, variables, context);
    },
    ...restOptions,
  });
};
