import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { accreditationsService } from 'hq/services';
import { useQuery } from 'react-query';
import { RawAccreditationData } from 'types/accreditations/accreditations.types';

const useGetAccreditationDetail = (certificateId: string, flexWorkerId: string) => {
  const queryFn = async () =>
    await accreditationsService.getAccreditationDetail(certificateId, flexWorkerId);

  return useQuery<RawAccreditationData, Error>(QUERY_KEYS_HQ.accreditationDetail(), queryFn);
};

export { useGetAccreditationDetail };
