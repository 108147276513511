import { useGetFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorker';
import { useNavigate, useParams } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { flexWorkerAlerts as alerts, useAlert } from 'shared/components/alerts';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { FlexWorkerFormEdit } from './flexworker-form-edit';
import { Page } from '../../../../../../redesign/page';
import { Header } from '../../../../../../redesign/header';
import { Toolbox } from '../../../../../../redesign/toolbox';
import { IconApproved, IconCancel } from '../../../../../../assets/img';
import { LwButton } from '../../../../../../redesign/button';
import { useHqRelativePath } from '../../../../../hooks/useHqRelativePath';
import { invariant } from '../../../../../../shared/utils/utils';
import { FlexWorkerData } from '../../../../../../types/flexWorkers/FlexWorkerTypes';
import { useUpdateFlexWorker } from '../../../../../hooks/queryHooks/flexWorkers/useUpdateFlexWorker';
import { hqRoutes } from '../../../../../Routes';

export const FlexWorkerEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Flex worker ID must be passed as a route param');
  const flexWorkerQuery = useGetFlexWorker(id);

  if (flexWorkerQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexWorkerQuery} />;
  }

  return <FlexWorkerEditImpl data={flexWorkerQuery.data} />;
};

const FlexWorkerEditImpl = ({ data }: { data: FlexWorkerData }) => {
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();

  const {
    firstName,
    lastName,
    infix,
    smallBusinessSchemeStartDate,
    smallBusinessSchemeEndDate,
    wageTaxCredit,
  } = data;

  const formattedSmallBusinessSchemeStartDate = smallBusinessSchemeStartDate
    ? DateTime.fromISO(smallBusinessSchemeStartDate).toFormat('yyyy-LL-dd')
    : undefined;

  const formattedSmallBusinessSchemeEndDate = smallBusinessSchemeEndDate
    ? DateTime.fromISO(smallBusinessSchemeEndDate).toFormat('yyyy-LL-dd')
    : undefined;

  const defaultValues = {
    firstName,
    lastName,
    infix,
    smallBusinessSchemeStartDate: formattedSmallBusinessSchemeStartDate,
    smallBusinessSchemeEndDate: formattedSmallBusinessSchemeEndDate,
    wageTaxCredit,
  };

  const methods = useForm<FlexWorkerData>({
    defaultValues,
  });

  const updateFlexWorker = useUpdateFlexWorker(data.id);

  const onSubmit = (data: FlexWorkerData) =>
    updateFlexWorker.mutate(data, {
      onSuccess: () => {
        alertSuccess(alerts.success.updated);
        navigate(generatePath(hqRoutes.FlexWorkers, {}, { flexWorkerId: data.id }));
      },
      onError: (e) => alertError(e),
    });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Page
          header={<Header titleText="Flexwerker bewerken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Flexwerker bewerken
              </LwButton>
              <LwButton
                onClick={() =>
                  navigate(generatePath(hqRoutes.FlexWorkers, {}, { flexWorkerId: data.id }))
                }
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <FlexWorkerFormEdit flexWorkerData={data} />
        </Page>
      </form>
    </FormProvider>
  );
};
