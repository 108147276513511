import { Box, Typography } from '@mui/material';
import { DefaultTheme, styled } from '@mui/styles';
import IconCross from 'assets/img/icons/cross.svg?react';
import { IconCall, IconEdit, IconMail, IconProfileUsers, IconTrash } from 'assets/img';
import { LwIconButton } from 'redesign/icon-button';
import {
  EmploymentType,
  GetPartnerWorkerResponse,
} from 'partner/shared/services/partner-worker-service.types';
import { ContextMenuButton, ContextMenuItem } from 'redesign/context-menu';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { useNavigate } from 'react-router-dom';
import { PARTNER_PATHS } from 'partner/paths';
import { partnerWorkerAlerts, useAlert } from 'shared/components/alerts';
import { useDeletePartnerWorker } from '../../hooks/use-delete-partner-worker';

type PartnerWorkerHeadProps = {
  data: GetPartnerWorkerResponse;
  close: () => void;
};

export const PartnerWorkerDetailModalHead = ({ data, close }: PartnerWorkerHeadProps) => {
  const generatePath = usePartnerRelativePath();
  const navigate = useNavigate();
  const { mutate } = useDeletePartnerWorker(data.id);
  const { alertSuccess, alertError } = useAlert();

  const handleEdit = () => {
    navigate(generatePath(PARTNER_PATHS.WorkerUpdate, { id: data.id }));
  };
  const handleDelete = () => {
    mutate(undefined, {
      onSuccess: () => {
        alertSuccess(partnerWorkerAlerts.success.deleted);
        navigate(generatePath(PARTNER_PATHS.Worker));
      },
      onError: (e) => alertError(e),
    });
  };

  const fullName = [data.firstName, data.infix, data.lastName]
    .filter((d) => d !== undefined && d.length > 0)
    .join(' ');

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column">
          <Typography noWrap variant="h5" mt={2}>
            {fullName}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" ml="auto" alignItems="center" gap={8}>
          <Box display="flex" flexDirection="row" ml="auto" alignItems="middle" gap="32px">
            <ContextMenuButton size="medium" color="secondary">
              <ContextMenuItem onClick={handleEdit}>
                <IconEdit />
                Wijzig
              </ContextMenuItem>
              <ContextMenuItem onClick={handleDelete}>
                <IconTrash />
                Verwijderen
              </ContextMenuItem>
            </ContextMenuButton>
          </Box>
          <LwIconButton
            iconColorMode="stroke"
            color="secondary"
            onClick={close}
            data-testid="partner-worker-details-modal-close"
          >
            <IconCross width={24} height={24} />
          </LwIconButton>
        </Box>
      </Box>
      <Box gap={10} display="flex" flexDirection="row" marginTop={5}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <MailIcon />
          <Typography noWrap variant="subtitle2">
            {data.email}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <CallIcon />
          <Typography noWrap variant="subtitle2">
            {data.phone}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <ProfileUsers />
          <Typography noWrap variant="subtitle2">
            {data.employmentType === EmploymentType.FREELANCER ? 'Zelfstandige' : 'Uitzendkracht'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const getIconStyles = (theme: DefaultTheme) => ({
  opacity: 0.6,
  marginRight: theme.spacing(2),
  flexShrink: 0,
  width: '20px',
  height: '20px',
});

const CallIcon = styled(IconCall)(({ theme }) => getIconStyles(theme));
const MailIcon = styled(IconMail)(({ theme }) => getIconStyles(theme));
const ProfileUsers = styled(IconProfileUsers)(({ theme }) => getIconStyles(theme));
