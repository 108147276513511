type DateFilters = {
  fromDate?: string;
  toDate?: string;
};

export type ShiftClaimFilters = DateFilters & {
  companyId?: string;
  branchOfficeId?: string;
  jobId?: string;
  flexWorkerId?: string;
  state?: 'accepted' | 'canceled' | 'completed' | 'resolved';
};

const emptyDateFilters: DateFilters = {
  fromDate: undefined,
  toDate: undefined,
};

export const emptyShiftClaimFilters: ShiftClaimFilters = {
  ...emptyDateFilters,
  jobId: undefined,
  flexWorkerId: undefined,
  state: undefined,
};
