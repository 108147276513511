import { Box, Drawer } from '@mui/material';
import { Page } from 'redesign/page';
import { useToggle } from 'shared/hooks';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import { RenderUnsettledUI } from 'shared/components';
import { useLevelPagination } from 'ogp/hooks/queryHooks/misc/use-level-pagination';
import { PaginationIndicator } from 'ogp/components/common/pagination-indicator';
import { Toolbox } from 'redesign/toolbox';
import { useGetCheckoutsArchiveShiftClaims } from './hooks/use-get-checkouts-archive-shift-claims';
import { CheckoutsArchiveFilters } from './components/checkouts-archive-filters/checkouts-archive-filters';
import { CheckoutsArchiveTable } from './components/table/checkouts-archive-table';
import { FilterButton, useCheckoutsArchivePage } from '../../shared';
import { CheckoutTypeToggle } from '../../checkouts/checkouts-type-provider';

const CheckoutsArchive = () => {
  const { filters, setFilters, filterCount, sortState, updateSort } = useCheckoutsArchivePage();
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const pagination = useLevelPagination();

  const claimsQuery = useGetCheckoutsArchiveShiftClaims({
    page: pagination.page,
    size: pagination.size,
    ...sortState,
    ...filters,
  });
  const handleSubmit = (data: Partial<typeof filters>) => {
    setFilters(data);
    toggleFilters();
  };

  if (claimsQuery.status !== 'success') {
    return <RenderUnsettledUI data={claimsQuery} />;
  }

  return (
    <>
      <Page
        header={
          <HeaderStatistics
            type="checkouts-archive"
            titleText="Archief"
            subheaderText="Hier zie je alle gewerkte diensten"
          />
        }
        toolbox={
          <Toolbox>
            <Toolbox.PushRight>
              <CheckoutTypeToggle />
              <FilterButton
                onClick={toggleFilters}
                nrOfActiveFilters={filterCount}
                showSpinner={claimsQuery.status === 'success' && claimsQuery.isFetching}
              />
            </Toolbox.PushRight>
          </Toolbox>
        }
      >
        <Box display="flex" width="100%" maxHeight={'100%'} flexDirection={'column'}>
          <CheckoutsArchiveTable
            data={claimsQuery.data}
            sortState={sortState}
            handleSortClick={updateSort}
          />
          <Box mt="auto">
            <PaginationIndicator pageCount={claimsQuery.data.totalCount} pagination={pagination} />
          </Box>
        </Box>
      </Page>
      <Drawer anchor="right" open={filtersOpen} onClose={toggleFilters}>
        <CheckoutsArchiveFilters
          onSubmit={handleSubmit}
          filters={filters}
          onCancel={toggleFilters}
        />
      </Drawer>
    </>
  );
};

export { CheckoutsArchive };
