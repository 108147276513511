import { FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';
import { HqApiService } from './hqApiService';
import { AddProposalPayload } from '../../shared/services/placement-service.types';

export class PlacementService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async cancelPlacement(placementId: string) {
    const response = await this.hqApiService.create<void>({
      path: `placements/${placementId}/cancel`,
    });

    return response;
  }

  public async terminatePlacement(placementId: string) {
    const response = await this.hqApiService.create<void>({
      path: `placements/${placementId}/terminate`,
    });

    return response;
  }

  public async completePlacement(placementId: string) {
    const response = await this.hqApiService.create<void>({
      path: `placements/${placementId}/complete`,
    });

    return response;
  }

  public downloadContract = async (placementId: string) => {
    const response = await this.hqApiService.create({
      path: `placements/contract`,
      body: {
        placementId,
      },
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      decoder: (arrayBuffer: any) => new Blob([arrayBuffer], { type: 'application/pdf' }),
    });

    return response;
  };

  public async settleCheckouts(checkoutIds: string[]) {
    const response = await this.hqApiService.create<string[]>({
      path: 'placements/checkouts/settle',
      body: {
        checkoutIds,
      },
    });

    return response;
  }

  public async addProposal(placementId: string, checkoutId: string, payload: AddProposalPayload) {
    const response = await this.hqApiService.create<void>({
      path: `placements/${placementId}/checkouts/${checkoutId}/add-proposal`,
      body: payload,
    });

    return response;
  }

  public getFlexWorkerProfileThroughPlacement = async (
    placementId: string,
    flexWorkerId: string
  ) => {
    const response = await this.hqApiService.get<FlexWorkerProfileData>({
      path: `placements/${placementId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
