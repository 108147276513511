import { AddButton } from 'redesign/add-button';
import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import { Toolbox } from 'redesign/toolbox';
import { useCallback, useState } from 'react';
import { SearchBar } from 'redesign/search-bar';
import { Box } from '@mui/material';
import { useExternalParams } from 'shared/hooks';
import { LWModal } from 'redesign/modal';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { PartnerWorkerList } from './partner-worker-list/partner-worker-list';
import { PartnerWorkerDetail } from './partner-worker-detail-modal/partner-worker-detail-modal';

const initialValues = {
  id: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

const PartnerWorkers = () => {
  const [filter, setFilter] = useState<string>('');
  const debouncedSearchInput = useDebounce(filter, 800);
  const [params, setParams] = useExternalParams(initialValues);

  const openWorkerDetail = useCallback(
    (id: string | undefined) => {
      setParams({ id });
    },
    [setParams]
  );

  const closeWorkerDetail = useCallback(() => {
    setParams({ id: undefined });
  }, [setParams]);

  return (
    <Box height={'100%'} display={'flex'}>
      <Page
        header={<Header titleText="Kandidaten" />}
        toolbox={
          <Toolbox>
            <SearchBar
              onChange={setFilter}
              initialValue={filter}
              placeholder="Filter gebruikers op naam"
            />
            <Toolbox.PushRight>
              <AddButton>Voeg kandidaat toe</AddButton>
            </Toolbox.PushRight>
          </Toolbox>
        }
        pageStyle={{ height: '75%', overflow: 'auto' }}
      >
        <PartnerWorkerList filter={debouncedSearchInput} openWorkerDetail={openWorkerDetail} />
        <LWModal
          isOpen={params.id != null}
          onClose={closeWorkerDetail}
          testId="partner-worker-detail"
        >
          {params.id ? <PartnerWorkerDetail /> : null}
        </LWModal>
      </Page>
    </Box>
  );
};

export { PartnerWorkers };
