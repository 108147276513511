import { Env, getEnv } from '../../env';

const ENVIRONMENT_PREFIX = 'VITE_';
const env = getEnv(import.meta.env);

type WithoutBuilderPrefix<Str extends keyof Env> =
  Str extends `${typeof ENVIRONMENT_PREFIX}${infer Variable}` ? Variable : never;

class EnvironmentService {
  static getVariable<V extends WithoutBuilderPrefix<keyof Env>>(
    variable: V
  ): (typeof env)[`${typeof ENVIRONMENT_PREFIX}${V}`] {
    return env[`${ENVIRONMENT_PREFIX}${variable}`];
  }
}

export { EnvironmentService };
