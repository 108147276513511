import { PartnerApiService } from './partner-api-service';
import {
  AccreditationFormRepresentation,
  CreatePartnerWorkerPayload,
  GetPartnerWorkerResponse,
  GetPartnerWorkersResponse,
  UpdatePartnerWorkerPayload,
} from './partner-worker-service.types';

export class PartnerWorkerService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  getPartnerWorkersListing = async (
    page: number,
    filter: string
  ): Promise<GetPartnerWorkersResponse> => {
    const result = this.partnerApiService.get<GetPartnerWorkersResponse>({
      path: 'workers/listing',
      params: {
        page,
        filter,
      },
    });

    return result;
  };

  getPartnerWorker = async (workerId: string): Promise<GetPartnerWorkerResponse> => {
    const result = await this.partnerApiService.get<GetPartnerWorkerResponse>({
      path: `workers/${workerId}`,
    });

    return result;
  };

  createPartnerWorker = async (body: CreatePartnerWorkerPayload): Promise<void> => {
    const formData = getPartnerWorkerPayloadFormData(body);

    await this.partnerApiService.create({
      path: `workers`,
      body: formData,
    });
  };

  updatePartnerWorker = async (
    partnerWorkerId: string,
    body: UpdatePartnerWorkerPayload
  ): Promise<void> => {
    const formData = getPartnerWorkerPayloadFormData(body);

    await this.partnerApiService.update({
      path: `workers/${partnerWorkerId}`,
      body: formData,
    });
  };

  deletePartnerWorker = async (partnerWorkerId: string): Promise<void> => {
    await this.partnerApiService.delete({
      path: `workers/${partnerWorkerId}`,
    });
  };
}

function addAccreditationFiles(
  formData: FormData,
  accreditations?: AccreditationFormRepresentation[]
) {
  if (!accreditations) {
    return;
  }
  accreditations.forEach((accreditation) => {
    if (!accreditation.files) {
      return;
    }
    accreditation.files.forEach((file) => {
      formData.append(`${accreditation.id}-files[]`, file);
    });
  });
}

function addFieldsToFormData(
  formData: FormData,
  body: { [key: string]: string | Blob | undefined }
) {
  for (const key in body) {
    if (body[key] === undefined) {
      continue;
    }
    formData.append(key, body[key] as string | Blob);
  }
}

function getPartnerWorkerPayloadFormData(
  body: CreatePartnerWorkerPayload | UpdatePartnerWorkerPayload
): FormData {
  const formData = new FormData();

  addAccreditationFiles(formData, body.accreditations);

  const accreditations = JSON.stringify(
    body.accreditations?.map((accreditation) => accreditation.id) ?? []
  );

  const bodyWithoutFiles: { [key: string]: string | undefined } = {
    ...body,
    accreditations,
  };

  addFieldsToFormData(formData, bodyWithoutFiles);

  return formData;
}
