import { Table } from 'redesign/table';
import { useMemo } from 'react';
import { getJobsColumnDefinition } from './get-jobs-column-definition';
import { getJobsTableData } from './get-jobs-table-data';
import { JobsTableProps } from './jobs-table.types';

const JobsTable = ({ data }: JobsTableProps) => {
  const columns = useMemo(() => getJobsColumnDefinition(), []);
  const tableData = useMemo(() => getJobsTableData(data), [data]);

  return <Table stickyHeader data={tableData} columns={[columns.name]} />;
};

export { JobsTable };
