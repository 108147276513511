import { companyService as ogpCompanyService } from 'ogp/services';
import { companyService as hqCompanyService } from 'hq/services';
import { useQuery } from 'react-query';
import { invariant } from '../../../../../../utils/utils';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';
import { SHARED_QUERY_KEYS_COMPANY } from '../../../../../../query-keys/company';
import { CompanyBasicInfo } from '../../../../../../../types/companies/company-types';

export const useGetCompanyBasicInfo = (workId?: string) => {
  const siteDetectionResult = useSiteDetection();
  const queryFn = getQueryFunction(siteDetectionResult, workId);

  return useQuery<CompanyBasicInfo>(
    SHARED_QUERY_KEYS_COMPANY.ownCompany(siteDetectionResult),
    queryFn
  );
};

function getQueryFunction(siteDetectionResult: SiteDetectionResult, workId: string | undefined) {
  if (siteDetectionResult.isHQ) {
    invariant(workId, 'useGetCompany: workId is required');
    return async () => await hqCompanyService.getCompanyBasicInfoByWorkId(workId);
  }

  if (siteDetectionResult.isOGP) {
    return async () => await ogpCompanyService.getCompanyBasicInfo();
  }

  throw new Error(`useGetCompany: site "${siteDetectionResult}" is neither HQ nor OGP.`);
}
