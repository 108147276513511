import Box from '@mui/material/Box';
import { LwButton } from 'redesign/button';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material';

interface Props {
  handleCancel: () => void;
  handleClear: () => void;
  handleSubmit: () => void;
  children?: any;
  isOpen: boolean;
}

export const FilterModal = (props: Props) => {
  const { handleCancel, handleClear, handleSubmit, isOpen } = props;

  const id = isOpen ? 'filter-popover' : undefined;

  return (
    <FilterPopover
      id={id}
      open={isOpen}
      aria-label="filter-popover"
      anchorEl={document.body}
      onClose={handleCancel}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Box width={'100%'}>
        {props.children}
        <ButtonFooter>
          <LwButton color="secondary" onClick={handleClear}>
            Filters wissen
          </LwButton>
          <LwButton
            color="primary"
            type="submit"
            aria-label="submit-filters"
            onClick={handleSubmit}
          >
            Filter
          </LwButton>
        </ButtonFooter>
      </Box>
    </FilterPopover>
  );
};

const FilterPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    justifyContent: 'center',
    width: '703px',
    padding: '40px',
    borderRadius: '12px',
    border: `1.5px solid ${theme.palette.lwSecondary[40]}`,
    background: theme.palette.lwWhite[100],
    boxShadow: `
      0px 4px 6px 0px ${theme.palette.lwSecondary[5]},
      0px 0px 8px 0px ${theme.palette.lwSecondary[5]},
      0px 12px 16px 0px ${theme.palette.lwSecondary[20]}
    `,
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 3,
  },
}));

const ButtonFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  marginTop: theme.spacing(8),
}));
