import { useCompanyEmploymentType } from 'ogp/hooks';
import { FeeType } from 'ogp/services/billing-entity-service.types';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { EmploymentType } from 'types/companies/company-types';

const [InternalFeeTypeProvider, useFeeTypeProvider] = ProviderFactory<FeeType[]>(
  'Fee types are only available inside FeeTypeProvider. Did you forget to render it?'
);

const FeeTypeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const employmentType = useCompanyEmploymentType();

  if (employmentType.status !== 'success') {
    return null;
  }

  return (
    <InternalFeeTypeProvider value={feeTypesByEmploymentType[employmentType.data]}>
      {children}
    </InternalFeeTypeProvider>
  );
};

const feeTypesByEmploymentType: Record<EmploymentType, FeeType[]> = {
  FREELANCER: ['platform', 'shiftManager'],
  TEMPWORKER: ['shiftManager'],
};

export { FeeTypeProvider, useFeeTypeProvider };
