import { FormGroup } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteOption, LwFormAutocomplete } from 'redesign/forms/autocomplete';
import { LwFormMultiSelect } from 'redesign/forms/multi-select';
import { BranchOfficeNameListItem } from 'types/companies/branch-office-types';
import { FlexWorkerName } from 'types/flexWorkers/FlexWorkerTypes';
import { getFilterOptions } from 'hq/components/views/planner/shifts/filters/utils/utils';
import { InboxFormValues } from './inbox-filter-form.types';

const BusinessUnitFiltering = ({
  data,
}: {
  data: {
    branchOffices: BranchOfficeNameListItem[];
    flexWorkers: FlexWorkerName[];
  };
}) => {
  const { branchOffices, flexWorkers } = data;
  const { setValue, watch, control } = useFormContext<InboxFormValues>();
  const selectedBranchOffice = watch('branchOffice');

  const flexWorkerOptions = useMemo(
    () => flexWorkers.map((flexWorker) => ({ id: flexWorker.id, name: flexWorker.fullName })),
    [flexWorkers]
  );

  const branchOfficeOptions = useMemo(() => {
    return branchOffices.map((bo) => ({
      value: bo.id,
      label: bo.name,
    }));
  }, [branchOffices]);

  const departmentOptions = useMemo(() => {
    if (!selectedBranchOffice) {
      return [];
    }
    const selectedBranch = branchOffices.find((bo) => bo.id === selectedBranchOffice.value);
    if (!selectedBranch) {
      return [];
    }
    return selectedBranch.departments.map((department) => ({
      value: department.id,
      label: department.name,
    }));
  }, [selectedBranchOffice, branchOffices]);

  const handleBranchOfficeChange = useCallback(
    (_event: any, newValue: AutocompleteOption | null) => {
      setValue('branchOffice', newValue ? { ...newValue } : null);
      setValue('department', null);
    },
    [setValue]
  );

  const handleDepartmentChange = useCallback(
    (_event: any, newValue: AutocompleteOption | null) => {
      if (newValue) {
        const newDepartmentIds = newValue;
        setValue('department', newDepartmentIds);
      }
    },
    [setValue]
  );

  return (
    <>
      <FormGroup row>
        <LwFormAutocomplete
          name="branchOffice"
          label="Vestigingen"
          getOptionLabel={(option) => option.label ?? ''}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value;
          }}
          options={branchOfficeOptions}
          isLoading={!branchOfficeOptions?.length}
          filterOptions={(options, state) => getFilterOptions(options, state)}
          control={control}
          getOptionKey={(option) => option.value}
          onChange={handleBranchOfficeChange}
        />
        <LwFormAutocomplete
          name="department"
          label="Afdelingen"
          getOptionLabel={(option) => option.label ?? ''}
          isOptionEqualToValue={(option, value) => {
            return option?.value === value.value;
          }}
          options={departmentOptions}
          isLoading={!departmentOptions?.length}
          filterOptions={(options, state) => getFilterOptions(options, state)}
          disabled={selectedBranchOffice?.label === undefined}
          control={control}
          getOptionKey={(option) => option.value}
          onChange={handleDepartmentChange}
        />
      </FormGroup>
      <LwFormMultiSelect
        name="flexWorkers"
        label="Flexwerkers"
        control={control}
        getOptionKey={(option) => option.id}
        onChange={(_e, value) => {
          setValue('flexWorkers', value);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value.id;
        }}
        options={flexWorkerOptions}
      />
    </>
  );
};

export { BusinessUnitFiltering };
