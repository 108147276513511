import { ShiftPlanningTableDataProvider } from './table/shift-planning-table';
import { Header } from '../../../../../redesign/header';
import { Page } from '../../../../../redesign/page';

const ShiftPlanning = () => {
  return (
    <Page header={<Header titleText="Shift Planning" />}>
      <ShiftPlanningTableDataProvider />
    </Page>
  );
};

export { ShiftPlanning };
