import React, { useCallback } from 'react';
import { Box, Grid, styled } from '@mui/material';
import { LwMessageBlock } from 'redesign/message-block/message-block';
import { RenderUnsettledUI } from 'shared/components';
import {
  GetPartnerInboxMessagesResponse,
  PartnerInboxMessage,
} from 'partner/shared/services/partner-inbox-service.types';
import { useEffect, useMemo, useRef } from 'react';
import { useOnScrollDown } from 'redesign/table';
import { useExternalParams } from 'shared/hooks';
import { LWModal } from 'redesign/modal';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import { useGetPartnerInboxMessages } from './hooks/use-get-inbox-messages';
import { useResolveMessageTask } from './hooks/use-resolve-message-task';
import { useUnresolveMessageTask } from './hooks/use-unresolve-message-task';
import { MessageDetail } from './message-detail-modal/message-detail-modal';

const initialValues = {
  id: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

const InboxMessagesDataProvider = () => {
  const messagesQuery = useGetPartnerInboxMessages();

  if (messagesQuery.status !== 'success') {
    return <RenderUnsettledUI data={messagesQuery} />;
  }

  return <InboxMessages messagesQuery={messagesQuery} />;
};

const InboxMessages = ({
  messagesQuery,
}: {
  messagesQuery: InfiniteQueryObserverSuccessResult<GetPartnerInboxMessagesResponse, unknown>;
}) => {
  const [params, setParams] = useExternalParams(initialValues);
  const flatData = useMemo(
    () => messagesQuery.data.pages?.flatMap((page) => page.messages) ?? [],
    [messagesQuery]
  );
  const { fetchIfBottomScrolled: onScroll } = useOnScrollDown({
    infiniteQueryResult: messagesQuery,
    flatData,
  });
  const openMessageDetail = useCallback(
    (id: string | undefined) => {
      setParams({ id });
    },
    [setParams]
  );
  const closeMessageDetail = useCallback(() => {
    setParams({ id: undefined });
  }, [setParams]);

  return (
    <StyledBox>
      <InboxContainer onScroll={onScroll} data={flatData} openMessageDetail={openMessageDetail} />
      <LWModal isOpen={!!params.id} onClose={closeMessageDetail} testId="modal-message-detail">
        {params.id ? <MessageDetail id={params.id} close={closeMessageDetail} /> : null}
      </LWModal>
    </StyledBox>
  );
};

const InboxContainer = ({
  onScroll,
  data,
  openMessageDetail,
}: {
  onScroll: ((el: HTMLDivElement | null) => void) | undefined;
  data: PartnerInboxMessage[];
  openMessageDetail: (messageId: string) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onScroll) {
      onScroll(containerRef.current);
    }
  }, [onScroll]);

  const mutationResolve = useResolveMessageTask();
  const mutationUnresolve = useUnresolveMessageTask();

  const handleMessageTaskAction = (messageId: string, isTaskDone: boolean) => {
    const mutation = isTaskDone ? mutationUnresolve : mutationResolve;
    mutation.mutate({ messageId });
  };

  return (
    <Box>
      <StyledContainerWrapper
        ref={containerRef}
        container
        onScroll={onScroll ? (e) => onScroll(e.target as HTMLDivElement) : () => {}}
        spacing={4}
        sx={{ marginBottom: '2px' }}
        data-testid={'inbox-messages-container'}
      >
        {data.map((message) => (
          <Grid item xs={6} md={3} lg={3} key={message.id}>
            <Box
              onClick={() => openMessageDetail(message.id)}
              data-testid={`message-block-${message.isTaskDone ? 'done' : 'todo'}`}
            >
              <LwMessageBlock
                message={message}
                menuItems={[
                  {
                    handler: () => handleMessageTaskAction(message.id, message.isTaskDone),
                    actionName: `Markeer als ${message.isTaskDone ? 'Nog te doen' : 'Voltooid'}`,
                  },
                ]}
              />
            </Box>
          </Grid>
        ))}
      </StyledContainerWrapper>
    </Box>
  );
};

const StyledBox = styled(Box)({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  marginBottom: '0px',
});

const StyledContainerWrapper = styled(Grid)({
  marginBottom: '0px',
  paddingBottom: '16px',
  height: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '22px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },
});
export { InboxMessagesDataProvider };
