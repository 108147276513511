import { createTheme, switchClasses } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { chipClasses } from '@mui/material/Chip';
import { radioClasses } from '@mui/material/Radio';
import { formLabelClasses } from '@mui/material/FormLabel';
import { palette } from './mui/palette';
import { inter, urbanist } from './base/fonts';
import { BLACK } from './base/colors';

const typography = {
  // Title here
  // https://www.figma.com/file/RdsOYhrJCnYOqq4RVKQX1G/Design?type=design&node-id=2594-202056&mode=dev
  h1: {
    '@media': {
      fontFamily: 'Urbanist',
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 700,
      color: BLACK[100],
    },
  },
  // Blocks header he re
  // https://www.figma.com/file/RdsOYhrJCnYOqq4RVKQX1G/Design?type=design&node-id=2594-202187&mode=dev
  h2: {
    '@media': {
      fontFamily: 'Urbanist',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 700,
      color: BLACK[100],
    },
  },
  // Unknown
  h3: {
    '@media ': {
      fontWeight: 700,
      fontSize: '20px',
    },
  },
  // Unknown
  h4: {},
  // Unknown
  h5: {},
  // Unknown
  h6: {},
  // Subtitle header here
  //
  subtitle1: {
    '@media': {
      color: BLACK[60],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
    },
  },
  subtitle2: {
    '@media': {
      color: BLACK[60],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
    },
  },
  body1: {
    '@media': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      color: BLACK[100],
    },
    // fontSize: '12px',
  },
  body2: {
    '@media': {
      color: BLACK[60],
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      // fontSize: '14px',
    },
  },
};

const themeConfig = {
  backgrounds: {
    main: '#FAFAFA',
  },
  blocks: {
    height: '210px',
    heightBigger: '260px',
  },
  icons: {
    height: '24px',
    width: '24px',
  },
} as const;

const baseTheme = createTheme({
  spacing: 4,
  palette: {
    ...palette,
    tertiary: {},
    sidebar: {
      main: grey['600'],
      dark: grey['800'],
    },
  },
});

const theme = createTheme(
  {
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            [`& .${inputLabelClasses.formControl}`]: {
              position: 'static',
              transform: 'none',
            },
            [`& .${autocompleteClasses.input}`]: {
              fontWeight: 'bold',
              padding: '0px !important',
            },
            [`& .${autocompleteClasses.inputRoot}`]: {
              marginTop: '8px',
              marginBottom: '30px',
              padding: '16px',
              color: 'rgba(0,0,0,1)',
              borderWidth: '1px',
              borderColor: 'rgba(0,26,153,0.2)',
              borderStyle: 'solid',
              borderRadius: '8px',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              backgroundColor: 'transparent',

              '&.Mui-error': {
                borderColor: 'rgba(255,0,0,1)',
              },

              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: 'rgba(0,26,153, 0.6)',
              },

              '&.Mui-disabled': {
                backgroundColor: 'rgba(240, 240, 240, 0.5)',
                '&:hover': {
                  borderColor: 'rgba(0,26,153,0.2)',
                  backgroundColor: 'rgba(240, 240, 240, 0.5)',
                },
              },

              '&:hover': {
                borderColor: 'rgba(0,26,153,1)',
                backgroundColor: 'transparent',
              },

              [`& .${autocompleteClasses.endAdornment}`]: {
                right: '7px',
                width: '24px',
                height: '24px',
              },
            },
            [`& .${autocompleteClasses.inputRoot}:has(.${chipClasses.root})`]: {
              paddingBottom: '7px',
              paddingTop: '7px',
            },
            [`& .${outlinedInputClasses.root}`]: {
              padding: '16px',
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '@media (min-width: 600px)': {
              justifyContent: 'center',
              left: '0',
              right: '0',
            },
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          disableInjectingGlobalStyles: true,
        },
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              pointerEvents: 'none',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          role: 'button',
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0,0,0,.1)',
            backdropFilter: 'blur(2px)',
          },
        },
        defaultProps: {
          BackdropProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
          paper: {
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: '16px',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            backdropFilter: 'unset',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: '16px',
            fontSize: '16px',
            fontWeight: 'bold',
            paddingBottom: '16px',
            paddingTop: '16px',
          },
          message: {
            padding: 0,
            overflow: 'visible',
          },
          standardError: {
            backgroundColor: '#f7e3d9',
            color: '#000000',
          },
          standardSuccess: {
            backgroundColor: '#d9f7ed',
            color: '#000000',
          },
          icon: {
            alignItems: 'center',
            marginRight: 0,
            padding: 0,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: {
            color: '#000000',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': [inter],
          fallbacks: [
            {
              '@font-face': urbanist,
            },
          ],
          html: {
            backgroundColor: '#fafafa',
            height: '100%',
            minHeight: '100%',
          },
          body: {
            margin: 0,
            fontFamily: 'Inter, Urbanist, sans-serif',
            height: '100%',
            minHeight: '100%',
          },
          a: {
            color: 'inherit',
            '&:visited': {
              color: 'unset',
            },
          },
          'h1, h2': {
            fontWeight: 'bold',
          },
          [`fieldset.${outlinedInputClasses.notchedOutline}`]: {
            border: 'none',
            borderRadius: '16px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          },
          '#root': {
            height: '100%',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0,26,153,0.2)',
            height: '2px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '12px',
            marginBottom: '8px',
            marginRight: '16px',
          },
          label: {
            padding: '8px 16px',
            color: 'rgba(0,0,0,0.6)',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          circular: {
            borderWidth: 1,
            borderColor: grey[200],
            borderStyle: 'solid',
            color: common.black,
            backgroundColor: common.white,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '24px',
            padding: '32px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            '& > h2': {
              fontSize: '24px',
              fontWeight: 'bold',
            },
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '16px',
            fontWeight: 'bold',
            minHeight: 'unset !important',
            minWidth: 'unset !important',
            padding: '8px 16px',
            textTransform: 'unset',
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              color: 'inherit',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& tr': {
              '& th': {
                borderBottom: 'none',
              },
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '16px',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
          },
          stickyHeader: { backgroundColor: '#fff' },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            marginRight: '16px',
            [`&[class*=${radioClasses.colorPrimary}][class*=Mui-checked]`]: {
              color: 'rgba(0, 43, 255, 1)',
            },
            [`&[class*=${radioClasses.colorSecondary}][class*=Mui-checked]`]: {
              color: 'rgba(0, 43, 255, 1)',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#000',
            fontSize: '16px',
            lineHeight: '1.75rem',
            fontWeight: 700,
            '&.Mui-focused': {
              color: '#000',
            },
            '&.Mui-error': {
              color: '#000',
            },

            '&.Mui-disabled': {
              [`& .${formLabelClasses.asterisk}`]: {
                color: 'rgba(0,0,0,0.38)',
              },
            },
          },
          asterisk: {
            color: '#000',
            '&.Mui-error': {
              color: '#000',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          filled: {
            whiteSpace: 'nowrap',
            overflowX: 'visible',
            overflowY: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            transform: 'none',
            position: 'relative',
            pointerEvents: 'auto',
            [`&[class*=${inputLabelClasses.shrink}]`]: {
              transform: 'none',
              overflow: 'hidden',
            },

            '&.Mui-disabled': {
              [`&>[class*="${formLabelClasses.asterisk}"]`]: {
                color: 'rgba(0, 0, 0, 0.38)',
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '16px',
            [`&.${outlinedInputClasses.input}`]: {
              padding: '10px 0px',
            },
            '&:focus': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
          root: {
            padding: '0px',
            color: 'rgba(0,0,0,1)',
            borderWidth: '1px',
            borderColor: 'rgba(0,26,153,0.2)',
            borderStyle: 'solid',
            borderRadius: '8px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            backgroundColor: 'transparent',

            '&.Mui-error': {
              borderColor: 'rgba(255,0,0,1)',
            },

            '&.Mui-focused': {
              backgroundColor: 'transparent',
              borderColor: 'rgba(0,26,153, 0.6)',
            },

            '&.Mui-disabled': {
              backgroundColor: 'rgba(240, 240, 240, 0.5)',
              '&:hover': {
                borderColor: 'rgba(0,26,153,0.2)',
                backgroundColor: 'rgba(240, 240, 240, 0.5)',
              },
            },

            '&:hover': {
              borderColor: 'rgba(0,26,153,1)',
              backgroundColor: 'transparent',
            },
          },
          input: {
            padding: '16px',
            fontWeight: 700,
            '&:-webkit-autofill': {
              borderRadius: 'inherit',
              // https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
              // because Chrome is really opinionated on how autofill shoud look like
              transition: 'background-color 600000s 0s, color 600000s 0s',
            },
          },
          multiline: {
            padding: '0px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            width: '100%',
            color: 'rgba(0,0,0,1)',
            fontSize: 14,
            fontWeight: 500,
            position: 'absolute',
            bottom: 0,
            marginTop: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          contained: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& fieldset': {
              display: 'none',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
            padding: 0,
            width: '48px',
            height: '24px',
          },
          colorPrimary: {
            color: 'white',

            [`& + .${switchClasses.track}`]: {
              backgroundColor: 'rgba(0,0,0,0.5)',
            },

            '&.Mui-disabled': {
              color: 'white',

              [`& + .${switchClasses.track}`]: {
                backgroundColor: 'rgba(0,0,0,1)',
              },

              '&.Mui-checked': {
                [`& + .${switchClasses.track}`]: {
                  backgroundColor: 'rgba(0,43,255,1)',
                },
              },
            },

            '&.Mui-checked': {
              color: 'white',

              [`& + .${switchClasses.track}`]: {
                backgroundColor: 'rgba(0,43,255,1)',
              },
            },
          },
          thumb: {
            color: 'white',
            boxShadow: '0 0 0 0 transparent',
          },
          switchBase: {
            padding: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            '&.Mui-checked': {
              '&.Mui-disabled': {
                [`& + .${switchClasses.track}`]: {
                  opacity: '0.2',
                },
              },
              [`& + .${switchClasses.track}`]: {
                opacity: '1',
              },
            },

            '&.Mui-disabled': {
              [`& + .${switchClasses.track}`]: {
                opacity: '0.1',
              },
            },
          },
          input: {
            width: '100%',
            height: '100%',
          },
        },
      },
    },
    typography: {
      ...typography,
    },
  },
  baseTheme
);

export { theme, themeConfig };
