import { useState } from 'react';
import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import AddIcon from '@mui/icons-material/Add';
import { Box, Tab, Tabs } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Toolbox } from 'redesign/toolbox';
import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { routes } from 'Routes';
import { CompanyDetailAndJobGroups } from 'types/companies/company-types';
import { IconEdit } from 'assets/img';
import { ItemOf } from 'types/utility';
import { makeStyles } from '@mui/styles';
import { invariant } from 'shared/utils/utils';
import { hasAccessWithinPolicyGroup, PolicyGroups } from 'shared/utils/policy-controller';
import { LwButton } from 'redesign/button';
import { LwTab } from 'redesign/tab';
import { FlexWorkerForNotWelcomeList } from 'ogp/services/company-service.types';
import { useDialog } from 'ogp/providers/dialog-provider';
import { FormProvider, useForm } from 'react-hook-form';
import { useToggle } from 'shared/hooks';
import { useAlert } from 'shared/components/alerts';
import { CompanyDataProvider } from './company-data-provider';
import { CompanyDetailInformation } from './tabs/company-detail-information';
import { CompanyDetailOrts } from './tabs/company-detail-orts';
import { CompanyDetailNotWelcomeList } from './tabs/not-welcome-list/not-welcome-list';
import { BillingEntities } from './tabs/billing-entities';
import { NotWelcomeListAddFlexWorker } from './tabs/not-welcome-list/not-welcome-list-add-flex-worker';
import { NotWelcomeListConfirmationDialog } from './tabs/not-welcome-list/not-welcome-list-confirmation-dialog';
import { useAddFlexWorkerToNotWelcomeList } from './tabs/not-welcome-list/hooks/use-add-flex-worker-to-not-welcome-list';

type PanelTabType = {
  id: React.ComponentProps<typeof Tab>['value'];
  tabLabel: React.ComponentProps<typeof Tab>['label'];
  element: React.ReactNode;
};

const CompanyDetail = () => {
  return <CompanyDataProvider>{(data) => <Company data={data} />}</CompanyDataProvider>;
};

const Company = ({ data }: { data: CompanyDetailAndJobGroups }) => {
  const { data: user } = useGetOGPUserInfo();
  const generatePath = useCompanyRelativePath();
  const links = getLinks(data.company.socials);
  const labelGroups = getLabelGroups(data);
  const editLink = generatePath(routes.CompanyEdit, { id: data.company.id });
  const classes = getClasses();
  const tabs: PanelTabType[] = getTabs(user, links, data);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const canEditCompany =
    user !== undefined &&
    hasAccessWithinPolicyGroup(PolicyGroups.COMPANY, user.userInfo, 'HQADMIN' || 'ACCOUNTHOLDER');

  const { alertSuccess, alertError } = useAlert();
  const mutation = useAddFlexWorkerToNotWelcomeList();
  const { openDialog, closeDialog } = useDialog();
  const methods = useForm<{ flexWorker: FlexWorkerForNotWelcomeList; reason: string }>({
    defaultValues: {
      flexWorker: {} as any, // because autocomplete bitches between controlled/uncontrolled state
      reason: '',
    },
  });
  const {
    on: isSecondaryConfirmationOpen,
    setOn: openSecondaryConfirmation,
    setOff: closeSecondaryConfirmation,
  } = useToggle();

  const submitAddFlexWorkerData = (data: {
    flexWorker: FlexWorkerForNotWelcomeList;
    reason: string;
  }) => {
    const { flexWorker, reason } = data;
    mutation.mutate(
      { flexWorkerId: flexWorker.id, reason },
      {
        onSuccess: () => {
          alertSuccess('Flexwerker toegevoegd aan lijst.');
          closeDialog();
          closeSecondaryConfirmation();
          methods.reset();
        },
        onError: (e) => {
          alertError(e);
          closeSecondaryConfirmation();
        },
      }
    );
  };

  const handleAddFlexWorker = () =>
    openDialog(
      {
        title: 'Toevoegen aan lijst',
        content: (
          <FormProvider {...methods}>
            <NotWelcomeListAddFlexWorker />
          </FormProvider>
        ),
      },
      () => {
        openSecondaryConfirmation();
      }
    );

  const handleConfirmAddFlexWorker = () => {
    methods.handleSubmit(submitAddFlexWorkerData)();
  };

  return (
    <Page
      header={
        <Header
          titleText={data.company.name}
          subheaderText="Bedrijfsdetails"
          labelGroups={labelGroups}
        />
      }
      toolbox={
        <Toolbox>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Tabs
              value={selectedTab.id}
              classes={{
                flexContainer: classes.tabs,
              }}
              onChange={(_, tabId) => {
                const selectedTab = tabs.find((i) => i.id === tabId);

                invariant(selectedTab, 'Tab not found');

                setSelectedTab(selectedTab);
              }}
              aria-label="tabbladen"
            >
              {tabs.map((tab) => (
                <LwTab key={tab.id} value={tab.id} label={tab.tabLabel} />
              ))}
            </Tabs>

            <Box className={classes.buttonWrap}>
              {canEditCompany ? (
                <Box position="absolute" bottom="130px" right="32px">
                  <LwButton
                    to={editLink}
                    startIcon={<IconEdit />}
                    color="primary"
                    iconColorMode="fill"
                  >
                    Bedrijf aanpassen
                  </LwButton>
                </Box>
              ) : null}
              {selectedTab.id === 'billing' ? (
                <LwButton
                  iconColorMode="fill"
                  startIcon={<AddIcon />}
                  color="secondary"
                  to={generatePath(routes.BillingEntitiesCreate)}
                >
                  Voeg facturatiegegevens toe
                </LwButton>
              ) : null}
              {selectedTab.id === 'nwl' ? (
                <LwButton color="secondary" onClick={handleAddFlexWorker}>
                  Toevoegen
                </LwButton>
              ) : null}
            </Box>
          </Box>
        </Toolbox>
      }
    >
      <Box width={'100%'}>{selectedTab.element}</Box>
      <NotWelcomeListConfirmationDialog
        isOpen={isSecondaryConfirmationOpen}
        onClose={closeSecondaryConfirmation}
        onConfirm={handleConfirmAddFlexWorker}
      />
    </Page>
  );
};

const getLabelGroups = (data: CompanyDetailAndJobGroups) => [
  {
    title: 'Locatie',
    description: data.company.location?.address ?? '',
  },
  {
    title: 'Functiegroep',
    description: data.jobGroups.map((jg) => jg.name).join(', '),
  },
];

const getLinks = (socials: CompanyDetailAndJobGroups['company']['socials']) => {
  const get = (type: ItemOf<typeof socials>['type']) =>
    socials.find((social) => social.type === type)?.url;

  const links = [
    {
      name: 'Website',
      url: get('website'),
    },
    {
      name: 'Facebook',
      url: get('facebook'),
    },
    {
      name: 'Instagram',
      url: get('instagram'),
    },
    {
      name: 'LinkedIn',
      url: get('linkedin'),
    },
  ];

  return links;
};

const getTabs = (
  user: Awaited<ReturnType<typeof useGetOGPUserInfo>>['data'],
  links: ReturnType<typeof getLinks>,
  data: CompanyDetailAndJobGroups
) => {
  const isAdmin =
    user !== undefined && hasAccessWithinPolicyGroup(PolicyGroups.COMPANY, user.userInfo, 'ADMIN');

  return [
    {
      id: 'information',
      tabLabel: 'Informatie',
      element: <CompanyDetailInformation data={data} links={links} />,
    },
    {
      id: 'ort',
      tabLabel: 'ORTs',
      element: (
        <CompanyDetailOrts
          weekdayOrts={data.company.weekdayOrts}
          bankHolidayOrts={data.company.bankHolidayOrts}
        />
      ),
    },
    ...(isAdmin
      ? [
          {
            id: 'billing',
            tabLabel: 'Facturatiegegevens',
            element: <BillingEntities />,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            id: 'nwl',
            tabLabel: 'Niet-welkom-lijst',
            element: <CompanyDetailNotWelcomeList />,
          },
        ]
      : []),
  ];
};

const getClasses = makeStyles((theme: Theme) => ({
  tabs: {
    gap: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflow: 'visible',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexWrap: 'wrap',

    '& > *(:last-child)': {
      marginRight: theme.spacing(8),
    },
  },
}));

export { CompanyDetail };
