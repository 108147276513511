import { Box } from '@mui/material';
import { useState } from 'react';
import { UploadBusCsvForm } from './bus-csv-upload-form';
import { XMLUploadForm } from './xml-upload-form';
import { Page } from '../../../../redesign/page';
import { Header } from '../../../../redesign/header';

const UploadPayments = () => {
  const [isCsvVisible, setIsCsvVisible] = useState(true);

  return (
    <Page header={<Header titleText={`Upload ${isCsvVisible ? 'CSV' : 'XML'} Payments`} />}>
      <Box width="100%">
        {isCsvVisible ? (
          <UploadBusCsvForm isCsvVisible={isCsvVisible} setIsCsvVisible={setIsCsvVisible} />
        ) : (
          <XMLUploadForm isCsvVisible={isCsvVisible} setIsCsvVisible={setIsCsvVisible} />
        )}
      </Box>
    </Page>
  );
};

export { UploadPayments };
