import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FlexWorkerData } from 'types/flexWorkers/FlexWorkerTypes';
import { compareDateStrings, validateDateString } from 'shared/utils/validate-date';
import { LwFormDate } from 'redesign/forms/date';
import { LwFormInput } from 'redesign/forms/input';
import { FormCheckbox } from 'redesign/forms/checkbox-group';

type FlexWorkerFormEditProps = {
  flexWorkerData: FlexWorkerData;
};

export const FlexWorkerFormEdit = ({ flexWorkerData }: FlexWorkerFormEditProps) => {
  const { control, watch } = useFormContext<FlexWorkerData>();

  const BSStartDate = watch('smallBusinessSchemeStartDate');
  const BSEndDate = watch('smallBusinessSchemeEndDate');

  const validateBSStartEndDateComparison = () =>
    BSStartDate?.length && BSEndDate?.length && !compareDateStrings(BSStartDate, BSEndDate)
      ? 'De opgegeven einddatum is eerder dan de begindatum'
      : undefined;

  const validateBSStartDateExists = () => {
    const BSStartDateExists = BSStartDate && BSStartDate.length > 0;
    const BSEndDateExists = BSEndDate && BSEndDate.length > 0;
    return !BSStartDateExists && BSEndDateExists ? 'Oops, geen KOR begindatum bekend' : undefined;
  };

  const validateBSDate = (value: string | undefined | null) =>
    value && value.length > 0 && !validateDateString(value)
      ? 'De datum die je invult lijkt niet te kloppen'
      : undefined;

  return (
    <Box>
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <h2 className="subtitle">Persoonsgegevens</h2>
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="firstName"
            label="Voornaam"
            control={control}
            rules={{ required: 'Voer een voornaam in' }}
          />
        </Grid>
        <Grid item xs={2}>
          <LwFormInput name="infix" label="Tussenvoegsel" control={control} />
        </Grid>
        <Grid item xs={4}>
          <LwFormInput
            name="lastName"
            label="Achternaam"
            control={control}
            rules={{ required: 'Voer een achternaam in' }}
          />
        </Grid>
        <Grid item xs={12}>
          <h2 className="subtitle">Zakelijke gegevens</h2>
        </Grid>
        <Grid item xs={6}>
          <LwFormDate
            name="smallBusinessSchemeStartDate"
            label="KOR Startdatum"
            control={control}
            rules={{
              validate: {
                // @ts-expect-error bad validation typing
                validateBSDate,
                validateBSStartDateExists,
                validateBSStartEndDateComparison,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormDate
            name="smallBusinessSchemeEndDate"
            label="KOR Einddatum"
            rules={{
              validate: {
                // @ts-expect-error bad validation typing
                validateBSDate,
                validateBSStartDateExists,
                validateBSStartEndDateComparison,
              },
            }}
            control={control}
          />
        </Grid>
        {flexWorkerData.employmentType === 'TEMPWORKER' ? (
          <>
            <Grid item xs={12}>
              <h2 className="subtitle">Zakelijke gegevens</h2>
            </Grid>
            <Grid item xs={6}>
              <FormCheckbox label="Loonheffingskorting" control={control} name="wageTaxCredit" />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};
