import { AutocompleteOption } from 'redesign/forms/autocomplete';
import { ShiftPlanningFiltersJobType } from 'hq/services/shift-planning-service.types';
import { ShiftPlanningFiltersData } from '../shift-planning-filters.types';
import { ShiftPlanningFilters } from '../../table/hooks/use-shift-planning-page.types';

export const checkBranchOfficesInCompany = (
  branchOfficeNames: string[],
  shiftPlanningFiltersData: ShiftPlanningFiltersData
): boolean => {
  const allBranchOfficeNames =
    shiftPlanningFiltersData.companies
      ?.flatMap((company) => company.branchOffices)
      .map((branchOffice) => branchOffice.branchOfficeName) || [];

  // Check if all branch office names are present
  return branchOfficeNames.every((name) => allBranchOfficeNames.includes(name));
};

export const getJobTypes = (
  appliedFilters: ShiftPlanningFilters,
  jobTypes: ShiftPlanningFiltersJobType[]
) => {
  let cleanedJobTypes: AutocompleteOption[] | undefined = undefined;
  if (!appliedFilters) {
    return undefined;
  } else {
    const jobTypeUncleaned = appliedFilters.jobType?.map((jobTypeParamId) => {
      const data = jobTypes?.find((databaseJobType) => {
        return databaseJobType.id === jobTypeParamId;
      });
      if (data) {
        return { value: data.id, label: data.name } as AutocompleteOption;
      } else {
        return undefined;
      }
    });
    cleanedJobTypes = jobTypeUncleaned?.filter(
      (jobType): jobType is AutocompleteOption => jobType !== undefined
    );
    return cleanedJobTypes;
  }
};
