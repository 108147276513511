import { useAlert } from 'shared/components/alerts';
import { branchOfficeAlerts as alerts } from 'shared/components/alerts/alert-messages';
import { BranchOfficeFormData } from 'types/companies/branch-office-types';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { BranchOfficeForm } from './branch-office-form';
import { useUpdateBranchOffice } from './hooks/use-update-branch-office';
import { useGetBranchOffice } from './hooks/use-get-branch-office';
import { Header } from '../../../../../../../../../../../redesign/header';
import { Page } from '../../../../../../../../../../../redesign/page';
import { GetBranchOfficeResponse } from '../../../../../../../../../../services/branch-office-service.types';
import { IconApproved, IconCancel } from '../../../../../../../../../../../assets/img';
import { LwButton } from '../../../../../../../../../../../redesign/button';
import { Toolbox } from '../../../../../../../../../../../redesign/toolbox';

const BranchOfficeEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const branchOfficeQuery = useGetBranchOffice(id);

  if (branchOfficeQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficeQuery} />;
  }

  return <BranchOfficeEditImpl data={branchOfficeQuery.data} />;
};

const BranchOfficeEditImpl = ({ data }: { data: GetBranchOfficeResponse['branchOffice'] }) => {
  const methods = useForm<BranchOfficeFormData>({
    defaultValues: data,
  });
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const mutation = useUpdateBranchOffice(data.id);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    methods.handleSubmit((formData: BranchOfficeFormData) =>
      mutation.mutate(
        { ...formData, companyId: data.companyId },
        {
          onSuccess: () => {
            alertSuccess(alerts.success.created);
            navigate(generatePath(routes.CompanyDetail, { id: data.companyId }));
          },
          onError: (e) => alertError(e),
        }
      )
    )();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Page
          header={<Header titleText="Vestiging bewerken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Vestiging aanmaken
              </LwButton>
              <LwButton
                onClick={() => navigate(generatePath(routes.CompanyDetail, { id: data.companyId }))}
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <BranchOfficeForm
            state="update"
            companyId={data.companyId}
            address={data.location.address}
          />
        </Page>
      </form>
    </FormProvider>
  );
};
export { BranchOfficeEdit };
