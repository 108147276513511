import { ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconRatingThumb } from 'assets/img';

type Props = {
  percentage: number;
};

const RatingPercentage = styled(({ percentage, ...props }: Props) => (
  <ListItemText {...props} sx={{ '.MuiTypography-root': { display: 'flex', gap: '8px' } }}>
    <IconRatingThumb />
    <Typography component="span" variant="body2" sx={{ margin: 'auto 0' }}>
      {percentage} %
    </Typography>
  </ListItemText>
))(({ theme }) => ({
  flex: '1 1',
  display: 'flex',
  gap: theme.spacing(2),
}));

export { RatingPercentage };
