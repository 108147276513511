import { Box, Divider } from '@mui/material';
import { OGPShiftDetailResponse } from 'ogp/services/shift-service.types';
import { LWModal, ModalPanelBody } from 'redesign/modal';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { ShiftDetailHead } from 'shared/components/shift-detail-modal/components/shift-detail-head/shift-detail-head';
import { useOgpGetShiftDetailsTabs } from 'shared/components/shift-detail-modal/hooks/ogp/use-ogp-get-shift-detail-tabs';
import { useGetShiftDetailHeadActions } from 'shared/components/shift-detail-modal/hooks/use-get-shift-detail-head-actions';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';

export const PlanningModalProvider = ({
  onClose,
  shiftDetailId,
}: {
  onClose: () => void;
  shiftDetailId: string | null;
}) => {
  const shiftQuery = useOgpGetShift(shiftDetailId);

  if (!shiftDetailId) {
    return <></>;
  }

  if (shiftQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={shiftQuery}
      />
    );
  }

  return <PlanningModal onClose={onClose} data={shiftQuery.data} />;
};

const PlanningModal = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data: OGPShiftDetailResponse;
}) => {
  const shiftDetailTabs = useOgpGetShiftDetailsTabs(data);

  const { children: shiftDetailHeadActions, isArchiving } = useGetShiftDetailHeadActions({
    data,
    closeModal: onClose,
  });

  return (
    <LWModal isOpen={true} onClose={onClose} testId="shift-detail">
      <Box p={8} height="100%" display="flex" flexDirection="column">
        <ShiftDetailHead
          data={data}
          close={onClose}
          actions={shiftDetailHeadActions}
          isArchiving={isArchiving}
        />
        <Box mt={8} mb={8}>
          <Divider />
        </Box>
        <ModalPanelBody tabs={shiftDetailTabs} />
      </Box>
    </LWModal>
  );
};
