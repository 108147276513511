import { LevelTableCellProps } from 'redesign/table';
import { ShiftPlanningShiftResponse } from 'partner/shared/services/partner-planner-service.types';
import { DateTime } from 'luxon';

export const INITIAL_PAGE = 0;

export type PartnerShiftPlanningColumnDefinition = ShiftPlanningShift & {
  action: string;
};

export type PartnerShiftPlanningTableOptions = {
  openShiftDetail: (shiftId: string) => void;
};
export type PartnerShiftPlanningCellProp<T extends keyof PartnerShiftPlanningColumnDefinition> =
  LevelTableCellProps<
    PartnerShiftPlanningColumnDefinition,
    PartnerShiftPlanningColumnDefinition[T],
    PartnerShiftPlanningTableOptions
  >;

type ShiftPlanningShift = Omit<ShiftPlanningShiftResponse, 'dateTime'> & {
  dateTime: {
    fromDateTime: DateTime;
    toDateTime: DateTime;
  };
};
