import { OgpProjectGetResponse } from 'ogp/services/project-service.types';
import { Table } from 'redesign/table';
import { IconApproved, IconCloseCircle, IconDocument1, IconTrash } from 'assets/img';
import { ContextMenuItem } from 'redesign/context-menu';
import { useAlert } from 'shared/components/alerts';
import { useDialog } from 'ogp/providers/dialog-provider';
import { EmptyTableMessage } from 'ogp/components/common/empty-table-message';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { useMemo } from 'react';
import { PlacementColumnDefinition, PlacementTableOptions } from './table/placements-table.types';
import { getPlacementsTableData } from './table/get-placements-table-data';
import { getPlacementsColumnDefinition } from './table/get-placements-column-definition';
import { useGetPlacementContract } from '../../../../shift-detail-modal/hooks/use-get-placement-contract';
import { useSiteDetection } from '../../../../../hooks';
import { usePlacementCancel } from '../../../../shift-detail-modal/hooks/use-placement-cancel';
import { usePlacementTerminate } from '../../../../shift-detail-modal/hooks/use-placement-terminate';
import { usePlacementComplete } from '../../../../shift-detail-modal/hooks/use-placement-complete';

export const PlacementTable = ({ project }: { project: OgpProjectGetResponse }) => {
  const { openDialog, enableDialog, disableDialog, closeDialog } = useDialog();
  const columns = useMemo(() => getPlacementsColumnDefinition(), []);
  const tableData = useMemo(() => getPlacementsTableData(project), [project]);
  const { alertSuccess, alertError } = useAlert();
  const cancelPlacementMutation = usePlacementCancel();
  const terminatePlacementMutation = usePlacementTerminate();
  const completePlacementMutation = usePlacementComplete();
  const getContractMutation = useGetPlacementContract({
    onSuccess: () => alertSuccess('Opdracht van overeenkomst succesvol gedownload!'),
    onError: (e) => alertError(e),
  });

  const siteDetectionResult = useSiteDetection();

  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();
  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    openPopupProfile(flexWorkerId, relation);
  };

  const handleCancelPlacement = (placementId: string) => {
    openDialog(
      {
        content: 'Weet je zeker dat je de plaatsing wilt annuleren?',
      },
      () => {
        disableDialog();
        cancelPlacementMutation.mutate(
          { placementId, siteDetectionResult },
          {
            onSuccess: () => {
              alertSuccess('Plaatsing geannuleerd');
              closeDialog();
            },
            onError: (e) => alertError(e),
            onSettled: enableDialog,
          }
        );
      }
    );
  };

  const handleTerminatePlacement = (placementId: string) => {
    openDialog(
      {
        content: 'Weet je zeker dat je de plaatsing wilt beëindigen?',
      },
      () => {
        disableDialog();
        terminatePlacementMutation.mutate(
          { placementId, siteDetectionResult },
          {
            onSuccess: () => {
              alertSuccess('Plaatsing beëindigd');
              closeDialog();
            },
            onError: (e) => alertError(e),
            onSettled: enableDialog,
          }
        );
      }
    );
  };

  const handleCompletePlacement = (placementId: string) => {
    openDialog(
      {
        content: 'Weet je zeker dat je de plaatsing wilt voltooien?',
      },
      () => {
        disableDialog();
        completePlacementMutation.mutate(
          { placementId, siteDetectionResult },
          {
            onSuccess: () => {
              alertSuccess('Plaatsing voltooid');
              closeDialog();
            },
            onError: (e) => alertError(e),
            onSettled: enableDialog,
          }
        );
      }
    );
  };

  const downloadOvo = (placementId: string) => {
    getContractMutation.mutate({ placementId, siteDetectionResult });
  };

  const getRowActions = ({ placement }: PlacementColumnDefinition['actions']) => {
    const actions: React.ReactNode[] = [];

    actions.push(
      <ContextMenuItem onClick={() => handleCancelPlacement(placement.id)} key="cancel">
        <IconTrash />
        Annuleren
      </ContextMenuItem>
    );
    actions.push(
      <ContextMenuItem onClick={() => handleTerminatePlacement(placement.id)} key="terminate">
        <IconCloseCircle />
        Beëindigen
      </ContextMenuItem>
    );
    actions.push(
      <ContextMenuItem onClick={() => handleCompletePlacement(placement.id)} key="complete">
        <IconApproved />
        Voltooien
      </ContextMenuItem>
    );
    actions.push(
      <ContextMenuItem onClick={() => downloadOvo(placement.id)} key="download-contract">
        <IconDocument1 />
        Overeenkomst
      </ContextMenuItem>
    );

    return actions;
  };

  return tableData.length === 0 ? (
    <EmptyTableMessage
      title="Er zijn geen flexwerkers ingeschreven!"
      subtitle="Er hebben zich nog geen flexwerkers aangemeld voor dit project."
    />
  ) : (
    <>
      {PopupProfileElement}
      <Table<PlacementColumnDefinition, PlacementTableOptions>
        columns={columns}
        data={tableData}
        getRowActions={getRowActions}
        onProfileClick={onProfileClick}
      />
    </>
  );
};
