import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/styles';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { usePartnerCheckoutsFiltersState } from 'partner/hooks/queryHooks/partner-checkouts/use-partner-checkouts-filters';
import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import { Toolbox } from 'redesign/toolbox';
import { useToggle } from 'shared/hooks';
import { RenderUnsettledUI } from 'shared/components';
import { InboxFilterForm } from 'partner/shared/components/filter-form';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { PartnerCheckoutsStateToggle } from './partner-checkouts-state-toggle';
import { PartnerWorkedShiftsTableProvider } from './partner-worked-shifts-table';
import { PartnerCounterOffersTable } from './partner-counter-offers-table';
import { usePartnerCheckoutInboxFilters } from '../../../hooks/queryHooks/partner-checkouts/use-partner-checkout-inbox-filters';
import { INITIAL_PAGE } from '../checked-out/partner-checked-out.types';

const PartnerCheckouts = () => {
  const usePartnerCheckoutsFilterState = usePartnerCheckoutsFiltersState();
  const checkoutsInboxFiltersQuery = usePartnerCheckoutInboxFilters();
  const { on: drawerOpen, toggle: toggleDrawer } = useToggle();

  if (checkoutsInboxFiltersQuery.status !== 'success') {
    return <RenderUnsettledUI data={checkoutsInboxFiltersQuery} />;
  }

  return (
    <StyledBox>
      <Page
        header={<Header titleText="Checkouts" />}
        pageStyle={{ height: '70%' }}
        toolbox={
          <Toolbox>
            <PartnerCheckoutsStateToggle />
            <Toolbox.PushRight>
              <FilterButton
                onClick={toggleDrawer}
                nrOfActiveFilters={usePartnerCheckoutsFilterState.nrOfActiveFilters}
              />
            </Toolbox.PushRight>
          </Toolbox>
        }
      >
        {usePartnerCheckoutsFilterState.filters.checkoutState === 'workedShifts' ? (
          <PartnerWorkedShiftsTableProvider
            filters={{
              ...usePartnerCheckoutsFilterState.activeFilters,
              pageParam: INITIAL_PAGE,
              pageSizeParam: DEFAULT_PAGE_SIZE,
            }}
          />
        ) : (
          <PartnerCounterOffersTable
            filters={{
              ...usePartnerCheckoutsFilterState.activeFilters,
              pageParam: INITIAL_PAGE,
              pageSizeParam: DEFAULT_PAGE_SIZE,
            }}
          />
        )}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
          <InboxFilterForm
            onCancel={toggleDrawer}
            data={{ ...checkoutsInboxFiltersQuery.data }}
            {...usePartnerCheckoutsFilterState}
          />
        </Drawer>
      </Page>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
});

export { PartnerCheckouts };
