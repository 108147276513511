import { Cell, Header, Table } from 'redesign/table';
import { NotWelcomeListItem } from 'ogp/services/company-service.types';
import { formatDate } from 'shared/utils/formatting';
import { IconCancel } from 'assets/img';
import { ContextMenuButton, ContextMenuItem } from 'redesign/context-menu';
import { useAlert } from 'shared/components/alerts';
import { useDialog } from 'ogp/providers/dialog-provider';
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { useRemoveFlexWorkerFromNotWelcomeList } from './hooks/use-remove-flex-worker-from-not-welcome-list';
import { NotWelcomeListColumnDefinition } from './not-welcome-list-table.types';

export const NotWelcomeListTable = ({
  notWelcomeList,
}: {
  notWelcomeList: NotWelcomeListItem[];
}) => {
  const columns = React.useMemo(() => getNotWelcomeListColumnDefinition(), []);
  const data = useMemo(() => {
    return notWelcomeList.map((i) => {
      const res: NotWelcomeListColumnDefinition = {
        flexWorkerName: `${i.firstName} ${i.infix} ${i.lastName}`,
        reason: i.reason,
        dateAdded: i.dateAdded.toString(),
        actions: { id: i.id },
      };

      return res;
    });
  }, [notWelcomeList]);

  return <Table columns={columns} data={data} tableStyle={{ tableLayout: 'fixed' }} />;
};

const getNotWelcomeListColumnDefinition = () => {
  const columns: Column<NotWelcomeListColumnDefinition>[] = [
    {
      Header: () => <Header>Naam</Header>,
      accessor: 'flexWorkerName',
      Cell: (cell) => <Cell>{cell.value}</Cell>,
    },
    {
      accessor: 'dateAdded',
      Header: <Header>Toegevoegd op</Header>,
      Cell: (cell) => <Cell>{formatDate(new Date(cell.value))}</Cell>,
    },
    {
      accessor: 'reason',
      Header: () => <Header>Reden</Header>,
      Cell: (cell) => (
        <Cell>
          <Typography noWrap={false} variant="body1">
            {cell.value}
          </Typography>
        </Cell>
      ),
    },
    {
      Header: () => <Header></Header>,
      accessor: 'actions',
      Cell: (cell) => <ActionsCell id={cell.value.id} />,
    },
  ];

  return columns;
};

const ActionsCell = ({ id }: { id: string }) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();
  const mutation = useRemoveFlexWorkerFromNotWelcomeList();

  const handleRemoval = () => {
    openDialog(
      {
        title: 'Verwijderen van lijst?',
        content:
          'Weet je zeker dat je de flexwerker van de niet-welkom-lijst wilt verwijderen? De flexwerker kan hierna weer aanmelden voor shifts en aan flexpools toegevoegd worden.',
      },
      () => {
        disableDialog();
        mutation.mutate(id, {
          onSuccess: () => {
            alertSuccess('Flexwerker van niet-welkom-lijst verwijderd.');
            closeDialog();
          },
          onError: (e) => {
            alertError(e);
            enableDialog();
          },
        });
      }
    );
  };

  return (
    <Cell>
      <ContextMenuButton>
        <ContextMenuItem onClick={handleRemoval}>
          <IconCancel />
          Verwijderen
        </ContextMenuItem>
      </ContextMenuButton>
    </Cell>
  );
};
