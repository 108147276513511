import { Box, styled, Tabs } from '@mui/material';
import { CompanyDetailSelectModel } from 'hq/services/mappers/mappers';
import { useState } from 'react';
import { ModalPanelTabType } from 'redesign/modal';
import { LwTab } from 'redesign/tab';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { getCompanyDetailTabs } from './get-company-detail-tabs';

export const CompanyDetailTabs = ({ data }: { data: CompanyDetailSelectModel }) => {
  const { allowPartnerRelations } = useFeatureFlagContext();
  const tabs: ModalPanelTabType[] = getCompanyDetailTabs(allowPartnerRelations, data);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <Box>
      <Box mb={4}>
        <StyledTabs
          value={selectedTab.id}
          onChange={(_, tabId) => {
            const selectedTab = tabs.find((i) => i.id === tabId) ?? tabs[0].id;
            setSelectedTab(selectedTab);
          }}
          aria-label="partner-detail-tabs"
        >
          {tabs.map((tab) => (
            <LwTab key={tab.id} value={tab.id} label={tab.tabLabel} />
          ))}
        </StyledTabs>
      </Box>
      <Box>{selectedTab.element}</Box>
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& div.MuiTabs-scroller': {
    '& .MuiTabs-flexContainer': {
      gap: theme.spacing(2),
      overflow: 'visible',
    },
  },
}));
