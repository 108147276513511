import { useParams } from 'react-router-dom';
import { routes } from 'ogp/Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { Box, Grid, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { IconDuplicate, IconEdit, IconTrash } from 'assets/img';
import { vatLabel } from 'types/Vat';
import { TakeDefined } from 'types/utility';
import { LwButton } from 'redesign/button';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { CompanyRole } from 'types/roles/CompanyRole';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { usePageProvider } from 'ogp/layouts';
import { useGetDepartmentAsDetail } from '../hooks';
import { useArchiveDepartmentDialog } from '../hooks/use-archive-department-dialog';

const DepartmentDetailDataProvider = () => {
  const { id } = useParams<'id'>();
  const departmentQuery = useGetDepartmentAsDetail(id);

  if (departmentQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  return <DepartmentDetail data={departmentQuery.data} id={id} />;
};

const DepartmentDetail = ({
  data,
  id,
}: {
  data: TakeDefined<ReturnType<typeof useGetDepartmentAsDetail>['data']>;
  id: string | undefined;
}) => {
  const generatePath = useCompanyRelativePath();
  const [{ filters }] = usePageProvider();
  const { handleArchival, handleForbiddenArchival } = useArchiveDepartmentDialog(data.id);
  const statistics: React.ComponentProps<typeof Header>['labelGroups'] = [
    {
      title: 'Wettelijke vertegenwoordiger',
      description: data.legalPerson ?? '-',
    },
  ];

  return (
    <Page
      header={
        <Header titleText={data.name} subheaderText="Afdelingsdetails" labelGroups={statistics} />
      }
      toolbox={
        <Toolbox>
          <LwButton color="primary" to={generatePath(routes.Departments, undefined, filters)}>
            Terug
          </LwButton>
          <LwButton
            color="secondary"
            iconColorMode="fill"
            startIcon={<IconEdit />}
            to={generatePath(routes.DepartmentEdit, { id }, filters)}
          >
            Afdeling aanpassen
          </LwButton>
          <LwButton
            color="secondary"
            iconColorMode="fill"
            startIcon={<IconDuplicate />}
            to={generatePath(routes.DepartmentDuplicate, { id }, filters)}
          >
            Afdeling dupliceren
          </LwButton>
          <AuthenticatedElement roles={CompanyRole.ADMIN} policyGroup={PolicyGroups.COMPANY}>
            <LwButton
              onClick={data.isArchivable ? handleArchival : handleForbiddenArchival}
              startIcon={<IconTrash />}
              iconColorMode="stroke"
              color="secondary"
            >
              Verwijderen
            </LwButton>
          </AuthenticatedElement>
        </Toolbox>
      }
    >
      <Box>
        <Grid container spacing={2} item xs={12} md={12} lg={8}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Adres
            </Typography>
            <Typography variant="body1">{data.location?.address ?? '-'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              BTW-tarief
            </Typography>
            <Typography variant="body1">{vatLabel(data.VAT)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Locatie (maps link)
            </Typography>
            {data.locationUrl ? (
              <a href={data.locationUrl} rel="noopener noreferrer" target="_blank">
                {data.locationUrl}
              </a>
            ) : (
              '-'
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Kostenplaats
            </Typography>
            <Typography variant="body1">{data.costCenter ?? '-'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Facturatiegegevens
            </Typography>
            <Typography variant="body1">{data.billingEntity?.name ?? '-'}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export { DepartmentDetailDataProvider };
