import { Box, Grid } from '@mui/material';
import {
  GetProjectFormInputsProps,
  useGetProjectFormInputs,
} from '../hooks/use-get-project-form-inputs';

type ProjectFormProps = GetProjectFormInputsProps;

export const ProjectForm = (options: ProjectFormProps) => {
  const formInputs = useGetProjectFormInputs(options);

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <form aria-label="Projects form">
        <Grid container item spacing={4} xs={12}>
          {...formInputs.map((element, i) => (
            <Grid key={i} item xs={6}>
              {element}
            </Grid>
          ))}
        </Grid>
      </form>
    </Box>
  );
};
