import { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FormSwitchProps } from './form-switch.types';

const FormSwitch = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  checked,
  onChange,
  className,
}: FormSwitchProps<ControlFields>) => {
  const classes = getClasses();
  return (
    <FormControlLabel
      disabled={disabled}
      className={clsx(classes.customLabel, className)}
      htmlFor={name}
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Switch
              {...field}
              className={classes.rootWithLabel}
              id={name}
              title={label}
              checked={field.value || checked || false}
              value={field.value ?? false}
              onChange={(e) => {
                field.onChange(e.target.checked);
                if (onChange) {
                  onChange(e.target.checked);
                }
              }}
              disabled={disabled}
              color="primary"
            />
          )}
        />
      }
      label={label}
    />
  );
};

const UncontrolledFormSwitch = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  disabled,
  checked,
  onChange,
  className,
  value,
}: Omit<FormSwitchProps<ControlFields>, 'control' | 'rules'> & { value: boolean }) => {
  const classes = getClasses();
  return (
    <FormControlLabel
      disabled={disabled}
      className={className}
      htmlFor={name}
      label={label}
      control={
        <Switch
          className={classes.root}
          id={name}
          title={label}
          checked={value || checked || false}
          value={value ?? false}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked);
            }
          }}
          disabled={disabled}
          color="primary"
        />
      }
    />
  );
};

// If done on theme level, this doesn't work because of specifity. needs to be done here
// https://stackoverflow.com/questions/64260821/override-hover-styles-for-muicheckbox
const getClasses = makeStyles(({ spacing }) => ({
  rootWithLabel: {
    marginRight: spacing(4),
    '& .MuiSwitch-switchBase': {
      transform: 'translate(-11px)',
    },
    '& .Mui-checked': {
      transform: 'translate(11px)',
    },
  },
  root: {
    '& .MuiSwitch-switchBase': {
      transform: 'translate(0px)',
    },
    '& .Mui-checked': {
      transform: 'translate(20px)',
    },
    '& .MuiIconButton-label': {
      width: '100%',
      height: '100%',
    },
    '& .MuiIconButton-root': {
      marginTop: '2px',
      marginLeft: '2px',
      width: '20px',
      height: '20px',
      justifyContent: 'flex-start',
      boxShadow: '0 0 0 0 transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  customLabel: {
    '& .MuiTypography-root': {
      lineHeight: '20px',
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      color: 'rgba(0,0,0,1)',
    },
  },
}));

export { FormSwitch, UncontrolledFormSwitch };
