import { z } from 'zod';

type PartnerRole = 'PARTNER';

const GetPartnerUserInfoSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  partner: z.object({
    slug: z.string(),
    name: z.string(),
  }),
});

type GetPartnerUserInfoResponse = z.infer<typeof GetPartnerUserInfoSchema>;

export { GetPartnerUserInfoSchema };
export type { GetPartnerUserInfoResponse, PartnerRole };
