import { userService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { HqUser } from 'types/users/UsersTypes';

export const useGetUser = (userId: string) => {
  const queryFn = async () => await userService.getUser(userId);

  return useQuery<Promise<HqUser>, Error, HqUser>(QUERY_KEYS_HQ.user(userId), queryFn);
};
