import TextField from '@mui/material/TextField';
import { Box, InputAdornment, styled, TextFieldProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { filledInputClasses } from '@mui/material/FilledInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { inputBaseClasses } from '@mui/material/InputBase';
import {
  StyledErrorText,
  StyledHelperTextWrapper,
  StyledIconWarning,
} from 'redesign/utils/helper-text';
import { StyledLabel } from 'redesign/utils/labels';
import { FormInputProps } from './form-input.types';

const LwFormInput = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  inputType = 'text',
  disabled,
  placeholder,
  helperText,
  onChange,
  ...props
}: FormInputProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const message = error ? error.message : helperText;
        return (
          <Box>
            <StyledTextField
              {...field}
              id={name}
              title={label}
              label={<StyledLabel required={!!rules?.required}>{label}</StyledLabel>}
              value={field.value ?? ''}
              type={inputType}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              fullWidth
              placeholder={placeholder}
              error={!!error}
              InputProps={{
                endAdornment: error ? (
                  <InputAdornment position="end">
                    <StyledIconWarning />
                  </InputAdornment>
                ) : null,
              }}
              disabled={disabled}
              {...props}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
            <StyledHelperTextWrapper className="error-text-wrapper">
              {message && (
                <StyledErrorText className={error ? 'error-state' : ''}>{message}</StyledErrorText>
              )}
            </StyledHelperTextWrapper>
          </Box>
        );
      }}
    />
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwPrimary[10]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${inputAdornmentClasses.root}`]: {
    marginRight: theme.spacing(3),
  },
  [`& .${inputBaseClasses.input}`]: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export { LwFormInput };
