import { InputAdornment, styled, TextField, TextFieldProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { filledInputClasses } from '@mui/material/FilledInput';
import { inputBaseClasses } from '@mui/material/InputBase';
import { LwIconButton } from 'redesign/icon-button';
import { IconCross, IconSearch } from 'assets/img';

type Props = {
  id: string;
  value: string;
  setValue: (value: string) => void;
};

export const FlexWorkersSearchBar = ({ value, setValue }: Props) => {
  const classes = getClasses();

  return (
    <StyledTextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="filled"
      fullWidth
      className={classes.searchField}
      InputProps={{
        startAdornment: (
          <Adornment>
            <StyledIconSearch />
          </Adornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <LwIconButton
              sx={{
                '&:hover': {
                  background: 'none',
                },
              }}
              iconColorMode="stroke"
              color="default"
              aria-label="veld leegmaken"
              onClick={() => setValue('')}
              onMouseDown={(e) => e.preventDefault()}
            >
              <StyledIconCross />
            </LwIconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const Adornment = styled('span')(({ theme }) => ({
  height: '24px',
  margin: theme.spacing(0, 0, 0, 4),
  width: '24px',
}));

const StyledIconSearch = styled(IconSearch)(({ theme }) => ({
  path: {
    stroke: `${theme.palette.lwSecondary[60]}`,
  },
}));

const StyledIconCross = styled(IconCross)(({ theme }) => ({
  path: {
    stroke: `${theme.palette.lwSecondary[60]}`,
  },
}));

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwPrimary[10]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${inputBaseClasses.input}`]: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

const getClasses = makeStyles((theme: Theme) => ({
  startAdornment: {
    marginTop: '0px !important',
  },
  searchField: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1),
  },
}));
