import { useUpdateBranchOffice } from 'ogp/hooks/queryHooks/branchoffices/useUpdateBranchOffice';
import { branchOfficeAlerts as alerts, useAlert } from 'shared/components/alerts';
import { BranchOfficeFormData } from 'types/companies/branch-office-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBranchOffice } from 'ogp/hooks/queryHooks/branchoffices/use-get-branch-office';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { LwButton } from 'redesign/button';
import { BranchOfficeForm } from '../../form/branch-office-form';

const BranchOfficeEditDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const branchOfficeQuery = useGetBranchOffice(id);

  if (branchOfficeQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficeQuery} />;
  }

  const defaultValues: BranchOfficeFormData = {
    ...branchOfficeQuery.data,
    costCenter: '',
    billingEntityId: '',
  };

  return <BranchOfficeEdit defaultValues={defaultValues} />;
};

const BranchOfficeEdit = ({ defaultValues }: { defaultValues: BranchOfficeFormData }) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { id } = useParams<'id'>();
  invariant(id);
  const invalidateQuery = useInvalidateQuery();
  const formMethods = useForm({
    defaultValues,
  });
  const mutation = useUpdateBranchOffice({
    onSuccess: () => {
      alertSuccess(alerts.success.updated);
      navigate(generatePath(routes.BranchOfficesDetail, { id }));
      invalidateQuery(QUERY_KEYS_OGP.branchOffice(id));
    },
    onError: (e) => alertError(e),
  });
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => mutation.mutate({ branchOfficeId: id, data }))();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Vestiging bewerken" />}
        toolbox={
          <Toolbox underline>
            <LwButton iconColorMode="stroke" onClick={handleSubmit} {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              iconColorMode="fill"
              onClick={returnTo(routes.BranchOfficesDetail, { id })}
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <BranchOfficeForm state="update" />
      </Page>
    </FormProvider>
  );
};

export { BranchOfficeEditDataProvider };
