import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftClaimService } from 'ogp/services';
import { OGPShiftClaimExportFilterResponse } from 'ogp/services/shift-claim-service.types';
import { useQuery } from 'react-query';

export const useGetShiftClaimExportFilters = () => {
  const queryFn = async () => shiftClaimService.getShiftClaimsExportFilter();

  return useQuery<
    Promise<OGPShiftClaimExportFilterResponse[]>,
    Error,
    OGPShiftClaimExportFilterResponse[]
  >(QUERY_KEYS_OGP.exportFilters(), queryFn);
};
