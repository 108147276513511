import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftService } from 'ogp/services';
import { OGPShiftDetailResponse } from 'ogp/services/shift-service.types';
import { useQuery } from 'react-query';

export const useOgpGetShift = (shiftId: string | null) => {
  const queryFn = async () => await shiftService.getShift(shiftId!);
  const queryKey = QUERY_KEYS_OGP.shift(shiftId!);

  return useQuery<Promise<OGPShiftDetailResponse>, Error, OGPShiftDetailResponse>({
    queryKey,
    queryFn,
    enabled: !!shiftId,
  });
};
