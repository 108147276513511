import {
  Autocomplete,
  FormControl,
  PaperProps,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import arrowDown from 'assets/img/icons/redesign/arrow-down.svg';
import { filledInputClasses } from '@mui/material/FilledInput';
import { Controller, FieldValues } from 'react-hook-form';
import { StyledLabel } from 'redesign/utils/labels';
import { StyledIconWarning } from 'redesign/utils/helper-text';
import { AutocompleteElementProps } from './form-autocomplete.types';
import { StyledAutocompletePaper } from '../multi-select';

const LwFormAutocomplete = <
  T extends object = object,
  ControlFields extends FieldValues = FieldValues,
>({
  name,
  label,
  control,
  rules,
  disabled,
  isLoading,
  ...autocompleteProps
}: AutocompleteElementProps<T, ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="filled" fullWidth disabled={disabled} error={!!error}>
          <StyledAutocomplete
            {...field}
            {...autocompleteProps}
            noOptionsText={autocompleteProps.noOptionsText ?? 'Geen resultaten gevonden'}
            loadingText={autocompleteProps.loadingText ?? 'Laden...'}
            loading={isLoading}
            freeSolo={false}
            disableClearable
            disabled={disabled}
            PaperComponent={(paperProps: PaperProps) => (
              <StyledAutocompletePaper hasError={!!error} {...paperProps} />
            )}
            filterOptions={autocompleteProps.filterOptions ?? ((allOptions) => allOptions)}
            renderInput={({ inputProps, ...params }) => (
              <StyledTextField
                inputProps={{ ...inputProps, id: name }}
                {...params}
                label={
                  <StyledLabel
                    icon={error ? <StyledIconWarning /> : null}
                    required={!!rules?.required}
                  >
                    {label}
                  </StyledLabel>
                }
                name={name}
                InputLabelProps={{
                  shrink: true,
                  htmlFor: name,
                }}
                InputProps={{ ...params.InputProps }}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </FormControl>
      )}
    />
  );
};

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${autocompleteClasses.inputRoot}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    marginTop: 0,
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwSecondary[20]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${autocompleteClasses.tag}`]: {
    backgroundColor: theme.palette.lwPrimary[10],
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '16px',
    height: '32px',
    gap: '8px',
    borderRadius: theme.spacing(6),
    padding: '6px 12px',
    margin: 0,
  },
  '.MuiChip-label': {
    color: theme.palette.lwPrimary[100],
    padding: 0,
  },
  [`& .${autocompleteClasses.popupIndicator}`]: {
    color: 'transparent',
    background: `url("${arrowDown}") no-repeat center center`,
    '&.Mui-disabled': {
      color: 'transparent',
      background: `url("${arrowDown}") no-repeat center center`,
    },
  },
  '.MuiAutocomplete-inputRoot:has(.MuiChip-root)': {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    marginRight: 10,
  },
  '&& .MuiChip-deleteIcon': {
    width: '16px',
    height: '16px',
    margin: 0,
    fill: theme.palette.lwSecondary[100],
  },
  [`& .${formHelperTextClasses.root}`]: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  [`& .${inputBaseClasses.root}`]: {
    display: 'inline-flex',
    alignContent: 'center',
    padding: '16px',
    gap: 8,
  },
  [`.${autocompleteClasses.input}`]: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
  },
})) as typeof Autocomplete;

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${inputAdornmentClasses.root}`]: {
    marginRight: theme.spacing(20),
  },
}));

export { LwFormAutocomplete };
