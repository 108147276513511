import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useGetShiftClaimContract } from 'hq/hooks/queryHooks/shiftclaims/use-get-shift-claim-contract';
import { useCallback } from 'react';
import { LwButton } from '../../../../../../../redesign/button';

interface ShiftClaimOvoDownloadProps {
  shiftClaimId: string;
}

const ShiftClaimOvoDownload = ({ shiftClaimId }: ShiftClaimOvoDownloadProps) => {
  const { alertSuccess, alertError } = useAlert();
  const mutation = useGetShiftClaimContract();

  const handleDownload = useCallback(() => {
    mutation.mutate(shiftClaimId),
      {
        onSuccess: () => alertSuccess(alerts.success.downloadOvo),
        onError: (e: unknown) => alertError(e),
      };
  }, []);

  return (
    <LwButton onClick={handleDownload} color="secondary" disabled={mutation.isLoading}>
      {mutation.isLoading ? 'Downloading OVO...' : 'Download OVO'}
    </LwButton>
  );
};

export { ShiftClaimOvoDownload };
