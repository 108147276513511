import { useCreateCompany } from 'hq/hooks/queryHooks/companies/use-create-company';
import { companyAlerts, useAlert } from 'shared/components/alerts';
import { useNavigate } from 'react-router-dom';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { FormProvider, useForm } from 'react-hook-form';
import { FormEvent, useCallback } from 'react';
import { DateTime } from 'luxon';
import { CompanyForm, CompanyFormData } from './company-form';
import { RenderUnsettledUI } from '../../../../../../shared/components';
import { OrtListItem } from '../../../../../../types/companies/company-types';
import { useGetOrts } from '../../../../../hooks/queryHooks/companies/use-get-orts';
import { Header } from '../../../../../../redesign/header';
import { Page } from '../../../../../../redesign/page';
import { EmploymentType } from '../../../../../../partner/shared/services/partner-worker-service.types';
import { routes } from '../../../../../../Routes';
import { IconApproved, IconCancel } from '../../../../../../assets/img';
import { LwButton } from '../../../../../../redesign/button';
import { Toolbox } from '../../../../../../redesign/toolbox';
import {
  PrimaryButton,
  SecondaryButton,
  useDialog,
} from '../../../../../../ogp/providers/dialog-provider';
import { hqRoutes } from '../../../../../Routes';

export const CompanyCreate = () => {
  const orts = useGetOrts();
  if (orts.status !== 'success') {
    return <RenderUnsettledUI data={orts} />;
  }

  return <CompanyCreateImpl data={orts.data} />;
};

const CompanyCreateImpl = ({ data }: { data: OrtListItem[] }) => {
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      employmentType: EmploymentType.FREELANCER,
      isTempworkingCompany: false,
      workCooldownMinutes: 0,
      ortStartDate: DateTime.now().startOf('day').toISODate(),
    },
  });
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const createCompany = useCreateCompany();

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    formMethods.handleSubmit((data) => {
      const { isTempworkingCompany, ...rest } = data;

      if (!isTempworkingCompany) {
        createCompany.mutate(
          { ...rest, employmentType: 'FREELANCER' },
          {
            onSuccess: () => {
              alertSuccess(companyAlerts.success.created);
              navigate(generatePath(hqRoutes.Companies));
            },
            onError: (e) => alertError(e),
          }
        );
        return;
      }

      openDialog(
        {
          title: 'Weet je het zeker?',
          content:
            'Weet je zeker dat dit bedrijf gaat werken met uitzendkrachten? Dit kan achteraf niet meer worden aangepast',
          secondaryButton: (props) => (
            <SecondaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
              Nee
            </SecondaryButton>
          ),
          primaryButton: (props) => (
            <PrimaryButton startIcon={<IconApproved />} {...props}>
              Jaa
            </PrimaryButton>
          ),
        },
        () => {
          disableDialog();
          createCompany.mutate(
            { ...rest, employmentType: 'TEMPWORKER' },
            {
              onSuccess: () => {
                alertSuccess(companyAlerts.success.created);
                navigate(generatePath(hqRoutes.Companies));
                closeDialog();
              },
              onError: (e) => alertError(e),
              onSettled: () => enableDialog(),
            }
          );
        }
      );
    })();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Page
          header={<Header titleText="Nieuw bedrijf aanmaken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Bedrijf aanpassen
              </LwButton>
              <LwButton
                onClick={() => navigate(generatePath(routes.Companies))}
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <CompanyForm state="create" orts={data} />
        </Page>
      </form>
    </FormProvider>
  );
};
