import { Page } from 'redesign/page';
import { Toolbox } from 'redesign/toolbox';
import { Header } from 'redesign/header';
import { LwButton } from 'redesign/button';
import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/styles';
import { partnerCheckedOutService } from 'partner/shared/services';
import { useAlert } from 'shared/components/alerts';
import { useCallback, useState } from 'react';
import { PartnerCheckedOutDataProvider } from './partner-checked-out-table';
import { INITIAL_PAGE } from './partner-checked-out.types';
import { FilterButton } from '../../../../ogp/components/views/administration/shared';
import { useToggle } from '../../../../shared/hooks';
import { usePartnerCheckoutsFiltersState } from '../../../hooks/queryHooks/partner-checkouts/use-partner-checkouts-filters';
import { InboxFilterForm } from '../../../shared/components/filter-form';
import { usePartnerCheckoutInboxFilters } from '../../../hooks/queryHooks/partner-checkouts/use-partner-checkout-inbox-filters';
import { RenderUnsettledUI } from '../../../../shared/components';
import { DEFAULT_PAGE_SIZE } from '../../../../shared/utils/constants';
import { PartnerCheckoutInboxFiltersResponse } from '../../../shared/services/partner-checkouts-service.types';

export const CheckedOutDataProvider = () => {
  const checkoutsInboxFiltersQuery = usePartnerCheckoutInboxFilters();

  if (checkoutsInboxFiltersQuery.status !== 'success') {
    return <RenderUnsettledUI data={checkoutsInboxFiltersQuery} />;
  }

  return <CheckedOut filterData={checkoutsInboxFiltersQuery.data} />;
};
const CheckedOut = ({ filterData }: { filterData: PartnerCheckoutInboxFiltersResponse }) => {
  const { alertSuccess, alertError } = useAlert();
  const [selection, setSelection] = useState<'ALL' | string[]>([]);
  const onSelectionChange = useCallback((allSelected: boolean, selectedIds: string[]) => {
    setSelection(allSelected ? 'ALL' : selectedIds);
  }, []);
  const { on: drawerOpen, toggle: toggleDrawer } = useToggle();
  const usePartnerCheckoutsFilterState = usePartnerCheckoutsFiltersState();

  const exportData = useCallback(async () => {
    try {
      if (selection === 'ALL') {
        await partnerCheckedOutService.createCheckedOutExport(
          usePartnerCheckoutsFilterState.activeFilters
        );
      } else if (selection.length === 0) {
        alertSuccess('Selecteer alstublieft minstens één checkout om te exporteren.');
        return;
      } else {
        await partnerCheckedOutService.createCheckedOutExport({
          ...usePartnerCheckoutsFilterState.activeFilters,
          shiftClaimIds: selection,
        });
      }
      alertSuccess(
        'Export succesvol aangevraagd. Binnen enkele minuten kunt u een email verwachten.'
      );
    } catch (error) {
      alertError(error);
    }
  }, [alertError, alertSuccess, selection, usePartnerCheckoutsFilterState.activeFilters]);

  return (
    <StyledBox>
      <Page
        header={<Header titleText="Checked out" />}
        toolbox={
          <Toolbox>
            <Toolbox.PushRight>
              <FilterButton
                onClick={toggleDrawer}
                nrOfActiveFilters={usePartnerCheckoutsFilterState.nrOfActiveFilters}
              />
              <LwButton type="button" color="secondary" onClick={exportData}>
                Export
              </LwButton>
            </Toolbox.PushRight>
          </Toolbox>
        }
        pageStyle={{ height: '70%' }}
      >
        <PartnerCheckedOutDataProvider
          onSelectionChange={onSelectionChange}
          filters={{
            ...usePartnerCheckoutsFilterState.activeFilters,
            pageParam: INITIAL_PAGE,
            pageSizeParam: DEFAULT_PAGE_SIZE,
          }}
        />
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
          <InboxFilterForm
            onCancel={toggleDrawer}
            data={filterData}
            {...usePartnerCheckoutsFilterState}
          />
        </Drawer>
      </Page>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
});
