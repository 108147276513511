import { useQuery } from 'react-query';
import { companyService } from 'hq/services';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { companiesResponse2CompaniesSelectModel } from 'hq/services/mappers/mappers';
import { EmploymentType } from '../../../../types/companies/company-types';

type Response = Awaited<ReturnType<typeof companyService.getCompanies>>;

const useGetCompanies = <TData = Response>(
  filter?: string,
  employmentType?: EmploymentType,
  includeArchived?: boolean,
  select?: (data: Response) => TData
) => {
  const queryFn = async () =>
    await companyService.getCompanies(
      includeArchived,
      employmentType ?? 'ALL',
      filter ?? undefined
    );

  return useQuery(QUERY_KEYS_HQ.companiesList(filter, employmentType), queryFn, {
    select,
    cacheTime: CACHE_TIME_LONG,
  });
};

const useGetCompaniesAsSelect = (
  filter?: string,
  employmentType?: EmploymentType,
  includeArchived?: boolean
) => {
  const result = useGetCompanies(
    filter,
    employmentType,
    includeArchived,
    companiesResponse2CompaniesSelectModel
  );
  return result;
};

export { useGetCompanies, useGetCompaniesAsSelect };
