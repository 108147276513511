import saveAs from 'file-saver';
import { projectService } from 'ogp/services';
import { useMutation } from 'react-query';
import { formatCsvExportDateTime } from 'shared/utils/formatting';
import { FirstParamOfFn } from 'types/utility';

const useGetProjectsCheckoutsExport = () => {
  return useMutation(
    async (params: FirstParamOfFn<typeof projectService.getCheckoutsExport>) =>
      projectService.getCheckoutsExport(params),
    {
      onSuccess: (data) => {
        const dateTimeString = formatCsvExportDateTime(new Date(Date.now()));
        saveAs(data, `project_checkouts_export_${dateTimeString}.csv`);
      },
    }
  );
};

export { useGetProjectsCheckoutsExport };
