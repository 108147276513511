import AddIcon from '@mui/icons-material/Add';

import { Toolbox } from 'redesign/toolbox';
import { hqRoutes } from 'hq/Routes';
import { LwButton } from 'redesign/button';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useParams } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { Box } from '@mui/material';
import { PartnerUsersTableProvider } from './partner-users-table';
import { PartnerPage } from '../partner-page';
import { useGetPartner } from '../../partners/hooks/use-get-partner';

export const PartnerUsersList = () => {
  const generatePath = useHqRelativePath();
  const { id } = useParams<'id'>();
  invariant(id);
  const partnerQuery = useGetPartner(id);

  if (partnerQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerQuery} />;
  }

  return (
    <PartnerPage>
      <Box>
        <Toolbox upperDivider={false}>
          <LwButton
            to={generatePath(hqRoutes.PartnerUserCreate, { id })}
            startIcon={<AddIcon />}
            iconColorMode="fill"
            color="primary"
            disabled={partnerQuery.data.deactivated}
          >
            Nieuwe partner gebruiker
          </LwButton>
        </Toolbox>
        <PartnerUsersTableProvider deactivated={partnerQuery.data.deactivated} />
      </Box>
    </PartnerPage>
  );
};
