import { DateTime } from 'luxon';
import { ItemOf } from '../../types/utility';

export type WeekDays = ItemOf<typeof weekDays>;

export const SECONDS_IN_DAY = 60 * 60 * 24;

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

const todayToISO = DateTime.now().toISODate();
export const isToday = (date: string = todayToISO): boolean => {
  const headDate = DateTime.fromISO(date).toISODate();
  const today = DateTime.now().toISODate();

  return today === headDate;
};

export const isCurrentDayOfWeek = (day: WeekDays): boolean => {
  return DateTime.now().weekday === weekDays.indexOf(day) + 1;
};

export const timeToSeconds = (timeString: string) => {
  const dateTime = DateTime.fromFormat(timeString, 'HH:mm');

  const hour = dateTime.hour;
  const minute = dateTime.minute;

  const secondsInHour = hour * 3600;
  const secondsInMinute = minute * 60;

  const totalSeconds = secondsInHour + secondsInMinute;

  return totalSeconds;
};

export const translateDaysToDutch = (day: WeekDays): string => {
  const dutchWeekDays = {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
  };
  return dutchWeekDays[day];
};

export const translateDaysToShortDutch = (day: WeekDays): string => {
  const dutchWeekDays = {
    monday: 'MA',
    tuesday: 'DI',
    wednesday: 'WO',
    thursday: 'DO',
    friday: 'VR',
    saturday: 'ZA',
    sunday: 'ZO',
  };
  return dutchWeekDays[day];
};
