import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { branchOfficeService } from 'ogp/services';
import { branchOfficeResponse2BranchOfficesSelectModel } from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { FirstParamOfFn } from 'types/utility';

type Response = Awaited<ReturnType<typeof branchOfficeService.getBranchOffices>>;

type Params = FirstParamOfFn<typeof useGetBranchOffices>;
const useGetBranchOffices = <TData = Response>(
  params: FirstParamOfFn<typeof branchOfficeService.getBranchOffices>,
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await branchOfficeService.getBranchOffices(params);

  return useQuery(QUERY_KEYS_OGP.branchOffices(), queryFn, {
    select,
  });
};

const useGetBranchOfficesAsSelect = (params: Params) => {
  const result = useGetBranchOffices(params, branchOfficeResponse2BranchOfficesSelectModel);
  return result;
};

export { useGetBranchOfficesAsSelect };
