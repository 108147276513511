import { Box } from '@mui/material';
import { FormRadioGroup } from 'redesign/forms/radio-group/form-radio-group';
import { FormCheckbox } from 'redesign/forms/checkbox-group';
import { useFormContext } from 'react-hook-form';
import { FilledStatus, FilledStatusFormContext } from './filter-filled-status.types';

const availableStatuses: { value: FilledStatus; label: string }[] = [
  { value: 'inProgress', label: 'Plekken open' },
  { value: 'filled', label: 'Volledig bezet' },
];

export const FilterFilledStatus = () => {
  const { watch, control } = useFormContext<FilledStatusFormContext>();
  const isFilled = watch('filledStatusCheckboxValue');
  return (
    <Box width={200}>
      <FormCheckbox name="filledStatusCheckboxValue" label="Bezetting" control={control} />
      {isFilled ? (
        <FormRadioGroup items={availableStatuses} name="filledStatus" control={control} />
      ) : null}
    </Box>
  );
};
