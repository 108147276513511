import { TempWorkJobFormData } from 'types/jobs/JobTypes';
import { Control, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LwFormInput } from 'redesign/forms/input';
import { LwFormNumberInput } from 'redesign/forms/number-input';
import { LwFormAutocomplete } from 'redesign/forms/autocomplete';
import { filterOptions } from 'shared/utils/utils';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import useGeocode from 'ogp/hooks/queryHooks/locations/useGeocode';
import { useEffect, useMemo, useState } from 'react';
import { LocationAutoSuggestItem } from 'types/geocoding/GeocodingTypes';
import React from 'react';
import { TakeDefined } from 'types/utility';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';
import { JobFormState, useGetSharedJobFormElements } from './use-get-shared-job-form-elements';

type TempWorkJobFormElementProps = {
  control: Control<Partial<TempWorkJobFormData>>;
  state: JobFormState;
};

type TempWorkJobFormElements = { [key in keyof TempWorkJobFormData]: React.ReactNode };

export const useGetTempWorkJobFormData = (
  state: JobFormState,
  jobData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>
): Omit<TempWorkJobFormElements, 'hasOpenShiftClaims'> => {
  const { control, watch, setValue } = useFormContext<Partial<TempWorkJobFormData>>();
  const departmentId = watch('departmentId');
  const { data: department } = useGetDepartmentAsDetail(departmentId);
  const sharedElements = useGetSharedJobFormElements(state, jobData, department);

  useEffect(() => {
    if (department && state === 'create') {
      setValue('location', department.location);
      setValue('VAT', department.VAT);
    }
  }, [department, setValue, state]);

  const res = {
    flexPoolOnly: sharedElements.flexPoolOnly,
    ortApplicable: sharedElements.ortApplicable,
    departmentId: sharedElements.departmentId,
    name: sharedElements.name,
    hourlyRate: <HourlyRate control={control} state={state} />,
    breakMinutes: sharedElements.breakMinutes,
    jobTypeId: sharedElements.jobTypeId,
    cla: <CLA control={control} state={state} />,
    VAT: sharedElements.VAT,
    descriptionShort: sharedElements.descriptionShort,
    descriptionLong: sharedElements.descriptionLong,
    descriptionLawRequirements: sharedElements.descriptionLawRequirements,
    contactName: sharedElements.contactName,
    contactPhoneNumber: sharedElements.contactPhoneNumber,
    costCenter: sharedElements.costCenter,
    location: <Location state={state} />,
    locationUrl: sharedElements.locationUrl,
    imageUrl: sharedElements.imageUrl,
  };

  return res;
};

const HourlyRate = ({ control, state }: TempWorkJobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormNumberInput
        name="hourlyRate"
        label="Bruto uurloon"
        placeholder="10,50"
        control={control}
        rules={{ required: 'Voer een bruto uurloon in' }}
        step={0.01}
        min={0}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const CLA = ({ control, state }: TempWorkJobFormElementProps) => {
  return (
    <Grid item xs={12}>
      <LwFormInput
        name="cla"
        label="Cao / beloningsregeling opdrachtgever"
        control={control}
        rules={{ required: 'Voer een cao/beloningsregeling opdrachtgever in' }}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const Location = ({ state }: { state: JobFormState }) => {
  const context = useFormContext<Partial<TempWorkJobFormData>>();
  const watchedAddress = context.watch('location')?.address ?? '';
  const defaultLocationValue = useMemo<LocationAutoSuggestItem>(
    () => ({ type: 'defaultOption', address: watchedAddress }),
    [watchedAddress]
  );

  const [locationValue, setLocationValue] = useState<LocationAutoSuggestItem>(defaultLocationValue);
  const [locationInputValue, setLocationInputValue] = useState(watchedAddress);
  const debouncedInput = useDebounce(locationInputValue, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== watchedAddress);
  const { data, isFetching } = geoQuery;
  const [options, setOptions] = useState<LocationAutoSuggestItem[]>([]);

  useEffect(() => {
    if (data) {
      setOptions((previousOptions) => [
        ...previousOptions,
        ...data.filter(
          (option) =>
            previousOptions.every((previousOption) => previousOption.address !== option.address) // We need to filter the options that we include because those can be repeated
        ),
      ]);
    }
  }, [data]);

  return (
    <Grid item xs={12}>
      <LwFormAutocomplete
        name="location"
        label="Locatie"
        control={context.control}
        rules={{ required: 'Voer een locatie in' }}
        disableClearable={true}
        value={locationValue}
        disabled={state === 'read'}
        getOptionKey={(option) => option.hereId ?? ''}
        getOptionLabel={(option) => option.address}
        onChange={(_event, value) => {
          if (value) {
            setLocationValue(value);
            context.setValue('location', value);
          }
        }}
        isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
        inputValue={locationInputValue}
        onInputChange={(_event, value) => {
          setLocationInputValue(value);
        }}
        options={options}
        filterOptions={filterOptions}
        isLoading={isFetching}
      />
    </Grid>
  );
};
