import { Grid } from '@mui/material';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';

import { useShiftPlanningPage } from 'hq/components/views/planner/shifts/table/hooks/use-shift-planning';
import { HqShiftForm } from '../hq-shift-create-form';
import { useHqCreateShift } from '../../hooks/hq/use-hq-create-shift';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { transformData } from '../../utils/transform-data';
import { CompanyInput } from '../../components/forms/shared/inputs/company-input';
import { useHqGeneratePlannerPath } from '../hq-generate-shift-form-path';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';
import { stringifyFilters } from '../../../../utils/stringify-filters';

export const HqShiftCreateDataProvider = () => {
  const companiesQuery = useGetCompanies();

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return <HqShiftCreate companies={companiesQuery.data} />;
};

type HqShiftCreateProps = {
  companies: GetCompaniesResponse[];
};

const HqShiftCreate = ({ companies }: HqShiftCreateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePlannerPath = useHqGeneratePlannerPath();
  const { filters: appliedFilters } = useShiftPlanningPage();

  const formMethods = useForm<
    { companyId: string } & (FreelanceShiftFormData | TempWorkShiftFormData)
  >();

  const mutation = useHqCreateShift({
    onSuccess: (shift) => {
      alertSuccess(shiftAlerts.success.created);
      navigate(
        generatePlannerPath({ ...stringifyFilters(appliedFilters), shiftDetailId: shift.id })
      );
    },
    onError: (e) => {
      alertError(e);
    },
  });

  const onCancel = useCallback(
    () => navigate(generatePlannerPath(stringifyFilters(appliedFilters))),
    [appliedFilters, generatePlannerPath, navigate]
  );

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: FreelanceShiftFormData | TempWorkShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate(data);
    })();
  }, [formMethods, mutation]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe shift aanmaken" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={6}>
            <CompanyInput
              options={companies.map((i) => ({ label: i.name, value: i.id }))}
              disabled={false}
            ></CompanyInput>
            <HqShiftForm companies={companies} mode="create" />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
