import { useMemo } from 'react';
import { Chip, DialogContent, DialogContentText, Typography } from '@mui/material';
import { ShiftPlanningShiftsPartner } from 'hq/services/shift-planning-service.types';
import { useFormContext } from 'react-hook-form';
import { LwFormMultiSelect } from 'redesign/forms/multi-select';
import { StyledIconError, StyledIconWarning } from 'redesign/utils/helper-text';
import { SelectableFor } from './dialog-form';
import { BulkForwardToShiftsFormInput } from '../bulk-forward-to-partner';

type Props = {
  availablePartners: ShiftPlanningShiftsPartner[];
  numberOfShifts: number;
  selectableFor: SelectableFor;
};

export const BulkForwardToPartnerDialogContent = ({
  availablePartners,
  numberOfShifts,
  selectableFor,
}: Props) => {
  const { setValue, control } = useFormContext<BulkForwardToShiftsFormInput>();
  const message: {
    icon?: JSX.Element;
    explanationText?: string;
    color?: 'warning' | 'error' | undefined;
  } = useMemo(() => {
    if (selectableFor === SelectableFor.Some) {
      return {
        explanationText:
          'Sommige partners zijn mogelijk niet selecteerbaar. Alleen de partners die de geselecteerde diensten gemeen hebben, kunnen geselecteerd worden.',
        color: 'warning',
        icon: <StyledIconWarning color="warning" />,
      };
    } else if (selectableFor === SelectableFor.None) {
      return {
        explanationText: 'Geen overlappende partners onder de geselecteerde diensten.',
        color: 'error',
        icon: <StyledIconError />,
      };
    }
    return {};
  }, [selectableFor]);

  return (
    <DialogContent>
      <Typography variant="body1">{numberOfShifts} diensten geselecteerd</Typography>
      {message.explanationText ? (
        <Chip
          icon={message.icon}
          label={<DialogContentText>{message.explanationText}</DialogContentText>}
          variant="outlined"
          color={message.color}
          sx={(theme) => ({
            height: 'auto',
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            backgroundColor: 'transparent',
            width: '100%',
            '& .MuiChip-label': {
              whiteSpace: 'normal',
              maxWidth: '450px',
            },
          })}
        />
      ) : null}

      <LwFormMultiSelect
        name="partners"
        control={control}
        options={availablePartners}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) =>
          option.isOverlappingForAll
            ? option.name
            : `${option.name} (Niet overlappend voor alle geselecteerde diensten)`
        }
        onChange={(event, value) => setValue('partners', value)}
        label="Selecteer een Partner"
        disabled={availablePartners.length === 0}
        getOptionDisabled={(option) => !option.isOverlappingForAll}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </DialogContent>
  );
};
