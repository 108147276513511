import { Box, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { FreelanceJobDetailsWithId } from 'types/jobs/JobTypes';
import { ShiftFormProps } from '../shared/types';
import { JobInputs } from '../shared/inputs/job-inputs';
import { ShiftDateTimeInputs } from '../shared/inputs/date-time-inputs';
import { BreakMinutesInput } from '../shared/inputs/break-minutes-input';
import { NoShowFineInput } from '../shared/inputs/no-show-fine-input';
import { MaxClaimantsElement } from '../shared/inputs/max-claimants-input';
import { HourlyRateInput } from '../shared/inputs/hourly-rate-input';
import { getHelperTexts } from '../shared/get-helper-texts/get-helper-texts';

export const ShiftForm = ({
  employmentType,
  isDisabled,
  mode,
  shiftCreateData,
  jobsData,
  hasDivider,
  jobData,
  allowDateBeforeToday,
}: ShiftFormProps) => {
  const classes = getClasses();
  const { setValue } = useFormContext();
  const helperTexts = useMemo(() => getHelperTexts(jobData), [jobData]);
  const contractFieldsDisabled =
    isDisabled || (!allowDateBeforeToday && mode === 'disableContractFields');

  useEffect(() => {
    if (!jobData) {
      return;
    }
    if (mode !== 'create') {
      return;
    }
    setValue('hourlyRate', jobData.hourlyRateCents / 100);
    setValue('breakMinutes', jobData.breakMinutes);
    if (employmentType !== 'TEMPWORKER') {
      setValue('noShowFine', (jobData as FreelanceJobDetailsWithId).noShowFineCents / 100);
    }
  }, [jobData, employmentType, mode, setValue]);

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <form name="shift-form">
        <Grid container item spacing={4} xs={12}>
          <Grid item xs={12}>
            <JobInputs
              disabled={contractFieldsDisabled}
              jobsData={jobsData ?? []}
              branchOfficesWithDepartments={shiftCreateData?.branchOfficesWithDepartments ?? []}
            />
          </Grid>
          <Grid item xs={12}>
            <ShiftDateTimeInputs
              disabled={contractFieldsDisabled}
              allowDateBeforeToday={allowDateBeforeToday}
            />
          </Grid>
          <Grid item xs={6}>
            <HourlyRateInput
              disabled={contractFieldsDisabled}
              helperText={helperTexts.hourlyRate ?? ''}
              placeholder={`${(jobData?.hourlyRateCents || 0) / 100}`}
              employmentType={employmentType ?? 'FREELANCER'}
            />
          </Grid>
          <Grid item xs={6}>
            <MaxClaimantsElement disabled={isDisabled} />
          </Grid>
          <Grid item xs={6}>
            <BreakMinutesInput
              options={shiftCreateData?.breakOptions ?? []}
              disabled={contractFieldsDisabled}
              helperText={helperTexts.breakMinutes}
            />
          </Grid>
          {employmentType === 'FREELANCER' ? (
            <Grid item xs={6}>
              <NoShowFineInput
                disabled={contractFieldsDisabled}
                helperText={helperTexts.noShowFine ?? ''}
                placeholder={`${
                  ((jobData as FreelanceJobDetailsWithId)?.noShowFineCents || 0) / 100
                }`}
              />
            </Grid>
          ) : null}
        </Grid>
      </form>
      {hasDivider ? (
        <Box>
          <Divider orientation="vertical" className={classes.divider} />
        </Box>
      ) : null}
    </Box>
  );
};

const getClasses = makeStyles(() => ({
  divider: {
    height: '100%',
  },
}));
