import type { Column } from 'react-table';
import { Tooltip, Typography } from '@mui/material';
import { Cell, Header } from 'redesign/table';
import { ContextMenuButton } from 'redesign/context-menu';
import { ArchiveTableColumnDefinition, CellProp } from './archive-table.types';
import { getCheckoutsColumnDefinitionBase } from '../../../../shared/columns/get-checkouts-column-definition-base';
import { CheckoutsColumnBase } from '../../../../shared/columns/get-checkouts-column-definition-base.types';
import { WorkedTimeCell } from '../components/worked-time-cell';

export const getArchiveColumnDefinition: (
  allowExpenses: boolean
) => Column<ArchiveTableColumnDefinition>[] = (allowExpenses) => {
  const status: Column<ArchiveTableColumnDefinition> = {
    Header: () => <Header>Status</Header>,
    accessor: 'status',
    Cell: StatusCell,
  };

  const worked: Column<CheckoutsColumnBase> = {
    Header: () => (
      <>
        <Header>Gewerkte tijden</Header>
        <Header>Gewerkte uren</Header>
      </>
    ),
    accessor: 'workedTime',
    Cell: WorkedTimeCell,
  };

  const actions: Column<ArchiveTableColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  const { expense, fw, job, date, scheduled, timeDeviationAndBreak } =
    getCheckoutsColumnDefinitionBase(allowExpenses);

  return [fw, job, date, scheduled, worked, timeDeviationAndBreak, expense, status, actions];
};

const StatusCell = (cell: CellProp<'status'>) => {
  if (cell.value.state === 'noShow') {
    return (
      <Tooltip title={cell.value.noShowReason.longDescription}>
        <Typography variant="body1" color="secondary">
          No-show
        </Typography>
      </Tooltip>
    );
  }
  if (cell.value.state === 'counterOffer') {
    return <Cell>Tegenvoorstel</Cell>;
  }
  if (cell.value.state === 'cancelationOfferSettled') {
    return <Cell>Annuleringsaanbod</Cell>;
  }
  return <Cell>Akkoord</Cell>;
};

const ActionCell = (cell: CellProp<'actions'>) => {
  const actions = cell.getRowActions(cell.value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${cell.value.worker.workerId}`}>
      {actions}
    </ContextMenuButton>
  );
};
