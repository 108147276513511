import axios from 'axios';
import { ResponseType as axiosResponseType } from 'axios/index';
import { EnvironmentService } from './environment.service';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface TokenProvider {
  currentToken(): Promise<string>;
}

interface GetOptions<ResponseType = unknown> {
  path: string;
  body?: object;
  params?: object;
  responseType?: axiosResponseType;
  headers?: object;
  decoder?: (json: unknown) => ResponseType;
  signal?: AbortSignal;
}

export abstract class AbstractApiService {
  constructor(
    private readonly endpoint: string,
    private readonly tokenProvider: TokenProvider
  ) {}

  public getHeaders() {
    const platformClient = EnvironmentService.getVariable('PLATFORM_CLIENT');
    return {
      'X-App-PlatformClient': platformClient,
    };
  }

  public async create<ResponseType>(options: GetOptions<ResponseType>): Promise<ResponseType> {
    return this.send<ResponseType>('POST', options);
  }

  public async get<ResponseType>(options: GetOptions<ResponseType>): Promise<ResponseType> {
    return this.send<ResponseType>('GET', options);
  }

  public async update<ResponseType>(options: GetOptions<ResponseType>): Promise<ResponseType> {
    return this.send<ResponseType>('PUT', options);
  }

  public async delete<ResponseType>(options: GetOptions<ResponseType>): Promise<ResponseType> {
    return this.send<ResponseType>('DELETE', options);
  }

  private async send<ResponseType>(
    method: Method,
    options: GetOptions<ResponseType>
  ): Promise<ResponseType> {
    const { path, body, params, responseType, headers, decoder, signal } = options;
    const token = await this.tokenProvider.currentToken();

    try {
      const response = await axios.request({
        method,
        params,
        url: this.endpoint + path,
        data: body,
        responseType: responseType,
        signal,
        headers: {
          Authorization: `Bearer ${token}`,
          ...this.getHeaders(),
          ...headers,
        },
      });

      if (decoder) {
        return decoder(response.data);
      }

      return response.data as unknown as ResponseType;
    } catch (e) {
      throw this.mapError(e);
    }
  }

  private mapError(e: unknown): Error {
    if (axios.isAxiosError(e)) {
      const errorData = e.response?.data;

      if (isKnownError(errorData)) {
        throw new ExtendedError(errorData);
      }
      if (isValidationError(errorData)) {
        throw new ValidationError(errorData);
      }

      return e;
    }

    if (e instanceof Error) {
      return e;
    }

    return new Error(`Got an unknown error from Axios: ${e}`);
  }
}

/** Error data to sent when actor is external or unknown */
type KnownErrorData = {
  statusCode?: number;
  type?: string;
  errorCode?: string;
  message: string;
  name: string;
  title: string;
};

function isKnownError(data: any): data is KnownErrorData {
  if (!data) {
    return false;
  }

  const { name, title, message, errorCode } = data as KnownErrorData;
  if (errorCode) {
    return true;
  }
  return message !== undefined && title !== undefined && name !== undefined;
}

function isValidationError(data: unknown): data is ValidationErrorDetails {
  if (data) {
    const { status, error } = data as ValidationErrorDetails;
    return status === 422 && error !== undefined;
  } else {
    return false;
  }
}

interface ValidationErrorDetails {
  message: string;
  status: number;
  error: { message: { msg: string; param: string; value: string }[] };
}

/**
 * Error types that have special handlers in the environment
 */
export enum ExtendedErrorType {
  ImposterMissingCompanyId = 'ImposterMissingCompanyId',
  ImposterMissingPartnerId = 'ImposterMissingPartnerId',
  UserTypeNotAllowed = 'UserTypeNotAllowed',
}

/** Standardized errors coming from the API with already translated messages we can display to the user. */
export class ExtendedError extends Error {
  public readonly statusCode: number | undefined;
  public readonly type: string;
  public readonly message: string;
  public readonly errorCode: string | undefined;
  public readonly title: string;

  constructor({ statusCode, title, type, name, message, errorCode }: KnownErrorData) {
    super(message);
    this.statusCode = statusCode;
    this.type = type ?? name;
    this.title = title;
    this.message = message;
    this.errorCode = errorCode;
  }
}

/** Standardized errors coming from the API during the validation process */
export class ValidationError extends Error {
  public readonly errors;
  constructor({ message, error }: ValidationErrorDetails) {
    super(message);
    this.errors = error;
  }
}
