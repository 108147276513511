import { PARTNER_PATHS } from 'partner/paths';
import { partnerWorkerAlerts as alerts, useAlert } from 'shared/components/alerts';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { FormProvider, useForm } from 'react-hook-form';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign/button';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PartnerWorkerFormValues } from './partner-worker-form.types';
import { useCreatePartnerWorker } from './hooks/use-create-partner-worker';
import { PartnerWorkerFormDataProvider } from './partner-worker-form';

export const PartnerWorkerCreate = () => {
  const generatePath = usePartnerRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<PartnerWorkerFormValues>({ defaultValues: { infix: '' } });
  const navigate = useNavigate();
  const mutation = useCreatePartnerWorker();
  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) =>
      mutation.mutate(data, {
        onSuccess: () => {
          navigate(generatePath(PARTNER_PATHS.Worker));
          alertSuccess(alerts.success.created);
        },
        onError: (e: unknown) => alertError(e),
      })
    )();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe kandidaat aanmaken" />}
        toolbox={
          <Toolbox>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(PARTNER_PATHS.Worker))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerWorkerFormDataProvider />
      </Page>
    </FormProvider>
  );
};
