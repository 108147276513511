import { usePartnerCounterOffers } from 'partner/hooks/queryHooks/partner-checkouts/use-partner-checkouts';
import { Table, useOnScrollDown } from 'redesign/table';
import { PartnerPortalCheckoutsParams } from 'partner/shared/services/partner-checkouts-service.types';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import {
  getCounterOffersColumnDefinition,
  PartnerCounterOffersColumnDefinition,
} from './utils/partner-counter-offers-table-columns';

export type PartnerCounterOffersTableProviderProps = {
  filters: PartnerPortalCheckoutsParams;
};

export const PartnerCounterOffersTable = (props: PartnerCounterOffersTableProviderProps) => {
  const partnerCounterOffersQuery = usePartnerCounterOffers(props.filters);
  const flatData = useMemo(
    () => partnerCounterOffersQuery.data?.pages?.flatMap((page) => page.items) ?? [],
    [partnerCounterOffersQuery.data?.pages]
  );
  const columns = useMemo(() => getCounterOffersColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: partnerCounterOffersQuery,
    flatData: flatData,
  });

  return (
    <Box maxHeight="100%">
      <Table<PartnerCounterOffersColumnDefinition>
        stickyHeader={true}
        columns={columns}
        data={flatData}
        tableStyle={{ tableLayout: 'fixed' }}
        onScroll={fetchIfBottomScrolled}
        data-testid="checked-out-virtual-table"
      />
    </Box>
  );
};
