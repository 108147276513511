import { Avatar, Box, Divider, styled, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconAttendance, IconCall, IconLocation, IconRatingThumb, IconShifts } from 'assets/img';
import { formatTextValue } from 'redesign/utils';
import { FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';
import { LazyImage } from 'redesign/lazy-image/lazy-image';

const ProfileHeader = ({ data }: { data: FlexWorkerProfileData }) => {
  const classes = getClasses();
  const initials = data.firstName.charAt(0) + data.lastName.charAt(0);

  return (
    <Box>
      <BoxInfoRowFlex>
        {data?.imageUrl ? (
          <StyledLazyImage src={data?.imageUrl} />
        ) : (
          <NoProfileIconBox>
            <StyledAvatar aria-label="avatar">
              <Typography variant="body1">{initials}</Typography>
            </StyledAvatar>
            {data.partnerName ? (
              <Tooltip title={data.partnerName}>
                <LabelBox>{data.partnerName}</LabelBox>
              </Tooltip>
            ) : null}
          </NoProfileIconBox>
        )}
        <Box display="flex" flexDirection="column">
          <FirstName noWrap>
            {data.firstName} {data.lastName}
          </FirstName>
          <Email>{data.email}</Email>
        </Box>
        <Box flex={1} width={380} marginLeft={20}>
          {data.partnerName ? null : (
            <>
              <BioHeader>over {data.firstName}</BioHeader>
              <BioText>{data.bio}</BioText>
            </>
          )}
        </Box>
      </BoxInfoRowFlex>
      <BoxRowFlex>
        <WrapperIconsColumn marginRight={20}>
          <InfoBox>
            <IconLocation width={24} height={24} className={classes.svgIcon} />
            <TextSpacingFromIcon>{data.city}</TextSpacingFromIcon>
          </InfoBox>
          <InfoBox>
            <IconRatingThumb width={24} height={24} className={classes.svgIcon} />
            <TextSpacingFromIcon>{formatTextValue(data.rating, 'percentage')}</TextSpacingFromIcon>
          </InfoBox>
        </WrapperIconsColumn>
        <WrapperIconsColumn>
          <InfoBox>
            <IconCall width={24} height={24} className={classes.svgIcon} />
            <TextSpacingFromIcon>{data.phone}</TextSpacingFromIcon>
          </InfoBox>
          <WrapperBottom>
            <InfoBox>
              <IconAttendance width={24} height={24} className={classes.svgIcon} />
              <TextSpacingFromIcon>
                {formatTextValue(data.attendanceRate, 'percentage')}
              </TextSpacingFromIcon>
            </InfoBox>
            <InfoBox>
              <IconShifts width={24} height={24} className={classes.svgIcon} />
              <TextSpacingFromIcon>{data.workedShifts}</TextSpacingFromIcon>
            </InfoBox>
          </WrapperBottom>
        </WrapperIconsColumn>
      </BoxRowFlex>
      <StyledDivider />
    </Box>
  );
};

const BoxRowFlex = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: '56px',
  height: '56px',
}));

const NoProfileIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: theme.spacing(4),
}));

const BoxInfoRowFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(8),
}));

const StyledLazyImage = styled(LazyImage)(({ theme }) => ({
  width: '56px',
  height: '56px',
  objectFit: 'contain',
  borderRadius: '50%',
  marginRight: theme.spacing(4),
}));

const FirstName = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontFamily: 'Urbanist',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: theme.spacing(8),
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));

const LabelBox = styled(Box)(() => ({
  border: '1px solid #eeeeee',
  borderRadius: '16px',
  width: '100%',
  backgroundColor: 'white',
  padding: '4px 8px',
  marginTop: '-16px',
  lineHeight: '12px',
  fontSize: '10px',
  textAlign: 'center',
  zIndex: 100,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const Email = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: 'rgba(0, 0, 0, 0.60)',
    whiteSpace: 'nowrap',
  },
}));

const BioHeader = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    color: 'rgba(0, 0, 0, 0.60)',
    lineHeight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

const BioText = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    paddingRight: theme.spacing(20),
    color: 'rgba(0, 0, 0, 0.30)',
    lineHeight: theme.spacing(4),
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
}));

const TextSpacingFromIcon = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    marginLeft: theme.spacing(4),
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: theme.spacing(5),
  },
}));

const WrapperIconsColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const InfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const WrapperBottom = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(40),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(8),
  borderColor: 'rgba(0, 0, 0, 0.3)',
  backgroundColor: 'transparent',
  marginBottom: theme.spacing(8),
}));

const getClasses = makeStyles(() => ({
  svgIcon: {
    '& path': {
      stroke: 'rgba(0, 0, 0, 0.4)',
    },
  },
}));

export { ProfileHeader };
