import { FormGroup, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FeatureFlags } from 'shared/services/mappers/mappers';
import { useMemo } from 'react';
import { FormSwitch } from '../../../../redesign/forms/switch';

const FeatureFlagsForm = ({ featureFlags }: { featureFlags: FeatureFlags }) => {
  const { control } = useFormContext<FeatureFlags>();

  const featureFlagsSorted = useMemo(
    () => Object.entries(featureFlags).sort(([a], [b]) => a.localeCompare(b)),
    [featureFlags]
  );

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item xs={12}>
        <FormGroup>
          {featureFlagsSorted.map(([flagName]) => (
            <Grid item key={flagName} xs={12} mt={1}>
              <FormSwitch
                key={flagName}
                label={flagName}
                control={control}
                name={flagName as keyof FeatureFlags}
              />
            </Grid>
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export { FeatureFlagsForm };
