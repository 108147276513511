import { RatingPercentage } from 'ogp/components/common/rating-percentage';
import type { Column } from 'react-table';
import { ContextMenuButton } from 'redesign/context-menu';
import { Cell, Header } from 'redesign/table';
import { pluralizeWithValue } from 'shared/utils/pluralize';
import { EmploymentType } from 'types/companies/company-types';
import { ArchiveTableColumnDefinition } from 'ogp/components/views/administration/shifts-checkouts/archive/components/table/helpers/archive-table.types';
import { Box, Tooltip } from '@mui/material';
import { IconCertificate } from 'assets/img';
import { ApplicantCellProps, ApplicantColumnDefinition } from './applicant-table.types';
import { getShiftDetailsColumnDefinitionBase } from '../../../shared/columns/get-shift-details-column-definition-base';
import { convertToExperienceFormat } from '../../../../../../flex-workers-for-work-table/utils/helpers';

export const getApplicantColumnDefinition: <TAction extends object>(
  employmentType: EmploymentType,
  options?: { showAppliedShifts?: boolean }
) => Column<ApplicantColumnDefinition<TAction>>[] = (
  employmentType,
  { showAppliedShifts = true } = { showAppliedShifts: true }
) => {
  const [flexWorkerImage, fw] = getShiftDetailsColumnDefinitionBase();

  const hasRequiredCertificates: Column<ApplicantColumnDefinition> = {
    Header: () => <Header>Certificaat</Header>,
    accessor: 'hasRequiredCertificates',
    Cell: CertificateChecksCell,
  };

  const rating: Column<ApplicantColumnDefinition> = {
    Header: () => <Header>Beoordeling</Header>,
    accessor: 'rating',
    Cell: RatingCell,
  };

  const attendanceRate: Column<ApplicantColumnDefinition> = {
    Header: () => <Header>Opkomst</Header>,
    accessor: 'attendanceRate',
    Cell: AttendanceRateCell,
  };

  const workedShifts: Column<ApplicantColumnDefinition> = {
    Header: () => <Header>Gewerkte shifts</Header>,
    accessor: 'workedShifts',
    Cell: WorkedShiftsCell,
  };

  const hourlyRate: Column<ApplicantColumnDefinition> = {
    Header: () => (
      <Header>{employmentType === 'FREELANCER' ? 'Uurtarief' : 'Bruto uurloon'}</Header>
    ),
    accessor: 'hourlyRate',
    Cell: HourlyRateCell,
  };

  const appliedShifts: Column<ApplicantColumnDefinition> = {
    Header: () => <Header># shifts aangemeld</Header>,
    accessor: 'appliedShifts',
    Cell: AppliedShiftsCell,
  };

  const phone: Column<ApplicantColumnDefinition> = {
    Header: () => <Header>Telefoonnummer</Header>,
    accessor: 'phone',
    Cell: PhoneCell,
  };

  const experience: Column<ApplicantColumnDefinition> = {
    Header: () => <Header>Werkervaring</Header>,
    accessor: 'experienceInMonths',
    Cell: ExperienceCell,
  };

  const actions: Column<ArchiveTableColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  return [
    flexWorkerImage,
    fw,
    hasRequiredCertificates,
    rating,
    attendanceRate,
    workedShifts,
    hourlyRate,
    ...(showAppliedShifts ? [appliedShifts] : []),
    phone,
    experience,
    actions,
  ];
};

const RatingCell = ({ value }: ApplicantCellProps<'rating'>) => {
  return value ? <RatingPercentage percentage={value} /> : null;
};

const CertificateChecksCell = ({ value }: ApplicantCellProps<'hasRequiredCertificates'>) => {
  return value ? (
    <Tooltip
      title="Deze flexwerker is in het bezit van alle benodigde certificaten"
      aria-label="certificate-check"
    >
      <Box paddingLeft={5}>
        <IconCertificate width={24} />
      </Box>
    </Tooltip>
  ) : null;
};

const AttendanceRateCell = ({ value }: ApplicantCellProps<'attendanceRate'>) => {
  return value ? <Cell>{value}%</Cell> : null;
};

const WorkedShiftsCell = ({ value }: ApplicantCellProps<'workedShifts'>) => {
  return <Cell>{pluralizeWithValue(value, 'shift', 'shifts', '0')}</Cell>;
};

const HourlyRateCell = ({ value }: ApplicantCellProps<'hourlyRate'>) => {
  return (
    <Cell>
      {value.toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      })}
    </Cell>
  );
};

const AppliedShiftsCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  return <Cell>{value}</Cell>;
};

const PhoneCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  return <Cell>{value}</Cell>;
};

const ExperienceCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  return <Cell>{convertToExperienceFormat(value)}</Cell>;
};

const ActionCell = ({ value, getRowActions }: ApplicantCellProps<'actions'>) => {
  const actions = getRowActions(value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${value.workerId}`}>
      {actions}
    </ContextMenuButton>
  );
};
