import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';

import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { Toolbox } from 'redesign/toolbox';
import { LwButton } from 'redesign/button';
import { routes } from 'Routes';
import { IconApproved, IconCancel } from 'assets/img';
import { FormProvider, useForm } from 'react-hook-form';
import { PartnerInformationEditForm } from './partner-information-edit-form';
import {
  PartnerInformationEditFormData,
  PartnerInformationFormData,
} from './partner-information.types';
import { useGetPartner } from '../../partners/hooks/use-get-partner';
import { partnersAlerts, useAlert } from '../../../../../shared/components/alerts';
import { useEditPartnerInformation } from '../hooks/use-edit-partner-information';

export const PartnerInfoEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const partnerQuery = useGetPartner(id);
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<PartnerInformationFormData>({});

  const mutation = useEditPartnerInformation(id);

  const handleSubmit = () => {
    formMethods.handleSubmit((data: PartnerInformationFormData) => {
      const submissionData: PartnerInformationEditFormData = {
        ...data,
        deactivated: data.deactivated === 'true',
      };
      mutation.mutate(submissionData, {
        onSuccess: () => {
          alertSuccess(partnersAlerts.success.updated);
          navigate(generatePath(routes.PartnerDetail, { id }));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  if (partnerQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerQuery} />;
  }

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Partnerinformatie bewerken" />}
        toolbox={
          <Toolbox underline>
            <LwButton
              onClick={handleSubmit}
              iconColorMode="stroke"
              color="primary"
              startIcon={<IconApproved />}
            >
              Partner aanpassen
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(routes.PartnerDetail, { id }))}
              iconColorMode="fill"
              color="secondary"
              startIcon={<IconCancel />}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerInformationEditForm partner={partnerQuery.data} />
      </Page>
    </FormProvider>
  );
};
