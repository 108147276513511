import { Box } from '@mui/material';
import { ApplicantTable } from 'shared/components/shift-detail-modal/components/tabs/applicants/applicant-table';
import { CancelledTable } from 'shared/components/shift-detail-modal/components/tabs/cancelled/cancelled-table';
import { ClaimantTable } from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table';
import {
  ShiftDetailTab,
  ShiftDetailTabData,
} from 'shared/components/shift-detail-modal/components/types';
import { ShiftAddFlexWorker } from 'shared/components/shift-detail-modal/components/tabs/add-flex-worker/shift-add-flex-worker';
import { LwTabIndicator } from 'redesign/tab';
import { useGetAddShiftClaimActions } from 'shared/components/shift-detail-modal/hooks/shared/use-get-add-shift-claim-actions';
import { HQShiftDetailResponse } from 'hq/services/shift-service.types';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useHqGetCancelledActions } from './actions/use-hq-get-cancelled-actions';
import { useHqGetClaimantActions } from './actions/use-hq-get-claimant-actions';
import { useHqGetApplicantActions } from './actions/use-hq-get-applicant-actions';
import { ShiftAddPartnerDataProvider } from '../../components/tabs/shift-add-partner/shift-add-partner';
import { mapShiftDetails } from '../shared/map-shift-details';

const useHqGetShiftDetailsTabs = (shiftData: HQShiftDetailResponse) => {
  const { getCancelledActions } = useHqGetCancelledActions();
  const { getClaimantActions, NoShowDialog, ChooseFlexWorkerFlexPools } = useHqGetClaimantActions(
    shiftData.job.companyId
  );
  const { getApplicantActions, BulkAcceptShiftApplicationsDialog } = useHqGetApplicantActions();
  const flexWorkerTableActions = useGetAddShiftClaimActions({ isHq: true });
  const { allowPartnerRelations, allowPartners } = useFeatureFlagContext();
  const allowPartnerShiftClaimAssign = allowPartnerRelations && allowPartners;

  const mappedWorkerData = mapShiftDetails(shiftData);

  const claimTableDetails = {
    mutableUntilDate: shiftData.mutableUntilDate,
    employmentType: shiftData.employmentType,
    id: shiftData.id,
    jobId: shiftData.job.id,
    startDate: shiftData.startDate,
    startTime: shiftData.startTime,
  };

  const applicantTableDetail = {
    startDate: shiftData.isoStartDateTime,
    employmentType: shiftData.employmentType,
  };

  if (!shiftData) {
    return [];
  }

  const tabs = [
    {
      id: ShiftDetailTab.TEAM,
      tabLabel: 'Jouw team',
      element: (
        <>
          {NoShowDialog}
          {ChooseFlexWorkerFlexPools}
          <ClaimantTable
            shiftDetails={claimTableDetails}
            shiftClaimants={mappedWorkerData.claimants}
            getActions={getClaimantActions}
          />
        </>
      ),
    },
    {
      id: ShiftDetailTab.APPLICATIONS,
      tabLabel: (
        <>
          {BulkAcceptShiftApplicationsDialog}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            Aanmeldingen
            {mappedWorkerData.isThereApplicants ? (
              <LwTabIndicator>{mappedWorkerData.pendingApplicants?.length}</LwTabIndicator>
            ) : null}
          </Box>
        </>
      ),
      element: (
        <ApplicantTable
          shiftDetails={applicantTableDetail}
          applicants={mappedWorkerData?.applicants}
          getActions={getApplicantActions}
        />
      ),
    },
    {
      id: ShiftDetailTab.CANCELS,
      tabLabel: 'Annuleringen',
      element: (
        <CancelledTable
          cancelledCandidates={mappedWorkerData?.cancelled}
          getActions={getCancelledActions}
        />
      ),
    },
    {
      id: ShiftDetailTab.ADD,
      tabLabel: 'Flexwerker toevoegen',
      element: <ShiftAddFlexWorker shiftId={shiftData.id} getRowActions={flexWorkerTableActions} />,
    },
    allowPartnerShiftClaimAssign
      ? {
          id: ShiftDetailTab.PARTNERS,
          tabLabel: 'Shift doorverwijzen',
          element: <ShiftAddPartnerDataProvider shiftId={shiftData.id} />,
        }
      : null,
  ];

  return tabs.filter((tab) => tab !== null) as ShiftDetailTabData[];
};

export { useHqGetShiftDetailsTabs };
