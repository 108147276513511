import { useGetShiftClaimContract } from 'hq/hooks/queryHooks/shiftclaims/use-get-shift-claim-contract';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { useDialog } from 'ogp/providers/dialog-provider';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { DateTime } from 'luxon';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { isApplicant } from 'shared/components/shift-detail-modal/components/tabs/helpers';
import { IconCloseCircle, IconDocument1, IconFlexPools, IconNoShow } from 'assets/img';
import { ContextMenuItem } from 'redesign/context-menu';
import { formatFullName } from 'shared/utils/formatting';
import { useChooseFlexWorkerFlexPoolsDialog } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/use-choose-flex-worker-flex-pool-dialog';
import { useHqUpdateFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/hq/use-hq-update-flex-worker-flex-pools';
import { useHqGetFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/hq/use-hq-flex-worker-flex-pools';
import { useHqSetNoShow } from 'shared/components/dialogs/no-show-dialog/query-hooks/hq/use-hq-set-no-show';
import { useNoShowDialog } from 'shared/components/dialogs/no-show-dialog/use-no-show-dialog';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';
import { useHqCancelShiftClaim } from '../../../query-hooks/hq/use-hq-cancel-shift-claim';
import { useHqUndoNoShow } from '../../../query-hooks/hq/use-hq-undo-no-show';

const useHqGetClaimantActions = (companyId: string) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const invalidateQuery = useInvalidateQuery();
  const {
    open: openNoShow,
    Element: NoShowDialog,
    setIsFlexWorker,
  } = useNoShowDialog(useHqSetNoShow);
  const { open: openChooseFlexWorkerFlexPools, Element: ChooseFlexWorkerFlexPools } =
    useChooseFlexWorkerFlexPoolsDialog(
      useHqUpdateFlexWorkerFlexPools,
      useHqGetFlexWorkerFlexPools,
      companyId
    );
  const getContract = useGetShiftClaimContract({
    onSuccess: () => alertSuccess(shiftAlerts.success.downloadOvo),
    onError: (e) => alertError(e),
  });
  const cancelShiftClaim = useHqCancelShiftClaim();
  const undoNoShowMutation = useHqUndoNoShow();

  const handleUndoNoShow = (params: {
    shiftClaimId: string;
    firstName: string;
    shiftId: string;
  }) => {
    openDialog(
      {
        title: `Weet je zeker dat je ${params.firstName} niet als no show wilt rapporteren?`,
        content: `Als er nog geen ingediende uren zijn krijgt ${params.firstName} de mogelijkheid om de checkout te starten in de app. Zo niet, dan is de checkout te zien in de checkouts-inbox.`,
      },
      () => {
        disableDialog();
        undoNoShowMutation.mutate(
          { shiftClaimId: params.shiftClaimId, shiftId: params.shiftId },
          {
            onSuccess: () => {
              invalidateQuery(QUERY_KEYS_HQ.shiftPlanningShifts());
              invalidateQuery(QUERY_KEYS_HQ.shift(params.shiftId));
              closeDialog();
              alertSuccess(shiftAlerts.success.undoneNoShow);
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const handleCancelShiftClaim = async (
    shiftData: ShiftDetailData,
    shiftClaimId: string,
    isFlexWorker: boolean
  ) => {
    const isCancelationOffer = DateTime.now() > DateTime.fromISO(shiftData.mutableUntilDate);
    openDialog(
      shiftData.employmentType === 'FREELANCER' && isCancelationOffer && isFlexWorker
        ? {
            title: 'Let op! Je annuleert de flexwerker binnen het annuleringstermijn',
            content:
              'Hierbij heeft de flexwerker recht op 50% van ingeplande uren volgens de Overeenkomst van Opdracht.  Bij bevestiging ga je hiermee akkoord.',
          }
        : {
            title: 'Weet je zeker dat je deze inschrijving wilt annuleren?',
          },
      () => {
        disableDialog();
        cancelShiftClaim.mutate(shiftClaimId, {
          onSuccess: () => {
            invalidateQuery(QUERY_KEYS_HQ.shiftPlanningShifts());
            invalidateQuery(QUERY_KEYS_HQ.shifts());
            closeDialog();
            alertSuccess(shiftAlerts.success.unsubscribed);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };

  const getClaimantActions = (candidate: ShiftClaimant, shiftData: ShiftDetailData) => {
    const startDateTime = DateTime.fromISO(`${shiftData.startDate}T${shiftData.startTime}`);
    const allowNoShow = DateTime.now() >= startDateTime.plus({ minutes: 15 });
    const noShowNotAllowedMessage =
      candidate.state === 'checkoutSettled'
        ? 'No-shows melden kan niet meer, deze claim is al gesettled.'
        : 'No-shows melden kan pas 15 minuten na aanvang van de shift.';

    const actions: React.ReactNode[] = [];

    if (isApplicant(candidate)) {
      return actions;
    }

    if (!candidate.isFlexWorker) {
      actions.push(
        <ContextMenuItem
          onClick={() =>
            handleCancelShiftClaim(shiftData, candidate.claimId, candidate.isFlexWorker)
          }
        >
          <IconCloseCircle />
          Annuleren
        </ContextMenuItem>
      );
      return actions;
    }

    const onContextMenuClick = () => {
      if (candidate.state === 'noShow') {
        handleUndoNoShow({
          shiftClaimId: candidate.claimId,
          firstName: candidate.firstName,
          shiftId: shiftData.id,
        });
        return;
      }
      if (allowNoShow) {
        setIsFlexWorker(candidate.isFlexWorker);
        openNoShow(candidate.claimId, shiftData.id);
        return;
      }
      alertError(new Error(noShowNotAllowedMessage));
    };

    if (candidate.state !== 'canceled') {
      actions.push(
        <ContextMenuItem
          onClick={() =>
            handleCancelShiftClaim(shiftData, candidate.claimId, candidate.isFlexWorker)
          }
        >
          <IconCloseCircle />
          Annuleren
        </ContextMenuItem>
      );

      actions.push(
        <ContextMenuItem onClick={onContextMenuClick}>
          <IconNoShow />
          {candidate.state === 'noShow'
            ? `Undo no show - ${candidate.noShowReason?.shortDescription}`
            : 'No show'}
        </ContextMenuItem>
      );
    }

    actions.push(
      <ContextMenuItem
        onClick={() =>
          openChooseFlexWorkerFlexPools(
            shiftData.jobId,
            shiftData.id,
            candidate.workerId,
            formatFullName(candidate)
          )
        }
      >
        <IconFlexPools />
        Flexpool beheer
      </ContextMenuItem>
    );

    actions.push(
      <ContextMenuItem onClick={() => getContract.mutate(candidate.claimId)}>
        <IconDocument1 />
        Overeenkomst
      </ContextMenuItem>
    );

    return actions;
  };

  return { getClaimantActions, NoShowDialog, ChooseFlexWorkerFlexPools };
};

export { useHqGetClaimantActions };
