import type { PrebuiltBlock } from 'redesign/block';
import { GetBillingEntitiesResponse } from 'shared/services/billing-entity/billing-entity-service.types';
import {
  CreateBillingEntityPayload,
  CreateBillingEntityQuery,
  CreateBillingEntityResponse,
  GetBillingEntityResponse,
  UpdateBillingEntityPayload,
} from './billing-entity-service.types';
import { OgpApiService } from './ogp-api-service';

export class BillingEntityService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public async getBillingEntitiesListing() {
    const response = await this.ogpApiService.get<PrebuiltBlock[]>({
      path: 'billing-entities/listing',
    });

    return response;
  }

  public async getBillingEntities() {
    const response = await this.ogpApiService.get<GetBillingEntitiesResponse[]>({
      path: 'billing-entities',
    });

    return response;
  }

  public async getBillingEntity(id: string) {
    const response = await this.ogpApiService.get<GetBillingEntityResponse>({
      path: `billing-entities/${id}`,
    });

    return response;
  }

  public async createBillingEntity({
    companyId,
    ...payload
  }: CreateBillingEntityPayload & CreateBillingEntityQuery): Promise<CreateBillingEntityResponse> {
    const response = await this.ogpApiService.create<CreateBillingEntityResponse>({
      path: `billing-entities?companyId=${companyId}`,
      body: payload,
    });

    return response;
  }

  public async updateBillingEntity(id: string, payload: UpdateBillingEntityPayload) {
    const response = await this.ogpApiService.update({
      path: `billing-entities/${id}`,
      body: payload,
    });

    return response;
  }

  public async archiveBillingEntity(id: string) {
    const response = await this.ogpApiService.delete<null>({
      path: `billing-entities/${id}`,
    });

    return response;
  }
}
