import { paths } from 'ogp/paths';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { Header } from 'redesign/header';
import { Page } from 'redesign/page';
import { Toolbox } from 'redesign/toolbox';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { useAlert } from 'shared/components/alerts';
import { LwButton } from 'redesign/button';
import { BillingEntityForm } from '../../components';
import type { BillingEntityFormFields } from '../../components';
import { useGetBillingEntityAsDetail } from '../../hooks/use-get-billing-entity';
import { useUpdateBillingEntity } from '../../hooks/use-update-billing-entity';
import { decodeFees, getOverlappingFeeTypes, mapFeesResponseToForm } from '../../utils/utils';
import { FeeTypeProvider } from '../../providers/fee-type-provider';

const BillingEntityEditProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Billing entity id is required');
  const billingEntity = useGetBillingEntityAsDetail(id);

  if (billingEntity.status !== 'success') {
    return <RenderUnsettledUI data={billingEntity} />;
  }

  const encodedFees: BillingEntityFormFields['fees'] = mapFeesResponseToForm(
    billingEntity.data.fees,
    false
  );

  const defaultValues: BillingEntityFormFields = {
    vatNumber: billingEntity.data.vatNumber,
    name: billingEntity.data.name,
    companyName: billingEntity.data.companyName,
    contactPerson: billingEntity.data.contactPerson,
    contactEmail: billingEntity.data.contactEmail,
    cocNumber: billingEntity.data.cocNumber,
    location: billingEntity.data.location,
    fees: encodedFees,
    paymentTerm: billingEntity.data.paymentTerm,
  };

  return <BillingEntityEdit initialValues={defaultValues} id={billingEntity.data.id} />;
};

const BillingEntityEdit = ({
  initialValues,
  id,
}: {
  initialValues: BillingEntityFormFields;
  id: string;
}) => {
  const formMethods = useForm<BillingEntityFormFields>({
    defaultValues: initialValues,
  });
  const mutation = useUpdateBillingEntity();
  const { alertError } = useAlert();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      const { fees, ...rest } = data;
      const overlappingFeeTypes = getOverlappingFeeTypes(fees);

      if (overlappingFeeTypes.length > 0) {
        alertError(
          new Error(`Vergoedingsintervallen overlappen elkaar ${overlappingFeeTypes.join(', ')}`)
        );

        return;
      }

      const decodedFees = decodeFees(fees);

      mutation.mutate({ id, payload: { ...rest, fees: decodedFees } });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe facturatiegegevens aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton iconColorMode="stroke" onClick={handleSubmit} {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton iconColorMode="fill" onClick={returnTo(paths.Company)} {...cancelButtonProps}>
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <FeeTypeProvider>
          <BillingEntityForm />
        </FeeTypeProvider>
      </Page>
    </FormProvider>
  );
};

export { BillingEntityEditProvider };
