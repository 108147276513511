import {
  AccreditationsColumnDefinition,
  RawAccreditationData,
} from 'types/accreditations/accreditations.types';
export const getAccreditationsTableData = (data: RawAccreditationData[]) => {
  const result: AccreditationsColumnDefinition[] = data.map((item) => {
    return {
      flexWorkerId: item.flexWorkerId,
      certificateId: item.certificateId,
      flexWorkerName: `${item.flexWorker.firstName} ${item.flexWorker.lastName}`,
      email: item.flexWorker.email,
      certificate: item.certificate.name,
      submittedDate: item.createdAt,
      state: item.state,
      actions: {
        flexWorkerId: item.flexWorkerId,
        certificateId: item.certificateId,
      },
    };
  });
  return result;
};
