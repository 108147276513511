import { departmentService } from 'ogp/services';
import { useMutation } from 'react-query';
import { FirstParamOfFn } from 'types/utility';

const useCreateDepartment = () => {
  const mutationFn = async (
    department: FirstParamOfFn<typeof departmentService.createDepartment>
  ) => await departmentService.createDepartment(department);

  return useMutation({ mutationFn });
};

export { useCreateDepartment };
