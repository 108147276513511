import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LwFormInput } from 'redesign/forms/input';
import { useGetCertificates } from 'partner/shared/hooks/accreditations/use-get-certificates';
import { useMemo } from 'react';
import { PartnerWorkerFormValues } from './partner-worker-form.types';
import { AccreditationInput } from '../../../../../redesign/forms/accreditations-input/accreditations-input';
import { RenderUnsettledUI } from '../../../../../shared/components';
import { Certificate } from '../../../../../types/accreditations/accreditations.types';
import { LwFormSelect } from '../../../../../redesign/forms/select';
import { EmploymentType } from '../../../../shared/services/partner-worker-service.types';
import { formatEmploymentType } from '../../../../../shared/utils/formatting';

export const PartnerWorkerFormDataProvider = () => {
  const certificatesQuery = useGetCertificates();

  if (certificatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={certificatesQuery} />;
  }

  return <PartnerWorkerForm data={certificatesQuery.data} />;
};

const PartnerWorkerForm = ({ data }: { data: Certificate[] }) => {
  const { control, setValue } = useFormContext<PartnerWorkerFormValues>();
  const phoneNumberRegex = new RegExp('^\\+[0-9]+$');

  const employmentTypeOptions = useMemo(() => {
    return Object.keys(EmploymentType).map((item) => ({
      value: item,
      label: formatEmploymentType(item as keyof typeof EmploymentType),
    }));
  }, []);

  return (
    <Grid container item display="flex" spacing={2} xs={3} direction="column">
      <Grid item>
        <LwFormInput
          name="firstName"
          control={control}
          rules={{ required: 'Voer een voornaam in' }}
          label="Voornaam"
        />
      </Grid>
      <Grid item>
        <LwFormInput name="infix" label="Tussenvoegsel" control={control} />
      </Grid>
      <Grid item>
        <LwFormInput
          name="lastName"
          label="Achternaam"
          control={control}
          rules={{ required: 'Voer een achternaam in' }}
        />
      </Grid>
      <Grid item>
        <LwFormInput
          name="phone"
          inputType="tel"
          label="Telefoon"
          control={control}
          placeholder="+31612345678"
          rules={{
            required: 'Voer een telefoon in',
            pattern: {
              value: phoneNumberRegex,
              message: 'Voorbeeld: +31612345678',
            },
          }}
        />
      </Grid>
      <Grid item>
        <LwFormInput
          name="email"
          inputType="email"
          label="Email"
          control={control}
          rules={{ required: 'Voer een email in' }}
        />
      </Grid>
      <Grid item>
        <LwFormSelect
          name="employmentType"
          control={control}
          options={employmentTypeOptions}
          label="Werkvorm"
          rules={{ required: 'Selecteer de juiste werkvorm' }}
        />
      </Grid>
      <Grid item>
        <AccreditationInput certificates={data} name="accreditations" label="Certificaten" />
      </Grid>
    </Grid>
  );
};
