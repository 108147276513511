import { branchOfficeAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useFormButtons } from 'redesign/forms/hooks/use-form-buttons';
import { TakeDefined } from 'types/utility';
import { LwButton } from 'redesign/button';
import { usePageProvider } from 'ogp/layouts';
import { useGetDepartmentAsForm, useUpdateDepartment } from '../../hooks';
import { DepartmentFormDataProvider } from '../../components/department-form/department-form';

const DepartmentEditDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Department id is missing');
  const departmentQuery = useGetDepartmentAsForm(id);

  if (departmentQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  return <DepartmentEdit defaultValues={departmentQuery.data} />;
};

const DepartmentEdit = ({
  defaultValues,
}: {
  defaultValues: TakeDefined<ReturnType<typeof useGetDepartmentAsForm>['data']>;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { id } = useParams<'id'>();
  const [{ filters }] = usePageProvider();
  invariant(id);
  const invalidateQuery = useInvalidateQuery();
  const formMethods = useForm({
    defaultValues,
  });
  const mutation = useUpdateDepartment();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((payload) =>
      mutation.mutate(
        // @ts-expect-error fix this
        { departmentId: id, payload },
        {
          onSuccess: () => {
            alertSuccess(alerts.success.updated);
            navigate(generatePath(routes.DepartmentDetail, { id }, filters));
            invalidateQuery(QUERY_KEYS_OGP.branchOffice(id));
          },
          onError: (e) => alertError(e),
        }
      )
    )();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Afdeling bewerken" />}
        toolbox={
          <Toolbox underline>
            <LwButton iconColorMode="stroke" onClick={handleSubmit} {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              iconColorMode="fill"
              onClick={returnTo(routes.DepartmentDetail, { id }, filters)}
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <DepartmentFormDataProvider state="update" />
      </Page>
    </FormProvider>
  );
};

export { DepartmentEditDataProvider };
