import {
  BaseJobData,
  FreelanceJobDetailsWithId,
  FreelanceJobFormData,
  TempWorkJobDetailsWithId,
  TempWorkJobFormData,
} from '../../../../../../types/jobs/JobTypes';

export function encodeJobToJobForm(job: FreelanceJobDetailsWithId): FreelanceJobFormData;
export function encodeJobToJobForm(job: TempWorkJobDetailsWithId): TempWorkJobFormData;
export function encodeJobToJobForm(
  job: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId
): FreelanceJobFormData | TempWorkJobFormData;
export function encodeJobToJobForm(
  job: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId
): FreelanceJobFormData | TempWorkJobFormData {
  if (job.employmentType === 'FREELANCER') {
    return {
      name: job.name,
      descriptionShort: job.descriptionShort,
      descriptionLawRequirements: job.descriptionLawRequirements,
      descriptionLong: job.descriptionLong,
      contactName: job.contactName,
      contactPhoneNumber: job.contactPhoneNumber,
      location: job.location,
      locationUrl: job.locationUrl,
      departmentId: job.departmentId,
      breakMinutes: job.breakMinutes,
      imageUrl: job.imageUrl,
      VAT: job.VAT,
      hasOpenShiftClaims: job.hasOpenShiftClaims,
      flexPoolOnly: job.flexPoolOnly,
      costCenter: job.costCenter,
      jobTypeId: job.jobTypeId,
      ortApplicable: job.ortApplicable,
      hourlyRate: job.hourlyRateCents / 100,
      noShowFine: job.noShowFineCents / 100,
      mutableUntilHours: job.mutableUntilMinutes / 60,
    };
  }
  return {
    name: job.name,
    descriptionShort: job.descriptionShort,
    descriptionLawRequirements: job.descriptionLawRequirements,
    descriptionLong: job.descriptionLong,
    contactName: job.contactName,
    contactPhoneNumber: job.contactPhoneNumber,
    location: job.location,
    locationUrl: job.locationUrl,
    departmentId: job.departmentId,
    breakMinutes: job.breakMinutes,
    imageUrl: job.imageUrl,
    VAT: job.VAT,
    hasOpenShiftClaims: job.hasOpenShiftClaims,
    flexPoolOnly: job.flexPoolOnly,
    costCenter: job.costCenter,
    jobTypeId: job.jobTypeId,
    ortApplicable: job.ortApplicable,
    cla: job.cla,
    hourlyRate: job.hourlyRateCents / 100,
  };
}

export const decodeJobFormToJob = (
  job: TempWorkJobFormData | FreelanceJobFormData
): BaseJobData => {
  const result = {
    ...job,
    hourlyRateCents: Math.round(job.hourlyRate * 100),
    jobTypeId: job.jobTypeId,
  };

  if ('mutableUntilHours' in result) {
    result.mutableUntilMinutes = Math.round(result.mutableUntilHours * 60);
    result.noShowFineCents = Math.round(result.noShowFine * 100);
  }

  return result;
};
