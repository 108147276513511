import { Box, Divider, styled, Tabs } from '@mui/material';
import { useGetFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorker';
import { ModalPanelTabType } from 'redesign/modal';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useState } from 'react';
import { FlexPools } from './tabs/flex-pools/flex-pools';
import { FlexWorkerDetailHead } from './flex-worker-detail-head';
import { FlexWorkerIdCheck } from './tabs/id-check/flex-worker-id-check';
import { ShiftClaims } from './tabs/shift-claims/flex-worker-shift-claims';
import { FlexWorkerPersonalData } from './tabs/personal-data/flex-worker-personal-data';
import { FlexWorkerProfile } from './tabs/flex-worker-profile/profile/flex-worker-profile';
import { FlexWorkerAvailability } from '../../../../../shared/components/flex-worker-availability/flex-worker-availability';
import { useFeatureFlagContext } from '../../../../../shared/contexts/feature-flag-provider';
import { LwTab } from '../../../../../redesign/tab';

type FlexWorkerDetailNewProps = {
  id: string;
  onClose: () => void;
};

export const FlexWorkerDetail = ({ id, onClose }: FlexWorkerDetailNewProps) => {
  const flexWorkerQuery = useGetFlexWorker(id);
  const [selectedTab, setSelectedTab] = useState(0);
  const { allowAvailability } = useFeatureFlagContext();

  if (flexWorkerQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={flexWorkerQuery}
      />
    );
  }

  const tabs: ModalPanelTabType[] = [
    {
      id: 'Persoonlijke gegevens',
      tabLabel: 'Persoonlijke gegevens',
      element: <FlexWorkerPersonalData flexWorkerData={flexWorkerQuery.data} />,
    },
    {
      id: 'Profiel',
      tabLabel: 'Profiel',
      element: <FlexWorkerProfile flexWorkerId={id} />,
    },
    {
      id: 'Shiftclaims',
      tabLabel: 'Shiftclaims',
      element: <ShiftClaims flexWorkerId={id} />,
    },
    {
      id: 'Flexpools',
      tabLabel: 'Flexpools',
      element: (
        <FlexPools
          flexWorkerId={id}
          employmentType={flexWorkerQuery.data.employmentType}
          level={flexWorkerQuery.data.level}
        />
      ),
    },
    ...(allowAvailability
      ? [
          {
            id: 'availability',
            tabLabel: 'Beschikbaarheid',
            element: <FlexWorkerAvailability flexWorkerId={id} />,
          },
        ]
      : []),
    ...(flexWorkerQuery.data.hasPendingIdentityVerification
      ? [
          {
            id: 'ID check',
            tabLabel: 'ID check',
            element: (
              <FlexWorkerIdCheck
                flexWorkerId={id}
                employmentType={flexWorkerQuery.data.employmentType}
                closeModal={onClose}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <FlexWorkerDetailWrapper>
      <FlexWorkerDetailHead data={flexWorkerQuery.data} onClose={onClose} />
      <Box mt={6} mb={6}>
        <Divider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        minHeight={0}
        flex="0 1 auto"
        paddingLeft="6px"
      >
        <StyledTabs
          value={tabs[selectedTab].id}
          onChange={(_, tabId) => {
            const selectedTab = tabs.findIndex((i) => i.id === tabId);
            setSelectedTab(selectedTab);
          }}
        >
          {tabs.map((tab) => {
            return <LwTab key={tab.id} value={tab.id} label={tab.tabLabel} />;
          })}
        </StyledTabs>
        <ContentWrapper role="tabpanel">{tabs[selectedTab].element}</ContentWrapper>
      </Box>
    </FlexWorkerDetailWrapper>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    gap: theme.spacing(8),
  },
}));

const FlexWorkerDetailWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentWrapper = styled(Box)({
  height: '100%',
  position: 'relative',
  overflowY: 'auto',
  paddingLeft: '6px',
  '&::-webkit-scrollbar': {
    width: '22px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
});
