import { useMutation } from 'react-query';
import { partnerWorkerService } from 'partner/shared/services';
import { PartnerWorkerFormValues } from '../partner-worker-form.types';

export const useCreatePartnerWorker = () => {
  const mutationFn = async (partnerWorker: PartnerWorkerFormValues) => {
    await partnerWorkerService.createPartnerWorker({
      firstName: partnerWorker.firstName,
      infix: partnerWorker.infix,
      lastName: partnerWorker.lastName,
      email: partnerWorker.email,
      phone: partnerWorker.phone,
      accreditations: partnerWorker?.accreditations,
      employmentType: partnerWorker.employmentType,
    });
  };

  return useMutation(mutationFn);
};
