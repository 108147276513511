import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { departmentService } from 'hq/services';
import { useQuery } from 'react-query';
import { SecondParamOfFn } from 'types/utility';
import { departmentsResponse2DepartmentsSelectModel } from '../../../../../../ogp/services/mappers/mappers';

type Response = Awaited<ReturnType<typeof departmentService.getDepartments>>;

type Params = SecondParamOfFn<typeof useGetDepartments>;

const useGetDepartments = <TData = Response>(
  companyId: string,
  params: SecondParamOfFn<typeof departmentService.getDepartments>,
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await departmentService.getDepartments(companyId, params);

  return useQuery(QUERY_KEYS_HQ.departments(companyId), queryFn, {
    select,
    enabled: !!companyId,
  });
};

export const useGetDepartmentsAsSelect = (companyId: string, params: Params) => {
  const result = useGetDepartments(companyId, params, departmentsResponse2DepartmentsSelectModel);
  return result;
};
