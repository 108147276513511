import { userAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useCreateUser } from 'hq/hooks/queryHooks/users/useCreateUser';
import { HqUser } from 'types/users/UsersTypes';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { UserForm } from './user-form';
import { useUsersPage } from '../hooks/use-users';
import { Header } from '../../../../../redesign/header';
import { Page } from '../../../../../redesign/page';
import { Toolbox } from '../../../../../redesign/toolbox';
import { LwButton } from '../../../../../redesign/button';
import { useRolesMatrix } from '../../../../hooks/queryHooks/users/useRolesMatrix';
import { RenderUnsettledUI } from '../../../../../shared/components';
import { RoleMatrix } from '../../../../services/userService';

export type HqUserFormContext = {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  companyId?: string;
  nickname?: string;
  roles?: HqUser['roles'];
};

export const UserCreate = () => {
  const rolesMatrixQuery = useRolesMatrix();

  if (rolesMatrixQuery.status !== 'success') {
    return <RenderUnsettledUI data={rolesMatrixQuery} />;
  }

  return <UserCreateImpl roleMatrix={rolesMatrixQuery.data} />;
};

const UserCreateImpl = ({ roleMatrix }: { roleMatrix: RoleMatrix[] }) => {
  const methods = useForm<HqUserFormContext>();
  const { alertSuccess, alertError } = useAlert();
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const { filters } = useUsersPage();

  const createUser = useCreateUser();

  const onSubmit = () => {
    methods.handleSubmit((data: HqUser) => {
      createUser.mutate(data, {
        onSuccess: () => {
          alertSuccess(alerts.success.created);
          navigate(generatePath(routes.HQUsers, undefined, filters));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  const onCancel = () => navigate(-1);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Page
          header={<Header titleText="Nieuwe gebruiker aanmaken" />}
          toolbox={
            <Toolbox>
              <LwButton onClick={onSubmit}>Akkoord</LwButton>
              <LwButton color="secondary" onClick={onCancel}>
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <UserForm state={'create'} roleMatrix={roleMatrix} />
        </Page>
      </form>
    </FormProvider>
  );
};
