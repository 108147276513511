import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign/forms/select';

export const CompanyInput = ({
  options,
  disabled,
}: {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  const { control } = useFormContext<{ companyId: string }>();
  return (
    <LwFormSelect
      name="companyId"
      label="Company"
      defaultLabel="Selecteer een company"
      options={options}
      disabled={disabled}
      control={control}
      rules={{ required: 'Selecteer een company' }}
      data-testid="company-select"
    />
  );
};
