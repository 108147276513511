import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles, styled } from '@mui/styles';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { Link } from 'react-router-dom';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { ShiftData } from './grid-shift.types';
import { GridShiftPartnersPopover } from './grid-shift-partners-popover/grid-shift-partners-popover';

export const GridShift = ({ shiftData }: ShiftData) => {
  const classes = getClasses();
  const updatePlanningPath = useUpdatePlanningPath();

  if (!shiftData) {
    return <Grid item className={clsx(classes.shiftWrapper, classes.emptyShift)} />;
  }

  return (
    <Grid
      data-testid={`shift-${shiftData.id}`}
      item
      className={clsx(classes.shiftWrapper, {
        [classes.historical]: DateTime.fromISO(shiftData.dateTime) < DateTime.now(),
        [classes.full]: shiftData.claimants === shiftData.maxClaimants,
      })}
    >
      <Link to={updatePlanningPath({ shiftDetailId: shiftData.id })} className={classes.shiftLink}>
        <StyledShiftBox>
          <Typography
            variant="body2"
            className={classes.shiftTime}
            title={`${shiftData.startTime} - ${shiftData.endTime}`}
            alignSelf="center"
            component="div"
            mt={2}
          >
            <FlexPoolOnlyIndicator flexPoolOnly={shiftData.flexPoolOnly}>
              {shiftData.startTime} - {shiftData.endTime}
              <GridShiftPartnersPopover shiftId={shiftData.id} />
            </FlexPoolOnlyIndicator>
          </Typography>
          <StyledCapacityBox>
            <Typography variant="body2">
              {shiftData.claimants} / {shiftData.maxClaimants}
            </Typography>
            {shiftData.applicants > 0 ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                pt="1px"
                className={classes.badge}
              >
                <Typography variant="body2">{shiftData.applicants}</Typography>
              </Box>
            ) : null}
          </StyledCapacityBox>
        </StyledShiftBox>
      </Link>
    </Grid>
  );
};

const StyledShiftBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  width: '100%',
  height: '6.75rem',
  flexShrink: 0,
  borderRadius: '1rem',
  backgroundColor: theme.palette.lwWhite['100'],
  boxShadow:
    '0px 4px 6px 0px rgba(51, 51, 51, 0.03), 0px 0px 8px 0px rgba(51, 51, 51, 0.03), 0px 12px 16px 0px rgba(51, 51, 51, 0.08)',
  transition: '0.5s',
  '&:hover': {
    boxShadow: '0px 12px 16px rgba(51, 51, 51, 0.16), 0px 4px 6px rgba(51, 51, 51, 0.08)',
  },
}));

const StyledCapacityBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexDirection: 'row',
  borderRadius: '0.75rem',
  background: '#F5FAFF',
  padding: '1rem 0.75rem',
});

const getClasses = makeStyles((theme: Theme) => ({
  shiftWrapper: {
    marginBottom: theme.spacing(2),
  },
  emptyShift: {
    backgroundColor: 'transparent',
    height: theme.spacing(4),
  },
  full: {
    opacity: 1,
  },
  historical: {
    opacity: 0.5,
  },
  shiftLink: {
    textDecoration: 'none',
  },
  shiftInfo: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  shiftTime: {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    letterSpacing: '.5px',
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
  },
  badge: {
    backgroundColor: 'black',
    height: theme.spacing(6),
    width: theme.spacing(6),
    borderRadius: '50%',

    '& .MuiTypography-root': {
      color: 'white',
    },
  },
}));
