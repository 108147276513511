import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { CompanyRole } from 'types/roles/CompanyRole';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { RenderUnsettledUI } from 'shared/components';
import { AcknowledgeButton, useDialog } from 'ogp/providers/dialog-provider';
import { useArchiveJob } from 'ogp/hooks/queryHooks/jobs/useArchiveJob';
import { jobAlerts, useAlert } from 'shared/components/alerts';
import { invariant } from 'shared/utils/utils';
import {
  FreelanceJobDetailsWithId,
  FreelanceJobFormData,
  TempWorkJobDetailsWithId,
  TempWorkJobFormData,
} from 'types/jobs/JobTypes';
import { Page } from 'redesign/page';
import { Header } from 'redesign/header';
import { Toolbox } from 'redesign/toolbox';
import { IconDuplicate, IconEdit, IconTrash } from 'assets/img';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { formatTextValue } from 'redesign/utils';
import { vatLabel } from 'types/Vat';
import { formatDuration } from 'shared/utils/formatting';
import { Duration } from 'luxon';
import { TakeDefined } from 'types/utility';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';
import { LwButton } from 'redesign/button';
import { usePageProvider } from 'ogp/layouts';
import { useGetJob } from 'shared/hooks/query-hooks/use-get-job';
import { GetBranchOfficeResponse } from '../../../../../../services/branch-office-service.types';

type Job = (
  | (TempWorkJobFormData & { employmentType: 'TEMPWORKER' })
  | (FreelanceJobFormData & { employmentType: 'FREELANCER' })
) & {
  department: Pick<GetBranchOfficeResponse['branchOffice'], 'name'>;
  jobType: string;
};

const JobDetailDataProvider = () => {
  const { id } = useParams<'id'>();
  const jobQuery = useGetJob(id);
  const departmentQuery = useGetDepartmentAsDetail(jobQuery.data?.departmentId);
  const jobCreateDataQuery = useGetJobCreateData();

  if (jobCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobCreateDataQuery} />;
  }

  if (jobQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobQuery} />;
  }

  if (departmentQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  const data = {
    ...transformJob(jobQuery.data, departmentQuery.data, jobCreateDataQuery.data),
  };

  return <JobDetail data={data} />;
};

const JobDetail = ({ data }: { data: Job }) => {
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [{ filters }] = usePageProvider();
  const generatePath = useCompanyRelativePath();
  const labelGroups = getLabelGroups(data);
  const { alertSuccess, alertError } = useAlert();
  const archiveJob = useArchiveJob({
    onSuccess: () => {
      alertSuccess(jobAlerts.success.deleted);
      navigate(generatePath(routes.Jobs));
      closeDialog();
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();

  const handleArchiveJob = () => {
    invariant(id, 'Job id is not defined');
    disableDialog();
    archiveJob.mutate(id);
  };

  const handleForbiddenDelete = () => {
    openDialog(
      {
        title: 'Oeps, dit kan niet!',
        content:
          'Het is niet mogelijk om de functie te verwijderen aangezien er nog actieve shifts voor deze functie zijn',
        secondaryButton: (props) => <AcknowledgeButton {...props} />,
        primaryButton: null,
      },
      () => {}
    );
  };

  const handleDelete = () => {
    openDialog({ title: 'Weet je zeker dat je deze functie wilt verwijderen?' }, handleArchiveJob);
  };

  return (
    <Page
      header={
        <Header
          labelGroups={labelGroups}
          titleText={data.name}
          subheaderText="Functie details"
        ></Header>
      }
      toolbox={
        <Toolbox>
          <LwButton to={generatePath(routes.Jobs, undefined, filters)} color="secondary">
            Terug
          </LwButton>
          <LwButton
            to={generatePath(routes.JobsEdit, { id }, filters)}
            startIcon={<IconEdit />}
            iconColorMode="fill"
            color="secondary"
          >
            Functie aanpassen
          </LwButton>
          <LwButton
            to={generatePath(routes.JobsDuplicate, { id }, filters)}
            startIcon={<IconDuplicate />}
            iconColorMode="fill"
            color="secondary"
          >
            Functie dupliceren
          </LwButton>
          <AuthenticatedElement roles={CompanyRole.ADMIN} policyGroup={PolicyGroups.COMPANY}>
            <LwButton
              onClick={data.hasOpenShiftClaims ? handleForbiddenDelete : handleDelete}
              startIcon={<IconTrash />}
              iconColorMode="stroke"
              color="secondary"
            >
              Verwijderen
            </LwButton>
          </AuthenticatedElement>
        </Toolbox>
      }
    >
      <Box>
        <Grid container spacing={6}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Enkel binnen de flexpool
            </Typography>
            <Typography variant="body1">{data.flexPoolOnly ? 'Ja' : 'Nee'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              ORT toegepast
            </Typography>
            <Typography variant="body1">{data.ortApplicable ? 'Ja' : 'Nee'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              BTW-tarief
            </Typography>
            <Typography variant="body1">{vatLabel(data.VAT)}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Locatie
            </Typography>
            <Typography variant="body1">{data.location.address}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Functiegroep
            </Typography>
            <Typography variant="body1">{data.jobType}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Contactpersoon
            </Typography>
            <Typography variant="body1">{data.contactName}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Telefoon contactpersoon
            </Typography>
            <Typography variant="body1">{data.contactPhoneNumber}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Locatie URL
            </Typography>
            <Typography variant="body1">
              <a href={data.locationUrl} target="_blank" rel="noreferrer">
                {data.locationUrl}
              </a>
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Kostenplaats
            </Typography>
            <Typography variant="body1">{data.costCenter}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Korte omschrijving
            </Typography>
            <Typography variant="body1">{data.descriptionShort}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Lange omschrijving
            </Typography>
            <Typography variant="body1">{data.descriptionLong}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">
              Afspraken en vereisten
            </Typography>
            <Typography variant="body1">{data.descriptionLawRequirements}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

const getLabelGroups = (data: ReturnType<typeof transformJob>) => {
  const result = [
    {
      title: 'Vestiging',
      description: data.department.name,
    },
    {
      title: data.employmentType === 'FREELANCER' ? 'Uurtarief' : 'Bruto uurloon',
      description: formatTextValue(data.hourlyRate, 'currency'),
    },
  ];

  if (data.employmentType === 'FREELANCER') {
    result.push({
      title: 'No show boete',
      description: formatTextValue(data.noShowFine, 'currency'),
    });
    result.push({
      title: 'Annuleringstermijn',
      description: formatDuration(
        Duration.fromMillis(data.mutableUntilHours * 60 * 60 * 1000).toObject()
      ),
    });
    result.push({
      title: 'Pauze',
      description: formatDuration(Duration.fromMillis(data.breakMinutes * 60 * 1000).toObject()),
    });
  }

  return result;
};

// TODO this can be probably fixed with function overloads
const transformJob = (
  job: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId,
  department: TakeDefined<ReturnType<typeof useGetDepartmentAsDetail>['data']>,
  jobCreateData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>
): Job => {
  const result = {
    ...job,
    ...(job.employmentType === 'FREELANCER'
      ? {
          noShowFine: job.noShowFineCents / 100,
          mutableUntilHours: job.mutableUntilMinutes / 60,
        }
      : {}),
    hourlyRate: job.hourlyRateCents / 100,
    department: {
      name: department.name,
    },
    jobType: jobCreateData.jobGroups.find((jg) => jg.jobType.find((jt) => jt.id === job.jobTypeId))
      ?.name,
  };

  return result as any;
};

export { JobDetailDataProvider };
