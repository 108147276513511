import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { JobListItem } from 'types/jobs/JobTypes';
import { RenderUnsettledUI } from 'shared/components';
import { EmploymentType } from 'types/companies/company-types';
import { useGetDepartmentsAsSelect } from '../../hooks/use-get-departments';

type Props = {
  companyId: string;
  jobsData: JobListItem[];
  employmentType: EmploymentType;
  isProjectWithActivePlacement: boolean;
};

export const ProjectFormProvider = ({
  companyId,
  jobsData,
  employmentType,
  isProjectWithActivePlacement,
}: Props) => {
  const departmentsQuery = useGetDepartmentsAsSelect(companyId, { includeArchived: false });

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectForm
      mode="update"
      jobsData={jobsData}
      isProjectWithActivePlacement={isProjectWithActivePlacement}
      departments={departmentsQuery.data}
      employmentType={employmentType}
    />
  );
};
