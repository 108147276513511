import { Table } from 'redesign/table';
import { TakeDefined } from 'types/utility';
import { useMemo } from 'react';
import { FlexPoolsTableColumnDefinition } from './helpers/flex-pools-table.types';
import { getFlexPoolsColumnDefinition } from './helpers/get-flex-pools-column-definition';
import { getFlexPoolsTableData } from './helpers/get-flex-pools-table-data';
import { useGetFlexPools } from '../../hooks/use-get-flex-pools';

type Props = {
  data: TakeDefined<ReturnType<typeof useGetFlexPools>['data']>;
  onRowClick: (row: FlexPoolsTableColumnDefinition) => void;
};

export const FlexPoolsTable = ({ data, onRowClick }: Props) => {
  const columns = useMemo(() => getFlexPoolsColumnDefinition(), []);
  const tableData = useMemo(() => getFlexPoolsTableData(data), [data]);

  return (
    <Table<FlexPoolsTableColumnDefinition>
      columns={columns}
      data={tableData}
      onRowClick={onRowClick}
    />
  );
};
