import { ReactNode, useState } from 'react';
import { SmallLoader } from 'shared/components';

function LazyImage({
  alt,
  fallBackComponent,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & { fallBackComponent?: ReactNode }) {
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return <>{fallBackComponent}</>;
  }

  return (
    <>
      {!loaded && <SmallLoader />}
      <img alt={alt} {...props} onLoad={() => setLoaded(true)} onError={() => setHasError(true)} />
    </>
  );
}

export { LazyImage };
