import { IconAccessTime } from 'assets/img';
import type { Column } from 'react-table';
import { Box, styled, Typography } from '@mui/material';
import {
  PartnerPlacementsCellProp,
  PartnerPlacementsColumnDefinition,
} from '../partner-placements.types';
import { ContextMenuButton } from '../../../../../redesign/context-menu';

const getPartnerPlacementsColumnDefinition: () => Column<PartnerPlacementsColumnDefinition>[] =
  () => {
    const workerName = {
      Header: () => <Header>Werker</Header>,
      accessor: 'workerName',
      Cell: WorkerCell,
      width: '250px',
    } as Column<PartnerPlacementsColumnDefinition>;

    const companyBranchOfficeInfo = {
      Header: () => <Header>Bedrijf & Afdelingen</Header>,
      accessor: 'companyBranchOfficeInfo',
      Cell: CompanyBranchOfficeInfoCell,
    } as Column<PartnerPlacementsColumnDefinition>;

    const jobName = {
      Header: () => <Header>Functie</Header>,
      accessor: 'jobName',
      width: '200px',
      Cell: JobCell,
    } as Column<PartnerPlacementsColumnDefinition>;

    const departmentName = {
      Header: () => <Header>Afdeling</Header>,
      accessor: 'departmentName',
      Cell: JobCell,
      width: '200px',
    } as Column<PartnerPlacementsColumnDefinition>;

    const scheduledTime = {
      Header: () => <Header>Datum & tijd</Header>,
      accessor: 'dateTime',
      Cell: DateTimeCell,
      width: '200px',
    } as Column<PartnerPlacementsColumnDefinition>;

    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'placementId',
      Cell: ActionCell,
      width: '65px',
    } as Column<PartnerPlacementsColumnDefinition>;

    return [workerName, companyBranchOfficeInfo, departmentName, jobName, scheduledTime, action];
  };

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const WorkerCell = (cell: PartnerPlacementsCellProp<'workerName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const CompanyBranchOfficeInfoCell = (
  cell: PartnerPlacementsCellProp<'companyBranchOfficeInfo'>
) => {
  return (
    <BoxInfo>
      <TypographyWithPreLine variant="body1">
        {`${cell.value.companyName} \n ${cell.value.branchOfficeName}`}
      </TypographyWithPreLine>
    </BoxInfo>
  );
};

const JobCell = (cell: PartnerPlacementsCellProp<'jobName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const DateTimeCell = (cell: PartnerPlacementsCellProp<'dateTime'>) => {
  return (
    <BoxInfo>
      <Typography variant="body1" color="secondary">
        {cell.value.fromDateTime.toFormat('dd MMM yyyy')}
      </Typography>
      <BoxTime>
        <IconAccessTime />
        <Typography variant="body1">
          {cell.value.fromDateTime.toFormat('HH:mm') +
            '-' +
            cell.value.toDateTime.toFormat('HH:mm')}
        </Typography>
      </BoxTime>
    </BoxInfo>
  );
};

const ActionCell = (cell: PartnerPlacementsCellProp<'placementId'>) => {
  const actions = cell.getRowActions(cell.value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${cell.value}`}>{actions}</ContextMenuButton>
  );
};
const TypographyWithPreLine = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
  color: theme.palette.text.secondary,
}));

const BoxInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));
const BoxTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: theme.spacing(0.5),
}));

export { getPartnerPlacementsColumnDefinition };
