import type { Column } from 'react-table';
import { Chip, styled, Typography } from '@mui/material';
import { Header, LevelTableHeaderProps } from 'redesign/table';
import { LwButton } from 'redesign/button';
import SortIcon from 'assets/img/icons/caret-down.svg?react';
import {
  PartnerRelationCellProp,
  PartnerRelationColumnDefinition,
  PartnerRelationTableOptions,
  SortState,
} from '../company-partners-list.types';

const getCompanyPartnersColumnDefinition: () => Column<PartnerRelationColumnDefinition>[] = () => {
  const partner = {
    Header: () => <Header>Partner</Header>,
    accessor: 'partner',
    Cell: BoldCell,
    width: '300px',
  } as Column<PartnerRelationColumnDefinition>;

  const branchOffice = {
    Header: SortableHeader,
    accessor: 'branchOffice',
    Cell: BoldCell,
    width: '600px',
  } as Column<PartnerRelationColumnDefinition>;

  const jobTypes = {
    Header: () => <Header>Functie groep</Header>,
    accessor: 'jobTypes',
    Cell: JobTypesCell,
  } as Column<PartnerRelationColumnDefinition>;

  return [partner, branchOffice, jobTypes];
};

const SortableHeader = (
  cell: LevelTableHeaderProps<PartnerRelationColumnDefinition, PartnerRelationTableOptions>
) => {
  return (
    <StyledHeaderButton
      style={{ padding: 0 }}
      color="secondary"
      onClick={() => {
        cell.setSortState((prev: SortState) => (prev === 'ASC' ? 'DESC' : 'ASC'));
      }}
    >
      <Typography variant="body2" color="secondary">
        Vestiging
      </Typography>
      <StyledSortIcon
        style={{
          transform: cell.sortState === 'ASC' ? 'rotate(180deg)' : '',
        }}
      />
    </StyledHeaderButton>
  );
};

const BoldCell = (cell: PartnerRelationCellProp<'branchOffice'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const JobTypesCell = (cell: PartnerRelationCellProp<'jobTypes'>) => {
  return (
    <Typography component="div">
      {cell.value.map((jobType) => (
        <Chip key={jobType} label={jobType} />
      ))}
    </Typography>
  );
};

const StyledHeaderButton = styled(LwButton)(() => ({ padding: 0 }));

const StyledSortIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  height: '12px',
  width: '12px',
  transition: 'ease transform .5s',
}));

export { getCompanyPartnersColumnDefinition };
