import { EmptyTableMessage } from 'ogp/components/common/empty-table-message';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { GetAllFlexWorkersReponse } from 'ogp/services/flexWorkerService.types';
import { Table, useOnScrollDown } from 'redesign/table';
import { useMemo } from 'react';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import { getFlexworkerColumnDefinition } from './utils/get-flex-worker-column-definition';
import { getFlexworkerTableData } from './utils/get-flex-worker-table-data';
import {
  FlexworkerColumnDefinition,
  FlexWorkerTableOptions,
} from './flex-workers-for-work-table.types';

type TableProps = {
  queryResult: InfiniteQueryObserverSuccessResult<GetAllFlexWorkersReponse>;
  entityId: string;
  getRowActions: FlexWorkerTableOptions['getRowActions'];
  showAvailabilityColumn?: boolean;
};

const FlexWorkersTable = ({
  queryResult,
  entityId,
  getRowActions,
  showAvailabilityColumn,
}: TableProps) => {
  const profilePopup = usePopupProfile();

  const flatData = useMemo(
    () => queryResult.data.pages.flatMap((page) => page.items) ?? [],
    [queryResult.data.pages]
  );

  const columns = useMemo(
    () => getFlexworkerColumnDefinition(showAvailabilityColumn),
    [showAvailabilityColumn]
  );
  const tableData = useMemo(() => getFlexworkerTableData(flatData, entityId), [flatData, entityId]);

  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    profilePopup.open(flexWorkerId, relation);
  };

  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: queryResult,
    flatData: flatData,
  });

  if (queryResult.data.pages.length === 0) {
    return (
      <EmptyTableMessage
        title="Er zijn nog geen flexwerkers!"
        subtitle="Er hebben zich nog geen flexwerkers aangemeld."
      />
    );
  }

  return (
    <>
      {profilePopup.Element}
      <Table<FlexworkerColumnDefinition, FlexWorkerTableOptions>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        onScroll={fetchIfBottomScrolled}
        onProfileClick={onProfileClick}
        getRowActions={getRowActions}
      />
    </>
  );
};

export { FlexWorkersTable };
