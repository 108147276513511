import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FlexWorkerFilterCounts, FlexWorkersFilters } from 'types/flexWorkers/FlexWorkerTypes';
import { PaginatedCollection } from 'types/Pagination';
import CrossIcon from 'assets/img/icons/cross.svg?react';
import { FormCheckbox } from 'redesign/forms/checkbox-group';
import { LwFormSelect } from 'redesign/forms/select';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { getEmploymentTypeOptions } from 'shared/utils/filters';
import { FlexWorkerParams, SetFlexWorkerParams } from './flex-workers.types';
import { LwFormInput } from '../../../../redesign/forms/input';

type FlexWorkerTableProps = {
  data: (PaginatedCollection<any> & FlexWorkerFilterCounts) | undefined;
  params: FlexWorkerParams;
  setParams: SetFlexWorkerParams;
};

const FlexWorkersFilterForm = ({ data, params, setParams }: FlexWorkerTableProps) => {
  const { control, setValue } = useFormContext<FlexWorkersFilters>();
  const [filterValue, setFilterValue] = useState(params.filter);
  const debounceFilter = useDebounce(filterValue, 1000);
  const { allowFreelancerEmploymentType, allowTempWorkerEmploymentType } = useFeatureFlagContext();

  useEffect(() => {
    if (debounceFilter !== undefined) {
      setValue('filter', debounceFilter, { shouldDirty: true });
      setParams({ filter: debounceFilter });
    }
  }, [debounceFilter, setValue]);

  const levelOptions = useMemo(() => getLevelOptions(data), []);
  const employmentTypeOptions = useMemo(
    () =>
      getEmploymentTypeOptions({
        allowFreelancerEmploymentType,
        allowTempWorkerEmploymentType,
      }),
    []
  );
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <LwFormInput
          control={control}
          name="filter"
          label="Zoeken"
          placeholder="Naam, email of telefoonnummer"
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="veld leegmaken"
                  onClick={() => setFilterValue('')}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <CrossIcon width={20} height={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LwFormSelect
          name="level"
          control={control}
          label="Level"
          options={levelOptions}
          onChange={(e) => {
            setParams({ level: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LwFormSelect
          name="employmentType"
          control={control}
          label="Flexwerker type"
          options={employmentTypeOptions}
          onChange={(e) => {
            setParams({ employmentType: e.target.value });
          }}
        />
      </Grid>
      <Box alignSelf={'center'} ml={4}>
        <FormCheckbox
          name="disabled"
          control={control}
          label={`Disabled (${data ? data.disabledCount : ''})`}
          onChange={(e) => {
            setParams({ disabled: e });
          }}
        />
      </Box>
    </Grid>
  );
};

const getLevelOptions = (data: (PaginatedCollection<any> & FlexWorkerFilterCounts) | undefined) => [
  {
    label: `Alles  ${data ? `(${data.unfilteredCount})` : ''}`,
    value: 'ALL',
  },
  {
    label: `Incomplete ${data ? `(${data.incompleteCount})` : ''}`,
    value: 'INCOMPLETE',
  },
  {
    label: `Explorer ${data ? `(${data.explorerCount})` : ''}`,
    value: 'EXPLORER',
  },
  {
    label: `Starter ${data ? `(${data.starterCount})` : ''} `,
    value: 'STARTER',
  },
  {
    label: `Candidate ${data ? `(${data.candidateCount})` : ''} `,
    value: 'CANDIDATE',
  },
  {
    label: `Worker ${data ? `(${data.workerCount})` : ''} `,
    value: 'WORKER',
  },
];

export { FlexWorkersFilterForm };
