import { Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BranchOfficeFormData } from 'types/companies/branch-office-types';
import useGeocode from 'ogp/hooks/queryHooks/locations/useGeocode';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { VatListOptions } from 'types/Vat';
import { LwFormAutocomplete } from 'redesign/forms/autocomplete';
import { LwFormInput } from 'redesign/forms/input';
import { LwFormSelect } from 'redesign/forms/select';
import { useGetBillingEntitiesAsSelect } from '../../billing-entities/hooks/use-get-billing-entities';

type Props = {
  state: 'read' | 'update' | 'create';
  disabled?: true;
};

const BranchOfficeForm = ({ disabled, state }: Props) => {
  const { control, setValue, getValues } = useFormContext<BranchOfficeFormData>();
  const billingEntitiesQuery = useGetBillingEntitiesAsSelect();
  const defaultAddress = React.useRef(getValues().location?.address ?? '');
  const [input, setInput] = React.useState(defaultAddress.current);
  const debouncedInput = useDebounce(input, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== defaultAddress.current);
  const { data, isFetching } = geoQuery;
  const ref = React.useRef<HTMLFormElement | null>(null);
  const billingEntitiesOptions = billingEntitiesQuery.data ?? [];

  return (
    <form aria-label="Branch office form" ref={ref}>
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een vestigingsnaam in' }}
            label="Vestigingsnaam"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="companyNameForOVO"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam voor de OVO in' }}
            label="Bedrijfsnaam voor OVO"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="legalPerson"
            control={control}
            rules={{ required: 'Voer een wettelijke vertegenwoordiger in' }}
            label="Wettelijke vertegenwoordiger"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="cocNumber"
            control={control}
            rules={{
              required: 'Voer een KVK-nummer in',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Een KVK-nummer bestaat uit acht cijfers',
              },
            }}
            label="KVK-nummer"
            placeholder="12345678"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormAutocomplete
            label="Locatie"
            name="location"
            control={control}
            // 'defaultOption' used purely to display the current address in the field as long as it doesn't get changed
            defaultValue={{ type: 'defaultOption', address: defaultAddress.current }}
            rules={{ required: 'Voer een locatie in' }}
            disableClearable={true}
            getOptionLabel={(option) => option.address}
            isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
            getOptionKey={(option) => option.hereId ?? ''}
            onChange={(_event, value) => {
              if (value) {
                setValue('location', value);
              }
            }}
            onInputChange={(_e, value) => {
              setInput(value);
            }}
            disabled={disabled}
            options={data ? data : []}
            isLoading={isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="locationUrl"
            control={control}
            label="Locatie (maps link)"
            placeholder="Maps link"
            rules={{ required: 'Voer een locatie link in' }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormSelect
            name="VAT"
            control={control}
            options={VatListOptions}
            label="BTW-tarief"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="costCenter"
            label="Kostenplaats"
            control={control}
            disabled={disabled}
          />
        </Grid>
        {state === 'create' ? (
          <Grid item xs={12}>
            <LwFormSelect
              name="billingEntityId"
              options={billingEntitiesOptions}
              label="Facturatiegegevens"
              control={control}
              rules={{ required: 'Selecteer een facturatiegegevens' }}
            />
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
};

export { BranchOfficeForm };
