import { FormProvider, useForm } from 'react-hook-form';
import { useFeatureFlagContext } from '../../../../../shared/contexts/feature-flag-provider';
import { getDefaultEmploymentTypeFilter } from '../../../../../shared/utils/filters';
import { PaginatedCollection, ParamsWithPagination } from '../../../../../types/Pagination';
import {
  FlexWorkerFilterCounts,
  FlexWorkerListItem,
  FlexWorkersFilters,
} from '../../../../../types/flexWorkers/FlexWorkerTypes';
import { FlexWorkersFilterForm } from '../flex-workers-filter-form';
import { FlexWorkerParams, SetFlexWorkerParams } from '../flex-workers.types';

export const FlexWorkerToolbox = ({
  params,
  setParams,
  data,
}: {
  params: FlexWorkerParams;
  setParams: SetFlexWorkerParams;
  data: (PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts) | undefined;
}) => {
  const { allowFreelancerEmploymentType, allowTempWorkerEmploymentType } = useFeatureFlagContext();

  const methods = useForm<ParamsWithPagination<FlexWorkersFilters>>({
    mode: 'onChange',
    defaultValues: {
      filter: params.filter,
      level: params.level as FlexWorkersFilters['level'],
      employmentType:
        params.employmentType ??
        getDefaultEmploymentTypeFilter({
          allowFreelancerEmploymentType,
          allowTempWorkerEmploymentType,
        }),
      disabled: params.disabled,
      page: params.page,
      size: params.size,
    },
    shouldUnregister: false,
  });

  return (
    <FormProvider {...methods}>
      <FlexWorkersFilterForm data={data} params={params} setParams={setParams} />
    </FormProvider>
  );
};
