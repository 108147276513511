import {
  BaseJobData,
  FreelanceJobDetailsWithId,
  JobDataWithId,
  JobListItem,
  TempWorkJobDetailsWithId,
} from 'types/jobs/JobTypes';
import { BreakOption } from 'types/shifts/shift-types';
import { PrebuiltBlock } from 'redesign/block';
import {
  CreateJobResponse,
  GetFilteredJobsListingResponse,
  GetJobCreateDataResponse,
} from './job-service.types';
import { OgpApiService, Response } from './ogp-api-service';

export class JobService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Create a new job
   */
  createJob = async (job: BaseJobData): Promise<CreateJobResponse> => {
    return await this.ogpApiService.create<CreateJobResponse>({
      body: job,
      path: `jobs`,
    });
  };

  getJobCreateData = async () => {
    const response = await this.ogpApiService.get<GetJobCreateDataResponse>({
      path: `jobs/get-job-create-data`,
    });

    return response;
  };

  /**
   * Update an existing jobs
   */
  updateJob = async (job: JobDataWithId) => {
    await this.ogpApiService.update({
      body: job,
      path: `jobs/${job.id}`,
    });
  };

  /**
   * Get a list of existing jobs
   */
  getFilteredJobsForCompany = async (departmentId?: string): Promise<JobListItem[]> => {
    const response = await this.ogpApiService.get<{ jobs: JobListItem[] }>({
      path: 'jobs',
      params: { departmentId },
    });

    return response.jobs;
  };

  getFilteredJobsListing = async (departmentId?: string): Promise<PrebuiltBlock[]> => {
    const response = await this.ogpApiService.get<GetFilteredJobsListingResponse>({
      path: 'jobs/listing',
      params: { departmentId },
    });

    return response.jobs;
  };

  /**
   * Get a single job
   */
  getJob = async <T = TempWorkJobDetailsWithId | FreelanceJobDetailsWithId>(
    jobId: string
  ): Promise<T> => {
    const response = await this.ogpApiService.get<{ job: T }>({
      path: `jobs/${jobId}`,
    });

    return response.job;
  };

  getBreakOptions = async (jobId: string): Promise<BreakOption[]> => {
    const response = await this.ogpApiService.get<Response<BreakOption[]>>({
      path: `jobs/${jobId}/break`,
    });

    return response.breakOptions;
  };

  archiveJob = async (jobId: string) =>
    await this.ogpApiService.delete<undefined>({
      path: `jobs/${jobId}`,
    });
}
