import { Grid, Typography } from '@mui/material';
import { IconMail } from 'assets/img';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LwButton } from 'redesign/button';
import { LwFormMultiSelect } from 'redesign/forms/multi-select';
import { shiftPartnerAlerts as alerts, useAlert } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useGetShiftEligiblePartners } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-shift-eligible-partners';
import { useGetShiftPartners } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-shift-partners';
import { useOpenShiftToPartners } from 'shared/components/shift-detail-modal/query-hooks/hq/use-open-shift-to-partners';
import {
  GetShiftEligiblePartnerResponse,
  GetShiftPartnerResponse,
} from '../../../../../../hq/services/shift-service.types';

type PartnerInput = {
  partners: {
    id: string;
    name: string;
  }[];
};

export const ShiftAddPartnerDataProvider = ({ shiftId }: { shiftId: string }) => {
  const getShiftPartnersQuery = useGetShiftPartners(shiftId);
  const getShiftEligiblePartnersQuery = useGetShiftEligiblePartners(shiftId);

  if (getShiftPartnersQuery.status !== 'success') {
    return <RenderUnsettledUI data={getShiftPartnersQuery} />;
  }

  if (getShiftEligiblePartnersQuery.status !== 'success') {
    return <RenderUnsettledUI data={getShiftEligiblePartnersQuery} />;
  }

  return (
    <ShiftAddPartner
      partners={getShiftPartnersQuery.data}
      eligiblePartners={getShiftEligiblePartnersQuery.data}
      shiftId={shiftId}
    />
  );
};

const ShiftAddPartner = ({
  shiftId,
  partners,
  eligiblePartners,
}: {
  shiftId: string;
  partners: GetShiftPartnerResponse;
  eligiblePartners: GetShiftEligiblePartnerResponse;
}) => {
  const { handleSubmit, control } = useForm<PartnerInput>({
    defaultValues: {
      partners,
    },
  });
  const { alertSuccess, alertError } = useAlert();
  const mutation = useOpenShiftToPartners(shiftId, {
    onSuccess: () => {
      alertSuccess(alerts.success.opened);
    },
    onError: (e) => alertError(e),
  });

  const partnerValues = useMemo(
    () => eligiblePartners.map(({ id, name }) => ({ id, name })),
    [eligiblePartners]
  );

  const onSubmit = (formData: PartnerInput, e: React.BaseSyntheticEvent | undefined) => {
    e?.preventDefault();
    const partnerIdsToSubmit = formData.partners.map((i) => i.id).sort();
    const partnerIds = partners.map((i) => i.id).sort();
    const hasPartnerSelectionChanged =
      JSON.stringify(partnerIds) !== JSON.stringify(partnerIdsToSubmit);
    if (hasPartnerSelectionChanged) {
      mutation.mutate({ shiftId, partnerIds: formData.partners.map((i) => i.id) });
    }
  };

  return (
    <form aria-label="Shift partners form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column">
        <Grid item paddingTop={3}>
          <Typography noWrap variant="h6">
            Shift doorverwijzen
          </Typography>
        </Grid>
        <Grid item xs={6} paddingTop={3}>
          <LwFormMultiSelect
            name="partners"
            control={control}
            options={partnerValues}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            label="Selecteer een Partner"
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
                {partners.some((partner) => partner.id === option.id) && <IconMail />}
              </li>
            )}
          />
        </Grid>
        <Grid item>
          <LwButton type="submit">Doorverwijzen</LwButton>
        </Grid>
      </Grid>
    </form>
  );
};
