import { Page } from 'redesign/page';
import { useToggle } from 'shared/hooks';
import { routes } from 'ogp/Routes';
import { RenderUnsettledUI } from 'shared/components';
import { Header } from 'redesign/header';
import { PaginationIndicator } from 'ogp/components/common/pagination-indicator';
import { Box, Drawer } from '@mui/material';
import { useGetProjectsCheckouts } from './hooks';
import { ProjectsInboxTable } from './components/projects-inbox-table';
import { useLevelPagination } from '../../../../../hooks/queryHooks/misc/use-level-pagination';
import { InboxFilterForm, useInboxFilterFormState, useInboxSorting } from '../../shared';
import { DividerElement } from '../../shifts-checkouts/inbox/checkouts-inbox';
import { useGetProjectCheckoutInboxFilters } from '../../../../../hooks/queryHooks/shiftclaims/useGetProjectCheckoutInboxFilters';

const ProjectsCheckoutsInbox = () => {
  const { on: drawerOpen, toggle: toggleDrawer } = useToggle();
  const [sortState, handleSortClick] = useInboxSorting(
    routes.CheckoutsInbox,
    routes.CheckoutsInbox.queryParams
  );
  const inboxFilterFormState = useInboxFilterFormState();
  const checkoutsInboxFiltersQuery = useGetProjectCheckoutInboxFilters();
  const pagination = useLevelPagination();
  const checkoutsQuery = useGetProjectsCheckouts({
    page: pagination.page,
    size: pagination.size,
    ...inboxFilterFormState.activeFilters,
    ...sortState,
  });

  if (checkoutsInboxFiltersQuery.status !== 'success') {
    return <RenderUnsettledUI data={checkoutsInboxFiltersQuery} />;
  }

  return (
    <>
      <Page
        header={
          <Header
            titleText="Projecten Checkouts"
            subheaderText="Hier zie je alle inkomende checkouts van projecten"
          />
        }
        toolbox={DividerElement}
      >
        {checkoutsQuery.status !== 'success' ? (
          <RenderUnsettledUI data={checkoutsQuery} />
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            <ProjectsInboxTable
              data={checkoutsQuery.data}
              sortState={sortState}
              handleSortClick={handleSortClick}
              toggleDrawer={toggleDrawer}
              nrOfActiveFilters={inboxFilterFormState.nrOfActiveFilters}
            />
            <Box mt="auto">
              <PaginationIndicator
                pageCount={checkoutsQuery.data.totalCount}
                pagination={pagination}
              />
            </Box>
          </Box>
        )}
      </Page>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <InboxFilterForm
          onCancel={toggleDrawer}
          data={checkoutsInboxFiltersQuery.data}
          {...inboxFilterFormState}
        />
      </Drawer>
    </>
  );
};

export { ProjectsCheckoutsInbox };
