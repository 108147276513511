import { FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';
import { IconApproved } from 'assets/img';
import { IconBox, SkillBox, StyledChip } from './shared/components';

const ProfileQualifications = ({ data }: { data: FlexWorkerProfileData }) => {
  const { qualifications } = data;
  qualifications.sort((x, y) => y.skills.length - x.skills.length);
  qualifications.forEach((q) => q.skills.sort());

  return (
    <SkillBox>
      {qualifications && qualifications.length > 0
        ? qualifications
            .reduce<string[]>((acc, q) => acc.concat(q.skills), [])
            .map((s) => (
              <StyledChip
                label={
                  <IconBox>
                    {s} <IconApproved data-testid="skill-svg" />
                  </IconBox>
                }
                key={s}
                data-testid="skill-chip"
              />
            ))
        : 'De flexwerker heeft nog geen skills toegevoegd'}
    </SkillBox>
  );
};

export { ProfileQualifications };
