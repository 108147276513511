import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { flexPoolService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { FlexPoolFormValuesOGP } from 'types/flex-pools/flex-pool-types';

type FlexPoolMutationOptions = UseMutationOptions<unknown, unknown, FlexPoolFormValuesOGP>;

const useCreateFlexPool = (options?: FlexPoolMutationOptions) => {
  const mutationFn = async (flexPool: FlexPoolFormValuesOGP) =>
    await flexPoolService.createFlexPool({
      name: flexPool.name,
      departmentId: flexPool.departmentId,
      description: flexPool.description,
      jobIds: flexPool.jobs.map((i) => i.id),
    });

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: FlexPoolMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.flexPoolList());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};

export { useCreateFlexPool };
