import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { Table } from 'redesign/table';
import { hqRoutes } from 'hq/Routes';
import { GetPartnerUsersResponse } from 'hq/services/partner-user-service.types';
import { ContextMenuItem } from 'redesign/context-menu';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { getPartnerUserTableData } from './get-partner-user-table-data';
import { getPartnerUserColumnDefinition } from './partner-user-table-column-definition';
import { PartnerUserColumnDefinition, PartnerUserTableOptions } from './partner-user.types';
import { useGetPartnerUsers } from './../hooks/use-get-users';
import { useArchivePartnerUserDialog } from '../hooks/use-archive-partner-user-dialog';

type Props = { deactivated: boolean };

export const PartnerUsersTableProvider = ({ deactivated }: Props) => {
  const { id } = useParams<'id'>();

  invariant(id);
  const userQuery = useGetPartnerUsers(id);

  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return <PartnerUsersTable data={userQuery.data} deactivated={deactivated} />;
};

const PartnerUsersTable = ({
  data,
  deactivated,
}: {
  data: GetPartnerUsersResponse;
  deactivated: boolean;
}) => {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const columns = useMemo(() => getPartnerUserColumnDefinition(), []);
  const tableData = useMemo(() => getPartnerUserTableData(data), [data]);

  const { open: archivePartnerUser, Element } = useArchivePartnerUserDialog(id ?? '');

  const getMenuActionsFromPartnerUser = (user: { userId: string }) => {
    return [
      <ContextMenuItem
        key="edit"
        onClick={() =>
          navigate(generatePath(hqRoutes.PartnerUserEdit, { partnerId: id, userId: user.userId }))
        }
        disabled={deactivated}
      >
        Edit
      </ContextMenuItem>,
      <ContextMenuItem
        key="archive"
        onClick={() => {
          archivePartnerUser(user.userId);
        }}
      >
        Archive
      </ContextMenuItem>,
    ];
  };

  return (
    <>
      <Table<PartnerUserColumnDefinition, PartnerUserTableOptions>
        columns={columns}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        getRowActions={getMenuActionsFromPartnerUser}
      />
      {Element}
    </>
  );
};
