import type { Column } from 'react-table';
import { Box, styled, Typography } from '@mui/material';
import { IconPause } from 'assets/img';
import { LevelTableCellProps } from 'redesign/table';
import { PartnerPortalCounterOffers } from 'partner/shared/services/partner-checkouts-service.types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PARTNER_PATHS } from 'partner/paths';
import { BoxAction } from '../../../../../shared/components/planner/common/utils';

export type PartnerCounterOffersColumnDefinition = PartnerPortalCounterOffers['items'][number];

type CounterOfferCellProp<T extends keyof PartnerCounterOffersColumnDefinition> =
  LevelTableCellProps<
    PartnerCounterOffersColumnDefinition,
    PartnerCounterOffersColumnDefinition[T]
  >;

const getCounterOffersColumnDefinition: () => Column<PartnerCounterOffersColumnDefinition>[] =
  () => {
    const worker = {
      Header: () => <Header>Flexwerker</Header>,
      accessor: 'flexWorkerName',
      Cell: WorkerCell,
      width: '150px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    const job = {
      Header: () => <Header>Functie</Header>,
      accessor: 'jobName',
      Cell: JobCell,
      width: '150px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    const company = {
      Header: () => <Header>Bedrijf</Header>,
      accessor: 'companyName',
      Cell: CompanyCell,
      width: '150px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    const date = {
      Header: () => <Header>Datum</Header>,
      accessor: 'workedDate',
      Cell: WorkedDateCell,
      width: '80px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    const submittedTime = {
      Header: () => <Header>Ingediende tijd</Header>,
      accessor: 'submittedTime',
      Cell: SubmittedTimeCell,
      width: '150px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    const counterOfferTime = {
      Header: () => <Header>Tegenvoorstel tijd</Header>,
      accessor: 'counterOfferTime',
      Cell: CounterOfferTimeCell,
      width: '150px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'shiftClaimId',
      Cell: ActionCell,
      width: '65px',
    } as Column<PartnerCounterOffersColumnDefinition>;

    return [worker, job, company, date, submittedTime, counterOfferTime, action];
  };

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const WorkerCell = (cell: CounterOfferCellProp<'flexWorkerName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const JobCell = (cell: CounterOfferCellProp<'jobName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const CompanyCell = (cell: CounterOfferCellProp<'companyName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const WorkedDateCell = (cell: CounterOfferCellProp<'workedDate'>) => {
  return (
    <Typography variant="body1" noWrap>
      {DateTime.fromISO(cell.value).toFormat('dd LLL', { locale: 'nl' })}
    </Typography>
  );
};

const SubmittedTimeCell = (cell: CounterOfferCellProp<'submittedTime'>) => {
  return (
    <BoxDateTime>
      <Typography variant="body1" noWrap>
        {DateTime.fromISO(cell.value.startDate).toFormat('HH:mm')} -{' '}
        {DateTime.fromISO(cell.value.endDate).toFormat('HH:mm')}{' '}
        {cell.value.breakMinutes ? `, ${cell.value.breakMinutes} min.` : ''}
      </Typography>
      {!!cell.value.breakMinutes && <IconPauseStyled />}
    </BoxDateTime>
  );
};

const CounterOfferTimeCell = (cell: CounterOfferCellProp<'counterOfferTime'>) => {
  return (
    <BoxDateTime>
      <Typography variant="body1" noWrap>
        {DateTime.fromISO(cell.value.startDate).toFormat('HH:mm')} -{' '}
        {DateTime.fromISO(cell.value.endDate).toFormat('HH:mm')}{' '}
        {cell.value.breakMinutes ? `, ${cell.value.breakMinutes} min.` : ''}
      </Typography>
      {!!cell.value.breakMinutes && <IconPauseStyled />}
    </BoxDateTime>
  );
};

const ActionCell = (cell: CounterOfferCellProp<'shiftClaimId'>) => {
  const generatePath = usePartnerRelativePath();
  const counterOfferLink = generatePath(PARTNER_PATHS.CounterOffer, { id: cell.value });
  return (
    <BoxAction>
      <Link to={counterOfferLink}>
        <Typography>Bekijk</Typography>
      </Link>
    </BoxAction>
  );
};

const IconPauseStyled = styled(IconPause)(() => ({
  flex: 'none',
  width: '14px',
}));

const BoxDateTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));

export { getCounterOffersColumnDefinition };
