import { Grid } from '@mui/material';
import { LwFormNumberInput } from 'redesign/forms/number-input';
import { FreelanceJobFormData } from 'types/jobs/JobTypes';
import { Control, useFormContext } from 'react-hook-form';
import { LwFormAutocomplete } from 'redesign/forms/autocomplete';
import { useLocationAutocomplete } from 'redesign/forms/hooks/use-location-autocomplete';
import { filterOptions } from 'shared/utils/utils';
import { useEffect } from 'react';
import React from 'react';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { TakeDefined } from 'types/utility';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';
import { JobFormState, useGetSharedJobFormElements } from './use-get-shared-job-form-elements';
import { useGetBranchOffice } from '../../../../../../hooks/queryHooks/branchoffices/use-get-branch-office';

type FreelanceJobFormElementProps = {
  control: Control<Partial<FreelanceJobFormData>>;
  state: JobFormState;
};

type FreelanceJobFormElements = { [key in keyof FreelanceJobFormData]: React.ReactNode };

export const useGetFreelanceJobFormData = (
  state: JobFormState,
  jobData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>
): Omit<FreelanceJobFormElements, 'hasOpenShiftClaims'> => {
  const { control, watch, setValue } = useFormContext<Partial<FreelanceJobFormData>>();
  const departmentId = watch('departmentId');
  const { data: department } = useGetDepartmentAsDetail(departmentId);
  const sharedElements = useGetSharedJobFormElements(state, jobData, department);

  useEffect(() => {
    if (department && state === 'create') {
      setValue('VAT', department.VAT);
    }
  }, [department, setValue, state]);

  const res = {
    flexPoolOnly: sharedElements.flexPoolOnly,
    ortApplicable: sharedElements.ortApplicable,
    departmentId: sharedElements.departmentId,
    name: sharedElements.name,
    hourlyRate: <HourlyRate control={control} state={state} />,
    noShowFine: <NoShowFine control={control} state={state} />,
    jobTypeId: sharedElements.jobTypeId,
    VAT: sharedElements.VAT,
    mutableUntilHours: <MutableUntilHours control={control} state={state} />,
    breakMinutes: sharedElements.breakMinutes,
    descriptionShort: sharedElements.descriptionShort,
    descriptionLong: sharedElements.descriptionLong,
    descriptionLawRequirements: sharedElements.descriptionLawRequirements,
    contactName: sharedElements.contactName,
    contactPhoneNumber: sharedElements.contactPhoneNumber,
    costCenter: sharedElements.costCenter,
    location: <Location state={state} />,
    locationUrl: sharedElements.locationUrl,
    imageUrl: sharedElements.imageUrl,
  };

  return res;
};

const HourlyRate = ({ control, state }: FreelanceJobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormNumberInput
        name="hourlyRate"
        label="Uurtarief"
        placeholder="10,50"
        control={control}
        rules={{ required: 'Voer een uurtarief in' }}
        step={0.01}
        min={0}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const NoShowFine = ({ control, state }: FreelanceJobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormNumberInput
        name="noShowFine"
        label="No show boete"
        placeholder="10,50"
        control={control}
        rules={{ required: 'Voer een no show boete in, 0 euro is toegestaan' }}
        step={0.01}
        min={0}
        disabled={state === 'read'}
      />
    </Grid>
  );
};
const MutableUntilHours = ({ control, state }: FreelanceJobFormElementProps) => {
  return (
    <Grid item xs={6}>
      <LwFormNumberInput
        name="mutableUntilHours"
        label="Annuleringstermijn"
        placeholder="In uren"
        control={control}
        rules={{ required: 'Voer een annuleringstermijn in' }}
        step={1}
        min={0}
        disabled={state === 'read'}
      />
    </Grid>
  );
};

const Location = ({ state }: { state: FreelanceJobFormElementProps['state'] }) => {
  const context = useFormContext<Partial<FreelanceJobFormData>>();
  const watchedLocation = context.watch('location');
  const branchOfficeData = useGetBranchOffice();
  const { suggestions, loading, handleInputChange, locationInputValue } = useLocationAutocomplete(
    watchedLocation?.address,
    branchOfficeData.data?.location?.address
  );

  return (
    <Grid item xs={12}>
      <LwFormAutocomplete
        name="location"
        label="Locatie"
        control={context.control}
        rules={{ required: 'Voer een locatie in' }}
        disableClearable={true}
        disabled={state === 'read'}
        getOptionLabel={(option) => option.address}
        getOptionKey={(option) => option.hereId ?? ''}
        onChange={(_event, value) => {
          if (value) {
            context.setValue('location', value);
          }
        }}
        isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
        inputValue={locationInputValue}
        onInputChange={handleInputChange}
        options={suggestions}
        filterOptions={filterOptions}
        isLoading={loading}
      />
    </Grid>
  );
};
