import { IconCancel, IconCoin, IconDuplicate, IconEdit, IconTrash } from 'assets/img';
import { routes } from 'ogp/Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { DeleteButton, PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useNavigate } from 'react-router-dom';
import { ContextMenuItem } from 'redesign/context-menu';
import { useAlert } from 'shared/components/alerts';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { HQShiftDetailResponse } from 'hq/services/shift-service.types';
import { useOgpArchiveShift } from '../query-hooks/ogp/use-ogp-archive-shift';

type Props = {
  data?: HQShiftDetailResponse;
  closeModal: () => void;
};

const useGetShiftDetailHeadActions = ({ data, closeModal }: Props) => {
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const updatePlanningPath = useUpdatePlanningPath();

  const { mutate, isLoading: isArchiving } = useOgpArchiveShift(data?.id, {
    onSuccess: () => {
      alertSuccess('De shift is succesvol verwijderd');
      navigate(updatePlanningPath({ shiftDetailId: null }));
      closeDialog();
      closeModal();
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const handleDelete = () => {
    openDialog(
      {
        title: 'Shift verwijderen',
        content:
          'Weet je zeker dat je de shift wilt verwijderen? Dit kan alleen als er geen geaccepteerde shiftclaims zijn.',
        secondaryButton: (props) => (
          <PrimaryButton startIcon={<IconCancel />} {...props} iconColorMode="fill">
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => <DeleteButton {...props} />,
      },
      () => {
        disableDialog();
        mutate();
      }
    );
  };

  const handleEdit = () => {
    navigate(generatePath(routes.ShiftsEdit, { id: data?.id }));
  };

  const handleDuplicate = () => {
    navigate(generatePath(routes.ShiftsDuplicate, { id: data?.id }));
  };

  const handleMultiply = () => {
    navigate(generatePath(routes.ShiftsMultiply, { id: data?.id }));
  };

  const children = [
    <ContextMenuItem key="edit" onClick={handleEdit}>
      <IconEdit />
      Wijzig
    </ContextMenuItem>,
    <ContextMenuItem key="duplicate" onClick={handleDuplicate}>
      <IconDuplicate />
      Dupliceer
    </ContextMenuItem>,
    <ContextMenuItem key="multiply" onClick={handleMultiply}>
      <IconCoin />
      Meerdere shifts
    </ContextMenuItem>,
    <ContextMenuItem key="delete" onClick={handleDelete}>
      <IconTrash />
      Verwijderen
    </ContextMenuItem>,
  ];

  return { children, isArchiving };
};

export { useGetShiftDetailHeadActions };
