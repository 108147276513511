import { FlexWorkerListItem } from 'types/flexWorkers/FlexWorkerTypes';
import { PaginatedCollection } from 'types/Pagination';
import { Box, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { FlexWorkerCard } from './flex-worker-card/flex-worker-card';
import { FlexWorkerParams, SetFlexWorkerParams } from './flex-workers.types';

type FlexWorkerTableProps = {
  flexWorkersData: PaginatedCollection<FlexWorkerListItem> | undefined;
  params: FlexWorkerParams;
  setParams: SetFlexWorkerParams;
};

export function FlexWorkersTable({ flexWorkersData, params, setParams }: FlexWorkerTableProps) {
  const flexWorkers = flexWorkersData?.items ?? [];

  const openModal = useCallback((flexWorkerId: string) => setParams({ flexWorkerId }), []);
  if (flexWorkers.length === 0) {
    return (
      <Box>
        <Typography>Er zijn geen flexwerkers gevonden!</Typography>
        <Typography>Met de huidige filters zijn er geen flexwerkers gevonden.</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-end">
      <Grid container spacing={1} data-testid="flex-worker-list">
        {flexWorkers.map((flexWorker) => (
          <Grid key={flexWorker.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FlexWorkerCard
              filter={params.filter ?? ''}
              flexWorker={flexWorker}
              openModal={openModal}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
