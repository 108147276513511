import { departmentService } from 'ogp/services';
import { useMutation } from 'react-query';
import { FirstParamOfFn, SecondParamOfFn } from 'types/utility';

const useUpdateDepartment = () => {
  const mutationFn = async (params: {
    departmentId: FirstParamOfFn<typeof departmentService.updateDepartment>;
    payload: SecondParamOfFn<typeof departmentService.updateDepartment>;
  }) => await departmentService.updateDepartment(params.departmentId, params.payload);

  return useMutation({ mutationFn });
};

export { useUpdateDepartment };
