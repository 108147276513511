import { PrebuiltBlock } from 'redesign/block';
import { OgpApiService, Response } from './ogp-api-service';
import {
  CreateUserPayload,
  GetOGPUserInfoSchema,
  GetRolesMatrixResponse,
  GetUserResponse,
  UpdateUserPayload,
} from './user-service.types';

export class UserService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Create a new user
   */
  createUser = async (user: CreateUserPayload) => {
    await this.ogpApiService.create({
      path: `users`,
      body: user,
    });
  };

  /**
   * Update an existing user
   */
  updateUser = async (userId: string, user: UpdateUserPayload) => {
    await this.ogpApiService.update({
      path: `users/${userId}`,
      body: user,
    });
  };

  /**
   * Get a list of existing users
   */
  getUserListing = async (): Promise<PrebuiltBlock[]> => {
    const { ogpUsers } = await this.ogpApiService.get<Response<PrebuiltBlock[]>>({
      path: 'users/listing',
    });
    return ogpUsers;
  };

  /**
   * Get a single user
   */
  getUser = async (userId: string) => {
    const response = await this.ogpApiService.get<GetUserResponse>({
      path: `users/${userId}`,
    });

    return response;
  };

  getRolesMatrix = async () => {
    const result = await this.ogpApiService.get<GetRolesMatrixResponse>({
      path: `users/get-roles-matrix`,
    });

    return result;
  };

  /**
   * Get a single user
   */
  getOGPUserInfo = async () => {
    const response = await this.ogpApiService.get({
      path: `get-user-info`,
    });

    const result = GetOGPUserInfoSchema.parse(response);

    return result;
  };

  /**
   * Archive the user
   */
  archiveUser = async (userId: string) =>
    await this.ogpApiService.delete<undefined>({
      path: `users/${userId}`,
    });

  /** resend verification email */

  resendTemporaryCredentials = async (email: string) =>
    await this.ogpApiService.create<undefined>({
      path: `users/resend-temporary-credentials-email`,
      body: {
        email,
      },
    });
}
