import { FlexWorkersForShiftFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { FlexPoolFilters } from 'types/flex-pools/flex-pool-types';
import { ParamsWithPagination } from 'types/Pagination';
import { FirstParamOfFn } from 'types/utility';
import type { projectService } from './services';
import {
  ShiftsInboxFiltersParams,
  ShiftsOutboxFiltersParams,
} from './services/shift-claim-service.types';
import { GetShiftPlanningListItemsParams } from './services/shift-service.types';

/**
 * Alls React-Query keys are managed here.
 * Make sure to have the correct dependency structure for cache invalidation etc.
 * Read this article for more background info: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const QUERY_KEYS_OGP = {
  /** ALL OGP related data. */
  allOGP: () => ['ogp'],
  billingEntities: () => ['billingEntities'],
  billingEntity: (id: string) => [...QUERY_KEYS_OGP.billingEntities(), { id }],

  /** ALL flexWorker related data. */
  flexWorkers: () => [...QUERY_KEYS_OGP.allOGP(), 'flexWorkers'],
  /** A single flexWorker */
  flexWorker: (flexWorkerId?: string) => [...QUERY_KEYS_OGP.flexWorkers(), { flexWorkerId }],
  /** FlexWorkers for a job. */
  flexWorkersForJob: (jobId: string) => [...QUERY_KEYS_OGP.flexWorkers(), 'for-job', { jobId }],
  /** FlexWorkers for a project. */
  flexWorkersForProject: (projectId: string, filters?: FlexWorkersForShiftFilters) => [
    ...QUERY_KEYS_OGP.flexWorkers(),
    'for-project',
    { projectId },
    { ...filters },
  ],
  /** FlexWorkers for a shift. */
  flexWorkersForShift: (shiftId: string, filters?: FlexWorkersForShiftFilters) => [
    ...QUERY_KEYS_OGP.flexWorkers(),
    'for-shift',
    { shiftId },
    { ...filters },
  ],
  /**FlexPools for a flexWorker. */
  flexWorkerFlexPools: (flexWorkerId: string, jobId: string) => [
    ...QUERY_KEYS_OGP.flexWorker(flexWorkerId),
    'for-job',
    { jobId },
  ],
  /** ALL company related data. */
  companies: () => [...QUERY_KEYS_OGP.allOGP(), 'companies'],
  /** Your company. */
  ownCompany: () => [...QUERY_KEYS_OGP.companies(), 'own-company'],
  /** ALL branch office related data. */
  branchOffices: () => [...QUERY_KEYS_OGP.allOGP(), 'branch-offices'],
  /** Branch office overview. */
  branchOfficesList: () => [...QUERY_KEYS_OGP.branchOffices(), 'list'],
  branchOfficesListing: () => [...QUERY_KEYS_OGP.branchOffices(), 'listing'],
  /** A single branch office. */
  branchOffice: (branchOfficeId: string) => [...QUERY_KEYS_OGP.branchOffices(), { branchOfficeId }],
  /** The branch offices for a job. */
  branchOfficesForJobs: () => [...QUERY_KEYS_OGP.branchOffices(), 'for-jobs'],
  departments: (params?: Record<string, unknown>) => [
    ...QUERY_KEYS_OGP.allOGP(),
    'departments',
    params,
  ],
  departmentListing: (branchOfficeId?: string) => [
    ...QUERY_KEYS_OGP.departments({ branchOfficeId }),
    'listing',
  ],
  department: (departmentId: string) => [...QUERY_KEYS_OGP.departments(), { departmentId }],
  /** A single flexPool. */
  flexPool: (flexPoolId: string) => [...QUERY_KEYS_OGP.flexPoolList(), { flexPoolId }],
  /** ALL flexPool related data. */
  flexPoolList: () => [...QUERY_KEYS_OGP.allOGP(), 'flex-pool-list'],
  /** ALL flexPool jobs. */
  flexPoolJobs: (flexPoolId: string) => [...QUERY_KEYS_OGP.flexPool(flexPoolId), 'jobs'],
  /** All flexPools for a flexWorker. */
  flexPoolsForJob: (jobId: string) => [...QUERY_KEYS_OGP.flexPoolList(), 'for-job', { jobId }],
  flexPoolListWithParams: (params: ParamsWithPagination<FlexPoolFilters>) => [
    ...QUERY_KEYS_OGP.allOGP(),
    'flex-pool-list',
    { params },
  ],
  flexPoolWithFlexWorkers: (flexPoolId: string) => [
    ...QUERY_KEYS_OGP.flexPoolList(),
    'with-flex-workers',
    { flexPoolId },
  ],
  flexWorkersForFlexPool: (flexPoolId: string, flexWorkerName?: string) => [
    ...QUERY_KEYS_OGP.allOGP(),
    { flexPoolId, flexWorkerName },
  ],

  /** ALL job related data. */
  jobs: () => [...QUERY_KEYS_OGP.allOGP(), 'jobs'],
  /** Jobs overview for a branch office. */
  jobList: (branchOfficeId?: string) => [...QUERY_KEYS_OGP.jobs(), { branchOfficeId }],
  jobListing: (branchOfficeId?: string) => [
    ...QUERY_KEYS_OGP.jobs(),
    { branchOfficeId },
    'listing',
  ],
  /** A single job. */
  job: (jobId: string) => [...QUERY_KEYS_OGP.jobs(), { jobId }],

  /** Break options for jobs and shifts. */
  getJobCreateData: () => [...QUERY_KEYS_OGP.allOGP(), 'getJobCreateData'],
  getShiftCreateData: () => [...QUERY_KEYS_OGP.allOGP(), 'getShiftCreateData'],
  breakOptions: () => [...QUERY_KEYS_OGP.allOGP(), 'breakOptions'],

  /** ALL shift related data. */
  shifts: () => [...QUERY_KEYS_OGP.allOGP(), 'shifts'],
  /** A single shift. */
  shift: (shiftId: string) => [...QUERY_KEYS_OGP.shifts(), { shiftId }],
  /** The shift planning. */
  shiftPlanning: (params: GetShiftPlanningListItemsParams) => [...QUERY_KEYS_OGP.shifts(), params],

  /** ALL shift partner related data. */
  shiftPartners: () => [...QUERY_KEYS_OGP.allOGP(), 'shift-partners'],
  shiftPartner: (shiftId: string) => [...QUERY_KEYS_OGP.shiftPartners(), shiftId],
  shiftEligiblePartners: (shiftId: string) => [
    ...QUERY_KEYS_OGP.shiftPartners(),
    shiftId,
    'eligible-partners',
  ],

  /** ALL checkout related data */
  checkouts: () => [...QUERY_KEYS_OGP.allOGP(), 'checkouts'],
  /** Pending checkout shift claims. */
  checkoutsPending: (params?: ParamsWithPagination<ShiftsInboxFiltersParams>) => {
    const key = [...QUERY_KEYS_OGP.checkouts(), 'pending'];
    return params ? [...key, params] : key;
  },
  /** Settled checkout shift claims. */
  checkoutsSettled: (params?: ParamsWithPagination<ShiftsOutboxFiltersParams>) => {
    const key = [...QUERY_KEYS_OGP.checkouts(), 'settled'];
    return params ? [...key, params] : key;
  },

  /** ALL user related data. */
  users: () => [...QUERY_KEYS_OGP.allOGP(), 'users'],
  /** All users overview. */
  userList: () => [...QUERY_KEYS_OGP.users(), 'list'],
  /** User data. */
  user: (userId: string) => [...QUERY_KEYS_OGP.users(), { userId }],
  /** User info. */
  userInfo: () => [...QUERY_KEYS_OGP.users(), 'user-info'],

  /** Export filters for shift claims. */
  exportFilters: () => [...QUERY_KEYS_OGP.allOGP(), 'export-filters'],

  /** Inbox filters for checkouts. */
  checkoutInboxFilters: () => [...QUERY_KEYS_OGP.allOGP(), 'checkout-inbox-filters'],
  checkoutArchiveFilters: () => [...QUERY_KEYS_OGP.allOGP(), 'checkouts-archive-filters'],
  checkoutInboxProjectsFilters: () => [...QUERY_KEYS_OGP.allOGP(), 'checkouts-filters'],

  /** Roles matrix. */
  rolesMatrix: () => [...QUERY_KEYS_OGP.allOGP(), 'roles-matrix'],

  /** Versioning */
  version: () => [...QUERY_KEYS_OGP.allOGP(), 'version'],
  noShowReasons: () => [...QUERY_KEYS_OGP.allOGP(), 'no-show-reasons'],
  statistics: () => ['statistics'],
  allProjects: () => [...QUERY_KEYS_OGP.allOGP(), 'projects'],
  departmentProjects: (departmentId?: string) => [
    ...QUERY_KEYS_OGP.allProjects(),
    { departmentId },
  ],
  projectDetail: (projectId: string) => [...QUERY_KEYS_OGP.allProjects(), { projectId }],
  projectsCheckouts: (params?: FirstParamOfFn<typeof projectService.getCheckouts>) =>
    [...QUERY_KEYS_OGP.allProjects(), 'checkouts', params].filter(Boolean),
  projectsCheckoutsArchive: (params?: FirstParamOfFn<typeof projectService.getCheckoutsArchive>) =>
    [...QUERY_KEYS_OGP.allProjects(), 'checkouts', 'archive', params].filter(Boolean),
  projectWorkers: () => [...QUERY_KEYS_OGP.allProjects(), 'workers'],

  notWelcomeList: () => ['not-welcome-list'],
  notWelcomeListAvailable: () => [...QUERY_KEYS_OGP.notWelcomeList(), 'available'],

  workApplicationsForProject: (projectId: string) => [
    ...QUERY_KEYS_OGP.allOGP(),
    'project-work-applications',
    { projectId },
  ],

  /** Accreditations */
  accreditations: () => [...QUERY_KEYS_OGP.allOGP(), 'accreditations'],

  /** Shared */
  businessUnits: () => [...QUERY_KEYS_OGP.allOGP(), 'business-units'],
  jobTypes: () => [...QUERY_KEYS_OGP.allOGP(), 'job-types'],
} as const;
