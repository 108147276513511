import { FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';
import { OgpApiService } from './ogp-api-service';

type BulkAcceptShiftApplicationsPayload = {
  flexWorkerId: string;
  shiftApplicationIds: string[];
};

type BulkAcceptShiftApplicationsResult =
  | { status: 'success'; shiftId: string }
  | { status: 'failure'; error: unknown; shiftId: string };

type BulkAcceptShiftApplicationsFn = (
  payload: BulkAcceptShiftApplicationsPayload
) => Promise<BulkAcceptShiftApplicationsResult[]>;

export class ShiftApplicationService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  acceptShiftApplication = async (
    shiftApplicationId: string,
    shiftId: string,
    flexWorkerId: string
  ) => {
    await this.ogpApiService.update({
      path: `shift-applications/accept/${shiftApplicationId}`,
      body: { shiftId, flexWorkerId },
    });
  };

  bulkAcceptShiftApplications: BulkAcceptShiftApplicationsFn = async (payload) =>
    this.ogpApiService.update({
      path: 'shift-applications/bulk/accept',
      body: payload,
    });

  denyShiftApplication = async (shiftApplicationId: string, flexWorkerId: string) => {
    await this.ogpApiService.update({
      path: `shift-applications/deny/${shiftApplicationId}`,
      body: { flexWorkerId },
    });
  };

  /**
   * Get FlexWorker Profile through ShiftApplication
   **/
  getFlexWorkerProfileThroughShiftApplication = async (
    shiftApplicationId: string,
    flexWorkerId: string
  ) => {
    const response = await this.ogpApiService.get<FlexWorkerProfileData>({
      path: `shift-applications/${shiftApplicationId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
