import { FlexWorkerProfileData } from 'types/flexWorkers/FlexWorkerTypes';
import { HqApiService } from './hqApiService';

type BulkAcceptShiftApplicationsPayload = {
  flexWorkerId: string;
  shiftApplicationIds: string[];
};

type BulkAcceptShiftApplicationsResult =
  | { status: 'success'; shiftId: string }
  | { status: 'failure'; error: unknown; shiftId: string };

class ShiftApplicationService {
  constructor(private readonly hqApiService: HqApiService) {}

  getFlexWorkerProfileThroughShiftApplication = async (
    shiftApplicationId: string,
    flexWorkerId: string
  ) => {
    const response = await this.hqApiService.get<FlexWorkerProfileData>({
      path: `shift-applications/${shiftApplicationId}/profile/${flexWorkerId}`,
    });
    return response;
  };

  acceptShiftApplication = async (
    shiftApplicationId: string,
    shiftId: string,
    flexWorkerId: string
  ) => {
    await this.hqApiService.update({
      path: `shift-applications/accept/${shiftApplicationId}`,
      body: { shiftId, flexWorkerId },
    });
  };

  bulkAcceptShiftApplications = async (
    payload: BulkAcceptShiftApplicationsPayload
  ): Promise<BulkAcceptShiftApplicationsResult[]> =>
    this.hqApiService.update({
      path: 'shift-applications/bulk/accept',
      body: payload,
    });

  denyShiftApplication = async (shiftApplicationId: string, flexWorkerId: string) => {
    await this.hqApiService.update({
      path: `shift-applications/deny/${shiftApplicationId}`,
      body: { flexWorkerId },
    });
  };
}

export { ShiftApplicationService };
