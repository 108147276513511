import React from 'react';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import useGeocode from 'ogp/hooks/queryHooks/locations/useGeocode';
import { Autocomplete } from '@mui/material';

/**
 * @param potentialAddresses list of addresses to be used as initial value. Should any of the values be undefined or empty string, it will be skipped.
 */
const useLocationAutocomplete = (...potentialAddresses: (string | undefined)[]) => {
  const initialAddress =
    potentialAddresses.find((addr) => addr !== undefined && addr.length > 0) ?? '';

  const [locationInputValue, setLocationInputValue] = React.useState(initialAddress);
  const debouncedInput = useDebounce(locationInputValue, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== initialAddress);
  const { data, status } = geoQuery;

  const handleInputChange: React.ComponentProps<typeof Autocomplete>['onInputChange'] = (
    _event,
    value,
    reason
  ) => {
    if (reason === 'reset' && value === '') {
      return;
    }
    setLocationInputValue(value);
  };

  React.useEffect(() => {
    setLocationInputValue(initialAddress);
  }, [initialAddress]);

  return {
    suggestions: data ?? [],
    loading: status === 'loading',
    locationInputValue,
    handleInputChange,
  };
};

export { useLocationAutocomplete };
