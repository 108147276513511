import { Box, styled, Tabs } from '@mui/material';
import { useState } from 'react';
import { LwTab } from 'redesign/tab';
import { ModalPanelBodyProps } from './modal.types';

export const ModalPanelBody = ({ tabs }: ModalPanelBodyProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0} flex="0 1 auto">
      <FlexContainer
        value={tabs[selectedTab].id}
        onChange={(_, tabId) => {
          const selectedTab = tabs.findIndex((i) => i.id === tabId);

          setSelectedTab(selectedTab);
        }}
      >
        {tabs.map((tab) => {
          return <LwTab key={tab.id} value={tab.id} label={tab.tabLabel} />;
        })}
      </FlexContainer>
      <ContentWrapper
        role="tabpanel"
        sx={{
          '::-webkit-scrollbar': {
            width: '22px',
          },
          '::-webkit-scrollbar-track': {
            background: 'rgba(0, 0, 0, 0.1)',
            border: '8px solid white',
            borderRadius: '12px',
          },

          '::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.2)',
            backgroundClip: 'content-box',
            border: '8px solid transparent',
            borderRadius: '12px',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.4)',
              backgroundClip: 'content-box',
              border: '8px solid transparent',
              borderRadius: '12px',
            },
          },
        }}
      >
        {tabs[selectedTab].element}
      </ContentWrapper>
    </Box>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.spacing(12)})`,
  position: 'relative',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
}));

const FlexContainer = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    gap: theme.spacing(8),
  },
}));
