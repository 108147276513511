import { WorkerForWork } from 'types/flexWorkers/FlexWorkerTypes';
import { FlexworkerColumnDefinition } from '../flex-workers-for-work-table.types';

export const getFlexworkerTableData = (
  flexWorkers: WorkerForWork[],
  entityId: string
): FlexworkerColumnDefinition[] => {
  return flexWorkers.map((fw) => {
    return {
      ...fw,
      flexWorkerImage: {
        ...fw.flexWorkerImage,
        id: fw.flexWorkerId,
      },
      actions: {
        entityId,
        flexWorkerId: fw.flexWorkerId,
        flexWorkerName: fw.flexWorkerFullName,
      },
      availability: fw.availability,
      experienceInMonths: fw.experienceInMonths,
    };
  });
};
