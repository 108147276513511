import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign/forms/number-input';

type MaxClaimantsFields = {
  maxClaimants: number;
};

export const MaxClaimantsElement = ({ disabled }: { disabled: boolean }) => {
  const { control } = useFormContext<MaxClaimantsFields>();
  return (
    <LwFormNumberInput
      name="maxClaimants"
      label="Aantal plekken"
      control={control}
      rules={{ required: 'Vul gewenste aantal plekken in' }}
      step={1}
      min={1}
      max={100}
      data-testid="max-claimants"
      disabled={disabled}
    />
  );
};
