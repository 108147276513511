import { Grid, Typography } from '@mui/material';
import { CompanyDetailAndJobGroups } from 'types/companies/company-types';
import { styled } from '@mui/material/styles';

type Link = {
  name: string;
  url: string | undefined;
};

type CompanyDetailInformationProps = {
  data: CompanyDetailAndJobGroups;
  links: Link[];
};

const CompanyDetailInformation = ({ data, links }: CompanyDetailInformationProps) => (
  <Grid container spacing={2} role="tabpanel">
    <MyGrid item xs={8} md={5} lg={2} style={{ gap: '1rem' }}>
      {links.map((link) => (
        <Grid item key={link.name}>
          <Typography variant="subtitle2" color="secondary" noWrap>
            {link.name}
          </Typography>
          <Typography variant="body1" noWrap>
            {link.url ? (
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.url}
              </a>
            ) : (
              'Nog geen link'
            )}
          </Typography>
        </Grid>
      ))}
      <Grid item>
        <Typography variant="subtitle2" color="secondary" noWrap>
          Over het bedrijf
        </Typography>
        <Typography variant="body1">
          {data.company.description ?? 'Nog geen beschrijving'}
        </Typography>
      </Grid>
    </MyGrid>

    <MyGrid item xs={4} md={6} style={{ gap: '2rem' }}>
      <MyGrid md={4} item style={{ gap: '.5rem' }}>
        <Typography variant="subtitle2" color="secondary" noWrap>
          Profielfoto
        </Typography>
        <Picture alt="Company profile" src={data.company.profileImageUrl ?? undefined} />
      </MyGrid>
      <MyGrid item style={{ gap: '.5rem' }}>
        <MyGrid md={4} item style={{ gap: '.5rem' }}>
          <Typography variant="subtitle2" color="secondary" noWrap>
            Banner foto
          </Typography>
          <Picture alt="Banner" src={data.company.bannerImageUrl ?? undefined} />
        </MyGrid>
      </MyGrid>
    </MyGrid>
  </Grid>
);

const MyGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Picture = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(5),
  maxWidth: '100%',
}));

export { CompanyDetailInformation };
