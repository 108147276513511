import { companyService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FirstParamOfFn } from 'types/utility';
import { companyResponse2CompanyDetailModel } from 'hq/services/mappers/mappers';

type Response = Awaited<ReturnType<typeof companyService.getCompanyDetail>>;

type Params = FirstParamOfFn<typeof useGetCompanyDetail>;

const useGetCompanyDetail = <TData = Response>(
  companyId: string,
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await companyService.getCompanyDetail(companyId);

  return useQuery(QUERY_KEYS_HQ.company(companyId), queryFn, {
    enabled: companyId.length > 0,
    cacheTime: CACHE_TIME_LONG,
    select,
  });
};

const useGetCompanyAsDetail = (params: Params) => {
  const result = useGetCompanyDetail(params, companyResponse2CompanyDetailModel);
  return result;
};

export { useGetCompanyAsDetail };
